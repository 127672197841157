import axios from '../axiosConfig'
import { tokenConfig } from './auth'
import { FAIL_GET_SUSTITUTOS, FETCH_SUSTITUTOS, GET_SUSTITUTOS } from './types'

export const getArticulosSustitutos =
  (id_articulo, limit = 12) =>
  (dispatch, getState) => {
    dispatch({ type: FETCH_SUSTITUTOS, codigo_articulo: id_articulo })

    axios
      .get(
        `/articulos/sustitutos/?codigo_articulo=${id_articulo}&limit=${limit}`,
        tokenConfig(getState),
      )
      .then(res => {
        dispatch({
          type: GET_SUSTITUTOS,
          payload: res.data,
          codigo_articulo: id_articulo,
        })
      })
      .catch(error => {
        dispatch({ type: FAIL_GET_SUSTITUTOS })
        console.error(error)
      })
  }
