import axios from '../axiosConfig'
import { tokenConfig } from './auth'
import { FAIL_GET_RELACIONADOS, FETCH_RELACIONADOS, GET_RELACIONADOS } from './types'

// GET ARTICULOS
export const getArticulosRelacionados =
  (id_articulo, domicilio_envio = '', limit = 12) =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_RELACIONADOS,
      payload: true,
    })
    axios
      .get(
        `/articulos/relacionados/?codigo_articulo=${id_articulo}&domicilio_envio=${domicilio_envio}&limit=${limit}`,
        tokenConfig(getState),
      )
      .then(res => {
        dispatch({
          type: GET_RELACIONADOS,
          payload: res.data,
        })
      })
      .catch(err => {
        console.log(err)
        dispatch({ type: FAIL_GET_RELACIONADOS })
      })
  }
