export const ACTUALIZA_SUB_STOCK = 'ACTUALIZA_SUB_STOCK'
export const ACTUALIZA_SUB_STOCK_FAIL = 'ACTUALIZA_SUB_STOCK_FAIL'
export const ACTUALIZA_SUB_STOCK_SUCCESS = 'ACTUALIZA_SUB_STOCK_SUCCESS'
export const ACTUALIZAR_LINEA_LISTA = 'ACTUALIZAR_LINEA_LISTA'
export const ACTUALIZAR_LINEA_LISTA_SUCCESS = 'ACTUALIZAR_LINEA_LISTA_SUCCESS'
export const ACTUALIZAR_RESERVADO_USUARIO = 'ACTUALIZAR_RESERVADO_USUARIO'
export const ACTUALIZAR_RESERVADO_USUARIO_FAIL = 'ACTUALIZAR_RESERVADO_USUARIO_FAIL'
export const ACTUALIZAR_RESERVADO_USUARIO_SUCCESS = 'ACTUALIZAR_RESERVADO_USUARIO_SUCCESS'
export const ARCHIVE_CONVERSATION = 'ARCHIVE_CONVERSATION'
export const ARCHIVE_CONVERSATION_FAIL = 'ARCHIVE_CONVERSATION_FAIL'
export const ARCHIVE_CONVERSATION_SUCCESS = 'ARCHIVE_CONVERSATION_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const BORRA_HASH = 'BORRA_HASH'
export const CAMBIO_PASSWORD_FAIL = 'CAMBIO_PASSWORD_FAIL'
export const CAMBIO_PASSWORD_SUCCESS = 'CAMBIO_PASSWORD_SUCCESS'
export const CERRAR_EXPEDIENTE = 'CERRAR_EXPEDIENTE'
export const CERRAR_EXPEDIENTE_FAIL = 'CERRAR_EXPEDIENTE_FAIL'
export const CERRAR_EXPEDIENTE_SUCCESS = 'CERRAR_EXPEDIENTE_SUCCESS'
export const CERRAR_PEDIDO = 'CERRAR_PEDIDO'
export const CHANGE_ARTICULOS_ORDEN = 'CHANGE_ARTICULOS_ORDEN'
export const CHANGE_ARTICULOS_PAGINA = 'CHANGE_ARTICULOS_PAGINA'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const COMPROBAR_TOKEN = 'COMPROBAR_TOKEN'
export const COMPROBAR_TOKEN_FAIL = 'COMPROBAR_TOKEN_FAIL'
export const COMPROBAR_TOKEN_SUCCESS = 'COMPROBAR_TOKEN_SUCCESS'
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'
export const CREAR_SUB_STOCK = 'CREAR_SUB_STOCK'
export const CREAR_SUB_STOCK_FAIL = 'CREAR_SUB_STOCK_FAIL'
export const CREAR_SUB_STOCK_SUCCESS = 'CREAR_SUB_STOCK_SUCCESS'
export const CREATE_LISTA_COMPRA = 'CREATE_LISTA_COMPRA'
export const CREATE_LISTA_COMPRA_SUCCESS = 'CREATE_LISTA_COMPRA_SUCCESS'
export const DELETE_CONVERSATION = 'DELETE_CONVERSATION'
export const DELETE_CONVERSATION_FAIL = 'DELETE_CONVERSATION_FAIL'
export const DELETE_CONVERSATION_SUCCESS = 'DELETE_CONVERSATION_SUCCESS'
export const DELETE_LINEA_LISTA_COMPRA = 'DELETE_LINEA_LISTA_COMPRA'
export const DELETE_LINEA_LISTA_COMPRA_SUCCESS = 'DELETE_LINEA_LISTA_COMPRA_SUCCESS'
export const DELETE_LINEA_PEDIDO = 'DELETE_LINEA_PEDIDO'
export const DELETE_LISTA_COMPRA = 'DELETE_LISTA_COMPRA'
export const DELETE_LISTA_COMPRA_SUCCESS = 'DELETE_LISTA_COMPRA_SUCCESS'
export const DELETE_PEDIDO = 'DELETE_PEDIDO'
export const DELETE_PEDIDO_PENDIENTE = 'DELETE_PEDIDO_PENDIENTE'
export const DESCARGA_EFACTURA = 'DESCARGA_EFACTURA'
export const DESCARGA_REPORT = 'DESCARGA_REPORT'
export const DESCARGAR_FICHERO_EXPEDIENTE = 'DESCARGAR_FICHERO_EXPEDIENTE'
export const ENVIAR_CONTACTO = 'ENVIAR_CONTACTO'
export const ENVIAR_CONTACTO_FAIL = 'ENVIAR_CONTACTO_FAIL'
export const ENVIAR_CONTACTO_SUCCESS = 'ENVIAR_CONTACTO_SUCCESS'
export const ENVIAR_REGISTRO = 'ENVIAR_REGISTRO'
export const ENVIAR_REGISTRO_FAIL = 'ENVIAR_REGISTRO_FAIL'
export const ENVIAR_REGISTRO_HIJO = 'ENVIAR_REGISTRO_HIJO'
export const ENVIAR_REGISTRO_HIJO_FAIL = 'ENVIAR_REGISTRO_HIJO_FAIL'
export const ENVIAR_REGISTRO_HIJO_SUCCESS = 'ENVIAR_REGISTRO_HIJO_SUCCESS'
export const ENVIAR_REGISTRO_SUCCESS = 'ENVIAR_REGISTRO_SUCCESS'
export const FETCH_AGRUP_COND = 'FETCH_AGRUP_COND'
export const FETCH_AGRUP_HIJOS = 'FETCH_AGRUP_HIJOS'
export const FETCH_ALMACENES = 'FETCH_ALMACENES'
export const FETCH_ARTICULO = 'FETCH_ARTICULO'
export const FETCH_ARTICULOS = 'FETCH_ARTICULOS'
export const FETCH_ARTICULOS_AUX = 'FETCH_ARTICULOS_AUX'
export const FETCH_ARTICULOS_BUSC_RAP = 'FETCH_ARTICULOS_BUSC_RAP'
export const FETCH_ARTICULOS_DOC = 'FETCH_ARTICULOS_DOC'
export const FETCH_AVISOS = 'FETCH_AVISOS'
export const FETCH_BANNERS = 'FETCH_BANNERS'
export const FETCH_CADENA_LOGISTICA = 'FETCH_CADENA_LOGISTICA'
export const FETCH_CANTIDAD_STOCK = 'FETCH_CANTIDAD_STOCK'
export const FETCH_CARACTERISTICAS_ARTICULO = 'FETCH_CARACTERISTICAS_ARTICULO'
export const FETCH_CARNETS = 'FETCH_CARNETS'
export const FETCH_CODIGOS_BARRAS = 'FETCH_CODIGOS_BARRAS'
export const FETCH_COMUNIDADES_AUTONOMAS = 'FETCH_COMUNIDADES_AUTONOMAS'
export const FETCH_DOM_ENVIO = 'FETCH_DOM_ENVIO'
export const FETCH_ESTADO = 'FETCH_ESTADO'
export const FETCH_ESTADOS = 'FETCH_ESTADOS'
export const FETCH_EXPEDIENTES = 'FETCH_EXPEDIENTES'
export const FETCH_FESTIVOS = 'FETCH_FESTIVOS'
export const FETCH_FILTROS = 'FETCH_FILTROS'
export const FETCH_FORMAS_ENVIO = 'FETCH_FORMAS_ENVIO'
export const FETCH_FORMAS_PAGO = 'FETCH_FORMAS_PAGO'
export const FETCH_IMAGENES_FAMILIAS = 'FETCH_IMAGENES_FAMILIAS'
export const FETCH_IMG_BANNER = 'FETCH_IMG_BANNER'
export const FETCH_INFO_PEDIDO = 'FETCH_INFO_PEDIDO'
export const FETCH_LANGUAGES = 'FETCH_LANGUAGES'
export const FETCH_LINEAS_PEDIDO_ACTIVO = 'FETCH_LINEAS_PEDIDO_ACTIVO'
export const FETCH_LINEAS_PEDIDO_ACTIVO_FAIL = 'FETCH_LINEAS_PEDIDO_ACTIVO_FAIL'
export const FETCH_LINEAS_PEDIDO_ACTIVO_SUCCESS = 'FETCH_LINEAS_PEDIDO_ACTIVO_SUCCESS'
export const FETCH_MAS_ARTICULOS = 'FETCH_MAS_ARTICULOS'
export const FETCH_MENU_FAMILIAS = 'FETCH_MENU_FAMILIAS'
export const FETCH_MENU_INFORMES = 'FETCH_MENU_INFORMES'
export const FETCH_MIGAS_ARTICULO = 'FETCH_MIGAS_ARTICULO'
export const FETCH_MODULAR = 'FETCH_MODULAR'
export const FETCH_MODULAR_DET = 'FETCH_MODULAR_DET'
export const FETCH_NOVEDADES = 'FETCH_NOVEDADES'
export const FETCH_OFERTAS = 'FETCH_OFERTAS'
export const FETCH_OFERTAS_MULTIPLES = 'FETCH_OFERTAS_MULTIPLES'
export const FETCH_PEDIDO_ACTIVO = 'FETCH_PEDIDO_ACTIVO'
export const FETCH_PEDIDO_ACTIVO_FAIL = 'FETCH_PEDIDO_ACTIVO_FAIL'
export const FETCH_PEDIDO_ACTIVO_SUCCESS = 'FETCH_PEDIDO_ACTIVO_SUCCESS'
export const FETCH_PEDIDO_ANTERIOR = 'FETCH_PEDIDO_ANTERIOR'
export const FETCH_PEDIDO_HABITUAL = 'FETCH_PEDIDO_HABITUAL'
export const FETCH_PEDIDOS_ANTERIORES = 'FETCH_PEDIDOS_ANTERIORES'
export const FETCH_PEDIDOS_PENDIENTES = 'FETCH_PEDIDOS_PENDIENTES'
export const FETCH_PERMISOS_MENU_INFORMES = 'FETCH_PERMISOS_MENU_INFORMES'
export const FETCH_PESTANAS = 'FETCH_PESTANAS'
export const FETCH_PRESENTACIONES = 'FETCH_PRESENTACIONES'
export const FETCH_PROVINCIA = 'FETCH_PROVINCIA'
export const FETCH_PROVINCIAS = 'FETCH_PROVINCIAS'
export const FETCH_RUTAS = 'FETCH_RUTAS'
export const FETCH_RUTAS_DOM = 'FETCH_RUTAS_DOM'
export const FETCH_STOCK = 'FETCH_STOCK'
export const FETCH_STOCKS = 'FETCH_STOCKS'
export const FETCH_STOCK_ARTICULO = 'FETCH_STOCK_ARTICULO'
export const FETCH_STOCKS_ARTICULOS = 'FETCH_STOCKS_ARTICULOS'
export const FETCH_STOCKS_ARTICULOS_LISTA = 'FETCH_STOCKS_ARTICULOS_LISTA'
export const FETCH_STOCKS_PEDIDOS_ANTERIORES = 'FETCH_STOCKS_PEDIDOS_ANTERIORES'
export const FETCH_SUB_PRESENTACIONES = 'FETCH_SUB_PRESENTACIONES'
export const FETCH_SUBSCRIPCIONES_STOCK = 'FETCH_SUBSCRIPCIONES_STOCK'
export const FETCH_TARIFAS_ENVIO_PORTES = 'FETCH_TARIFAS_ENVIO_PORTES'
export const FETCH_TEXTOS_VENTAS = 'FETCH_TEXTOS_VENTAS'
export const FETCH_TIPOS_EXPEDIENTES = 'FETCH_TIPOS_EXPEDIENTES'
export const FETCH_TIPOS_EXPEDIENTES_CAMPOS = 'FETCH_TIPOS_EXPEDIENTES_CAMPOS'
export const FETCH_TIPOS_NIF = 'FETCH_TIPOS_NIF'
export const FETCH_TIPOS_PEDIDO = 'FETCH_TIPOS_PEDIDO'
export const FETCH_TIRAS = 'FETCH_TIRAS'
export const FETCH_TIRAS_DET = 'FETCH_TIRAS_DET'
export const FETCH_TRANSPORTISTAS = 'FETCH_TRANSPORTISTAS'
export const FETCH_USUARIOS_HIJOS = 'FETCH_USUARIOS_HIJOS'
export const FETCH_WIDGETS = 'FETCH_WIDGETS'
export const FLUSH_ARTICULO = 'FLUSH_ARTICULO'
export const FLUSH_PEDIDOS_ANTERIORES = 'FLUSH_PEDIDOS_ANTERIORES'
export const FLUSH_PEDIDOS_PENDIENTES = 'FLUSH_PEDIDOS_PENDIENTES'
export const GENERA_PAGO_PASARELA = 'GENERA_PAGO_PASARELA'
export const GENERA_PAGO_PASARELA_SUCCESS = 'GENERA_PAGO_PASARELA_SUCCESS'
export const GENERA_PEDIDO_INFORME = 'GENERA_PEDIDO_INFORME'
export const GENERA_PEDIDO_INFORME_FAIL = 'GENERA_PEDIDO_INFORME_FAIL'
export const GENERA_PEDIDO_INFORME_SUCCESS = 'GENERA_PEDIDO_INFORME_SUCCESS'
export const GENERA_PEDIDO_LISTA = 'GENERA_PEDIDO_LISTA'
export const GENERA_PEDIDO_LISTA_FAIL = 'GENERA_PEDIDO_LISTA_FAIL'
export const GENERA_PEDIDO_LISTA_SUCCESS = 'GENERA_PEDIDO_LISTA_SUCCESS'
export const GENERAR_PEDIDO_PEDIDO_ANTERIOR = 'GENERAR_PEDIDO_PEDIDO_ANTERIOR'
export const GENERAR_PEDIDO_PEDIDO_ANTERIOR_SUCCESS = 'GENERAR_PEDIDO_PEDIDO_ANTERIOR_SUCCESS'
export const GET_AGRUP_COND = 'GET_AGRUP_COND'
export const GET_AGRUP_HIJOS = 'GET_AGRUP_HIJOS'
export const GET_ALMACENES = 'GET_ALMACENES'
export const GET_ARCHIVED_CONVERSATIONS = 'GET_ARCHIVED_CONVERSATIONS'
export const GET_ARCHIVED_CONVERSATIONS_FAIL = 'GET_ARCHIVED_CONVERSATIONS_FAIL'
export const GET_ARCHIVED_CONVERSATIONS_SUCCESS = 'GET_ARCHIVED_CONVERSATIONS_SUCCESS'
export const GET_ARTICULO = 'GET_ARTICULO'
export const GET_ARTICULO_FAIL = 'GET_ARTICULO_FAIL'
export const GET_ARTICULO_SUCCESS = 'GET_ARTICULO_SUCCESS'
export const GET_ARTICULOS = 'GET_ARTICULOS'
export const GET_ARTICULOS_AUX = 'GET_ARTICULOS_AUX'
export const GET_ARTICULOS_DOC = 'GET_ARTICULOS_DOC'
export const GET_AVISOS = 'GET_AVISOS'
export const GET_BANNERS = 'GET_BANNERS'
export const GET_CADENA_LOGISTICA = 'GET_CADENA_LOGISTICA'
export const GET_CANTIDAD_STOCK = 'GET_CANTIDAD_STOCK'
export const GET_CARACTERISTICAS_ARTICULO = 'GET_CARACTERISTICAS_ARTICULO'
export const GET_CARNETS = 'GET_CARNETS'
export const GET_CARNETS_FAIL = 'GET_CARNETS_FAIL'
export const GET_CARNETS_SUCCESS = 'GET_CARNETS_SUCCESS'
export const GET_CODIGOS_BARRAS = 'GET_CODIGOS_BARRAS'
export const GET_COMUNIDADES_AUTONOMAS = 'GET_COMUNIDADES_AUTONOMAS'
export const GET_DATOS_EXPEDIENTE = 'GET_DATOS_EXPEDIENTE'
export const GET_DATOS_EXPEDIENTE_FAIL = 'GET_DATOS_EXPEDIENTE_FAIL'
export const GET_DATOS_EXPEDIENTE_SUCCESS = 'GET_DATOS_EXPEDIENTE_SUCCESS'
export const GET_DATOS_FICHEROS_EXPEDIENTE = 'GET_DATOS_FICHEROS_EXPEDIENTE'
export const GET_DATOS_FICHEROS_EXPEDIENTE_FAIL = 'GET_DATOS_FICHEROS_EXPEDIENTE_FAIL'
export const GET_DATOS_FICHEROS_EXPEDIENTE_SUCCESS = 'GET_DATOS_FICHEROS_EXPEDIENTE_SUCCESS'
export const GET_DATOS_INFORME = 'GET_DATOS_INFORME'
export const GET_DATOS_INFORME_FAIL = 'GET_DATOS_INFORME_FAIL'
export const GET_DATOS_INFORME_SUCCESS = 'GET_DATOS_INFORME_SUCCESS'
export const GET_DELETED_CONVERSATIONS = 'GET_DELETED_CONVERSATIONS'
export const GET_DELETED_CONVERSATIONS_FAIL = 'GET_DELETED_CONVERSATIONS_FAIL'
export const GET_DELETED_CONVERSATIONS_SUCCESS = 'GET_DELETED_CONVERSATIONS_SUCCESS'
export const GET_DESCRIPCIONES = 'GET_DESCRIPCIONES'
export const GET_DESCRIPCIONES_FAIL = 'GET_DESCRIPCIONES_FAIL'
export const GET_DESCRIPCIONES_SUCCESS = 'GET_DESCRIPCIONES_SUCCESS'
export const GET_DOM_ENVIO = 'GET_DOM_ENVIO'
export const GET_EFACTURAS_COMPRAS = 'GET_EFACTURAS_COMPRAS'
export const GET_EFACTURAS_COMPRAS_FAILURE = 'GET_EFACTURAS_COMPRAS_FAILURE'
export const GET_EFACTURAS_COMPRAS_SUCCESS = 'GET_EFACTURAS_COMPRAS_SUCCESS'
export const GET_EFACTURAS_VENTAS = 'GET_EFACTURAS_VENTAS'
export const GET_EFACTURAS_VENTAS_FAILURE = 'GET_EFACTURAS_VENTAS_FAILURE'
export const GET_EFACTURAS_VENTAS_SUCCESS = 'GET_EFACTURAS_VENTAS_SUCCESS'
export const GET_ESTADO = 'GET_ESTADO'
export const GET_ESTADOS = 'GET_ESTADOS'
export const GET_EXPEDIENTES = 'GET_EXPEDIENTES'
export const GET_EXPEDIENTES_FAIL = 'GET_EXPEDIENTES_FAIL'
export const GET_EXPEDIENTES_SUCCESS = 'GET_EXPEDIENTES_SUCCESS'
export const GET_FESTIVOS = 'GET_FESTIVOS'
export const GET_FESTIVOS_FAIL = 'GET_FESTIVOS_FAIL'
export const GET_FILTROS = 'GET_FILTROS'
export const GET_FILTROS_PLUGINS_INFORME = 'GET_FILTROS_PLUGINS_INFORME'
export const GET_FORMAS_ENVIO = 'GET_FORMAS_ENVIO'
export const GET_FORMAS_PAGO = 'GET_FORMAS_PAGO'
export const GET_GALERIA = 'GET_GALERIA'
export const GET_GALERIA_FAIL = 'GET_GALERIA_FAIL'
export const GET_GALERIA_SUCCESS = 'GET_GALERIA_SUCCESS'
export const GET_IMAGEN = 'GET_IMAGEN'
export const GET_IMAGEN_FAIL = 'GET_IMAGEN_FAIL'
export const GET_IMAGEN_SUCCESS = 'GET_IMAGEN_SUCCESS'
export const GET_IMAGENES_FAMILIAS = 'GET_IMAGENES_FAMILIAS'
export const GET_IMG_BANNER = 'GET_IMG_BANNER'
export const GET_INFO_PEDIDO = 'GET_INFO_PEDIDO'
export const GET_INFO_PEDIDO_FAIL = 'GET_INFO_PEDIDO_FAIL'
export const GET_LANGUAGES = 'GET_LANGUAGES'
export const GET_LINEAS_EXPEDIENTE = 'GET_LINEAS_EXPEDIENTE'
export const GET_LINEAS_EXPEDIENTE_FAIL = 'GET_LINEAS_EXPEDIENTE_FAIL'
export const GET_LINEAS_EXPEDIENTE_SUCCESS = 'GET_LINEAS_EXPEDIENTE_SUCCESS'
export const GET_LOV_EXPEDIENTE = 'GET_LOV_EXPEDIENTE'
export const GET_LOV_EXPEDIENTE_FAIL = 'GET_LOV_EXPEDIENTE_FAIL'
export const GET_LOV_EXPEDIENTE_SUCCESS = 'GET_LOV_EXPEDIENTE_SUCCESS'
export const GET_MAS_ARTICULOS = 'GET_MAS_ARTICULOS'
export const GET_MAS_DATOS_INFORME = 'GET_MAS_DATOS_INFORME'
export const GET_MAS_EFACTURAS_COMPRAS = 'GET_MAS_EFACTURAS_COMPRAS'
export const GET_MAS_EFACTURAS_COMPRAS_FAILURE = 'GET_MAS_EFACTURAS_COMPRAS_FAILURE'
export const GET_MAS_EFACTURAS_COMPRAS_SUCCESS = 'GET_MAS_EFACTURAS_COMPRAS_SUCCESS'
export const GET_MAS_EFACTURAS_VENTAS = 'GET_MAS_EFACTURAS_VENTAS'
export const GET_MAS_EFACTURAS_VENTAS_FAILURE = 'GET_MAS_EFACTURAS_VENTAS_FAILURE'
export const GET_MAS_EFACTURAS_VENTAS_SUCCESS = 'GET_MAS_EFACTURAS_VENTAS_SUCCESS'
export const GET_MAS_EXPEDIENTES = 'GET_MAS_EXPEDIENTES'
export const GET_MAS_EXPEDIENTES_FAIL = 'GET_MAS_EXPEDIENTES_FAIL'
export const GET_MENU_FAMILIAS = 'GET_MENU_FAMILIAS'
export const GET_MENU_INFORMES = 'GET_MENU_INFORMES'
export const GET_MENUS = 'GET_MENUS'
export const GET_MENUS_FAIL = 'GET_MENUS_FAIL'
export const GET_MENUS_SUCCESS = 'GET_MENUS_SUCCESS'
export const GET_MIGAS_ARTICULO = 'GET_MIGAS_ARTICULO'
export const GET_MODULAR = 'GET_MODULAR'
export const GET_MODULAR_DET = 'GET_MODULAR_DET'
export const GET_NOVEDADES = 'GET_NOVEDADES'
export const GET_OBSERVACIONES_EXPEDIENTE = 'GET_OBSERVACIONES_EXPEDIENTE'
export const GET_OBSERVACIONES_EXPEDIENTE_FAIL = 'GET_OBSERVACIONES_EXPEDIENTE_FAIL'
export const GET_OBSERVACIONES_EXPEDIENTE_SUCCESS = 'GET_OBSERVACIONES_EXPEDIENTE_SUCCESS'
export const GET_OFERTAS = 'GET_OFERTAS'
export const GET_OFERTAS_MULTIPLES = 'GET_OFERTAS_MULTIPLES'
export const GET_ORGANIZACIONES_COMPRAS = 'GET_ORGANIZACIONES_COMPRAS'
export const GET_ORGANIZACIONES_COMPRAS_FAILURE = 'GET_ORGANIZACIONES_COMPRAS_FAILURE'
export const GET_ORGANIZACIONES_COMPRAS_SUCCESS = 'GET_ORGANIZACIONES_COMPRAS_SUCCESS'
export const GET_PEDIDO_ACTIVO = 'GET_PEDIDO_ACTIVO'
export const GET_PEDIDO_ANTERIOR = 'GET_PEDIDO_ANTERIOR'
export const GET_PEDIDO_HABITUAL = 'GET_PEDIDO_HABITUAL'
export const GET_PEDIDOS_ANTERIORES = 'GET_PEDIDOS_ANTERIORES'
export const GET_PEDIDOS_PENDIENTES = 'GET_PEDIDOS_PENDIENTES'
export const GET_PEDIDOS_PENDIENTES_FAIL = 'GET_PEDIDOS_PENDIENTES_FAIL'
export const GET_PERMISOS_MENU_INFORMES = 'GET_PERMISOS_MENU_INFORMES'
export const GET_PESTANAS = 'GET_PESTANAS'
export const GET_PORTAL = 'GET_PORTAL'
export const GET_PORTAL_FAIL = 'GET_PORTAL_FAIL'
export const GET_PORTAL_SUCCESS = 'GET_PORTAL_SUCCESS'
export const GET_PRECIO = 'GET_PRECIO'
export const GET_PRECIO_FAIL = 'GET_PRECIO_FAIL'
export const GET_PRECIO_SUCCESS = 'GET_PRECIO_SUCCESS'
export const GET_PRECIOS = 'GET_PRECIOS'
export const GET_PRECIOS_FAIL = 'GET_PRECIOS_FAIL'
export const GET_PRECIOS_SUCCESS = 'GET_PRECIOS_SUCCESS'
export const GET_PRESENTACIONES = 'GET_PRESENTACIONES'
export const GET_PROVINCIA = 'GET_PROVINCIA'
export const GET_PROVINCIAS = 'GET_PROVINCIAS'
export const GET_RUTAS = 'GET_RUTAS'
export const GET_RUTAS_DOM = 'GET_RUTAS_DOM'
export const GET_STOCK = 'GET_STOCK'
export const GET_STOCK_FAIL = 'GET_STOCK_FAIL'
export const GET_STOCK_SUCCESS = 'GET_STOCK_SUCCESS'
export const GET_STOCKS = 'GET_STOCKS'
export const GET_STOCKS_FAIL = 'GET_STOCKS_FAIL'
export const GET_STOCKS_SUCCESS = 'GET_STOCKS_SUCCESS'
export const GET_STOCK_ARTICULO = 'GET_STOCK_ARTICULO'
export const LIMPIA_STOCK_ARTICULOS_AGRUPA = 'LIMPIA_STOCK_ARTICULOS_AGRUPA'
export const GET_STOCKS_ARTICULOS_AGRUPA = 'GET_STOCKS_ARTICULOS_AGRUPA'
export const GET_STOCK_ARTICULO_FICHA = 'GET_STOCK_ARTICULO_FICHA'
export const GET_STOCK_ARTICULO_LISTA = 'GET_STOCK_ARTICULO_LISTA'
export const GET_STOCKS_ARTICULOS = 'GET_STOCKS_ARTICULOS'
export const GET_STOCKS_ARTICULOS_LISTA = 'GET_STOCKS_ARTICULOS_LISTA'
export const GET_STOCKS_PEDIDOS_ANTERIORES = 'GET_STOCKS_PEDIDOS_ANTERIORES'
export const GET_SUB_PRESENTACIONES = 'GET_SUB_PRESENTACIONES'
export const GET_SUBSCRIPCIONES_STOCK = 'GET_SUBSCRIPCIONES_STOCK'
export const GET_SUBSCRIPCIONES_STOCK_FAIL = 'GET_SUBSCRIPCIONES_STOCK_FAIL'
export const GET_SUBSCRIPCIONES_STOCK_SUCCESS = 'GET_SUBSCRIPCIONES_STOCK_SUCCESS'
export const GET_TARIFAS_ENVIO_PORTES = 'GET_TARIFAS_ENVIO_PORTES'
export const GET_TEXTOS_VENTAS = 'GET_TEXTOS_VENTAS'
export const GET_TIPOS_EXPEDIENTES = 'GET_TIPOS_EXPEDIENTES'
export const GET_TIPOS_EXPEDIENTES_CAMPOS = 'GET_TIPOS_EXPEDIENTES_CAMPOS'
export const GET_TIPOS_EXPEDIENTES_CAMPOS_FAIL = 'GET_TIPOS_EXPEDIENTES_CAMPOS_FAIL'
export const GET_TIPOS_EXPEDIENTES_FAIL = 'GET_TIPOS_EXPEDIENTES_FAIL'
export const GET_TIPOS_NIF = 'GET_TIPOS_NIF'
export const GET_TIPOS_PEDIDO = 'GET_TIPOS_PEDIDO'
export const GET_TIRAS = 'GET_TIRAS'
export const GET_TIRAS_DET = 'GET_TIRAS_DET'
export const GET_TRANSPORTISTAS = 'GET_TRANSPORTISTAS'
export const GET_USUARIOS_HIJOS = 'GET_USUARIOS_HIJOS'
export const GET_USUARIOS_HIJOS_FAIL = 'GET_USUARIOS_HIJOS_FAIL'
export const GET_WIDGETS = 'GET_WIDGETS'
export const HIDE_LOADER = 'HIDE_LOADER'
export const INTEGRANDO_PEDIDO = 'INTEGRANDO_PEDIDO'
export const INTEGRAR_PEDIDO = 'INTEGRAR_PEDIDO'
export const INTEGRAR_PEDIDO_FAIL = 'INTEGRAR_PEDIDO_FAIL'
export const LIMPIAR_BUSQUEDA_AVANZADA = 'LIMPIAR_BUSQUEDA_AVANZADA'
export const LIMPIAR_CLAVES_SELECCIONADAS = 'LIMPIAR_CLAVES_SELECCIONADAS'
export const LIMPIAR_FILTROS = 'LIMPIAR_FILTROS'
export const LIMPIAR_FILTROS_EXPEDIENTES = 'LIMPIAR_FILTROS_EXPEDIENTES'
export const LIMPIAR_GALERIA = 'LIMPIAR_GALERIA'
export const LIMPIAR_HIJO_SELECCIONADO = 'LIMPIAR_HIJO_SELECCIONADO'
export const LIMPIAR_HIJOS = 'LIMPIAR_HIJOS'
export const LIMPIAR_STOCKS = 'LIMPIAR_STOCKS'
export const LIMPIAR_PRECIOS = 'LIMPIAR_PRECIOS'
export const LIMPIAR_PRESENTACION = 'LIMPIAR_PRESENTACION'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const MARCAR_LEIDO = 'MARCAR_LEIDO'
export const MARCAR_LEIDO_SUCCESS = 'MARCAR_LEIDO_SUCCESS'
export const MARCAR_LEIDOS = 'MARCAR_LEIDOS'
export const MARCAR_LEIDOS_FAIL = 'MARCAR_LEIDOS_FAIL'
export const MARCAR_LEIDOS_SUCCESS = 'MARCAR_LEIDOS_SUCCESS'
export const NEW_EXPEDIENTE = 'NEW_EXPEDIENTE'
export const NEW_EXPEDIENTE_FAIL = 'NEW_EXPEDIENTE_FAIL'
export const NEW_EXPEDIENTE_SUCCESS = 'NEW_EXPEDIENTE_SUCCESS'
export const NEW_RECIPE = 'NEW_RECIPE'
export const OBTENER_EFACTURA = 'OBTENER_EFACTURA'
export const OBTENER_FICHERO_EXPEDIENTE = 'OBTENER_FICHERO_EXPEDIENTE'
export const OBTENER_FICHERO_EXPEDIENTE_FAIL = 'OBTENER_FICHERO_EXPEDIENTE_FAIL'
export const OBTENER_REPORT = 'OBTENER_REPORT'
export const PEDIDOS_PENDIENTES_ISLOADING = 'PEDIDOS_PENDIENTES_ISLOADING'
export const POST_LINEA_LISTA_COMPRA = 'POST_LINEA_LISTA_COMPRA'
export const POST_LINEA_PEDIDO = 'POST_LINEA_PEDIDO'
export const POST_LINEAS_LISTA_COMPRA = 'POST_LINEAS_LISTA_COMPRA'
export const POST_LINEAS_PEDIDO = 'POST_LINEAS_PEDIDO'
export const POST_PEDIDO = 'POST_PEDIDO'
export const POST_PEDIDO_FAIL = 'POST_PEDIDO_FAIL'
export const POST_PEDIDO_SUCCESS = 'POST_PEDIDO_SUCCESS'
export const RECOVER_PASS = 'RECOVER_PASS'
export const RECOVER_PASS_FAIL = 'RECOVER_PASS_FAIL'
export const RECOVER_PASS_SUCCESS = 'RECOVER_PASS_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTRO_COMPLETO_FAIL = 'REGISTRO_COMPLETO_FAIL'
export const REINICIA_LV = 'REINICIA_LV'
export const REINICIAR_PASSWORD = 'REINICIAR_PASSWORD'
export const REINICIAR_PASSWORD_FAIL = 'REINICIAR_PASSWORD_FAIL'
export const REINICIAR_PASSWORD_SUCCESS = 'REINICIAR_PASSWORD_SUCCESS'
export const REMOVE_FILTRO = 'REMOVE_FILTRO'
export const SEND_CAMBIO_PASSWORD = 'SEND_CAMBIO_PASSWORD'
export const SEND_EXPEDIENTE = 'SEND_EXPEDIENTE'
export const SEND_EXPEDIENTE_FAIL = 'SEND_EXPEDIENTE_FAIL'
export const SEND_EXPEDIENTE_SUCCESS = 'SEND_EXPEDIENTE_SUCCESS'
export const SEND_OBSERVACIONES_EXPEDIENTE = 'SEND_OBSERVACIONES_EXPEDIENTE'
export const SEND_OBSERVACIONES_EXPEDIENTE_FAIL = 'SEND_OBSERVACIONES_EXPEDIENTE_FAIL'
export const SEND_OBSERVACIONES_EXPEDIENTE_SUCCESS = 'SEND_OBSERVACIONES_EXPEDIENTE_SUCCESS'
export const SET_ACEPTAR_COOKIES = 'SET_ACEPTAR_COOKIES'
export const SET_ALMACEN_RECOGIDA = 'SET_ALMACEN_RECOGIDA'
export const SET_ARTICULO = 'SET_ARTICULO'
export const SET_BUSQUEDA = 'SET_BUSQUEDA'
export const SET_BUSQUEDA_AVANZADA = 'SET_BUSQUEDA_AVANZADA'
export const SET_CARNET_PROFESIONAL = 'SET_CARNET_PROFESIONAL'
export const SET_CLAVE_SELECCIONADA = 'SET_CLAVE_SELECCIONADA'
export const SET_CLOSE = 'SET_CLOSE'
export const SET_COD_POSTAL_DOM_ENVIO_MOD = 'SET_COD_POSTAL_DOM_ENVIO_MOD'
export const SET_DATOS_INFORME = 'SET_DATOS_INFORME'
export const SET_DIRECCION = 'SET_DIRECCION'
export const SET_DIRECCION_DOM_ENVIO_MOD = 'SET_DIRECCION_DOM_ENVIO_MOD'
export const SET_EMAIL_PEDIDO = 'SET_EMAIL_PEDIDO'
export const SET_ESTADO_DOM_ENVIO_MOD = 'SET_ESTADO_DOM_ENVIO_MOD'
export const SET_EXPEDIENTE = 'SET_EXPEDIENTE'
export const SET_FAMILIA_SELECCIONADA = 'SET_FAMILIA_SELECCIONADA'
export const SET_FECHA_ENTREGA = 'SET_FECHA_ENTREGA'
export const SET_FORMA_ENVIO = 'SET_FORMA_ENVIO'
export const SET_FORMA_PAGO = 'SET_FORMA_PAGO'
export const SET_HIJO_SELECCIONADO = 'SET_HIJO_SELECCIONADO'
export const SET_ID_PEDIDO_SELECCIONADO = 'SET_ID_PEDIDO_SELECCIONADO'
export const SET_INFORME_SELECCIONADO = 'SET_INFORME_SELECCIONADO'
export const SET_LINEA_PED = 'SET_LINEA_PED'
export const SET_LINEAS = 'SET_LINEAS'
export const SET_LISTA_VALORES = 'SET_LISTA_VALORES'
export const SET_LOCALIDAD_DOM_ENVIO_MOD = 'SET_LOCALIDAD_DOM_ENVIO_MOD'
export const SET_MAS_DATOS_INFORME = 'SET_MAS_DATOS_INFORME'
export const SET_MESSAGE = 'SET_MESSAGE'
export const SET_MOSTRAR_EXPEDIENTES = 'SET_MOSTRAR_EXPEDIENTES'
export const SET_NOMBRE_DOM_ENVIO_MOD = 'SET_NOMBRE_DOM_ENVIO_MOD'
export const SET_NUMERO_PEDIDO_CLIENTE = 'SET_NUMERO_PEDIDO_CLIENTE'
export const SET_OBSERVACIONES_PEDIDO = 'SET_OBSERVACIONES_PEDIDO'
export const SET_OPEN = 'SET_OPEN'
export const SET_ORDEN_EXPEDIENTES = 'SET_ORDEN_EXPEDIENTES'
export const SET_ORGANIZACION_COMPRAS = 'SET_ORGANIZACION_COMPRAS'
export const SET_PERSONA_PEDIDO = 'SET_PERSONA_PEDIDO'
export const SET_PRESENTACION = 'SET_PRESENTACION'
export const SET_PROVINCIA_DOM_ENVIO_MOD = 'SET_PROVINCIA_DOM_ENVIO_MOD'
export const SET_RUTA_REPARTO = 'SET_RUTA_REPARTO'
export const SET_SUB_PRESENTACION = 'SET_SUB_PRESENTACION'
export const SET_TIPO_EXPEDIENTE = 'SET_TIPO_EXPEDIENTE'
export const SET_TIPO_PEDIDO = 'SET_TIPO_PEDIDO'
export const SET_TIPO_PORTES_DOM_ENVIO_MOD = 'SET_TIPO_PORTES_DOM_ENVIO_MOD'
export const SET_TRANSPORTISTA = 'SET_TRANSPORTISTA'
export const SET_VALOR_CAMPO_EXPEDIENTE = 'SET_VALOR_CAMPO_EXPEDIENTE'
export const SET_VER_LISTA = 'SET_VER_LISTA'
export const SET_VER_LISTA_SUCCESS = 'SET_VER_LISTA_SUCCESS'
export const SET_VER_PEDIDO = 'SET_VER_PEDIDO'
export const SET_VER_PEDIDO_PENDIENTE = 'SET_VER_PEDIDO_PENDIENTE'
export const SHOW_LOADER = 'SHOW_LOADER'
export const SUBIR_EFACTURA_COMPRAS = 'SUBIR_EFACTURA_COMPRAS'
export const SUBIR_EFACTURA_COMPRAS_FAILURE = 'SUBIR_EFACTURA_COMPRAS_FAILURE'
export const SUBIR_EFACTURA_COMPRAS_SUCCESS = 'SUBIR_EFACTURA_COMPRAS_SUCCESS'
export const SUBIR_EFACTURA_VENTAS = 'SUBIR_EFACTURA_VENTAS'
export const SUBIR_EFACTURA_VENTAS_FAILURE = 'SUBIR_EFACTURA_VENTAS_FAILURE'
export const SUBIR_EFACTURA_VENTAS_SUCCESS = 'SUBIR_EFACTURA_VENTAS_SUCCESS'
export const TOKEN_FAIL = 'TOKEN_FAIL'
export const TOKEN_REFRESH = 'TOKEN_REFRESH'
export const UNSET_FAMILIA_SELECCIONADA = 'UNSET_FAMILIA_SELECCIONADA'
export const UPDATE_FILTROS = 'UPDATE_FILTROS'
export const USER_LOADED = 'USER_LOADED'
export const USER_LOADING = 'USER_LOADING'
export const VALIDAR_CAMPO = 'VALIDAR_CAMPO'
export const VALIDAR_CAMPO_ERROR = 'VALIDAR_CAMPO_ERROR'
export const VALIDAR_CAMPO_SUCCESS = 'VALIDAR_CAMPO_SUCCESS'
export const FETCH_OFERTAS_WEB = 'FETCH_OFERAS_WEB'
export const GET_OFERTAS_WEB = 'GET_OFERTAS_WEB'
export const CHECK_OFERTAS_WEB = 'CHECK_OFERTAS_WEB'
export const SET_OFERTA_VALIDADA = 'SET_OFERTA_VALIDADA'

//Plantillas
export const ERROR_FETCHING_MAS_PLANTILLA_DETAIL = 'ERROR_FETCHING_MAS_PLANTILLA_DETAIL'
export const ERROR_FETCHING_PLANTILLA_DETAIL = 'ERROR_FETCHING_PLANTILLA_DETAIL'
export const ERROR_FETCHING_PLANTILLAS = 'ERROR_FETCHING_PLANTILLAS'
export const FETCH_MAS_PLANTILLA_DETAIL = 'FETCH_MAS_PLANTILLA_DETAIL'
export const FETCH_PLANTILLA_DETAIL = 'FETCH_PLANTILLA_DETAIL'
export const FETCH_PLANTILLAS = 'FETCH_PLANTILLAS'
export const FLUSH_PLANTILLAS = 'FLUSH_PLANTILLAS'
export const GET_MAS_PLANTILLA_DETAILS = 'GET_MAS_PLANTILLA_DETAILS'
export const GET_PLANTILLAS = 'GET_PLANTILLAS'
export const GET_PLANTILLAS_DETAILS = 'GET_PLANTILLAS_DETAILS'
export const PLANTILLA_POST_LOADING = 'PLANTILLA_POST_LOADING'
export const POST_PEDIDO_PLANTILLA = 'POST_PEDIDO_PLANTILLA'
export const SET_PLANTILLA_SELECCIONADA = 'SET_PLANTILLA_SELECCIONADA'

// Sustitutos
export const FAIL_GET_SUSTITUTOS = 'FAIL_GET_SUSTITUTOS'
export const FETCH_SUSTITUTOS = 'FETCH_SUSTITUTOS'
export const GET_SUSTITUTOS = 'GET_SUSTITUTOS'

//Articulos Kit
export const FAIL_GET_ARTICULOS_KIT = 'FAIL_GET_ARTICULOS_KIT'
export const FETCH_ARTICULOS_KIT = 'FETCH_ARTICULOS_KIT'
export const GET_ARTICULOS_KIT = 'GET_ARTICULOS_KIT'

//TiposPedido Agencias
export const FETCH_TIPOS_PEDIDO_AGENCIAS = 'FETCH_TIPOS_PEDIDO_AGENCIAS'
export const GET_TIPOS_PEDIDO_AGENCIAS = 'GET_TIPOS_PEDIDO_AGENCIAS'

//Articulos Conjunto
export const FAIL_GET_ARTICULOS_CONJUNTO = 'FAIL_GET_ARTICULOS_CONJUNTO'
export const FETCH_ARTICULOS_CONJUNTO = 'FETCH_ARTICULOS_CONJUNTO'
export const GET_ARTICULOS_CONJUNTO = 'GET_ARTICULOS_CONJUNTO'

//Selector Cliente
export const SET_CUSTOMER = 'SET_CUSTOMER'

//Permisos
export const GET_PRECIO_PERMISOS_FAIL = 'GET_PRECIO_PERMISOS_FAIL'
export const GET_PRECIO_PERMISOS_SUCCESS = 'GET_PRECIO_PERMISOS_SUCCESS'
export const GET_PRECIOS_PERMISOS_FAIL = 'GET_PRECIOS_PERMISOS_FAIL'
export const GET_PRECIOS_PERMISOS_SUCCESS = 'GET_PRECIOS_PERMISOS_SUCCESS'

//Precios Manuales
export const SET_DTO_MANUAL = 'SET_DTO_MANUAL'
export const SET_PRECIO_MANUAL = 'SET_PRECIO_MANUAL'
export const FLUSH_DTO_MANUAL = 'FLUSH_DTO_MANUAL'
export const FLUSH_PRECIO_MANUAL = 'FLUSH_PRECIO_MANUAL'

//Listas Compra
export const FETCH_LISTAS_COMPRA = 'FETCH_LISTAS_COMPRA'
export const FLUSH_LISTAS_COMPRA_DETALLE = 'FLUSH_LISTAS_COMPRA_DETALLE'
export const GET_LISTAS_COMPRA = 'GET_LISTAS_COMPRA'
export const GET_LISTAS_COMPRA_FAIL = 'GET_LISTAS_COMPRA_FAIL'

//SelectorClientes
export const FECTH_ECOM_USUARIOS_WEB_PERMISOS = 'FECTH_ECOM_USUARIOS_WEB_PERMISOS'
export const GET_ECOM_USUARIOS_WEB_PERMISOS_SUCCESS = 'GET_ECOM_USUARIOS_WEB_PERMISOS_SUCCESS'
export const GET_ECOM_USUARIOS_WEB_PERMISOS_FAIL = 'GET_ECOM_USUARIOS_WEB_PERMISOS_FAIL'

//Relacionados
export const FAIL_GET_RELACIONADOS = 'FAIL_GET_RELACIONADOS'
export const FETCH_RELACIONADOS = 'FETCH_RELACIONADOS'
export const GET_RELACIONADOS = 'GET_RELACIONADOS'

//Equivalentes
export const FAIL_GET_EQUIVALENTES = 'FAIL_GET_EQUIVALENTES'
export const FETCH_EQUIVALENTES = 'FETCH_EQUIVALENTES'
export const GET_EQUIVALENTES = 'GET_EQUIVALENTES'
export const SET_ARTICULO_EQUIVALENTES = 'SET_ARTICULO_EQUIVALENTES'
