import React from "react";
import { useSelector } from "react-redux";
import i18n from "../../../lang/idiomas";
 
const DatosPlantilla = () => {
  
  const plantillaSeleccionada = useSelector((state) =>state.plantillas.plantillaSeleccionada);
  const locale = useSelector((state) =>state.precios.locale);
    
 
  return plantillaSeleccionada ? (
    <div className="plantilla-details">
        <span className="separator"></span>
        <div className="row">
          <div className="details-col col-sm-12 col-md-4 col-lg-4">
            <span className="title">{i18n.t("plantillas.pcodplan")}</span>
            <span className="plantilla-tag ">{plantillaSeleccionada.codigo_plantilla}</span>
          </div>
          <div className="details-col col-sm-12 col-md-4 col-lg-4">
            <span className="title">{i18n.t("plantillas.pfechini")}</span>
            <span className="plantilla-tag ">
              {isNaN(Date.parse(plantillaSeleccionada.fecha_inicio)) ? " - - - " : new Date(Date.parse(plantillaSeleccionada.fecha_inicio)).toLocaleDateString(locale).replaceAll("/", " - ")}
            </span>
          </div>
          <div className="details-col col-sm-12 col-md-4 col-lg-4">
            <span className="title">{i18n.t("plantillas.pfechafi")}</span>
            <span className="plantilla-tag ">
              {!plantillaSeleccionada.fecha_fin || isNaN(Date.parse(plantillaSeleccionada.fecha_fin))
                ? " - - - "
                : new Date(Date.parse(plantillaSeleccionada.fecha_fin)).toLocaleDateString(locale).replaceAll("/", " - ")}
            </span>
          </div>
        </div>
        <span className="separator"></span>
      </div>
  ) : (
    <></>
  );
}
 
export default DatosPlantilla;
