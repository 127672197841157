import {
  ArrowFatLineLeft,
  File,
  FileCsv,
  FileDoc,
  FileImage,
  FileJpg,
  FilePdf,
  FilePng,
  FileX,
  FileXls,
  FileZip,
} from 'phosphor-react'
import React, { Fragment, useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDatosExpediente,
  getDatosFicherosExpediente,
  obtenerFicheroExpediente,
  setTipoExpediente,
} from '../../actions/expedientes'
import { dynamicSort } from '../../helpers/funciones'
import i18n from '../../lang/idiomas'
import Spinner from '../layout/Spinner'

const ExpedienteDetalle = props => {
  const dispatch = useDispatch()

  const tipos_expedientes = useSelector(state => state.expedientes.tipos_expedientes)
  const tipo_expediente_seleccionado = useSelector(
    state => state.expedientes.tipo_expediente_seleccionado,
  )
  const expediente_seleccionado = useSelector(state => state.expedientes.expediente_seleccionado)
  const usuario = useSelector(state => state.auth.user)
  const expedientes = useSelector(state => state.expedientes.expedientes)
  const expedientes_loading = useSelector(state => state.expedientes.isLoading)
  const ficheros = useSelector(state => state.expedientes.lineas_expediente)

  useEffect(() => {
    dispatch(
      getDatosExpediente(
        tipos_expedientes[tipo_expediente_seleccionado],
        expedientes[expediente_seleccionado],
      ),
    )
    if (expedientes[expediente_seleccionado].tiene_ficheros === 'S') {
      dispatch(getDatosFicherosExpediente(expedientes[expediente_seleccionado]))
    }
    return () => {
      dispatch(setTipoExpediente(tipo_expediente_seleccionado))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pintarStatus = () => {
    switch (expedientes[expediente_seleccionado].status) {
      case '0300':
        return <div className='status-activo'>{i18n.t('expedientes.exst0300')}</div>
      case '0500':
        return <div className='status-espera'>{i18n.t('expedientes.exst0500')}</div>
      case '1000':
        if (expedientes[expediente_seleccionado].usuario_cierre === usuario.usuario_libra) {
          return <div className='status-cerrado'>{i18n.t('expedientes.exst1kb')}</div>
        } else {
          return <div className='status-cerrado'>{i18n.t('expedientes.exst1ka')}</div>
        }

      default:
        break
    }
  }

  const pintarTipo = tipo => {
    switch (tipo) {
      case 'PDF':
        return (
          <FilePdf
            size={40}
            weight={'bold'}
          />
        )
      case 'CSV':
        return (
          <FileCsv
            size={40}
            weight='bold'
          />
        )
      case 'DOC':
        return (
          <FileDoc
            size={40}
            weight='bold'
          />
        )
      case 'JPG':
      case 'JPEG':
        return (
          <FileJpg
            size={40}
            weight='bold'
          />
        )
      case 'PNG':
        return (
          <FilePng
            size={40}
            weight='bold'
          />
        )
      case 'XLS':
      case 'ODS':
        return (
          <FileXls
            size={40}
            weight='bold'
          />
        )
      case 'ZIP':
        return (
          <FileZip
            size={40}
            weight='bold'
          />
        )
      case 'MPEG':
        return (
          <FileImage
            size={40}
            weight='bold'
          />
        )
      default:
        return (
          <File
            size={40}
            weight='bold'
          />
        )
    }
  }

  const descargarFichero = fichero => {
    dispatch(obtenerFicheroExpediente(fichero))
  }

  const cerrarExpediente = () => {
    confirmAlert({
      title: i18n.t('expedientes.excietit'),
      message: i18n.t('expedientes.excietex'),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: i18n.t('general.aceptar'),
          onClick: () => {
            dispatch(cerrarExpediente(expedientes[expediente_seleccionado]))
          },
        },
        {
          label: i18n.t('general.no'),
        },
      ],
      willUnmount: () => {},
    })
  }

  let porcentaje = 100 / expedientes[expediente_seleccionado].campos.length
  porcentaje = porcentaje + '%'

  return (
    <Fragment>
      <div className='content-indent expediente'>
        <div className='base-expediente'>
          <div className='acciones-expediente'>
            <button
              className='btn-volver-listado'
              onClick={e => {
                e.preventDefault()
                dispatch(setTipoExpediente(tipo_expediente_seleccionado))
              }}>
              <ArrowFatLineLeft size={20} />
              <span>{i18n.t('general.volver')}</span>
            </button>
            <button
              className='btn-cerrar-exp'
              disabled={expedientes[expediente_seleccionado].status === '1000' ? true : false}
              onClick={e => {
                e.preventDefault()
                cerrarExpediente()
              }}>
              <FileX size={20} />
              <span>{i18n.t('expedientes.excerrar')}</span>
            </button>
          </div>
          <div className='numero'>
            <div className='titulo numero'>{i18n.t('expedientes.exnumexp') + ' :'} </div>
            <div className='valor numero'>
              {expedientes[expediente_seleccionado].numero_expediente}
            </div>
          </div>
          <div className='tipo'>
            <div className='titulo tipo'>{i18n.t('expedientes.extipo') + ' :'} </div>
            <div className='valor tipo'>
              {tipos_expedientes[tipo_expediente_seleccionado].nombre}
            </div>
          </div>
          <div className='status'>
            <div className='titulo status'>{i18n.t('expedientes.exstatus') + ' :'} </div>
            <div className='valor status'>{pintarStatus()}</div>
          </div>
        </div>

        <div className='detalle-expediente'>
          {expedientes_loading ? (
            <Spinner showSpinner={expedientes_loading} />
          ) : (
            expedientes[expediente_seleccionado].campos
              .filter(campo => campo.oculto !== 'S')
              .sort(dynamicSort('orden'))
              .map((campo, index) => (
                <div
                  className={'campo ' + campo.columna}
                  key={'campo ' + campo.columna + '-' + index}>
                  <div
                    className={'titulo-campo'}
                    key={'titulo-' + campo.columna + '-' + index}
                    style={{ width: porcentaje }}>
                    <span>{campo.literal}</span>
                  </div>
                  <div
                    className={'valor-campo'}
                    key={'valor-' + campo.columna + '-' + index}
                    style={{ width: porcentaje }}>
                    <span>
                      {' '}
                      {campo.tipo_input === 'file'
                        ? expedientes[expediente_seleccionado].tiene_ficheros
                        : campo.codigo_lov &&
                          (campo.d_valor || campo.d_valor === 0) &&
                          campo.tipo_lov !== 'M'
                        ? campo.d_valor
                        : campo.valor}
                    </span>
                  </div>
                </div>
              ))
          )}
        </div>
        {expedientes[expediente_seleccionado].tiene_ficheros === 'S' ? (
          <Fragment>
            <div className='titulo-ficheros'>{i18n.t('general.ficheros') + ' :'}</div>
            {ficheros?.length > 0 ? (
              <div className='detalle-ficheros'>
                {ficheros.map((fichero, index) => (
                  <div
                    className={'tarjeta-fichero ' + fichero.extension}
                    key={index}
                    onClick={e => {
                      e.preventDefault()
                      descargarFichero(fichero)
                    }}>
                    <div className='tipo'>{pintarTipo(fichero.extension.toUpperCase())}</div>
                    <div className='nombre'>{fichero.nombre}</div>
                  </div>
                ))}
              </div>
            ) : expedientes_loading ? (
              <Spinner showSpinner={expedientes_loading} />
            ) : (
              i18n.t('expedientes.exerrfic')
            )}
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </Fragment>
  )
}

export default ExpedienteDetalle
