import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { LinearProgress } from '@mui/material'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { esES } from '@mui/x-date-pickers/locales'
import 'dayjs/locale/es'
import { useEffect, useState } from 'react'
import ScrollDetector from '../../../common/components/ScrollDetector'
import useFetchDocumentacion from '../hooks/useFetchDocumentacion'
import useFetchDocumentacionMeta from '../hooks/useFetchDocumentacionMeta'
import DocumentacionCard from './DocumentacionCard'

/**
 * @module Documentos
 */

/**
 * Muestra los documentos de difusión
 * @components
 *
 * @param {Object} props
 * @param {Object} [props.parameters]
 */
const Documentos = (
  parameters,

  // DOCUMENT_TYPE = null,
  // PAGE_SIZE = null,
  // COMPACT = false,
  // LIMIT = 0,
  // DOCUMENT_TYPE_DESCRIPTION = null,
) => {
  useEffect(() => {
    window.wookie()
  })

  const orderArray = [
    {
      valor: 'FA',
      elemento: (
        <>
          <ArrowUpwardIcon /> Fecha publicación
        </>
      ),
    },
    {
      valor: 'FD',
      elemento: (
        <>
          <ArrowDownwardIcon /> Fecha publicación
        </>
      ),
    },
    {
      valor: 'NA',
      elemento: (
        <>
          <ArrowUpwardIcon /> Título
        </>
      ),
    },
    {
      valor: 'ND',
      elemento: (
        <>
          <ArrowDownwardIcon /> Título
        </>
      ),
    },
    {
      valor: 'CA',
      elemento: (
        <>
          <ArrowUpwardIcon /> Categoría
        </>
      ),
    },
    {
      valor: 'CD',
      elemento: (
        <>
          <ArrowDownwardIcon /> Categoría
        </>
      ),
    },
    {
      valor: 'TA',
      elemento: (
        <>
          <ArrowUpwardIcon /> Etiqueta
        </>
      ),
    },
    {
      valor: 'TD',
      elemento: (
        <>
          <ArrowDownwardIcon /> Etiqueta
        </>
      ),
    },
  ]

  const [texto, setTexto] = useState('')
  const [categoria, setCategoria] = useState('')
  const [categorias, setCategorias] = useState([])
  const [etiqueta, setEtiqueta] = useState('')
  const [etiquetas, setEtiquetas] = useState([])
  const [tipo, setTipo] = useState(parameters.DOCUMENT_TYPE ?? '')
  const [tipos, setTipos] = useState([])
  const [valorDesde, setValorDesde] = useState()
  const [valorHasta, setValorHasta] = useState()
  const [orden, setOrden] = useState('')

  const [busqueda, setBusqueda] = useState('')

  const [lastPage, setLastPage] = useState(0)
  const [page, setPage] = useState(1)
  const { response, loading } = useFetchDocumentacion(
    page,
    tipo,
    parameters.LIMIT ?? parameters.PAGE_SIZE,
    busqueda,
    categoria,
    etiqueta,
    valorDesde,
    valorHasta,
    orden,
  )
  const { resmeta, loadmeta } = useFetchDocumentacionMeta()

  const [documents, setDocuments] = useState([])

  useEffect(() => {
    if (!loading && response.results && page !== lastPage) {
      if (response.next) setLastPage(lastPage + 1)

      setDocuments(response.previous ? [...documents, ...response.results] : [...response.results])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    if (tipo !== parameters.DOCUMENT_TYPE) {
      resetState()
      setTipo(parameters.DOCUMENT_TYPE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters])

  useEffect(() => {
    if (!loadmeta && resmeta) {
      setCategorias(resmeta.categorias)
      setEtiquetas(resmeta.etiquetas)
      setTipos(resmeta.tipos)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadmeta])

  const resetState = () => {
    setDocuments([])
    setPage(1)
    setLastPage(0)
  }

  const handleChangeTipo = event => {
    resetState()
    const {
      target: { value },
    } = event
    setTipo(value)
  }

  const handleChangeCategoria = event => {
    resetState()
    const {
      target: { value },
    } = event
    setCategoria(value)
  }

  const handleChangeEtiqueta = event => {
    resetState()
    const {
      target: { value },
    } = event
    setEtiqueta(value)
  }

  const handleChangeDesde = nuevo_valor => {
    resetState()
    setValorDesde(nuevo_valor)
  }
  const handleChangeHasta = nuevo_valor => {
    resetState()
    setValorHasta(nuevo_valor)
  }

  const handleBlurTexto = () => {
    resetState()
    setBusqueda(texto)
  }

  const handleChangeOrden = event => {
    resetState()
    const {
      target: { value },
    } = event
    setOrden(value)
  }

  return (
    <>
      {parameters.DOCUMENT_TYPE ? (
        <></>
      ) : (
        <Box key='buscador'>
          <Grid
            container
            spacing={parameters.COMPACT ? 0.1 : 2}>
            <Grid
              key='busqueda'
              item
              xs={12}>
              <TextField
                fullWidth
                id='texto'
                label='Introduzca un texto para buscar'
                placeholder='Búsqueda por palabra'
                variant='outlined'
                value={texto}
                onChange={e => setTexto(e.currentTarget.value)}
                onBlur={handleBlurTexto}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={parameters.COMPACT ? 0.1 : 2}>
            <Grid
              key='tipo'
              item
              xs={12}
              md={4}>
              <FormControl fullWidth>
                <InputLabel id='select-tipo-label'>Tipo</InputLabel>
                <Select
                  labelId='select-tipo-label'
                  id='select-tipo'
                  value={tipo}
                  label='Tipo'
                  onChange={handleChangeTipo}
                  fullWidth>
                  <MenuItem value=''>
                    <em>Sin selección</em>
                  </MenuItem>
                  {tipos?.map(t => (
                    <MenuItem
                      key={t.codigo}
                      value={t.codigo}>
                      {t.descripcion}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              key='categoria'
              item
              xs={12}
              md={4}>
              <FormControl fullWidth>
                <InputLabel id='select-categoria-label'>Categoría</InputLabel>
                <Select
                  labelId='select-categoria-label'
                  id='select-categoria'
                  value={categoria}
                  label='Categoría'
                  onChange={handleChangeCategoria}
                  fullWidth>
                  <MenuItem value=''>
                    <em>Sin selección</em>
                  </MenuItem>
                  {categorias?.map(c => (
                    <MenuItem
                      key={c.id}
                      value={c.id}>
                      {c.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              key='etiqueta'
              item
              xs={12}
              md={4}>
              <FormControl fullWidth>
                <InputLabel id='select-etiqueta-label'>Etiqueta</InputLabel>
                <Select
                  labelId='select-etiqueta-label'
                  id='select-etiqueta'
                  value={etiqueta}
                  label='Etiqueta'
                  onChange={handleChangeEtiqueta}
                  fullWidth>
                  <MenuItem value=''>
                    <em>Sin selección</em>
                  </MenuItem>
                  {etiquetas?.map(e => (
                    <MenuItem
                      key={e.id}
                      value={e.id}>
                      {e.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <LocalizationProvider
            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
            dateAdapter={AdapterDayjs}
            adapterLocale='es'>
            <Grid
              container
              spacing={2}>
              <Grid
                key={`g-desde`}
                xs={12}
                md={4}>
                <DatePicker
                  key={`desde`}
                  label='Fecha desde'
                  value={valorDesde}
                  onChange={v => handleChangeDesde(v)}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid
                key={`g-hasta`}
                xs={12}
                md={4}>
                <DatePicker
                  key={`hasta`}
                  label='Fecha hasta'
                  value={valorHasta}
                  onChange={v => handleChangeHasta(v)}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid
                key={`boton`}
                xs={12}
                md={4}>
                <FormControl fullWidth>
                  <InputLabel id='select-orden-label'>Orden</InputLabel>
                  <Select
                    labelId='select-orden-label'
                    id='select-orden'
                    value={orden}
                    label='Orden'
                    onChange={handleChangeOrden}
                    fullWidth>
                    <MenuItem value=''>
                      <em>Sin selección</em>
                    </MenuItem>
                    {orderArray?.map((o, i) => (
                      <MenuItem
                        key={i}
                        value={o.valor}>
                        {o.elemento}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
      )}
      <Box
        key='documentos'
        sx={{ flexGrow: parameters.COMPACT ? 0 : 1 }}>
        {parameters.DOCUMENT_TYPE && parameters.DOCUMENT_TYPE_DESCRIPTION ? (
          <Typography
            sx={{
              fontWeight: 'bold',
              marginLeft: '2%',
              marginBottom: '1em',
              marginTop: '1em',
              fontSize: '2rem',
            }}>
            {parameters.DOCUMENT_TYPE_DESCRIPTION}
          </Typography>
        ) : null}
        <Grid
          container
          sx={{ marginLeft: '2%', marginRight: '2%' }}
          spacing={parameters.COMPACT ? 0.1 : 2}>
          {documents.length > 0 ? (
            documents?.map((document, idx) => (
              <Grid
                key={idx}
                item
                xs={12}
                sm={6}
                md={4}
                lg={2}>
                <DocumentacionCard
                  document={document}
                  compact={parameters.COMPACT}
                />
              </Grid>
            ))
          ) : (
            <></>
          )}
        </Grid>
        {loading ? <LinearProgress sx={{ mt: 2 }} /> : <></>}
        {parameters.LIMIT ? (
          <></>
        ) : (
          <ScrollDetector
            show={!loading && response?.next !== null && page === lastPage}
            onElementIsVisible={() => {
              if (response?.next && response?.next !== null) setPage(page + 1)
            }}
          />
        )}
      </Box>
    </>
  )
}

export default Documentos
