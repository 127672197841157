import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation()
    let navigate = useNavigate()
    let params = useParams()
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    )
  }

  return ComponentWithRouterProp
}

export const ProtectedRoute = ({ isAllowed, redirectPath = '/login', children }) => {
  if (!isAllowed) {
    return (
      <Navigate
        to={redirectPath}
        replace
      />
    )
  }

  return children
}

export const ordenarMenu = (a, b) => {
  if (a.orden == null) {
    a.orden = -1
  }
  if (b.orden == null) {
    b.orden = -1
  }
  if (a.orden < b.orden) {
    return -1
  } else if (a.orden > b.orden) {
    return 1
  } else {
    return 0
  }
}

export const ordenarLista = (a, b) => {
  if (a.codigo_lista < b.codigo_lista) {
    return -1
  } else if (a.codigo_lista > b.codigo_lista) {
    return 1
  } else {
    return 0
  }
}

export const b64DecodeUnicode = str => {
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
}

export const validaEmail = email => {
  return email.match(
    // eslint-disable-next-line
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  )
}

export const validaPass = pass => {
  /*
  6 tamaño mínimo
  1 mayúscula mínimo
  1 minúscula mínimo
  1 número mínimo
  1 símbolo mínimo(*+-_.:,;!¡?¿$&#)
  */

  let regexp =
    // eslint-disable-next-line
    /^(?=(?:[^a-z]*[a-z]){1,})(?=(?:[^A-Z]*[A-Z]){1,})(?=(?:[^0-9]*[0-9]){1,})(?=(?:.*[\*\+\-\_\.\:\,\;\!\¡\?\¿\$\&\#]){1,}).{6,}$/

  return regexp.test(pass)
}

export const obtenerValorParametro = (lista_parametros, codigo_parametro, codigo_origen) => {
  let parametros_filtrados
  let valor_esp

  if (lista_parametros && lista_parametros.length > 0) {
    //Filtramos la lista
    parametros_filtrados = lista_parametros.filter(
      parametro =>
        (parametro.modulo === codigo_origen && parametro.clave === codigo_parametro) ||
        (parametro.modulo === 'GEN' && parametro.clave === codigo_parametro),
    )

    //Si existen valores
    if (parametros_filtrados && parametros_filtrados.length > 0) {
      //Si solo existe uno lo retornamos
      if (parametros_filtrados.length === 1) {
        return parametros_filtrados[0].valor
      } else {
        //Si existen más  recorremos el array
        parametros_filtrados.forEach(function (value, index, array) {
          if (value.modulo === codigo_origen && value.clave === codigo_parametro) {
            //Seteamos el valor especifico
            valor_esp = value.valor
          }
        })
        return valor_esp
      }
    } else {
      // Si no encuentra valores retorna null
      console.log(
        'La clave de parametro indicada no coincide con ningún parametro suministrado. ' +
          codigo_parametro,
      )
      return null
    }
  } else {
    console.log('No se han suministrado parametros a la función')
    return null
  }
}

export const cargarArrayPrecios = (p_origen, articulos_a_procesar, precios_cargados) => {
  let articulos_aux = []
  switch (p_origen) {
    default:
      // origen: articulos_a_procesar => { articulos: state.listadoArticulos.articulos,
      //                      novedades: state.listadoArticulos.novedades,
      //                      ofertas: state.listadoArticulos.ofertas,
      //                      pedido_habitual: state.listadoArticulos.pedido_habitual,
      //                      relacionados: state.listadoArticulos.relacionados
      //                      buscadorRapido: state.listadoArticulos.articulos
      //                     }
      for (let actual of articulos_a_procesar.articulos) {
        if (!precios_cargados?.[actual.codigo_articulo]?.[actual.presentacion_web]) {
          articulos_aux.push({
            codigo_articulo: actual.codigo_articulo,
            cantidad: actual.cantidad_minima ? Number(actual.cantidad_minima) : 1,
            presentacion: actual.presentacion_web,
          })
        }
      }
      break

    case 'equivalentes':
      // articulos_a_procesar => state.listadoArticulos.equivalentes
      for (let actual of articulos_a_procesar.equivalentes) {
        for (let articulo_act of actual.articulos) {
          if (!precios_cargados[articulo_act.codigo_articulo]) {
            //Solo añadimos el articulo si no está en el estado
            articulos_aux.push({
              codigo_articulo: articulo_act.codigo_articulo,
              cantidad: actual.cantidad_minima ? Number(actual.cantidad_minima) : 1,
              presentacion: articulo_act.presentacion_web,
            })
          } else if (
            !precios_cargados?.[articulo_act.codigo_articulo]?.[articulo_act.presentacion_web]
          ) {
            // Si la presentacion buscada no está en el estado de precios
            articulos_aux.push({
              codigo_articulo: articulo_act.codigo_articulo,
              cantidad: articulo_act.cantidad_minima ? Number(articulo_act.cantidad_minima) : 1,
              presentacion: articulo_act.presentacion_web,
            })
          }
        }
      }

      break

    case 'listasCompra':
      // origen: articulos_a_procesar => { listas_compra: state.listasCompra.lineas_lista_detalle}
      for (let actual of articulos_a_procesar) {
        if (
          !precios_cargados?.[actual.codigo_articulo]?.[
            actual.presentacion_pedido || actual.presentacion_escogida
          ] ||
          parseFloat(
            precios_cargados[actual.codigo_articulo][
              actual.presentacion_pedido || actual.presentacion_escogida
            ].cantidad_precio,
          ).toFixed(4) !== parseFloat(actual.cantidad_pedida).toFixed(4)
        ) {
          // Si la presentacion buscada no está en el estado de precios
          let articulo = {
            codigo_articulo: actual.codigo_articulo,
            cantidad: actual.cantidad_pedida ? Number(actual.cantidad_pedida) : 1,
            presentacion: actual.presentacion_pedido || actual.presentacion_escogida,
          }
          articulos_aux.push(articulo)
        }
      }
      break

    case 'pedidos_anteriores':
    case 'pedidos_pendientes':
    case 'resumen':
      // origen: articulos_a_procesar => { listas_compra: state.listasCompra.lineas_lista_detalle
      //                                   pedidos_anteriores: state.pedidosAnteriores.lineas_pedido_anterior_detalle
      //                                   pedidos_pendientes: state.pedidosPendientes.lineas_pedido_pendiente_detalle
      //                                   resumen: state.pedido.lineas (filtradas sin regalos)
      //                                 }

      for (let actual of articulos_a_procesar) {
        if (
          !precios_cargados?.[actual.articulo]?.[
            actual.presentacion_pedido || actual.presentacion_web
          ] ||
          parseFloat(
            precios_cargados[actual.articulo][actual.presentacion_pedido || actual.presentacion_web]
              .cantidad_precio,
          ).toFixed(4) !== parseFloat(actual.cantidad_pedida).toFixed(4)
        ) {
          // Si la presentacion buscada no está en el estado de precios
          let articulo = {
            codigo_articulo: actual.articulo,
            cantidad: actual.cantidad_pedida ? parseFloat(actual.cantidad_pedida).toFixed(4) : 1,
            presentacion: actual.presentacion_pedido || actual.presentacion_web,
          }
          articulos_aux.push(articulo)
        }
      }
      break
    case 'plantillas':
      for (let actual of articulos_a_procesar) {
        if (
          !precios_cargados?.[actual.articulo]?.[actual.presentacion_web] ||
          parseFloat(
            precios_cargados[actual.articulo][actual.presentacion_web].cantidad_precio,
          ).toFixed(4) !== parseFloat(actual.cantidad_minima || 1).toFixed(4)
        ) {
          // Si la presentacion buscada no está en el estado de precios
          let articulo = {
            codigo_articulo: actual.codigo_articulo,
            cantidad: actual.cantidad_minima ? parseFloat(actual.cantidad_minima).toFixed(4) : 1,
            presentacion: actual.presentacion_web,
          }
          articulos_aux.push(articulo)
        }
      }
      break
    case 'sustitutos':
      articulos_a_procesar.sustitutos ??= []
      // articulos_a_procesar => state.listadoArticulos.sustitutos
      for (let actual of articulos_a_procesar.sustitutos) {
        if (actual?.articulos?.length > 0) {
          for (let articulo_act of actual.articulos) {
            if (
              !precios_cargados?.[articulo_act.codigo_articulo]?.[articulo_act.presentacion_web]
            ) {
              // Si la presentacion buscada no está en el estado de precios
              articulos_aux.push({
                codigo_articulo: articulo_act.codigo_articulo,
                cantidad: articulo_act.cantidad_minima ? Number(articulo_act.cantidad_minima) : 1,
                presentacion: articulo_act.presentacion_web,
              })
            }
          }
        }
      }
      break
  }
  return articulos_aux
}

export const cargarArrayStocks = (p_origen, articulos_a_procesar, stocks_cargados, pedido) => {
  let articulos_aux = []
  switch (p_origen) {
    default:
      // origen: articulos_a_procesar => { articulos: state.listadoArticulos.articulos,
      //                      novedades: state.listadoArticulos.novedades,
      //                      ofertas: state.listadoArticulos.ofertas,
      //                      pedido_habitual: state.listadoArticulos.pedido_habitual,
      //                      relacionados: state.listadoArticulos.relacionados
      //                      buscadorRapido: state.listadoArticulos.articulos
      //                     }
      // pedido => state.pedidos.pedido
      for (let actual of articulos_a_procesar.articulos) {
        if (!stocks_cargados?.[actual.codigo_articulo]?.[actual.presentacion_web]?.['']?.stock) {
          //Solo añadimos el articulo si no está en el estado
          articulos_aux.push({
            codigo_articulo: actual.codigo_articulo,
            presentacion: actual.presentacion_web,
            codigo_almacen: pedido.almacen,
            situacion: '',
          })
        }
      }
      break
    case 'equivalentes':
      // articulos_a_procesar => state.listadoArticulos.equivalentes
      // pedido => state.pedidos.pedido

      for (let actual of articulos_a_procesar.equivalentes) {
        for (let articulo_act of actual.articulos) {
          if (!stocks_cargados?.[actual.codigo_articulo]?.[actual.presentacion_web]?.['']?.stock) {
            //Solo añadimos el articulo si no está en el estado
            articulos_aux.push({
              codigo_articulo: articulo_act.codigo_articulo,
              presentacion: articulo_act.presentacion_web,
              codigo_almacen: pedido.almacen,
              situacion: '',
            })
          }
        }
      }
      break
    case 'listasCompra':
    case 'pedidos_anteriores':
    case 'pedidos_pendientes':
    case 'plantillas':
      // origen: articulos_a_procesar => { listasCompra: state.listasCompra.lineas_lista_detalle
      //                     pedidos_anteriores: state.pedidosAnteriores.lineas_pedido_anterior_detalle
      //                     pedidos_pendientes: state.pedidosPendientes.lineas_pedido_pendiente_detalle
      //                    }
      // pedido => state.pedidos.pedido/state.pedidosPendientes.pedidos_pendientes[pedido_pendiente_detalle(index)]
      for (let actual of articulos_a_procesar) {
        if (
          !stocks_cargados?.[actual.codigo_articulo]?.[
            actual.presentacion_pedido || actual.presentacion_web
          ]?.['']?.stock
        ) {
          // Si la presentacion buscada no está en el estado de precios

          articulos_aux.push({
            codigo_articulo: actual.articulo || actual.codigo_articulo,
            presentacion: actual.presentacion_pedido || actual.presentacion_web,
            codigo_almacen: pedido.almacen,
            situacion: '',
          })
        }
      }
      break

    case 'sustitutos':
      articulos_a_procesar.sustitutos ??= []
      // articulos_a_procesar => state.listadoArticulos.equivalentes
      // pedido => state.pedidos.pedido

      for (let actual of articulos_a_procesar.sustitutos) {
        if (actual?.articulos?.length > 0) {
          for (let articulo_act of actual.articulos) {
            if (
              !stocks_cargados?.[articulo_act.codigo_articulo]?.[articulo_act.presentacion_web]?.[
                ''
              ]?.stock
            ) {
              // Solo añadimos el articulo si no está en el estado
              articulos_aux.push({
                codigo_articulo: articulo_act.codigo_articulo,
                presentacion: articulo_act.presentacion_web,
                codigo_almacen: pedido.almacen,
                situacion: '',
              })
            }
          }
        }
      }
  }
  return articulos_aux
}

export const cargarParametros = (p_parametros, p_lista_parametros, p_origen) => {
  // El nombre del parametro solicitado debe ser identico al de la lista de parametros (keyinsensitive)
  let v_parametros = {}

  for (let parametro in p_parametros) {
    let clave = p_parametros[parametro].toUpperCase()
    v_parametros[p_parametros[parametro]] = obtenerValorParametro(
      p_lista_parametros,
      clave,
      p_origen,
    )
  }

  return v_parametros
}

export function dynamicSort(property) {
  let sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    let result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}

export function programaActivo(menus, programa) {
  return menus.some(
    menu =>
      menu.programa === programa ||
      (menu.hijos && menu.hijos.length > 0 && menu.hijos.some(hijo => hijo.programa === programa)),
  )
}

export function descargarFichero(nombre, fichero, extension = '') {
  const binary = atob(fichero.replace(/\s/g, ''))
  const len = binary.length
  const buffer = new ArrayBuffer(len)
  const view = new Uint8Array(buffer)

  for (let i = 0; i < len; i += 1) {
    view[i] = binary.charCodeAt(i)
  }
  // create the blob object with content-type "application/pdf"
  const blob = new Blob([view], { type: 'application/octet-stream' })
  const url = URL.createObjectURL(blob)

  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  a.href = url
  a.download = nombre

  a.click()
}
