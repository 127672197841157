import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getArticulos, limpiarListaArticulos, setBusqueda } from '../../actions/articulos'
import { postLineaLista } from '../../actions/listasCompra'
import { createMessage } from '../../actions/messages'
import { postLinea } from '../../actions/pedidos'
import { getPreciosYPermisos } from '../../actions/precios'
import { getStocksArticulos } from '../../actions/stocks'
import { cargarArrayPrecios, cargarArrayStocks, cargarParametros } from '../../helpers/funciones'
import i18n from '../../lang/idiomas'
import AnalyticsEdisa from '../common/AnalyticsEdisa'
import Spinner from '../layout/Spinner'

let lineas_coincidentes = 0
let linea_existe = false
let timer
let linea
let articulos_aux = []
let parametros_usados = ['maxim_agrup']
let parametros

const BuscadorRapido = props => {
  const dispatch = useDispatch()

  const portal = useSelector(state => state.portal.portal)
  const lista = useSelector(state => state.listasCompra)
  const articulos = useSelector(state => state.listadoArticulos.articulos)
  const articulos_pagina = useSelector(state => state.listadoArticulos.articulos_pagina)
  const articulos_orden = useSelector(state => state.listadoArticulos.articulos_orden)
  const isLoading = useSelector(state => state.listadoArticulos.articulos.isLoading)
  const isLoadingListArt = useSelector(state => state.listadoArticulos.isLoading)

  const precios = useSelector(state => state.precios.precio)
  const stocks = useSelector(state => state.stocks.stock)
  const pedido = useSelector(state => state.pedidos.pedido)
  const pedido_cargando = useSelector(state => state.pedidos.isLoading)

  const [valor, setValor] = useState('')
  const [isShowingContent, setIsShowingContent] = useState(true)
  const prevProps = useRef()

  prevProps.current ??= {}

  parametros = cargarParametros(parametros_usados, portal.parametros_ecom, 'GEN')

  useEffect(() => {
    dispatch(limpiarListaArticulos())
    dispatch(setBusqueda(''))

    return () => {
      dispatch(limpiarListaArticulos())
      dispatch(setBusqueda(''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if (valor.length >= 3) {
        dispatch(
          getArticulos(valor, articulos_pagina, articulos_orden, pedido.domicilio_envio || ''),
        )
      }
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valor])

  useEffect(() => {
    lineas_coincidentes = 0
    if (articulos?.articulos?.length > 0) {
      //Comprobamos si solicitan precios grupalmente
      articulos_aux = cargarArrayPrecios('buscadorRapido', articulos, precios)
      if (
        articulos_aux?.length > 0 &&
        JSON.stringify(prevProps?.current?.aux_precios) !== JSON.stringify(articulos_aux)
      ) {
        //Solo llamamos a getPrecios si hai al menos un elemento en el array
        dispatch(
          getPreciosYPermisos(
            articulos_aux, //articulos_cantidades
            'buscadorRapido', //origen
            null, //domicilio_envio
            parametros.maxim_agrup,
          ),
        )
        prevProps.current.aux_precios = articulos_aux
      }
      if (props.añadir_a === 'carrito') {
        //Comprobamos si solicitan stocks grupalmente
        articulos_aux = cargarArrayStocks('buscadorRapido', articulos, stocks, pedido)
        if (
          articulos_aux?.length > 0 &&
          JSON.stringify(prevProps?.current?.aux_stock) !== JSON.stringify(articulos_aux)
        ) {
          //Solo llamamos a getStocks si hai al menos 1 elemento en el array
          dispatch(
            getStocksArticulos(
              articulos_aux, //articulos_stock
              'buscadorRapido', //origen
              parametros.maxim_agrup, //Maximo de peticiones agrupadas
            ),
          )
          prevProps.current.aux_stocks = articulos_aux
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articulos])

  const onClick = articulo => {
    if (props.añadir_a === 'carrito') {
      anadirCarro('P', articulo.cantidad_minima || 1, articulo)
    } else {
      linea = {
        codigo_lista: lista.lista_detalle,
        codigo_articulo: articulo.codigo_articulo,
        presentacion_pedido: articulo.presentacion_web,
      }
      setValor('')
      setIsShowingContent(false)
      dispatch(postLineaLista(linea))
      dispatch(limpiarListaArticulos())
      dispatch(setBusqueda(''))
    }
  }

  const onBlur = e => {
    e.preventDefault()
    if (
      e.relatedTarget === null ||
      e.currentTarget.className !== e.relatedTarget.offsetParent.className
    ) {
      if (articulos.articulos && articulos.articulos.length > 0 && isShowingContent === true) {
        onClickButton()
      }
    }
  }

  const onClickDisabled = e => {
    dispatch(createMessage(i18n.t('buscador.busrpnst'), 'error'))
  }

  const onClickButton = () => {
    setIsShowingContent(!isShowingContent)
  }

  const onChange = e => {
    setValor(e.currentTarget.value)
    dispatch(limpiarListaArticulos())
    dispatch(setBusqueda(''))
  }

  const anadirCarro = (tipo, cantidad, articulo) => {
    if (!pedido_cargando) {
      if (precios?.[articulo.codigo_articulo]?.[articulo.presentacion_web]) {
        let cantidad_pedida = parseFloat(cantidad)

        let linea = {
          articulo: articulo.codigo_articulo,
          descripcion: articulo.descrip_comercial,
          precio_venta: null,
          observaciones: '',
          estado_linea: 'B',
          cantidad_pedida: cantidad_pedida,
          presentacion_pedido: articulo.presentacion_web,
          tipo_linea: tipo,
          sub_pres: '',
          sub_pres_cant: '',
          sub_pres_fc: '',
          presentacion_escogida: '',
          pres_fc: '',
        }

        dispatch(
          postLinea(
            linea,
            props.hash,
            props.id_pedido_seleccionado || props.pedido?.id_pedido,
            'N',
          ),
        )

        let analytics = new AnalyticsEdisa()

        analytics.registraEvento({
          modulo: 'Pedido',
          tipo: 'AddToCart',
          valor: articulo,
          params: {
            codigo_articulo: articulo.codigo_articulo,
            descrip_comercial: articulo.descrip_comercial,
            divisa: precios[articulo.codigo_articulo][articulo.presentacion_web].divisa_precio,
            precios: parseFloat(
              props.netear_precio === 'S'
                ? precios[articulo.codigo_articulo][articulo.presentacion_web].importe_neto
                : precios[articulo.codigo_articulo][articulo.presentacion_web].importe_bruto,
            ),
          },
        })
        setValor('')
        setIsShowingContent(false)
      }
    }
  }
  const mostrarMensaje = () => {
    if (valor.length < 3) {
      return (
        <div
          className={'row articulo-busc-rap-helper'}
          id={-1}>
          {i18n.t('buscador.busrpm3c')}
        </div>
      )
    } else if (isLoading || isLoadingListArt) {
      return (
        <div
          className={'row articulo-busc-rap-loader'}
          id={-1}>
          <Spinner showSpinner={isLoading || isLoadingListArt} />
        </div>
      )
    } else {
      return (
        <div
          className={'row articulo-busc-rap-helper'}
          id={-1}>
          {i18n.t('buscador.busrpnrs')}
        </div>
      )
    }
  }

  return (
    <div
      className='buscador-rapido'
      onBlur={onBlur}>
      <label
        htmlFor='buscador_rapido'
        className='label_busc_rap'>
        {i18n.t('buscador.busrptit')}
      </label>
      <div className='input-buscador-rap'>
        <input
          type='text'
          className='form-control inp-busc-rap'
          id='buscador_rapido'
          value={valor}
          placeholder='Teclee busqueda...'
          onChange={onChange}
          name='buscador_rapido'
        />
        <button
          className='btn busc-rap-btn'
          id='busc_rap_btn'
          onClick={onClickButton}
          disabled={!(articulos?.articulos?.length > 0 && !isLoadingListArt)}>
          {articulos?.articulos?.length <= 0 || isLoadingListArt ? (
            <i className='icon-e-01' />
          ) : isShowingContent ? (
            <i className='icon-e-04' />
          ) : (
            <i className='icon-e-01' />
          )}
        </button>
      </div>

      {articulos?.articulos?.length > 0 && !isLoadingListArt ? (
        <div
          className='tt-article-table listado-buscador'
          hidden={!isShowingContent}
          tabIndex={0}>
          <div className='col-12'>
            {props.añadir_a === ' carrito'
              ? articulos.articulos.map(articulo => {
                  if (
                    stocks?.[articulo.codigo_articulo]?.[articulo.presentacion_web]?.['']?.stock >
                      0 ||
                    stocks?.[articulo.codigo_articulo]?.[articulo.presentacion_web]?.['']?.stock ===
                      'S' ||
                    portal?.controlar_stock === 'C'
                  ) {
                    return (
                      <div
                        className={'row articulo-busc-rap'}
                        value={articulo.codigo_articulo}
                        id={articulo.codigo_articulo}
                        key={articulo.codigo_articulo}
                        onClick={e => {
                          e.preventDefault()
                          onClick(articulo)
                        }}>
                        {articulo.descrip_comercial + ' | ' + articulo.codigo_articulo}
                      </div>
                    )
                  } else {
                    return (
                      <div
                        className={'row articulo-busc-rap-dis'}
                        key={articulo.codigo_articulo}
                        value={articulo.codigo_articulo}
                        id={articulo.codigo_articulo}
                        onClick={onClickDisabled}>
                        {articulo.descrip_comercial + ' | ' + articulo.codigo_articulo}
                      </div>
                    )
                  }
                })
              : articulos.articulos.map((articulo, index) => {
                  linea_existe = false
                  lista.lineas_lista_detalle.forEach(linea => {
                    if (linea.codigo_articulo === articulo.codigo_articulo) {
                      lineas_coincidentes = lineas_coincidentes + 1
                      linea_existe = true
                    }
                  })
                  if (linea_existe === false) {
                    return (
                      <div
                        className={'row articulo-busc-rap'}
                        value={articulo.codigo_articulo}
                        id={articulo.codigo_articulo}
                        key={articulo.codigo_articulo}
                        onClick={e => {
                          e.preventDefault()
                          onClick(articulo)
                        }}>
                        {articulo.descrip_comercial + ' | ' + articulo.codigo_articulo}
                      </div>
                    )
                  } else if (articulos.articulos.length === 1) {
                    return (
                      <div
                        key={'busrplil-' + index}
                        className={'row articulo-busc-rap-dis'}
                        id={-1}>
                        {i18n.t('buscador.busrplil')}
                      </div>
                    )
                  } else if (articulos.articulos.length === lineas_coincidentes) {
                    return (
                      <div
                        key={'busrpl2l-' + index}
                        className={'row articulo-busc-rap-dis'}
                        id={-1}>
                        {i18n.t('buscador.busrpl2l')}
                      </div>
                    )
                  } else {
                    return ''
                  }
                })}
          </div>
        </div>
      ) : (
        mostrarMensaje()
      )}
    </div>
  )
}

export default BuscadorRapido
