import axios from "../axiosConfig";

import { GET_DOM_ENVIO, GET_ESTADO, GET_PROVINCIA, SET_DIRECCION, FETCH_DOM_ENVIO, FETCH_ESTADO, FETCH_PROVINCIA } from "./types";
import { tokenConfig } from "./auth";

// GET DOMICILIOS DE ENVIO DEL CLIENTE
export const getDomEnvio = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_DOM_ENVIO,
  });
  axios
    .get("/domicilios/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_DOM_ENVIO,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

// GET ESTADO
export const getEstado = (estado) => (dispatch, getState) => {
  dispatch({
    type: FETCH_ESTADO,
  });
  axios
    .get(`/estados/?search=${estado}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ESTADO,
        payload: res.data[0],
      });
    })
    .catch((err) => console.log(err));
};

// GET PROVINCIA
export const getProvincia = (estado, provincia) => (dispatch, getState) => {
  dispatch({
    type: FETCH_PROVINCIA,
  });
  axios
    .get(`/provincias/?estado=${estado}&provincia=${provincia}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PROVINCIA,
        payload: res.data[0],
      });
    })
    .catch((err) => console.log(err));
};

export const setDireccion = (direccion) => (dispatch) => {
  dispatch({
    type: SET_DIRECCION,
    payload: direccion,
  });
};
