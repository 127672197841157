export const isInt = n => {
  // eslint-disable-next-line
  return Number(n) == n && n % 1 === 0
}

export const isFloat = n => {
  // eslint-disable-next-line
  return Number(n) == n && n % 1 !== 0
}

export const compareDecimals = (n1, n2, dN = 0) => {
  return parseFloat(n1).toFixed(dN) === parseFloat(n2).toFixed(dN)
}
