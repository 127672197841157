import axios from '../axiosConfig'
import { createMessage } from './messages'
import {
  ARCHIVE_CONVERSATION,
  ARCHIVE_CONVERSATION_FAIL,
  ARCHIVE_CONVERSATION_SUCCESS,
  CERRAR_EXPEDIENTE,
  CERRAR_EXPEDIENTE_FAIL,
  CERRAR_EXPEDIENTE_SUCCESS,
  DELETE_CONVERSATION,
  DELETE_CONVERSATION_FAIL,
  DELETE_CONVERSATION_SUCCESS,
  DESCARGAR_FICHERO_EXPEDIENTE,
  FETCH_EXPEDIENTES,
  FETCH_TIPOS_EXPEDIENTES,
  FETCH_TIPOS_EXPEDIENTES_CAMPOS,
  GET_ARCHIVED_CONVERSATIONS,
  GET_ARCHIVED_CONVERSATIONS_FAIL,
  GET_ARCHIVED_CONVERSATIONS_SUCCESS,
  GET_DATOS_EXPEDIENTE,
  GET_DATOS_EXPEDIENTE_FAIL,
  GET_DATOS_EXPEDIENTE_SUCCESS,
  GET_DATOS_FICHEROS_EXPEDIENTE,
  GET_DATOS_FICHEROS_EXPEDIENTE_FAIL,
  GET_DATOS_FICHEROS_EXPEDIENTE_SUCCESS,
  GET_DELETED_CONVERSATIONS,
  GET_DELETED_CONVERSATIONS_FAIL,
  GET_DELETED_CONVERSATIONS_SUCCESS,
  GET_EXPEDIENTES,
  GET_EXPEDIENTES_FAIL,
  GET_EXPEDIENTES_SUCCESS,
  GET_LINEAS_EXPEDIENTE,
  GET_LINEAS_EXPEDIENTE_FAIL,
  GET_LINEAS_EXPEDIENTE_SUCCESS,
  GET_LOV_EXPEDIENTE,
  GET_LOV_EXPEDIENTE_FAIL,
  GET_LOV_EXPEDIENTE_SUCCESS,
  GET_MAS_EXPEDIENTES,
  GET_MAS_EXPEDIENTES_FAIL,
  GET_OBSERVACIONES_EXPEDIENTE,
  GET_OBSERVACIONES_EXPEDIENTE_FAIL,
  GET_OBSERVACIONES_EXPEDIENTE_SUCCESS,
  GET_TIPOS_EXPEDIENTES,
  GET_TIPOS_EXPEDIENTES_CAMPOS,
  GET_TIPOS_EXPEDIENTES_CAMPOS_FAIL,
  GET_TIPOS_EXPEDIENTES_FAIL,
  LIMPIAR_FILTROS_EXPEDIENTES,
  MARCAR_LEIDOS,
  MARCAR_LEIDOS_FAIL,
  MARCAR_LEIDOS_SUCCESS,
  NEW_EXPEDIENTE,
  NEW_EXPEDIENTE_FAIL,
  NEW_EXPEDIENTE_SUCCESS,
  OBTENER_FICHERO_EXPEDIENTE,
  OBTENER_FICHERO_EXPEDIENTE_FAIL,
  REINICIA_LV,
  SEND_EXPEDIENTE,
  SEND_EXPEDIENTE_FAIL,
  SEND_EXPEDIENTE_SUCCESS,
  SEND_OBSERVACIONES_EXPEDIENTE,
  SEND_OBSERVACIONES_EXPEDIENTE_FAIL,
  SEND_OBSERVACIONES_EXPEDIENTE_SUCCESS,
  SET_EXPEDIENTE,
  SET_MOSTRAR_EXPEDIENTES,
  SET_ORDEN_EXPEDIENTES,
  SET_TIPO_EXPEDIENTE,
  SET_VALOR_CAMPO_EXPEDIENTE,
  VALIDAR_CAMPO,
  VALIDAR_CAMPO_ERROR,
  VALIDAR_CAMPO_SUCCESS,
} from './types'

import { descargarFichero } from '../helpers/funciones'
import { tokenConfig } from './auth'

export const getTiposExpedientes = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_TIPOS_EXPEDIENTES,
  })
  axios
    .get(`/tiposexpedientes/`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_TIPOS_EXPEDIENTES,
        payload: res.data,
      })
    })
    .catch(err => {
      console.log(err)
      dispatch({
        type: GET_TIPOS_EXPEDIENTES_FAIL,
      })
    })
}

export const getTiposExpedientesCampos =
  (expediente = '') =>
  (dispatch, getState) => {
    const body = JSON.stringify({ expediente: expediente })
    dispatch({
      type: FETCH_TIPOS_EXPEDIENTES_CAMPOS,
    })
    axios
      .post(`/expedienteslisa/getCamposExpediente/`, body, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_TIPOS_EXPEDIENTES_CAMPOS,
          payload: res.data,
        })
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: GET_TIPOS_EXPEDIENTES_CAMPOS_FAIL,
        })
      })
  }

export const getExpedientes =
  (search = '') =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_EXPEDIENTES,
    })
    axios
      .get(`/expedientes/?search=${search}`, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_EXPEDIENTES,
          payload: res.data,
        })
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: GET_EXPEDIENTES_FAIL,
        })
      })
  }

export const nuevoExpediente = datos_exp => (dispatch, getState) => {
  const body = JSON.stringify({ datos: datos_exp })
  dispatch({
    type: NEW_EXPEDIENTE,
  })

  axios
    .post(`/expedienteslisa/`, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: NEW_EXPEDIENTE_SUCCESS,
        payload: res.data,
        expediente: datos_exp,
      })
      let mensaje = res.data?.texto_mensaje || 'Operacion realizada correctamente'
      let tipo = res.data?.tipo_mensaje || 'success'

      dispatch(createMessage(mensaje, tipo))
    })
    .catch(err => {
      console.log(err)
      dispatch({
        type: NEW_EXPEDIENTE_FAIL,
      })
      dispatch(createMessage(err.response.data, 'error'))
    })
}

//NUEVOS DE LA APP
export const sendConversation = expediente => async (dispatch, getState) => {
  dispatch({ type: SEND_EXPEDIENTE })

  let config = tokenConfig(getState)

  let body = JSON.stringify(expediente)
  axios
    .post('/expedientes/createconversation/', body, config)
    .then(res => {
      createMessage(res.data, 'success')
      dispatch({
        type: SEND_EXPEDIENTE_SUCCESS,
        payload: { expediente: res.data },
      })
    })
    .catch(err => {
      console.log(err)
      dispatch({ type: SEND_EXPEDIENTE_FAIL })
      dispatch(createMessage(err.response.data, 'error'))
    })
}

export const getLineasExpediente = numero_expediente => async (dispatch, getState) => {
  dispatch({ type: GET_LINEAS_EXPEDIENTE })

  let config = tokenConfig(getState)

  axios
    .get(`/expedienteslin/?exp=${numero_expediente}`, config)
    .then(res => {
      dispatch({
        type: GET_LINEAS_EXPEDIENTE_SUCCESS,
        payload: res.data,
      })
    })
    .catch(err => {
      dispatch({ type: GET_LINEAS_EXPEDIENTE_FAIL })
      console.log(err)
    })
}

export const getObservacionesExpediente = numero_expediente => async (dispatch, getState) => {
  dispatch({ type: GET_OBSERVACIONES_EXPEDIENTE })

  let config = tokenConfig(getState)

  axios
    .get(`/expedienteslinobs/?exp=${numero_expediente}`, config)
    .then(res => {
      dispatch({
        type: GET_OBSERVACIONES_EXPEDIENTE_SUCCESS,
        payload: res.data,
      })
    })
    .catch(err => {
      dispatch({ type: GET_OBSERVACIONES_EXPEDIENTE_FAIL })
      console.log(err)
    })
}

export const sendObservacionesExpediente =
  (numero_expediente, observacion) => async (dispatch, getState) => {
    dispatch({ type: SEND_OBSERVACIONES_EXPEDIENTE })

    let config = tokenConfig(getState)

    let body = JSON.stringify({
      observaciones: observacion,
      exp: numero_expediente,
    })

    axios
      .post(`/expedienteslinobs/`, body, config)
      .then(res => {
        dispatch({
          type: SEND_OBSERVACIONES_EXPEDIENTE_SUCCESS,
          payload: { observacion_expediente: res.data },
        })
      })
      .catch(err => {
        dispatch({ type: SEND_OBSERVACIONES_EXPEDIENTE_FAIL })
        console.log(err)
      })
  }

export const marcarLeidos = observaciones_leidas => async (dispatch, getState) => {
  dispatch({ type: MARCAR_LEIDOS })

  let config = tokenConfig(getState)
  let body = JSON.stringify({ mensajes_leidos: observaciones_leidas })

  await axios
    .post('/expedienteslinobs/marcarleidos/', body, config)
    .then(res => {
      dispatch({
        type: MARCAR_LEIDOS_SUCCESS,
      })
    })
    .catch(err => {
      console.log(err)
      dispatch({
        type: MARCAR_LEIDOS_FAIL,
      })
    })
}

export const deleteConversation = numero_expediente => async (dispatch, getState) => {
  dispatch({ type: DELETE_CONVERSATION })

  let config = tokenConfig(getState)

  let body = JSON.stringify({ numero_expediente: numero_expediente })

  await axios
    .post('/expedientes/borrar/', body, config)
    .then(res => {
      dispatch({
        type: DELETE_CONVERSATION_SUCCESS,
        payload: { numero_expediente: numero_expediente },
      })
    })
    .catch(err => {
      dispatch({ type: DELETE_CONVERSATION_FAIL })
      console.log(err)
    })
}

export const archiveConversation = numero_expediente => async (dispatch, getState) => {
  dispatch({ type: ARCHIVE_CONVERSATION })

  let config = tokenConfig(getState)

  let body = JSON.stringify({ numero_expediente: numero_expediente })

  await axios
    .post('/expedientes/archivar/', body, config)
    .then(res => {
      dispatch({
        type: ARCHIVE_CONVERSATION_SUCCESS,
        payload: { numero_expediente: numero_expediente },
      })
    })
    .catch(err => {
      dispatch({ type: ARCHIVE_CONVERSATION_FAIL })
      console.log(err)
    })
}

export const getArchivedConversations = () => async (dispatch, getState) => {
  dispatch({ type: GET_ARCHIVED_CONVERSATIONS })

  let config = tokenConfig(getState)

  await axios
    .get('/expedientes/archivados/', config)
    .then(res => {
      dispatch({
        type: GET_ARCHIVED_CONVERSATIONS_SUCCESS,
        payload: { expedientes_archivados: res.data },
      })
    })
    .catch(err => {
      dispatch({ type: GET_ARCHIVED_CONVERSATIONS_FAIL })
      console.log(err)
    })
}

export const getDeletedConversations = () => async (dispatch, getState) => {
  dispatch({ type: GET_DELETED_CONVERSATIONS })

  let config = tokenConfig(getState)

  await axios
    .get('/expedientes/borrados/', config)
    .then(res => {
      dispatch({
        type: GET_DELETED_CONVERSATIONS_SUCCESS,
        payload: { expedientes_borrados: res.data },
      })
    })
    .catch(err => {
      dispatch({ type: GET_DELETED_CONVERSATIONS_FAIL })
      console.log(err)
    })
}

export const getLOVExpediente =
  (expediente, codigo_lov, campo, filtros = []) =>
  (dispatch, getState) => {
    dispatch({
      type: GET_LOV_EXPEDIENTE,
      tipo_expediente: expediente,
      campo: campo,
      codigo_lov: codigo_lov,
    })

    const body = JSON.stringify({
      expediente,
      codigo_lov,
      campo,
      filtros,
    })

    axios
      .post('/expedienteslisa/getLOV/', body, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_LOV_EXPEDIENTE_SUCCESS,
          tipo_expediente: expediente,
          codigo_lov: codigo_lov,
          campo: campo,
          payload: { lov: res.data },
        })
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: GET_LOV_EXPEDIENTE_FAIL,
          tipo_expediente: expediente,
          codigo_lov: codigo_lov,
          campo: campo,
        })
        if (err.response) {
          dispatch(createMessage(err.response.data, 'error'))
        } else {
          dispatch(
            createMessage('Error al solicitar la lista, por favor intentelo denuevo.', 'error'),
          )
        }
      })
  }

export const getMenuExpedientes = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_TIPOS_EXPEDIENTES,
  })
  axios
    .get(`/menuexpedientes/`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_TIPOS_EXPEDIENTES,
        payload: res.data,
      })
    })
    .catch(err => {
      console.log(err)
      dispatch({
        type: GET_TIPOS_EXPEDIENTES_FAIL,
      })
    })
}

export const setTipoExpediente = tipo => (dispatch, getState) => {
  dispatch({
    type: SET_TIPO_EXPEDIENTE,
    payload: tipo,
  })
}

export const setExpediente = expediente => (dispatch, getState) => {
  dispatch({
    type: SET_EXPEDIENTE,
    payload: expediente,
  })
}

export const cerrarExpediente = expediente => (dispatch, getState) => {
  dispatch({
    type: CERRAR_EXPEDIENTE,
  })
  const body = JSON.stringify({
    expediente,
  })
  axios
    .put(`/expedienteslisa/${expediente.numero_expediente}/`, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: CERRAR_EXPEDIENTE_SUCCESS,
        payload: res.data,
      })
    })
    .catch(err => {
      console.log(err)
      dispatch({
        type: CERRAR_EXPEDIENTE_FAIL,
      })
    })
}

export const getExpedientesTipo =
  (expediente, mostrar, orden, inicio = 0) =>
  (dispatch, getState) => {
    dispatch({
      type: GET_EXPEDIENTES,
    })
    let body = JSON.stringify({ expediente, mostrar, orden, inicio })

    axios
      .post(`/expedienteslisa/getExpedientesUsuarioTipo/`, body, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: GET_EXPEDIENTES_SUCCESS,
          payload: res.data,
        })
      })
      .catch(err => {
        dispatch({
          type: GET_EXPEDIENTES_FAIL,
        })
        console.log(err)
      })
  }

export const limpiarFiltrosExpedientes = () => (dispatch, getState) => {
  dispatch({
    type: LIMPIAR_FILTROS_EXPEDIENTES,
  })
}

export const setOrdenExpedientes = orden => (dispatch, getState) => {
  dispatch({
    type: SET_ORDEN_EXPEDIENTES,
    payload: orden,
  })
}

export const setMostrarExpedientes =
  (mostrar = 12) =>
  (dispatch, getState) => {
    dispatch({
      type: SET_MOSTRAR_EXPEDIENTES,
      payload: mostrar,
    })
  }

export const mostrarMasExpedientes =
  (expediente, mostrar, orden, inicio = 0) =>
  (dispatch, getState) => {
    dispatch({
      type: GET_MAS_EXPEDIENTES,
    })

    axios
      .get(
        `/expedienteslisa/?tipo=${expediente.tipo_expediente}&id_pers=${expediente.id_pers}&mostrar=${mostrar}&orden=${orden}&inicio=${inicio}`,
        tokenConfig(getState),
      )
      .then(res => {
        dispatch({
          type: GET_EXPEDIENTES_SUCCESS,
          payload: res.data,
          inicio: inicio,
        })
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: GET_MAS_EXPEDIENTES_FAIL,
        })
      })
  }

export const getDatosExpediente = (tipo_expediente, expediente) => (dispatch, getState) => {
  dispatch({
    type: GET_DATOS_EXPEDIENTE,
  })
  let body = JSON.stringify({
    tipo_expediente: tipo_expediente.tipo_expediente,
    numero_expediente: expediente.numero_expediente,
    programa: tipo_expediente.programa,
    id_pers: tipo_expediente.id_pers,
    plantilla: tipo_expediente.plantilla,
  })

  axios
    .post(`/expedienteslisa/getDatosExpediente/`, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_DATOS_EXPEDIENTE_SUCCESS,
        payload: res.data,
        tipo_expediente: tipo_expediente.tipo_expediente,
        numero_expediente: expediente.numero_expediente,
      })
    })
    .catch(err => {
      console.log(err)
      dispatch({
        type: GET_DATOS_EXPEDIENTE_FAIL,
      })
    })
}

export const getDatosFicherosExpediente = expediente => (dispatch, getState) => {
  dispatch({
    type: GET_DATOS_FICHEROS_EXPEDIENTE,
  })
  axios
    .get(
      `/expedienteslisa/getDatosFicheros/?tipo=${expediente.tipo_expediente}&numero_expediente=${expediente.numero_expediente}`,
      tokenConfig(getState),
    )
    .then(res => {
      dispatch({
        type: GET_DATOS_FICHEROS_EXPEDIENTE_SUCCESS,
        payload: res.data,
      })
    })
    .catch(err => {
      console.log(err)
      dispatch({
        type: GET_DATOS_FICHEROS_EXPEDIENTE_FAIL,
      })
    })
}

export const obtenerFicheroExpediente = fichero => (dispatch, getState) => {
  dispatch({
    type: OBTENER_FICHERO_EXPEDIENTE,
  })
  let token = fichero.token

  const body = JSON.stringify({
    token,
  })
  axios
    .post(`/expedienteslisa/getFicheroExpediente/`, body, tokenConfig(getState))
    .then(res => {
      descargarFichero(res.data.nombre_archivo, res.data.fichero)
      dispatch({
        type: DESCARGAR_FICHERO_EXPEDIENTE,
      })
    })
    .catch(err => {
      dispatch({
        type: OBTENER_FICHERO_EXPEDIENTE_FAIL,
      })
      console.log(err)
      if (err.response && err?.response?.status === 403) {
        dispatch(
          createMessage(
            'Tiempo del validez del token de descarga excedido, por favor vuelva a intentarlo.',
            'error',
          ),
        )
      }
    })
}

export const validaCampoExpediente =
  (programa, campo, valor, id_pers, variables) => (dispatch, getState) => {
    const body = JSON.stringify({
      programa,
      campo,
      valor,
      id_pers,
      variables,
    })
    dispatch({
      type: VALIDAR_CAMPO,
    })

    axios
      .post(`/expedienteslisa/validaCampoExpediente/`, body, tokenConfig(getState))
      .then(res => {
        dispatch({
          type: VALIDAR_CAMPO_SUCCESS,
          payload: res.data,
        })
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: VALIDAR_CAMPO_ERROR,
        })
        dispatch(createMessage('Error validando campo', 'error'))
      })
  }

export const setValorCampoExpediente =
  (campo, valor, d_valor = null) =>
  (dispatch, getState) => {
    dispatch({
      type: SET_VALOR_CAMPO_EXPEDIENTE,
      payload: { campo, valor, d_valor },
    })
  }

export const reiniciaLV = (expediente, codigo_lov, campo) => (dispatch, getState) => {
  dispatch({
    type: REINICIA_LV,
    payload: { expediente, codigo_lov, campo },
  })
}
