// @ts-check
import React, { useEffect, useRef } from 'react'
import { useIntersectionObserver } from '../hooks/useIntercectionObserver'

/**
 * This Element can be used to trigger callbacks
 * when is on the viewport
 *
 * @param {Object} props
 * @param {boolean} props.show - This change the display to none when false
 * @param {Function} props.onElementIsVisible - The function to trigger when the element enters the viewport
 */
const ScrollDetector = ({ show, onElementIsVisible }) => {
  /** @type {React.MutableRefObject<HTMLDivElement>} */
  // @ts-ignore
  const scrollElement = useRef()
  const { isVisible } = useIntersectionObserver(scrollElement)

  useEffect(() => {
    if (isVisible && show) onElementIsVisible()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, onElementIsVisible])

  return (
    <div
      ref={scrollElement}
      style={{
        marginTop: `1em`,
        height: '30px',
        width: '100%',
        display: show ? 'block' : 'none',
      }}></div>
  )
}

export default ScrollDetector
