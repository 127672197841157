import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from '../../actions/auth'
import config from '../../config'
import { ordenarMenu } from '../../helpers/funciones'
import i18n from '../../lang/idiomas'
import MuiIconLoader from '../common/MuiIconLoader'

export class Footer extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    portal: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired,
  }

  render() {
    const { isAuthenticated } = this.props.auth
    const { portal } = this.props
    let menus = this.props.menus
    const invitado = this.props.auth.invitado

    if (menus && menus.length > 0) {
      menus.sort(ordenarMenu)
      if (this.props.auth.isAuthenticated && !invitado) {
        menus = menus.filter(menu => {
          return menu.programa !== 'login' && menu.programa !== 'registro'
        })
      }
      if (this.props.auth.user !== null && this.props.auth.user.puede_crear_usuarios !== 'S') {
        menus = menus.filter(menu => {
          return menu.programa !== 'registroHijos'
        })
      }
    }
    const enlaces_disponibles = (
      <Fragment>
        {menus && menus.length > 0
          ? menus.map((menu, index) => {
              menu.hijos.sort(ordenarMenu)
              return menu.visible === 'S' &&
                menu.nombre !== 'CARRITO' &&
                menu.nombre !== 'USUARIO' &&
                menu.nombre !== 'SELECTOR_CLIENTE' ? (
                <Fragment key={menu.codigo + toString(index)}>
                  {menu.hijos.length > 0 ? (
                    <Fragment>
                      {menu.hijos.map((hijo, indexHijo) => {
                        return hijo.visible === 'S' ? (
                          <li key={hijo.codigo + toString(indexHijo)}>
                            {/* <Link to={"/" + hijo.programa + (hijo.camino ? "/" + hijo.camino : "")}>{hijo.nombre}</Link> */}
                            <Link
                              to={
                                (hijo.programa.toUpperCase().startsWith('HTTP')
                                  ? hijo.programa
                                  : '/' + hijo.programa) + (hijo.camino ? '/' + hijo.camino : '')
                              }>
                              {hijo.nombre}
                            </Link>
                          </li>
                        ) : (
                          ''
                        )
                      })}
                    </Fragment>
                  ) : (
                    <li key={menu.codigo + toString(index)}>
                      <Link to={'/' + menu.programa + (menu.camino ? '/' + menu.camino : '')}>
                        {menu.nombre}
                      </Link>
                    </li>
                  )}
                </Fragment>
              ) : (
                ''
              )
            })
          : ''}
        <li key={'configCookies'}>
          <Link to={'/configurarCookies'}>{i18n.t('layout.lconfcoo')}</Link>
        </li>
      </Fragment>
    )

    const contenido = (
      <footer className='f-mobile-dark'>
        <div className='tt-footer-col tt-color-scheme-01'>
          <div className='container'>
            <div className='row contenido-contacto'>
              <div className='col-md-6 col-lg-6 col-xl-6 contenido'>
                <div className='tt-mobile-collapse'>
                  <h4 className='tt-collapse-title'>{i18n.t('layout.lcontmay')}</h4>
                  <div className='tt-collapse-content'>
                    <ul className='tt-list'>
                      {/* <li>
                        <Link to="/">{i18n.t("layout.linicio")}</Link>
                      </li> */}
                      {enlaces_disponibles}
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-6 col-xl-6 contacto'>
                {config.contacto.direccion !== '' ||
                config.contacto.telefono !== '' ||
                config.contacto.email !== '' ||
                config.contacto.horario !== '' ? (
                  <div className='tt-mobile-collapse'>
                    <h4 className='tt-collapse-title'>{i18n.t('layout.lcontact')}</h4>
                    <div className='tt-collapse-content'>
                      <address>
                        {config.contacto.direccion !== '' ? (
                          <p>
                            <span>{i18n.t('layout.ldirecc')}:</span> {config.contacto.direccion}
                          </p>
                        ) : (
                          ''
                        )}
                        {config.contacto.telefono !== '' ? (
                          <p>
                            <span>{i18n.t('layout.ltel')}</span> {config.contacto.telefono}
                          </p>
                        ) : (
                          ''
                        )}
                        {config.contacto.horario !== '' ? (
                          <p>
                            <span>{i18n.t('layout.lhorar')}:</span> {config.contacto.horario}
                          </p>
                        ) : (
                          ''
                        )}
                        {config.contacto.email !== '' ? (
                          <p>
                            <span>{i18n.t('layout.lemail')}:</span>{' '}
                            <a href={config.contacto.email}>{config.contacto.email}</a>
                          </p>
                        ) : (
                          ''
                        )}
                      </address>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <ul className='tt-social-icon'>
                  {config.redes_sociales.facebook !== '' ? (
                    <li>
                      <a
                        href={config.redes_sociales.facebook}
                        target='blank'>
                        <MuiIconLoader
                          iconName='Facebook'
                          size={'16px'}
                        />
                        <span className='ocultar'>Facebook</span>
                      </a>
                    </li>
                  ) : (
                    ''
                  )}
                  {config.redes_sociales.twitter !== '' ? (
                    <li>
                      <a
                        href={config.redes_sociales.twitter}
                        target='blank'>
                        <MuiIconLoader
                          iconName='Twitter'
                          size={'16px'}
                        />
                        <span className='ocultar'>Twitter</span>
                      </a>
                    </li>
                  ) : (
                    ''
                  )}
                  {config.redes_sociales.google_plus !== '' ? (
                    <li>
                      <a
                        href={config.redes_sociales.google_plus}
                        target='blank'>
                        <MuiIconLoader
                          iconName='Google'
                          size={'16px'}
                        />
                        <span className='ocultar'>Google Plus</span>
                      </a>
                    </li>
                  ) : (
                    ''
                  )}
                  {config.redes_sociales.instagram !== '' ? (
                    <li>
                      <a
                        href={config.redes_sociales.instagram}
                        target='blank'>
                        <MuiIconLoader
                          iconName='Instagram'
                          size={'16px'}
                        />
                        <span className='ocultar'>Instagram</span>
                      </a>
                    </li>
                  ) : (
                    ''
                  )}
                  {config.redes_sociales.linkedin !== '' ? (
                    <li>
                      <a
                        href={config.redes_sociales.linkedin}
                        target='blank'>
                        <MuiIconLoader
                          iconName='LinkedIn'
                          size={'16px'}
                        />
                        <span className='ocultar'>Linkedin</span>
                      </a>
                    </li>
                  ) : (
                    ''
                  )}
                  {config.redes_sociales.coorporativa !== '' ? (
                    <li>
                      <a
                        href={config.redes_sociales.coorporativa}
                        target='blank'
                        className='icon-e-40'>
                        <span className='ocultar'>{i18n.t('layout.lwebcor')}</span>
                      </a>
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='tt-footer-custom tt-color-scheme-04'>
          <div className='container'>
            <div className='tt-row'>
              <div className='tt-col-left'>
                <div className='tt-col-item tt-logo-col'>
                  <Link
                    to={'/'}
                    className='tt-logo tt-logo-alignment'>
                    {portal.logo ? (
                      <img
                        src={'data:image/png;base64, ' + portal.logo}
                        alt=''
                        className='loading'
                        data-was-processed='true'
                      />
                    ) : (
                      <span className='tt-title'>{portal.descripcion}</span>
                    )}
                  </Link>
                </div>
                <div className='tt-col-item'>
                  <div className='tt-box-copyright'>
                    &copy;{' '}
                    {config.copyright !== ''
                      ? config.copyright + '  ' + new Date().getFullYear()
                      : 'Edisa  ' + new Date().getFullYear()}{' '}
                    Todos los derechos reservados.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )

    return (
      <Fragment>{config.footer.publico ? contenido : isAuthenticated ? contenido : ''}</Fragment>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  portal: state.portal.portal,
  menus: state.portal.menus,
})

export default connect(mapStateToProps, { logout })(Footer)
