import { ArrowLineLeft, Eye, FloppyDiskBack, ShoppingCart, Trash } from 'phosphor-react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  actualizaLineaLista,
  crearLista,
  eliminarLineaLista,
  eliminarLista,
  generarPedidoListaCompra,
  getLineasListaCompra,
  getListasCompra,
  limpiarDatosListasCompra,
} from '../../actions/listasCompra'
import { postLinea } from '../../actions/pedidos'
import { getPreciosYPermisos } from '../../actions/precios'
import { getPresentaciones } from '../../actions/presentaciones'
import { getStocksArticulos } from '../../actions/stocks'
import {
  cargarArrayPrecios,
  cargarArrayStocks,
  cargarParametros,
  dynamicSort,
  ordenarLista,
} from '../../helpers/funciones'
import i18n from '../../lang/idiomas'
import Spinner from '../layout/Spinner'
import BuscadorRapido from './BuscadorRapido'
import Descripcion from './Descripcion'
import Precio from './Precio'

let articulos_aux = []
let parametros_usados = ['tipo_precio', 'mostrar_iva', 'busc_rapido', 'andr_a', 'maxim_agrup']
let parametros

const ListasCompra = () => {
  const dispatch = useDispatch()
  const portal = useSelector(state => state.portal.portal)
  const listas_compra = useSelector(state => state.listasCompra.listas_compra)
  const lista_detalle = useSelector(state => state.listasCompra.lista_detalle)
  const lineas_lista_detalle = useSelector(state => state.listasCompra.lineas_lista_detalle)
  const precios = useSelector(state => state.precios.precio)
  const stocks = useSelector(state => state.stocks.stock)
  const descripciones = useSelector(state => state.descripcion.descripciones)
  const locale = useSelector(state => state.precios.locale)
  const pedidos = useSelector(state => state.pedidos)
  const pedido = useSelector(state => state.pedidos.pedido)
  const pedido_cargando = useSelector(state => state.pedidos.isLoading)
  const presentaciones = useSelector(state => state.presentaciones.presentaciones)
  const listas_compra_loading = useSelector(state => state.listasCompra.isLoading)

  const [nombre_lista, setNombreLista] = useState('')
  const [cantidad_linea, setCantidadLinea] = useState({})
  const [mostrar_lineas, setMostrarLineas] = useState(false)
  const prevProps = useRef()

  prevProps.current ??= {}
  parametros = cargarParametros(parametros_usados, portal.parametros_ecom, 'LIS')

  useEffect(() => {
    // Solicitamos las listas del usuario
    dispatch(getListasCompra())

    return () => {
      dispatch(limpiarDatosListasCompra())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.wookie()
    if (lineas_lista_detalle?.length > 0) {
      articulos_aux = cargarArrayPrecios('listasCompra', lineas_lista_detalle, precios)
      if (
        articulos_aux?.length > 0 &&
        JSON.stringify(prevProps?.current?.aux_precios) !== JSON.stringify(articulos_aux)
      ) {
        //Solo llamamos a getPrecios si hai al menos un elemento en el array
        dispatch(
          getPreciosYPermisos(
            articulos_aux, //articulos_cantidades
            'listasCompra', //origen
            null, //domicilio_envio
            parametros.maxim_agrup,
          ),
        )
        prevProps.current.aux_precios = articulos_aux
      }

      articulos_aux = cargarArrayStocks('listasCompra', lineas_lista_detalle, stocks, pedido)
      if (
        articulos_aux?.length > 0 &&
        JSON.stringify(prevProps?.current?.aux_stocks) !== JSON.stringify(articulos_aux)
      ) {
        //Solo llamamos a getStocksArticulos si hai al menos un elemento en el array
        dispatch(
          getStocksArticulos(
            articulos_aux, //articulos_stock
            'listasCompra', //origen
            parametros.maxim_agrup,
          ),
        )
        prevProps.current.aux_stocks = articulos_aux
      }

      for (let lin of lineas_lista_detalle) {
        if (!presentaciones[lin.codigo_articulo]) {
          dispatch(getPresentaciones(lin.codigo_articulo))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineas_lista_detalle])

  const deleteLista = e => {
    e.preventDefault()
    let _codigo_lista = e.currentTarget.dataset.codigoLista

    confirmAlert({
      title: i18n.t('lista.elilisco'),
      message: i18n.t('lista.esegelli'),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: i18n.t('general.si'),
          onClick: e => {
            dispatch(eliminarLista(_codigo_lista))
          },
        },
        {
          label: i18n.t('general.no'),
        },
      ],
    })
  }

  const deleteLinea = e => {
    e.preventDefault()
    let _id = e.currentTarget.dataset.id

    confirmAlert({
      title: i18n.t('lista.elimline'),
      message: i18n.t('lista.esegelin'),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: i18n.t('general.si'),
          onClick: e => {
            dispatch(eliminarLineaLista(_id))
          },
        },
        {
          label: i18n.t('general.no'),
        },
      ],
    })
  }

  const anadirCarrito = e => {
    e.preventDefault()

    let _codigo_lista = e.currentTarget.dataset.codigoLista

    confirmAlert({
      title: i18n.t('lista.anlist'),
      message: i18n.t('lista.esegalic'),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: i18n.t('general.si'),
          onClick: e => {
            dispatch(generarPedidoListaCompra(_codigo_lista))
          },
        },
        {
          label: i18n.t('general.no'),
        },
      ],
    })
  }

  const actualizarLineaLista = e => {
    e.preventDefault()
    let _id = parseInt(e.currentTarget.dataset.id)

    //Buscamos la linea
    let linea_detalle = lineas_lista_detalle.filter(linea => linea.id === _id)[0]

    if (linea_detalle) {
      //Comprobamos estado
      if (
        Number.parseFloat(linea_detalle.cantidad_pedida).toFixed(0) !==
        Number.parseFloat(cantidad_linea?.[linea_detalle.codigo_articulo]?.cantidad).toFixed(0)
      ) {
        let input = document.getElementById('in-' + linea_detalle.codigo_articulo).value
        let input_mob = document.getElementById('im-' + linea_detalle.codigo_articulo).value

        // Comprobamos el valor defectivo
        if (
          Number.parseFloat(linea_detalle.cantidad_pedida).toFixed(0) !==
            Number.parseFloat(input).toFixed(0) ||
          Number.parseFloat(linea_detalle.cantidad_pedida).toFixed(0) !==
            Number.parseFloat(input_mob).toFixed(0)
        ) {
          linea_detalle.cantidad_pedida = Number.parseFloat(
            Object.keys(cantidad_linea).length > 0 && cantidad_linea[linea_detalle.codigo_articulo]
              ? cantidad_linea[linea_detalle.codigo_articulo].cantidad
              : linea_detalle.cantidad_pedida,
          )
          linea_detalle.cantidad = Number.parseFloat(
            Object.keys(cantidad_linea).length > 0 && cantidad_linea[linea_detalle.codigo_articulo]
              ? cantidad_linea[linea_detalle.codigo_articulo].cantidad
              : linea_detalle.cantidad_pedida,
          )

          // Actualizamos
          dispatch(actualizaLineaLista(linea_detalle))
        }
      }
    }
  }

  const anadirLineaCarrito = e => {
    e.preventDefault()
    let _id = parseInt(e.currentTarget.dataset.id)

    lineas_lista_detalle
      .filter(linea => linea.id === _id)
      .forEach(linea_detalle => {
        const linea = {
          articulo: linea_detalle.codigo_articulo,
          descripcion: descripciones[linea_detalle.codigo_articulo].data.descrip_comercial,
          precio_venta: null,
          observaciones: linea_detalle.observaciones,
          estado_linea: 'B',
          cantidad_pedida: parseFloat(
            Object.keys(cantidad_linea).length > 0 && cantidad_linea[linea_detalle.codigo_articulo]
              ? cantidad_linea[linea_detalle.codigo_articulo].cantidad
              : linea_detalle.cantidad_pedida,
          ),
          presentacion_pedido: linea_detalle.presentacion_pedido,
          tipo_linea: 'P',
          sub_pres: linea_detalle.sub_pres,
          sub_pres_cant: Number.parseInt(linea_detalle.sub_pres_cant) || '',
          sub_pres_fc: Number.parseFloat(linea_detalle.sub_pres_fc) || '',
          pres_fc: Number.parseFloat(linea_detalle.pres_fc) || '',
          presentacion_escogida: linea_detalle.presentacion_pedido || '',
        }

        dispatch(postLinea(linea, '', pedidos.id_pedido_seleccionado || pedido.id_pedido, 'N'))
      })
  }

  const nuevaLista = e => {
    e.preventDefault()
    if (nombre_lista !== '') {
      dispatch(crearLista(nombre_lista))
      setNombreLista('')
    }
  }

  const handleNombreListaChange = e => {
    setNombreLista(e.target.value)
  }

  const onChange = e => {
    let cantidad = e.target.value
    let articulo = e.currentTarget.id.slice(3)
    let nuevas_cantidades = {}
    if (Object.keys(cantidad_linea).length > 0) {
      nuevas_cantidades = JSON.parse(JSON.stringify(cantidad_linea))
    }
    if (cantidad < 0 || cantidad === 0) {
      nuevas_cantidades[articulo] = { cantidad: 1 }
    } else {
      nuevas_cantidades[articulo] = { cantidad: cantidad }
    }

    setCantidadLinea(nuevas_cantidades)
  }

  const lineas = e => {
    e.preventDefault()
    setCantidadLinea('', 0)
    dispatch(getLineasListaCompra(e.currentTarget.dataset.codigoLista))
    verLineas()
  }

  const cerrarLineas = () => {
    setMostrarLineas(false)
  }

  const verLineas = () => {
    setMostrarLineas(true)
  }

  const comprobarStock = linea => {
    return !!stocks?.[linea.codigo_articulo]?.[linea.presentacion_pedido]?.['']?.stock
  }

  const cargarLineas = () => {
    if ((lineas_lista_detalle.length === 0 || lista_detalle === 0) && parametros) {
      return (
        <div className='container-indent'>
          <div className='container modal-body'>
            <h5
              onClick={cerrarLineas}
              className='atras-listas'>
              <ArrowLineLeft
                size={22}
                weight={'regular'}
              />{' '}
              {i18n.t('lista.listas')}
            </h5>
            <h1 className='tt-title-subpages noborder'>{i18n.t('lista.detlinea')}</h1>
            <div className='row'>
              <div className='col-sm-12 col-md-12 col-lg-12'>
                <p>{i18n.t('lista.noartali')}</p>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className='container-indent'>
          <div className='container modal-body'>
            <h5
              onClick={cerrarLineas}
              className='atras-listas'>
              <ArrowLineLeft
                size={22}
                weight={'regular'}
              />{' '}
              {i18n.t('lista.listas')}
            </h5>
            <h1 className='tt-title-subpages noborder'>{i18n.t('lista.detlinea')}</h1>
            <div className='row'>
              <div className='col-sm-12 col-md-12 col-lg-12'>
                <div className='tt-shopcart-table listas_compra_detalle_desctop row'>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div className='row titulos'>
                      <div className='col-sm-12 col-md-2 col-lg-2'>{i18n.t('lista.artic')}</div>
                      <div className='col-sm-12 col-md-3 col-lg-3'>{i18n.t('lista.descr')}</div>
                      <div className='col-sm-12 col-md-2 col-lg-2'>{i18n.t('lista.prec')}</div>
                      <div className='col-sm-12 col-md-1 col-lg-1'>{i18n.t('lista.cantid')}</div>
                      <div className='col-sm-12 col-md-1 col-lg-1'>{i18n.t('lista.presntn')}</div>
                      <div className='col-sm-12 col-md-3 col-lg-3'>{i18n.t('lista.accnes')}</div>
                    </div>

                    {listas_compra_loading ? (
                      <Spinner showSpinner={listas_compra_loading} />
                    ) : (
                      lineas_lista_detalle.sort(dynamicSort('-id')).map(linea => {
                        return (
                          <div
                            className='row lista-compra-row'
                            key={linea.id}>
                            <div className='col-sm-12 col-md-2 col-lg-2'>
                              <span>{linea.codigo_articulo}</span>
                            </div>
                            <div className='col-sm-12 col-md-3 col-lg-3'>
                              <Descripcion codigo_articulo={linea.codigo_articulo} />
                            </div>
                            <div className='col-sm-12 col-md-2 col-lg-2'>
                              <Precio
                                codigo_articulo={linea.codigo_articulo}
                                presentacion={
                                  linea.presentacion_escogida || linea.presentacion_pedido
                                }
                                tipo_precio={parametros.tipo_precio}
                                mostrar_iva={parametros.mostrar_iva}
                                mostrar_pres={parametros.mostrar_pres}
                                estilo_precio={parametros.estilo_precio}
                              />
                            </div>
                            <div className='col-sm-12 col-md-1 col-lg-1'>
                              <input
                                className='quantity-input input-cantidad-listas'
                                type='number'
                                name='cantidad'
                                id={'in-' + linea.codigo_articulo}
                                key={linea.codigo_articulo}
                                value={
                                  Object.keys(cantidad_linea).length > 0 &&
                                  cantidad_linea[linea.codigo_articulo]
                                    ? cantidad_linea[linea.codigo_articulo].cantidad
                                    : linea.sub_pres && linea.sub_pres !== ''
                                    ? linea.sub_pres_cant + ' X ' + linea.sub_pres
                                    : Number.parseFloat(linea.cantidad).toFixed(0)
                                }
                                size='5'
                                onChange={onChange}
                              />
                            </div>
                            <div className='col-sm-12 col-md-1 col-lg-1'>
                              <span>{linea.presentacion_pedido}</span>
                            </div>

                            <div className='col-sm-12 col-md-3 col-lg-3'>
                              <div className='row'>
                                <div className='col-sm-12 col-md-4 col-lg-4'>
                                  <button
                                    className='btn btn-primary lista-compra-accion'
                                    //data-tooltip="Actualizar linea"
                                    onClick={actualizarLineaLista}
                                    data-id={linea.id}
                                    disabled={
                                      listas_compra_loading ||
                                      !cantidad_linea?.[linea.codigo_articulo] ||
                                      parseFloat(
                                        cantidad_linea[linea.codigo_articulo].cantidad,
                                      ).toFixed(4) === parseFloat(linea.cantidad).toFixed(4)
                                    }>
                                    <FloppyDiskBack
                                      size={22}
                                      weight={'regular'}
                                    />
                                  </button>
                                </div>
                                <div className='col-sm-12 col-md-4 col-lg-4'>
                                  <button
                                    className='btn btn-primary lista-compra-accion'
                                    //data-tooltip="Añadir linea a carrito"
                                    onClick={anadirLineaCarrito}
                                    data-id={linea.id}
                                    disabled={
                                      precios?.[linea.codigo_articulo]?.[linea.presentacion_pedido]
                                        ?.status !== 'iddling' ||
                                      pedido_cargando ||
                                      listas_compra_loading ||
                                      !comprobarStock(linea)
                                    }>
                                    <ShoppingCart
                                      size={22}
                                      weight={'regular'}
                                    />
                                  </button>
                                </div>
                                <div className='col-sm-12 col-md-4 col-lg-4'>
                                  <button
                                    className='btn btn-primary lista-compra-accion'
                                    //data-tooltip="Eliminar linea"
                                    onClick={deleteLinea}
                                    data-id={linea.id}
                                    disabled={listas_compra_loading}>
                                    <Trash
                                      size={22}
                                      weight={'regular'}
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    )}
                    {parametros?.busc_rapido === 'S' ? (
                      <div className='buscador'>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                          <BuscadorRapido añadir_a={parametros.andr_a} />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className='tt-shopcart-table listas_compra_detalle_mobile row'>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    {lineas_lista_detalle.map(linea => {
                      return (
                        <div
                          className='row lista-compra-row'
                          key={linea.codigo_articulo}>
                          <div className='col-12'>
                            <div className='row element-row'>
                              <div className='col-5 titulos'>{i18n.t('lista.artic')}</div>
                              <div className='col-7'>{linea.codigo_articulo}</div>
                            </div>

                            <div className='row element-row'>
                              <div className='col-5 titulos'>{i18n.t('lista.descr')}</div>
                              <div className='col-7'>
                                <Descripcion codigo_articulo={linea.codigo_articulo} />
                              </div>
                            </div>

                            <div className='row element-row'>
                              <div className='col-5 titulos'>{i18n.t('lista.prec')}</div>
                              <div className='col-7'>
                                <Precio
                                  codigo_articulo={linea.codigo_articulo}
                                  presentacion={
                                    linea.presentacion_escogida || linea.presentacion_pedido
                                  }
                                  tipo_precio={parametros.tipo_precio}
                                  mostrar_iva={parametros.mostrar_iva}
                                  mostrar_pres={parametros.mostrar_pres}
                                  estilo_precio={parametros.estilo_precio}
                                />
                              </div>
                            </div>

                            <div className='row element-row'>
                              <div className='col-5 titulos'>{i18n.t('lista.cantid')}</div>
                              <div className='col-7'>
                                <input
                                  className='quantity-input input-cantidad-listas'
                                  type='number'
                                  name='cantidad'
                                  id={'im-' + linea.codigo_articulo}
                                  key={linea.codigo_articulo}
                                  value={
                                    Object.keys(cantidad_linea).length > 0 &&
                                    cantidad_linea[linea.codigo_articulo]
                                      ? cantidad_linea[linea.codigo_articulo].cantidad
                                      : linea.sub_pres && linea.sub_pres !== ''
                                      ? linea.sub_pres_cant + ' X ' + linea.sub_pres
                                      : Number.parseFloat(linea.cantidad).toFixed(0)
                                  }
                                  size='5'
                                  onChange={onChange}
                                />
                              </div>
                            </div>

                            <div className='row element-row'>
                              <div className='col-5 titulos'>{i18n.t('lista.presntn')}</div>
                              <div className='col-7'>{linea.presentacion}</div>
                            </div>
                            <div className='row element-row'>
                              <div className='col-5 titulos'>{i18n.t('lista.dispon')}</div>
                              <div className='col-7'>
                                {comprobarStock(linea)
                                  ? i18n.t('lista.disponmi')
                                  : i18n.t('lista.agotmin')}
                              </div>
                            </div>
                            <div className='row element-row'>
                              <div className='col-5 titulos'>{i18n.t('lista.accnes')}</div>
                              <div className='col-7'>
                                <div className='row'>
                                  <div className='col-6'>
                                    <button
                                      className='btn btn-primary lista-compra-accion'
                                      onClick={anadirLineaCarrito}
                                      data-id={linea.id}>
                                      <ShoppingCart
                                        size={22}
                                        weight={'regular'}
                                      />
                                    </button>
                                  </div>

                                  <div className='col-6'>
                                    <button
                                      className='btn btn-primary lista-compra-accion'
                                      onClick={deleteLinea}
                                      data-id={linea.id}>
                                      <Trash
                                        size={22}
                                        weight={'regular'}
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  if (listas_compra?.length > 0) {
    listas_compra.sort(ordenarLista)
  }

  return (
    <Fragment>
      <div className='container'>
        <div
          id='tt-pageContent'
          className='listas-compra'>
          {!mostrar_lineas ? (
            <div className='container-indent'>
              <div className='container'>
                <Fragment>
                  <h1
                    className='tt-title-subpages noborder'
                    onClick={cerrarLineas}>
                    {i18n.t('lista.liscomma')}
                  </h1>

                  <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <form
                        className='form-inline'
                        id='nueva_lista'>
                        <div className='form-group'>
                          <label
                            htmlFor='nombre_lista'
                            className='sr-only'>
                            {i18n.t('lista.nommin')}
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='nombre_lista'
                            aria-describedby='nombre_lista_help'
                            placeholder={i18n.t('lista.nomllism')}
                            onChange={handleNombreListaChange}
                            name='nombre_lista'
                          />
                        </div>
                        <button
                          type='submit'
                          disabled={!document.getElementById('nombre_lista')?.value}
                          className='btn btn-primary'
                          onClick={nuevaLista}>
                          {i18n.t('lista.nuevmin')}
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <Spinner showSpinner={listas_compra_loading} />
                      {listas_compra?.length > 0 ? (
                        <Fragment>
                          <div className='tt-shopcart-table listas_compra_desctop row'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <div className='row titulos'>
                                <div className='col-sm-12 col-md-4 col-lg-4'>
                                  {i18n.t('lista.nombr')}
                                </div>
                                <div className='col-sm-12 col-md-4 col-lg-4'>
                                  {i18n.t('lista.fechcre')}
                                </div>
                                <div className='col-sm-12 col-md-4 col-lg-4'>
                                  {i18n.t('lista.accnes')}
                                </div>
                              </div>

                              {listas_compra.map(lista => {
                                return (
                                  <div
                                    className='row lista-compra-row'
                                    key={lista.codigo_lista}>
                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                      {lista.nombre_lista}
                                    </div>
                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                      {new Date(Date.parse(lista.fecha_alta)).toLocaleDateString(
                                        locale,
                                      )}
                                    </div>
                                    <div className='col-sm-12 col-md-4 col-lg-4'>
                                      <div className='row'>
                                        <div className='col-sm-12 col-md-4 col-lg-4'>
                                          <button
                                            className='btn btn-primary'
                                            //data-tooltip="Ver lista"
                                            onClick={lineas}
                                            data-codigo-lista={lista.codigo_lista}
                                            disabled={listas_compra_loading || !lista.tiene_lineas}>
                                            <Eye
                                              size={22}
                                              weight={'regular'}
                                            />
                                          </button>
                                        </div>

                                        <div className='col-sm-12 col-md-4 col-lg-4'>
                                          {!lista.tiene_lineas ? (
                                            ''
                                          ) : (
                                            <button
                                              className='btn btn-primary'
                                              //data-tooltip="Añadir a carrito"
                                              onClick={anadirCarrito}
                                              data-codigo-lista={lista.codigo_lista}
                                              disabled={listas_compra_loading || pedido_cargando}>
                                              <ShoppingCart
                                                size={22}
                                                weight={'regular'}
                                              />
                                            </button>
                                          )}
                                        </div>
                                        <div className='col-sm-12 col-md-4 col-lg-4'>
                                          <button
                                            className='btn btn-primary'
                                            //data-tooltip="Eliminar lista"
                                            onClick={deleteLista}
                                            data-codigo-lista={lista.codigo_lista}
                                            disabled={listas_compra_loading}>
                                            <Trash
                                              size={22}
                                              weight={'regular'}
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                          <div className='tt-shopcart-table listas_compra_mobile row'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              {listas_compra.map(lista => {
                                return (
                                  <div
                                    className='row lista-compra-row'
                                    key={lista.codigo_lista}>
                                    <div className='col-12'>
                                      <div className='row element-row'>
                                        <div className='col-6 titulos'>{i18n.t('lista.nombr')}</div>
                                        <div className='col-6'>{lista.nombre_lista}</div>
                                      </div>

                                      <div className='row element-row'>
                                        <div className='col-6 titulos'>
                                          {i18n.t('lista.fechcre')}
                                        </div>
                                        <div className='col-6'>
                                          {new Date(
                                            Date.parse(lista.fecha_alta),
                                          ).toLocaleDateString(locale)}
                                        </div>
                                      </div>
                                      <div className='row element-row'>
                                        <div className='col-6 titulos'>
                                          {i18n.t('lista.accnes')}
                                        </div>
                                        <div className='col-6'>
                                          <div className='row'>
                                            <div className='col-4'>
                                              <button
                                                className='btn btn-primary lista-compra-accion'
                                                //data-tooltip="Ver lista"
                                                onClick={lineas}
                                                data-codigo-lista={lista.codigo_lista}
                                                disabled={listas_compra_loading}>
                                                <Eye
                                                  size={22}
                                                  weight={'regular'}
                                                />
                                              </button>
                                            </div>

                                            <div className='col-4'>
                                              {lista.codigo_lista !== lista_detalle ? (
                                                ''
                                              ) : (
                                                <button
                                                  className='btn btn-primary lista-compra-accion'
                                                  //data-tooltip="Añadir a carrito"
                                                  onClick={anadirCarrito}
                                                  data-codigo-lista={lista.codigo_lista}
                                                  disabled={
                                                    listas_compra_loading || pedido_cargando
                                                  }>
                                                  <ShoppingCart
                                                    size={22}
                                                    weight={'regular'}
                                                  />
                                                </button>
                                              )}
                                            </div>

                                            <div className='col-4'>
                                              <button
                                                className='btn btn-primary lista-compra-accion'
                                                //data-tooltip="Eliminar lista"
                                                onClick={deleteLista}
                                                data-codigo-lista={lista.codigo_lista}
                                                disabled={listas_compra_loading}>
                                                <Trash
                                                  size={22}
                                                  weight={'regular'}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </Fragment>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </Fragment>
              </div>
            </div>
          ) : (
            ''
          )}

          {mostrar_lineas ? cargarLineas() : ''}
        </div>
      </div>
    </Fragment>
  )
}

export default ListasCompra
