export function creaURLArticulo(codigo, descripcion) {
  return '/articulo/' + codigo + '/' + (descripcion ? formateaDescripcionURL(descripcion) : '')
}

export function formateaDescripcionURL(descripcion) {
  return descripcion
    .replaceAll('\\', ' ')
    .replaceAll('/', ' ')
    .replaceAll(' ', '-')
    .replaceAll('%', '')
    .toLowerCase()
}
