import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Novedades from "./Novedades";
import Ofertas from "./Ofertas";

export class NovedadesOfertas extends Component {
  static propTypes = {};

  render() {
    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent">
            <Novedades />
            <Ofertas />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(NovedadesOfertas);
