import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAgrupacionCondiciones,
  getAgrupacionHijos,
  limpiarClavesSeleccionadas,
  limpiarHijoSeleccionado,
  setClaveSeleccionada,
  setHijoSeleccionado,
} from '../../../actions/agrupaciones'
import { getStockArticulo } from '../../../actions/stocks'
import { cargarParametros } from '../../../helpers/funciones'
import { retrieveDefaultPresentation } from '../../../helpers/presentations'
import i18n from '../../../lang/idiomas'
import AnadirCarrito from '../AnadirCarrito'
import Precio from '../Precio'
import Presentaciones from './Presentaciones'

let parametros_usados = ['mostrar_iva', 'tipo_precio', 'mostrar_pres', 'estilo_precio']
let parametros

const AgrupacionFiltros = props => {
  const dispatch = useDispatch()

  const portal = useSelector(state => state.portal.portal)
  const usuario = useSelector(state => state.auth.user)
  const articulo = useSelector(state => state.articulo.articulo)
  const cargandoArticulo = useSelector(state => state.articulo.cargandoArticulo)
  const hijos = useSelector(state => state.agrupaciones.hijos)
  const pedido = useSelector(state => state.pedidos.pedido)
  const condiciones = useSelector(state => state.agrupaciones.condiciones)
  const claves_seleccionadas = useSelector(state => state.agrupaciones.claves_seleccionadas)
  const id_pedido_seleccionado = useSelector(state => state.pedidos.id_pedido_seleccionado)
  const hijo_seleccionado = useSelector(state => state.agrupaciones.hijo_seleccionado)

  const [hijos_filtrados, setHijosFiltrados] = useState([])

  parametros = cargarParametros(parametros_usados, portal.parametros_ecom, 'AGFIL')

  useEffect(() => {
    window.wookie()
  })

  useEffect(() => {
    dispatch(getAgrupacionHijos(props.codigo_articulo))
    dispatch(getAgrupacionCondiciones(props.codigo_articulo))
    dispatch(limpiarClavesSeleccionadas())
    dispatch(limpiarHijoSeleccionado())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Forzamos la solicitud de stock del nuevo hijo seleccionado
    if (cargandoArticulo === 'idle' && articulo.codigo_articulo) {
      dispatch(
        getStockArticulo(
          hijo_seleccionado?.codigo_articulo || articulo.codigo_articulo,
          hijo_seleccionado?.presentacion_web ||
            retrieveDefaultPresentation(portal, usuario, articulo.presentacion_web),
          pedido.almacen,
          id_pedido_seleccionado,
          '',
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hijo_seleccionado])

  useEffect(() => {
    //Filtramos en funcion de las nuevas claves
    filtrar_hijos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claves_seleccionadas])

  useEffect(() => {
    //Si solo existe un hijo seleccionable lo seteamos
    if (hijos_filtrados?.length === 1) {
      dispatch(setHijoSeleccionado(hijos_filtrados[0]))
    } else {
      // Si no limpiamos la seleccion
      dispatch(limpiarHijoSeleccionado())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hijos_filtrados])

  const filtrar_hijos = () => {
    let hijos_validados = hijos.filter(hijo => {
      return claves_seleccionadas.every(clave => {
        let clave_aplicada = hijo.claves.some(
          clave_hijo =>
            clave_hijo.clave === clave.clave && clave_hijo.valor_clave === clave.valor_clave,
        )

        return clave_aplicada
      })
    })
    setHijosFiltrados(hijos_validados)
  }

  const onClick = e => {
    if (e.target.value && e.target.value !== '') {
      dispatch(limpiarHijoSeleccionado())
      dispatch(
        setClaveSeleccionada({
          clave: e.target.name,
          valor_clave: e.target.value,
        }),
      )
    }
  }

  const seleccionHijo = e => {
    if (e.target.value && e.target.value !== '') {
      let hijo = hijos.filter(hijo => hijo.codigo_articulo === e.target.value)

      if (hijo) {
        dispatch(setHijoSeleccionado(hijo[0]))
      }
    }
  }

  return (
    <div className='agrupacion_articulos'>
      {condiciones.map(condicion => (
        <div
          key={condicion.id}
          className='condicion_agrupacion'>
          <div className='titulo_agrupacion'>{condicion.clave.nombre}</div>
          {condicion.valores
            .filter(valor => {
              return hijos.some(hijo => {
                return hijo.claves.some(
                  clave_hijo =>
                    valor.valor_clave === clave_hijo.valor_clave &&
                    clave_hijo.clave === condicion.valor,
                )
              })
            })
            .map(valor => {
              return (
                <button
                  key={valor.id}
                  className={
                    claves_seleccionadas.find(
                      cla => cla.clave === valor.clave && cla.valor_clave === valor.valor_clave,
                    )
                      ? 'btn btn-small btn-primary'
                      : 'btn btn-small btn-border'
                  }
                  name={valor.clave}
                  value={valor.valor_clave}
                  onClick={onClick}>
                  {valor.nombre}
                </button>
              )
            })}
        </div>
      ))}
      {Object.keys(condiciones).length === Object.keys(claves_seleccionadas).length ? (
        hijos_filtrados.length > 0 ? (
          <div className='tabla_agrupacion'>
            <div className='col-12'>
              <div className='row titulo_agrupacion_fila'>
                <h5>
                  {!hijo_seleccionado?.codigo_articulo ? i18n.t('eparciales.epseluar') : null}
                </h5>
              </div>
              {hijos_filtrados.map(hijo => (
                <div
                  key={hijo.id}
                  className='row tabla_agrupacion_fila'>
                  <div
                    className=' col-sm-6 col-md-4 col-lg-4 tabla_agrupacion_columna columna_descripcion'
                    style={{ heigth: '100%' }}>
                    <div className='descrip-articulo-hijo-cont'>
                      <span className='descrip-articulo-hijo'>{hijo.descrip_comercial}</span>
                    </div>
                  </div>

                  {hijo_seleccionado.codigo_articulo === hijo.codigo_articulo ? (
                    <Fragment>
                      <div className='col-sm-6 col-md-2 col-lg-2 tabla_agrupacion_columna columna_precio'>
                        <div className='tt-price'>
                          <Precio
                            codigo_articulo={
                              hijo_seleccionado?.codigo_articulo || articulo.codigo_articulo
                            }
                            presentacion={
                              hijo_seleccionado?.presentacion_web ||
                              retrieveDefaultPresentation(
                                portal,
                                usuario,
                                articulo.presentacion_web,
                              )
                            }
                            mostrar_iva={parametros.mostrar_iva}
                            mostrar_pres={parametros.mostrar_pres}
                            estilo_precio={parametros.estilo_precio}
                            tipo_precio={parametros.tipo_precio}
                            forzar_peticion='S'
                          />
                        </div>
                      </div>

                      <div className=' col-sm-12 col-md-12 col-lg-12 tabla_agrupacion_columna columna_botonera'>
                        {hijo_seleccionado && hijo_seleccionado !== '' ? (
                          <>
                            <Presentaciones
                              articulo={hijo_seleccionado.codigo_articulo}
                              presentacion_defectiva={retrieveDefaultPresentation(
                                portal,
                                usuario,
                                hijo_seleccionado.presentacion_web,
                              )}
                            />
                            <AnadirCarrito
                              articulo={hijo_seleccionado}
                              mostrar_texto_btn='S'
                              origen='ficha'
                            />
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </Fragment>
                  ) : (
                    <div className='col-sm-6 col-md-4 col-lg-4 tabla_agrupacion_columna columna_descripcion'>
                      <button
                        onClick={seleccionHijo}
                        value={hijo.codigo_articulo}
                        className={
                          hijo_seleccionado.codigo_articulo === hijo.codigo_articulo
                            ? 'btn btn-small btn-primary'
                            : 'btn btn-small btn-border'
                        }>
                        {hijo_seleccionado.codigo_articulo === hijo.codigo_articulo
                          ? i18n.t('eparciales.epseldo')
                          : i18n.t('eparciales.epselar')}
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          i18n.t('eparciales.epnsenar')
        )
      ) : (
        i18n.t('eparciales.epuselco')
      )}
    </div>
  )
}

export default AgrupacionFiltros
