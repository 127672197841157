import Contacto from '../components/common/Contacto'
import Estatico from '../components/common/Estatico'
import Inicio from '../components/common/Inicio'
import Modular from '../components/common/Modular'
import Documentos from '../components/documentos/components/Documentos'
import Articulo from '../components/ecommerce/Articulo'
import Articulos from '../components/ecommerce/Articulos'
import ArticulosListItems from '../components/ecommerce/ArticulosListItems'
import Cardnet from '../components/ecommerce/Cardnet'
import Home from '../components/ecommerce/Home'
import Informes from '../components/ecommerce/Informes'
import ListasCompra from '../components/ecommerce/ListasCompra'
import NovedadesOfertas from '../components/ecommerce/NovedadesOfertas'
import OfertasWeb from '../components/ecommerce/OfertasWeb'
import Pedido from '../components/ecommerce/Pedido'
import PedidoHabitual from '../components/ecommerce/PedidoHabitual'
import PedidosAnteriores from '../components/ecommerce/PedidosAnteriores'
import PedidosPendientes from '../components/ecommerce/PedidosPendientes'
import Resumen from '../components/ecommerce/Resumen'
import Wompi from '../components/ecommerce/Wompi'
import EfacturaCompras from '../components/efactura/EfacturaCompras'
import EfacturaVentas from '../components/efactura/EfacturaVentas'
import Conversaciones from '../components/expedientes/Conversaciones'
import Expedientes from '../components/expedientes/Expedientes'
import Plantillas from '../components/plantillas/Plantillas'
import Cuenta from '../components/usuarios/Cuenta'
import Login from '../components/usuarios/Login'
import Logout from '../components/usuarios/Logout'
import Recuperar from '../components/usuarios/Recuperar'
import Registro from '../components/usuarios/Registro'
import RegistroCompleto from '../components/usuarios/RegistroCompleto'
import RegistroHijo from '../components/usuarios/RegistroHijo'
import Widgets from '../components/widgets/Widgets'

const programasRutas = props => ({
  conversaciones: <Conversaciones {...props} />,
  login: <Login {...props} />,
  articulos: <Articulos {...props} />,
  articulo: <Articulo {...props} />,
  cuenta: <Cuenta {...props} />,
  informes: <Informes {...props} />,
  resumen: <Resumen {...props} />,
  registro: <Registro {...props} />,
  logout: <Logout {...props} />,
  recuperar: <Recuperar {...props} />,
  listasCompra: <ListasCompra {...props} />,
  inicio: <Inicio {...props} />,
  estatico: <Estatico {...props} />,
  contacto: <Contacto {...props} />,
  pedidosAnteriores: <PedidosAnteriores {...props} />,
  pedidosPendientes: <PedidosPendientes {...props} />,
  pedidoHabitual: <PedidoHabitual {...props} />,
  novedadesOfertas: <NovedadesOfertas {...props} />,
  widgets: <Widgets {...props} />,
  registroCompleto: <RegistroCompleto {...props} />,
  pedido: <Pedido {...props} />,
  home: <Home {...props} />,
  wompi: <Wompi {...props} />,
  cardnet: <Cardnet {...props} />,
  modular: <Modular {...props} />,
  registroHijos: <RegistroHijo {...props} />,
  efacturaVentas: <EfacturaVentas {...props} />,
  efacturaCompras: <EfacturaCompras {...props} />,
  expedientes: <Expedientes {...props} />,
  plantillas: <Plantillas {...props} />,
  ofertasWeb: <OfertasWeb {...props} />,
  articulosListItems: <ArticulosListItems {...props} />,
  documentacion: <Documentos {...props} />,
})

export default programasRutas
