import React from "react";
import { setObservacionesPedido } from "../../actions/pedidos";
import i18n from "../../lang/idiomas";
import { useDispatch, useSelector } from "react-redux";

const ObservacionesPedido = ({ pedidoGuardado }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.pedidos.isLoading);
  const observaciones = useSelector((state) => state.pedidos.pedido.observaciones);

  const onChange = (e) => {
    if (!isLoading) {
      dispatch(setObservacionesPedido(e.target.value));
    }
  };

  return (
    <form className="form-default">
      <div className="form-group">
        <label htmlFor="observaciones">{i18n.t("pedido.pobsr")}</label>
        <textarea
          className="form-control"
          rows="7"
          name="observaciones"
          id="observaciones"
          value={observaciones && observaciones !== "" ? observaciones : ""}
          onChange={onChange}
          disabled={pedidoGuardado}
        />
      </div>
    </form>
  );
};

export default ObservacionesPedido;
