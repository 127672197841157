import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cambiarPass } from '../../actions/auth'
import { createMessage } from '../../actions/messages'
import { programaActivo, validaPass } from '../../helpers/funciones'
import i18n from '../../lang/idiomas'
import SubscripcionesStock from './SubscripcionesStock.js'

const Cuenta = props => {
  const dispatch = useDispatch()

  const auth = useSelector(state => state.auth)
  const subscripciones = useSelector(state => state.auth.user.subscripciones)
  const menus = useSelector(state => state.portal.menus)

  const [pass, setPass] = useState('')
  const [nuevoPass, setNuevoPass] = useState('')
  const [reNuevoPass, setReNuevoPass] = useState('')
  const [subsOcultas, setSubsOcultas] = useState(true)
  const [errorIguales, setErrorIguales] = useState(false)
  const [errorIgualAnterior, setErrorIgualAnterior] = useState(false)
  const [errorVacios, setErrorVacios] = useState(false)

  useEffect(() => {
    window.wookie()
  })

  const onSubmit = e => {
    e.preventDefault()

    // Validaciones
    if (pass !== '' && nuevoPass !== '' && reNuevoPass !== '') {
      if (nuevoPass !== reNuevoPass) {
        // No coinciden
        dispatch(createMessage(i18n.t('usuarios.ucontnoi'), 'error'))
        setErrorIguales(true)
        setErrorIgualAnterior(false)
        setErrorVacios(false)
      } else if (pass === nuevoPass) {
        dispatch(createMessage(i18n.t('usuarios.upassain'), 'error'))
        setErrorIguales(false)
        setErrorIgualAnterior(true)
        setErrorVacios(false)
      } else if (!validaPass(nuevoPass) || !validaPass(reNuevoPass)) {
        // No cumple requisitos
        dispatch(createMessage(i18n.t('usuarios.unocumre'), 'error'))
        setErrorIguales(true)
        setErrorIgualAnterior(false)
        setErrorVacios(false)
      } else {
        // Se modifica password
        dispatch(cambiarPass(pass, nuevoPass))
        // Limpiamos campos
        setPass('')
        setNuevoPass('')
        setReNuevoPass('')
        setErrorIguales(false)
        setErrorIgualAnterior(false)
        setErrorVacios(false)
      }
    } else {
      dispatch(createMessage(i18n.t('usuarios.ucamnopv'), 'error'))
      // Campos vacios
      setErrorIguales(false)
      setErrorIgualAnterior(false)
      setErrorVacios(true)
    }
  }

  const verSubscripciones = () => setSubsOcultas(!subsOcultas)
  const { isAuthenticated, user } = auth
  const subsActivas = programaActivo(menus, 'articulos')

  return (
    <>
      <div id='tt-pageContent'>
        <div className='container-indent'>
          <div className='container container-fluid-custom-mobile-padding'>
            <h1 className='tt-title-subpages noborder'>{i18n.t('usuarios.umicuent')}</h1>
            <div className='tt-shopping-layout'>
              <div className='tt-wrapper'>
                <h3 className='tt-title'>{i18n.t('usuarios.umisdato')}</h3>
                <div className='tt-table-responsive'>
                  <table className='tt-table-shop-02'>
                    <tbody>
                      <tr>
                        <td>{i18n.t('usuarios.uusr')}</td>
                        <td>{isAuthenticated ? user.usuario_web : ''} </td>
                      </tr>
                      <tr>
                        <td>{i18n.t('usuarios.uemail')}</td>
                        <td>{isAuthenticated ? user.email_usuario_web : ''} </td>
                      </tr>
                      <tr>
                        <td>{i18n.t('usuarios.unombr')}</td>
                        <td>{isAuthenticated ? user.nombre : ''} </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='tt-wrapper'>
                <h3 className='tt-title'>{i18n.t('usuarios.ucambcon')}</h3>
                <div className='tt-login-form'>
                  <div className='row justify-content-center'>
                    <div className='col-md-8 col-lg-6'>
                      <div className='tt-item'>
                        <div className='form-default'>
                          <form onSubmit={onSubmit}>
                            <div className='form-group'>
                              <label htmlFor='pass'>{i18n.t('usuarios.ucontact')}</label>
                              <div className='tt-required'>{i18n.t('usuarios.ucamobli')}</div>
                              <input
                                type='password'
                                name='pass'
                                onChange={e => setPass(e.target.value)}
                                value={pass}
                                className={
                                  errorVacios || errorIgualAnterior
                                    ? 'form-control error'
                                    : 'form-control'
                                }
                                id='pass'
                                placeholder={i18n.t('usuarios.uconactm')}
                                required
                              />
                            </div>
                            <div className='form-group'>
                              <label htmlFor='nuevoPass'>{i18n.t('usuarios.unuecont')}</label>
                              <input
                                type='password'
                                name='nuevoPass'
                                onChange={e => setNuevoPass(e.target.value)}
                                value={nuevoPass}
                                className={
                                  errorIguales || errorVacios || errorIgualAnterior
                                    ? 'form-control error'
                                    : 'form-control'
                                }
                                id='nuevoPass'
                                placeholder={i18n.t('usuarios.unueconm')}
                                required
                              />
                              <p>{i18n.t('usuarios.ureqmin')}</p>
                            </div>
                            <div className='form-group'>
                              <label htmlFor='reNuevoPass'>{i18n.t('usuarios.urepcont')}</label>
                              <input
                                type='password'
                                name='reNuevoPass'
                                onChange={e => setReNuevoPass(e.target.value)}
                                value={reNuevoPass}
                                className={
                                  errorIguales || errorVacios || errorIgualAnterior
                                    ? 'form-control error'
                                    : 'form-control'
                                }
                                id='reNuevoPass'
                                placeholder={i18n.t('usuarios.urecontm')}
                                required
                              />
                              <p>{i18n.t('usuarios.ureqmin')}</p>
                            </div>
                            <div className='row'>
                              <div className='col-auto'>
                                <div className='form-group'>
                                  <button
                                    className='btn btn-border'
                                    type='submit'>
                                    {i18n.t('usuarios.ucambiar')}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {subsActivas ? (
                <div className='tt-wrapper'>
                  <h3 className='tt-title'>{i18n.t('usuarios.missubs')}</h3>
                  <button
                    className='btn btn-border subscripciones'
                    disabled={
                      subscripciones?.lineas && subscripciones.lineas?.length > 0 ? false : true
                    }
                    onClick={e => {
                      e.preventDefault()
                      verSubscripciones()
                    }}>
                    {i18n.t('usuarios.gessubs')}
                  </button>
                  <div
                    id={isAuthenticated ? 'subscripciones-' + user.usuario_web : 'subscripciones'}
                    hidden={subsOcultas}>
                    <SubscripcionesStock origen={'tabla-subs'} />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cuenta
