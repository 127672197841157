import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getValoresClavesArticulo } from "../../../actions/articulo";
import i18n from "../../../lang/idiomas";

export class InfoClavesEstadisticas extends Component {
  static propTypes = {
    articulo: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (this.props.codigo_articulo) {
      this.props.getValoresClavesArticulo(this.props.codigo_articulo);
    }
  }

  componentDidUpdate(prevProps) {
    window.wookie();

    if (prevProps.codigo_articulo !== this.props.codigo_articulo) {
      this.props.getValoresClavesArticulo(this.props.codigo_articulo);
    }
  }

  render() {
    let cartacteristicas_articulo = [];

    if (this.props.caracteristicas && this.props.caracteristicas.length > 0) {
      let aux = this.props.caracteristicas.filter((caract) => caract.articulo === this.props.codigo_articulo)[0];

      if (aux) {
        cartacteristicas_articulo = aux.caracteristicas_articulo;
      }
    }

    if (this.props.origen && this.props.origen === "tabla") {
      return (
        <div className="row fila-caracteristicas">
          {this.props.caracteristicas
            ? cartacteristicas_articulo.map((caracteristica) => {
                return (
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <span>{caracteristica.nombre_clave}:</span>
                      </div>
                      <div className="col">
                        <span>{caracteristica.nombre_valor_clave}</span>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      );
    } else {
      return (
        <Fragment>
          <div className="tt-collapse-block">
            <div className="tt-item active">
              <div className="tt-collapse-title">{i18n.t("eparciales.epcarart")}</div>
              <div className="tt-collapse-content" style={{ display: "block" }}>
                <div className="caracteristicas-articulo">
                  {cartacteristicas_articulo.map((caracteristica, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className="col">{caracteristica.nombre_clave}</div>
                        <div className="col">{caracteristica.nombre_valor_clave}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  articulo: state.articulo.articulo,
  caracteristicas: state.articulo.caracteristicas,
});

export default connect(mapStateToProps, { getValoresClavesArticulo })(InfoClavesEstadisticas);
