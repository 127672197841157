import {
  FETCH_ARTICULO,
  FETCH_ARTICULOS_AUX,
  FETCH_ARTICULOS_DOC,
  FETCH_CADENA_LOGISTICA,
  FETCH_CARACTERISTICAS_ARTICULO,
  FETCH_CODIGOS_BARRAS,
  FLUSH_ARTICULO,
  GET_ARTICULO,
  GET_ARTICULO_FAIL,
  GET_ARTICULO_SUCCESS,
  GET_ARTICULOS_AUX,
  GET_ARTICULOS_DOC,
  GET_CADENA_LOGISTICA,
  GET_CARACTERISTICAS_ARTICULO,
  GET_CODIGOS_BARRAS,
} from '../actions/types.js'

const initialState = {
  articulo: {},
  stock: {},
  articulos_doc: [],
  articulos_aux: [],
  codigos_barras: [],
  cadena_logistica: {},
  caracteristicas: [],
  isLoading: false,
  cargandoArticulo: 'idle',
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ARTICULO:
      return {
        ...state,
        articulo: {},
        isLoading: true,
        cargandoArticulo: 'fetching',
      }
    case GET_ARTICULO:
      return {
        ...state,
        isLoading: false,
        articulo: action.payload,
        cargandoArticulo: 'loaded',
      }
    case GET_ARTICULO_SUCCESS:
    case GET_ARTICULO_FAIL:
      return {
        ...state,
        cargandoArticulo: 'idle',
      }
    case FETCH_ARTICULOS_DOC:
      return {
        ...state,
        articulos_doc: [],
        isLoading: true,
      }
    case GET_ARTICULOS_DOC:
      return {
        ...state,
        articulos_doc: action.payload,
        isLoading: false,
      }
    case FETCH_ARTICULOS_AUX:
      return {
        ...state,
        articulos_aux: [],
        isLoading: true,
      }
    case GET_ARTICULOS_AUX:
      return {
        ...state,
        articulos_aux: action.payload,
        isLoading: false,
      }

    case FETCH_CODIGOS_BARRAS:
      return {
        ...state,
        codigos_barras: [],
        isLoading: true,
      }
    case GET_CODIGOS_BARRAS:
      return {
        ...state,
        isLoading: false,
        codigos_barras: action.payload,
      }

    case FETCH_CADENA_LOGISTICA:
      return {
        ...state,
        cadena_logistica: {},
        isLoading: true,
      }
    case GET_CADENA_LOGISTICA:
      return {
        ...state,
        isLoading: false,
        cadena_logistica: action.payload,
      }
    case FETCH_CARACTERISTICAS_ARTICULO:
      return {
        ...state,
        isLoading: true,
      }
    case GET_CARACTERISTICAS_ARTICULO:
      let caracteristicas_aux = {
        articulo: action.articulo,
        caracteristicas_articulo: action.payload,
      }

      let caracteristicas_aux_filtradas = state.caracteristicas.filter(
        caract => caract.articulo !== action.articulo,
      )

      return {
        ...state,
        loading: false,
        caracteristicas: [...caracteristicas_aux_filtradas, caracteristicas_aux],
      }

    case FLUSH_ARTICULO:
      return {
        articulo: {},
        articulos_doc: [],
        articulos_aux: [],
        codigos_barras: [],
        cadena_logistica: {},
        caracteristicas: [],
        isLoading: false,
        cargandoArticulo: 'idle',
      }

    default:
      return state
  }
}
