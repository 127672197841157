import { FETCH_OFERTAS_WEB, GET_OFERTAS_WEB, CHECK_OFERTAS_WEB, SET_OFERTA_VALIDADA } from "../actions/types.js";

const initialState = {
  isLoading: "loading",
  ofertas_web: [],
  check:"no_ok"
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_OFERTAS_WEB:
      return {
        ...state,
        ofertas_web: [],
        isLoading: "loading",
        check: 'no_ok'
      };
    case GET_OFERTAS_WEB:
      return {
        ...state,
        isLoading: "succeeded",
        ofertas_web: action.payload.resultado,
        check: 'no_ok'
      };
    case CHECK_OFERTAS_WEB:
      return{
        ...state,
        isLoading:"in process...",
        check: action.payload.respuesta,
      }
    case SET_OFERTA_VALIDADA:
      return{
        ...state,
        check: action.payload
      }
    default:
      return state;
  }
}
