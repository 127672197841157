import React, { useEffect, Fragment, useState } from "react";
import FamiliasMenu from "./FamiliasMenu";
import { useDispatch, useSelector } from "react-redux";
import { getFiltros, añadirFiltro, quitarFiltro } from "../../actions/filtros";
import { getArticulosByFamily } from "../../actions/articulos";
import Spinner from "../layout/Spinner";
import Select from "react-select";
import ListadoArticulos from "./ListadoArticulos";

const ArticulosListItems = (props) => {
  const dispatch = useDispatch();

  const ecomFiltros = useSelector((state) => state.articulos.filtros.filtros);
  const ecomFiltrosClavesEstadisticas = useSelector((state) => state.articulos.filtros_claves_estadisticas.filtros_claves_estadisticas);
  const cargandoFiltros = useSelector((state) => state.articulos.filtros.isLoading);
  const filtros_aplicados = useSelector((state) => state.articulos.filtros_aplicados);

  const [filtros, setFiltros] = useState({});

  const selectorChange = (target) => {
    if (target?.value !== null) {
      if (target.tipo === "estad") {
        setFiltros({
          ...filtros,
          estad: {
            ...filtros.estad,
            [target.estadistico]: target.value,
          },
          claves: {
            ...filtros.claves,
          },
        });
      } else if (target.tipo === "clave") {
        setFiltros({
          ...filtros,
          estad: {
            ...filtros.estad,
          },
          claves: {
            ...filtros.claves,
            [target.estadistico]: target.value,
          },
        });
      }

      switch (target.tipo) {
        case "clave":
          if (filtros.hasOwnProperty("claves") && filtros.claves.hasOwnProperty(target.estadistico) && filtros.claves[target.estadistico] !== target.value) {
            dispatch(quitarFiltro({ estadistico: target.estadistico, familia: filtros.claves[target.estadistico], nombre: "" }));
          }
          break;

        case "estad":
          if (filtros.hasOwnProperty("estad") && filtros.estad.hasOwnProperty(target.estadistico) && filtros.estad[target.estadistico] !== target.value) {
            dispatch(quitarFiltro({ estadistico: target.estadistico, familia: filtros.estad[target.estadistico], nombre: "" }));
          }
          break;

        default:
          break;
      }

      dispatch(añadirFiltro({ estadistico: target.estadistico, familia: target.value, nombre: "", tipo: target.tipo }));
    } else {
      if (target.tipo === "estad") {
        dispatch(quitarFiltro({ estadistico: target.estadistico, familia: filtros.estad[target.estadistico], nombre: "" }));
      } else if (target.tipo === "clave") {
        dispatch(quitarFiltro({ estadistico: target.estadistico, familia: filtros.claves[target.estadistico], nombre: "" }));
      }
    }
  };

  useEffect(() => {
    window.wookie();

    if (!cargandoFiltros && ecomFiltros.length === 0 && ecomFiltrosClavesEstadisticas.length === 0) {
      dispatch(getFiltros());
    }

    dispatch(getArticulosByFamily(filtros_aplicados));
  });

  return (
    <Fragment>
      <FamiliasMenu />
      <div className="container" style={{ display: "inline-flex", gap: "4rem", paddingTop: "2rem" }}>
        <div className="filtros" style={{ width: "30%" }}>
          {cargandoFiltros ? (
            <Spinner showSpinner={cargandoFiltros} />
          ) : (
            <form id="form-claves-filtros">
              <div id="formClaves" className="form-group">
                {ecomFiltrosClavesEstadisticas.map((clave) => {
                  let optionsClave = [{ key: 0, estadistico: clave.clave_estadistica, value: null, label: "Seleccione una clave", tipo: "clave" }];

                  for (const indiceClave in clave.claves) {
                    optionsClave.push({
                      key: clave.id,
                      estadistico: clave.clave_estadistica,
                      value: clave.claves[indiceClave].valor_clave,
                      label: clave.claves[indiceClave].nombre,
                      tipo: "clave",
                    });
                  }

                  return (
                    <div key={"contaneir-clave-" + clave.clave_estadistica}>
                      <label htmlFor={"clave-" + clave.clave_estadistica}>{clave.nombre}</label>
                      <Select id={"clave-" + clave.clave_estadistica} key={"clave-" + clave.clave_estadistica} options={optionsClave} onChange={selectorChange} placeholder={"Seleccione una clave"} />
                    </div>
                  );
                })}
              </div>
              <div id="formFiltros" className="form-group">
                {ecomFiltros.map((filtro) => {
                  let optionsFiltro = [{ key: 0, estadistico: filtro.estadistico, value: null, label: "Seleccione un filtro", tipo: "estad" }];

                  for (const indiceFamilia in filtro.familias) {
                    optionsFiltro.push({
                      key: filtro.id,
                      estadistico: filtro.estadistico,
                      value: filtro.familias[indiceFamilia].codigo_familia,
                      label: filtro.familias[indiceFamilia].descripcion,
                      tipo: "estad",
                    });
                  }

                  return (
                    <div key={"container-filtro" + filtro.estadistico}>
                      <label htmlFor={"filtro" + filtro.estadistico}>{filtro.nombre}</label>
                      <Select id={"filtro" + filtro.estadistico} key={"filtro" + filtro.estadistico} options={optionsFiltro} onChange={selectorChange} placeholder={"Seleccione un filtro"} />
                    </div>
                  );
                })}
              </div>
            </form>
          )}
        </div>
        <div className="listaArticulos" style={{ width: "70%" }}>
          <ListadoArticulos mostrar="tabla" origen="articulos" />
        </div>
      </div>
    </Fragment>
  );
};
export default ArticulosListItems;
