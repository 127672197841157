import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { List, Note } from 'phosphor-react'
import React, { Fragment, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  cambiaArticulosOrden,
  cambiaArticulosPagina,
  getArticulos,
  getArticulosByFamily,
  getMasArticulos,
  limpiarBusquedaAvanzada,
  setArticuloEquivalentes,
  setBusqueda,
} from '../../actions/articulos'
import { getArticulosEquivalentes } from '../../actions/articulosEquivalentes'
import { getArticulosRelacionados } from '../../actions/articulosRelacionados'
import { getArticulosSustitutos } from '../../actions/articulosSustitutos'
import { getNovedades } from '../../actions/novedades'
import { getOfertas } from '../../actions/ofertas'
import { getPedidoHabitual } from '../../actions/pedidoHabitual'
import { postLinea } from '../../actions/pedidos'
import { getPreciosYPermisos } from '../../actions/precios'
import { getStocksArticulos } from '../../actions/stocks'
import {
  cargarArrayPrecios,
  cargarArrayStocks,
  cargarParametros,
  programaActivo,
} from '../../helpers/funciones'
import { creaURLArticulo } from '../../helpers/urls/articulos'
import i18n from '../../lang/idiomas'
import Spinner from '../layout/Spinner'
import AnadirCarrito from './AnadirCarrito'
import ArticuloNecesidades from './ArticuloNecesidades'
import ArticulosEquivalentes from './ArticulosEquivalentes'
import Badges from './Badges'
import BotonAnadirLista from './BotonAnadirLista'
import Imagen from './Imagen'
import Precio from './Precio'
import StockBadge from './StockBadge'

let articulos_aux = []
let parametros_usados = [
  'mostrar_iva',
  'tipo_precio',
  'maxim_agrup',
  'btn_subs',
  'mostrar_pres',
  'estilo_precio',
  'badgestockfc',
  'badgestock',
]

// BADGESTOCKFC -> muestra el stock total en base al factor de conversión
// BADGESTOCK -> muestra el stock normal

let parametros
let anadir_lista = false

const ListadoArticulos = props => {
  const dispatch = useDispatch()
  const prevProps = useRef()

  const user = useSelector(state => state.auth.user)
  const precios = useSelector(state => state.precios.precio)
  const stocks = useSelector(state => state.stocks.stock)
  const portal = useSelector(state => state.portal.portal)
  const articulos = useSelector(state => state.listadoArticulos.articulos)
  const pedido_habitual = useSelector(state => state.listadoArticulos.pedido_habitual)
  const ofertas = useSelector(state => state.listadoArticulos.ofertas)
  const novedades = useSelector(state => state.listadoArticulos.novedades)
  const relacionados = useSelector(state => state.listadoArticulos.relacionados)
  const sustitutos = useSelector(state => state.listadoArticulos.sustitutos)
  const equivalentes = useSelector(state => state.listadoArticulos.equivalentes)
  const articulos_pagina = useSelector(state => state.listadoArticulos.articulos_pagina)
  const articulos_search = useSelector(state => state.listadoArticulos.articulos_search)
  const articulos_orden = useSelector(state => state.listadoArticulos.articulos_orden)
  const filtros_aplicados = useSelector(state => state.articulos.filtros_aplicados)
  const menus = useSelector(state => state.portal.menus)
  const pedido = useSelector(state => state.pedidos.pedido)
  const busqueda_avanzada = useSelector(state => state.listadoArticulos.articulos_advanced_search)

  parametros = cargarParametros(parametros_usados, portal.parametros_ecom, 'LART')
  prevProps.current ??= {}

  if (menus) {
    anadir_lista = programaActivo(menus, 'listasCompra')
  }

  useEffect(() => {
    window.wookie()
  })

  useEffect(() => {
    switch (props.origen) {
      case 'articulos':
        if (!props.filtro_url) {
          if (filtros_aplicados.length > 0) {
            dispatch(
              getArticulosByFamily(
                filtros_aplicados,
                articulos_pagina,
                articulos_orden,
                articulos_search,
                pedido.domicilio_envio,
                busqueda_avanzada.avanzada,
                busqueda_avanzada.codigo_articulo,
                busqueda_avanzada.codigo_barras,
                busqueda_avanzada.descripcion,
                busqueda_avanzada.palabras_clave,
                busqueda_avanzada.equivalentes,
                busqueda_avanzada.referencia_cliente,
                busqueda_avanzada.codigo_sinonimo,
              ),
            )
          } else {
            dispatch(
              getArticulos(
                articulos_search,
                articulos_pagina,
                articulos_orden,
                pedido.domicilio_envio,
                busqueda_avanzada.avanzada,
                busqueda_avanzada.codigo_articulo,
                busqueda_avanzada.codigo_barras,
                busqueda_avanzada.descripcion,
                busqueda_avanzada.palabras_clave,
                busqueda_avanzada.equivalentes,
                busqueda_avanzada.referencia_cliente,
                busqueda_avanzada.codigo_sinonimo,
              ),
            )
          }
        }

        break
      case 'equivalentes':
        dispatch(getArticulosEquivalentes(props.id_articulo, pedido.domicilio_envio))
        break
      case 'novedades':
        dispatch(getNovedades(pedido.domicilio_envio))
        break
      case 'ofertas':
        dispatch(getOfertas(pedido.domicilio_envio))
        break
      case 'pedido_habitual':
        dispatch(getPedidoHabitual(pedido.domicilio_envio))
        break
      case 'relacionados':
        dispatch(getArticulosRelacionados(props.id_articulo, pedido.domicilio_envio))
        break
      case 'sustitutos':
        dispatch(getArticulosSustitutos(props.id_articulo, pedido.domicilio_envio))
        break
      default:
        if (filtros_aplicados.length > 0) {
          dispatch(
            getArticulosByFamily(
              filtros_aplicados,
              articulos_pagina,
              articulos_orden,
              articulos_search,
              pedido.domicilio_envio,
              busqueda_avanzada.avanzada,
              busqueda_avanzada.codigo_articulo,
              busqueda_avanzada.codigo_barras,
              busqueda_avanzada.descripcion,
              busqueda_avanzada.palabras_clave,
              busqueda_avanzada.equivalentes,
              busqueda_avanzada.referencia_cliente,
              busqueda_avanzada.codigo_sinonimo,
            ),
          )
        } else {
          dispatch(
            getArticulos(
              articulos_search,
              articulos_pagina,
              articulos_orden,
              pedido.domicilio_envio,
              busqueda_avanzada.avanzada,
              busqueda_avanzada.codigo_articulo,
              busqueda_avanzada.codigo_barras,
              busqueda_avanzada.descripcion,
              busqueda_avanzada.palabras_clave,
              busqueda_avanzada.equivalentes,
              busqueda_avanzada.referencia_cliente,
              busqueda_avanzada.codigo_sinonimo,
            ),
          )
        }
        break
    }
    prevProps.current.id_articulo = props.id_articulo
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (prevProps.current.id_articulo !== props.id_articulo) {
      switch (props.origen) {
        case 'equivalentes':
          dispatch(getArticulosEquivalentes(props.id_articulo, pedido.domicilio_envio))
          break
        case 'relacionados':
          dispatch(getArticulosRelacionados(props.id_articulo, pedido.domicilio_envio))
          break
        case 'sustitutos':
          dispatch(getArticulosSustitutos(props.id_articulo, pedido.domicilio_envio))
          break

        default:
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id_articulo])

  useEffect(() => {
    if (
      props.origen !== 'novedades' &&
      props.origen !== 'ofertas' &&
      props.origen !== 'pedido_habitual' &&
      props.origen !== 'equivalentes' &&
      props.origen !== 'relacionados' &&
      props.origen !== 'sustitutos'
    ) {
      if (articulos?.articulos?.length > 0) {
        articulos_aux = cargarArrayPrecios(props.origen, articulos, precios)
        if (
          articulos_aux?.length > 0 &&
          JSON.stringify(prevProps.current.aux_precios) !== JSON.stringify(articulos_aux)
        ) {
          //Solo llamamos a getPrecios si hai al menos un elemento en el array
          dispatch(
            getPreciosYPermisos(
              articulos_aux, //articulos_cantidades
              props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup, // Maximo de peticiones agrupadas
            ),
          )
        }
        // Guardamos los valores a prevProps
        prevProps.current.aux_precios = articulos_aux

        articulos_aux = cargarArrayStocks(props.origen, articulos, stocks, pedido)
        if (
          articulos_aux?.length > 0 &&
          JSON.stringify(prevProps.current.aux_stock) !== JSON.stringify(articulos_aux)
        ) {
          //Solo llamamos a getStocks si hai al menos 1 elemento en el array

          dispatch(
            getStocksArticulos(
              articulos_aux, // articulos_stock
              props.origen, // origen
              parametros.maxim_agrup, // Maximo de peticiones agrupadas
            ),
          )
        }
        // Guardamos los valores a prevProps
        prevProps.current.aux_stock = articulos_aux
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articulos])

  useEffect(() => {
    if (props.origen === 'equivalentes') {
      if (equivalentes?.equivalentes?.length > 0) {
        articulos_aux = cargarArrayPrecios(props.origen, equivalentes, precios)
        if (
          articulos_aux?.length > 0 &&
          JSON.stringify(prevProps.current.aux_precios) !== JSON.stringify(articulos_aux)
        ) {
          //Solo llamamos a getPrecios si hai al menos un elemento en el array
          dispatch(
            getPreciosYPermisos(
              articulos_aux, //articulos_cantidades
              props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup,
            ),
          )
        }
        // Guardamos los valores a prevProps
        prevProps.current.aux_precios = articulos_aux

        articulos_aux = cargarArrayStocks(props.origen, equivalentes, stocks, pedido)
        if (
          articulos_aux?.length > 0 &&
          JSON.stringify(prevProps.current.aux_stock) !== JSON.stringify(articulos_aux)
        ) {
          //Solo llamamos a getStocks si hai al menos 1 elemento en el array
          dispatch(
            getStocksArticulos(
              articulos_aux, // articulos_stock
              props.origen, // origen
              parametros.maxim_agrup, // Maximo de peticiones agrupadas
            ),
          )
        }
        // Guardamos los valores a prevProps
        prevProps.current.aux_stock = articulos_aux
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equivalentes])

  useEffect(() => {
    if (props.origen === 'novedades') {
      if (novedades?.articulos?.length > 0) {
        articulos_aux = cargarArrayPrecios(props.origen, novedades, precios)
        if (
          articulos_aux?.length > 0 &&
          JSON.stringify(prevProps.current.aux_precios) !== JSON.stringify(articulos_aux)
        ) {
          //Solo llamamos a getPrecios si hai al menos un elemento en el array
          dispatch(
            getPreciosYPermisos(
              articulos_aux, //articulos_cantidades
              props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup, // Maximo de peticiones agrupadas
            ),
          )
        }
        // Guardamos los valores a prevProps
        prevProps.current.aux_precios = articulos_aux

        articulos_aux = cargarArrayStocks(props.origen, novedades, stocks, pedido)
        if (
          articulos_aux?.length > 0 &&
          JSON.stringify(prevProps.current.aux_stock) !== JSON.stringify(articulos_aux)
        ) {
          //Solo llamamos a getStocks si hai al menos 1 elemento en el array
          dispatch(
            getStocksArticulos(
              articulos_aux, //articulos_stock
              props.origen, //origen
              parametros.maxim_agrup, // Maximo de peticiones agrupadasñ
            ),
          )
        }
        // Guardamos los valores a prevProps
        prevProps.current.aux_stock = articulos_aux
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [novedades])

  useEffect(() => {
    if (props.origen === 'ofertas') {
      if (ofertas?.articulos?.length > 0) {
        articulos_aux = cargarArrayPrecios(props.origen, ofertas, precios)
        if (
          articulos_aux?.length > 0 &&
          JSON.stringify(prevProps.current.aux_precios) !== JSON.stringify(articulos_aux)
        ) {
          //Solo llamamos a getPrecios si hai al menos un elemento en el array
          dispatch(
            getPreciosYPermisos(
              articulos_aux, // articulos_cantidades
              props.origen, // origen
              null, // domicilio_envio
              parametros.maxim_agrup, // Maximo de peticiones agrupadas
            ),
          )
          // Guardamos los valores a prevProps
          prevProps.current.aux_precios = articulos_aux
        }

        articulos_aux = cargarArrayStocks(props.origen, ofertas, stocks, pedido)
        if (
          articulos_aux?.length > 0 &&
          JSON.stringify(prevProps.current.aux_stock) !== JSON.stringify(articulos_aux)
        ) {
          //Solo llamamos a getStocks si hai al menos 1 elemento en el array
          dispatch(
            getStocksArticulos(
              articulos_aux, // articulos_stock
              props.origen, // origen
              parametros.maxim_agrup, // Maximo de peticiones agrupadas
            ),
          )
          // Guardamos los valores a prevProps
          prevProps.current.aux_stock = articulos_aux
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ofertas])

  useEffect(() => {
    if (props.origen === 'pedido_habitual') {
      if (pedido_habitual?.articulos?.length > 0) {
        articulos_aux = cargarArrayPrecios(props.origen, pedido_habitual, precios)
        if (
          articulos_aux?.length > 0 &&
          JSON.stringify(prevProps.current.aux_precios) !== JSON.stringify(articulos_aux)
        ) {
          //Solo llamamos a getPrecios si hai al menos un elemento en el array
          dispatch(
            getPreciosYPermisos(
              articulos_aux, //articulos_cantidades
              props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup, // Maximo de peticiones agrupadas
            ),
          )
          // Guardamos los valores a prevProps
          prevProps.current.aux_precios = articulos_aux
        }

        articulos_aux = cargarArrayStocks(props.origen, pedido_habitual, stocks, pedido)
        if (
          articulos_aux?.length > 0 &&
          JSON.stringify(prevProps.current.aux_stock) !== JSON.stringify(articulos_aux)
        ) {
          //Solo llamamos a getStocks si hai al menos 1 elemento en el array
          dispatch(
            getStocksArticulos(
              articulos_aux, //articulos_stock
              props.origen, //origen
              parametros.maxim_agrup, // Maximo de peticiones agrupadas
            ),
          )
          // Guardamos los valores a prevProps
          prevProps.current.aux_stock = articulos_aux
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pedido_habitual])

  useEffect(() => {
    if (props.origen === 'relacionados') {
      if (relacionados?.articulos?.length > 0) {
        articulos_aux = cargarArrayPrecios(props.origen, relacionados, precios)
        if (
          articulos_aux?.length > 0 &&
          JSON.stringify(prevProps.current.aux_precios) !== JSON.stringify(articulos_aux)
        ) {
          //Solo llamamos a getPrecios si hai al menos un elemento en el array
          dispatch(
            getPreciosYPermisos(
              articulos_aux, //articulos_cantidades
              props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup,
            ),
          )
          // Guardamos los valores a prevProps
          prevProps.current.aux_precios = articulos_aux
        }

        articulos_aux = cargarArrayStocks(props.origen, relacionados, stocks, pedido)
        if (
          articulos_aux?.length > 0 &&
          JSON.stringify(prevProps.current.aux_stock) !== JSON.stringify(articulos_aux)
        ) {
          //Solo llamamos a getStocks si hai al menos 1 elemento en el array
          dispatch(
            getStocksArticulos(
              articulos_aux, //articulos_stock
              props.origen, //origen
              parametros.maxim_agrup, //maximo peticiones agrupadas
            ),
          )
          // Guardamos los valores a prevProps
          prevProps.current.aux_stock = articulos_aux
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relacionados])

  useEffect(() => {
    if (props.origen === 'sustitutos') {
      if (sustitutos?.sustitutos?.length > 0) {
        articulos_aux = cargarArrayPrecios(props.origen, sustitutos, precios)
        if (
          articulos_aux?.length > 0 &&
          JSON.stringify(prevProps.current.aux_precios) !== JSON.stringify(articulos_aux)
        ) {
          //Solo llamamos a getPrecios si hai al menos un elemento en el array
          dispatch(
            getPreciosYPermisos(
              articulos_aux, //articulos_cantidades
              props.origen, //origen
              null, //domicilio_envio
              parametros.maxim_agrup,
            ),
          )
          // Guardamos los valores a prevProps
          prevProps.current.aux_precios = articulos_aux
        }

        articulos_aux = cargarArrayStocks(props.origen, sustitutos, stocks, pedido)
        if (
          articulos_aux?.length > 0 &&
          JSON.stringify(prevProps.current.aux_stock) !== JSON.stringify(articulos_aux)
        ) {
          //Solo llamamos a getStocks si hai al menos 1 elemento en el array
          dispatch(
            getStocksArticulos(
              articulos_aux, //articulos_stock
              props.origen, //origen
              parametros.maxim_agrup, // Maximo de peticiones agrupadas
            ),
          )
          // Guardamos los valores a prevProps
          prevProps.current.aux_stock = articulos_aux
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sustitutos])

  const masArticulos = e => {
    e.preventDefault()
    let articulos_siguiente = ''
    switch (props.origen) {
      case 'articulos':
        articulos_siguiente = articulos.articulos_siguiente
        break
      case 'equivalentes':
        articulos_siguiente = equivalentes.articulos_siguiente
        break
      case 'novedades':
        articulos_siguiente = novedades.articulos_siguiente
        break
      case 'ofertas':
        articulos_siguiente = ofertas.articulos_siguiente
        break
      case 'pedido_habitual':
        articulos_siguiente = pedido_habitual.articulos_siguiente
        break
      case 'relacionados':
        articulos_siguiente = relacionados.articulos_siguiente
        break
      case 'sustitutos':
        articulos_siguiente = sustitutos.articulos_siguiente
        break
      default:
        articulos_siguiente = articulos.articulos_siguiente
        break
    }

    dispatch(getMasArticulos(articulos_siguiente, filtros_aplicados, props.origen))
  }

  const limpiarBusqueda = e => {
    e.preventDefault()
    dispatch(setBusqueda(''))
    dispatch(limpiarBusquedaAvanzada())

    if (filtros_aplicados.length > 0) {
      dispatch(
        getArticulosByFamily(
          filtros_aplicados,
          articulos_pagina,
          articulos_orden,
          '',
          pedido.domicilio_envio,
          'N',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ),
      )
    } else {
      dispatch(
        getArticulos(
          '',
          articulos_pagina,
          articulos_orden,
          pedido.domicilio_envio,
          'N',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ),
      )
    }
  }

  const onChange = e => {
    dispatch(cambiaArticulosOrden(e.target.value))

    if (props.origen === 'articulos') {
      if (filtros_aplicados.length > 0) {
        dispatch(
          getArticulosByFamily(
            filtros_aplicados,
            articulos_pagina,
            e.target.value,
            articulos_search,
            pedido.domicilio_envio,
            busqueda_avanzada.avanzada,
            busqueda_avanzada.codigo_articulo,
            busqueda_avanzada.codigo_barras,
            busqueda_avanzada.descripcion,
            busqueda_avanzada.palabras_clave,
            busqueda_avanzada.equivalentes,
            busqueda_avanzada.referencia_cliente,
            busqueda_avanzada.codigo_sinonimo,
          ),
        )
      } else {
        dispatch(
          getArticulos(
            articulos_search,
            articulos_pagina,
            e.target.value,
            pedido.domicilio_envio,
            busqueda_avanzada.avanzada,
            busqueda_avanzada.codigo_articulo,
            busqueda_avanzada.codigo_barras,
            busqueda_avanzada.descripcion,
            busqueda_avanzada.palabras_clave,
            busqueda_avanzada.equivalentes,
            busqueda_avanzada.referencia_cliente,
            busqueda_avanzada.codigo_sinonimo,
          ),
        )
      }
    }
  }

  const changeArtPag = e => {
    e.preventDefault()

    dispatch(cambiaArticulosPagina(e.target.value))

    if (filtros_aplicados.length > 0) {
      dispatch(
        getArticulosByFamily(
          filtros_aplicados,
          e.target.value,
          articulos_orden,
          articulos_search,
          pedido.domicilio_envio,
          busqueda_avanzada.avanzada,
          busqueda_avanzada.codigo_articulo,
          busqueda_avanzada.codigo_barras,
          busqueda_avanzada.descripcion,
          busqueda_avanzada.palabras_clave,
          busqueda_avanzada.equivalentes,
          busqueda_avanzada.referencia_cliente,
          busqueda_avanzada.codigo_sinonimo,
        ),
      )
    } else {
      dispatch(
        getArticulos(
          articulos_search,
          e.target.value,
          articulos_orden,
          pedido.domicilio_envio,
          busqueda_avanzada.avanzada,
          busqueda_avanzada.codigo_articulo,
          busqueda_avanzada.codigo_barras,
          busqueda_avanzada.descripcion,
          busqueda_avanzada.palabras_clave,
          busqueda_avanzada.equivalentes,
          busqueda_avanzada.referencia_cliente,
          busqueda_avanzada.codigo_sinonimo,
        ),
      )
    }
  }

  let articulos_render
  switch (props.origen) {
    case 'articulos':
      articulos_render = articulos
      break
    case 'equivalentes':
      let equivalentes_articulo = equivalentes.equivalentes.filter(
        equivalente => equivalente.codigo_articulo === props.id_articulo,
      )
      articulos_render = equivalentes_articulo[0]
        ? equivalentes_articulo[0]
        : (articulos_render = {
            codigo_articulo: '',
            articulos: [],
            articulos_total: 0,
            articulos_siguiente: '',
            articulos_anterior: '',
          })
      break
    case 'novedades':
      articulos_render = novedades
      break
    case 'ofertas':
      articulos_render = ofertas
      break
    case 'relacionados':
      articulos_render = relacionados
      break
    case 'pedido_habitual':
      articulos_render = pedido_habitual
      break
    case 'sustitutos':
      let sustitutos_articulo = sustitutos.sustitutos.filter(
        sustituto => sustituto.codigo_articulo === props.id_articulo,
      )
      articulos_render = sustitutos_articulo[0]
        ? sustitutos_articulo[0]
        : {
            codigo_articulo: '',
            articulos: [],
            articulos_total: 0,
            articulos_siguiente: '',
            articulos_anterior: '',
          }

      articulos_render.articulos ??= []
      break

    default:
      articulos_render = articulos
      break
  }

  // Mostrar los articulos equivalentes en tabla
  const verEquivalentes = codigo_articulo => {
    if (equivalentes?.codigo_articulo !== codigo_articulo) {
      dispatch(setArticuloEquivalentes(codigo_articulo))
    } else {
      dispatch(setArticuloEquivalentes(''))
    }
  }

  // Desactivar botón observaciones (que se encarga de ocultarlas), si tiene
  const desactivarBoton = articulo => {
    // Recuperamos los elementos a tratar
    let boton = document.getElementById('boton-obs-' + articulo)
    let text_area = document.getElementById('observaciones-' + articulo)
    // Comprobamos si el boton existe
    if (boton) {
      // Comprobamos si el textarea existe y tiene valor
      if (text_area.value || text_area.value === 0) {
        boton.disabled = false
      } else {
        boton.disabled = true
      }
    }
  }

  // Ocultar observacion en onClick del botón previo
  const modificarObs = articulo => {
    // Recuperamos los elementos a tratar
    let contenedor = document.getElementById('obs-desp-' + articulo)
    let text_area = document.getElementById('observaciones-' + articulo)
    // Comprobamos si el text_area tiene valor
    if (text_area.value || text_area.value === 0) {
      if (contenedor.hidden) {
        contenedor.hidden = false
      } else {
        contenedor.hidden = true
      }
    }
  }

  // Recuperar observaciones de la linea para subcomponentes
  const getObservaciones = articulo => {
    if (
      document.getElementById('observaciones-' + articulo)?.value ||
      document.getElementById('observaciones-' + articulo)?.value === 0
    ) {
      return document.getElementById('observaciones-' + articulo).value
    } else {
      return ''
    }
  }

  // Setear observaciones en linea de pedido
  const setObservaciones = articulo => {
    //Obtenemos el elemento
    let elem = document.getElementById('observaciones-' + articulo?.codigo_articulo)
    if (elem?.value || elem?.value === 0) {
      //Comprobamos si la linea existe en el pedido
      let index = buscarLineaPedido(articulo)

      if (index) {
        // Si el valor no es el mismo actualizamos
        if (elem.value !== pedido.lineas[index].observaciones) {
          pedido.lineas[index].observaciones = elem.value
          dispatch(postLinea(pedido.lineas[index], '', '', 'S'))
        }
      }
    }
  }

  // Recuperar linea de pedido correspondiente
  const buscarLineaPedido = articulo => {
    if (pedido?.lineas?.length > 0) {
      let index = -1
      // Buscamos la linea en el pedido
      index = pedido.lineas.findIndex(
        linea =>
          linea.articulo === articulo.codigo_articulo &&
          linea.tipo_linea === 'P' &&
          linea.presentacion_pedido === articulo.presentacion_web,
      )
      if (index !== -1) {
        return index
      } else {
        return null
      }
    }
  }

  const retornaFiltros = () => {
    return (
      <div className='tt-filters-options'>
        {retornaTotales()}
        {props.origen === 'articulos' ? (
          <>
            <div className='tt-btn-toggle'>
              <a href='#'>{i18n.t('general.filtros')}</a>
            </div>
            <div className='tt-sort'>
              {articulos_search ? (
                <a
                  href='#'
                  onClick={limpiarBusqueda}
                  className=''>
                  <FontAwesomeIcon icon={faTimes} /> {articulos_search}
                </a>
              ) : (
                ''
              )}
              {busqueda_avanzada?.avanzada ? (
                <a
                  href='#'
                  onClick={limpiarBusqueda}
                  className=''>
                  <FontAwesomeIcon icon={faTimes} />{' '}
                  {busqueda_avanzada.codigo_articulo && busqueda_avanzada.codigo_articulo !== ''
                    ? i18n.t('articulos.busavca') + ' ' + busqueda_avanzada.codigo_articulo + ' '
                    : ''}
                  {busqueda_avanzada.codigo_barras && busqueda_avanzada.codigo_barras !== ''
                    ? i18n.t('articulos.busavcb') + ' ' + busqueda_avanzada.codigo_barras + ' '
                    : ''}
                  {busqueda_avanzada.descripcion && busqueda_avanzada.descripcion !== ''
                    ? i18n.t('articulos.busavds') + ' ' + busqueda_avanzada.descripcion + ' '
                    : ''}
                  {busqueda_avanzada.palabras_clave && busqueda_avanzada.palabras_clave !== ''
                    ? i18n.t('articulos.busavpc') + ' ' + busqueda_avanzada.palabras_clave + ' '
                    : ''}
                  {busqueda_avanzada.codigo_articulo && busqueda_avanzada.equivalentes !== ''
                    ? i18n.t('articulos.busaveq') + ' ' + busqueda_avanzada.equivalentes + ' '
                    : ''}
                  {busqueda_avanzada.referencia_cliente &&
                  busqueda_avanzada.referencia_cliente !== ''
                    ? i18n.t('articulos.busavrc') + ' ' + busqueda_avanzada.referencia_cliente + ' '
                    : ''}
                  {busqueda_avanzada.codigo_sinonimo && busqueda_avanzada.codigo_sinonimo !== ''
                    ? i18n.t('articulos.busavcs') + ' ' + busqueda_avanzada.codigo_sinonimo + ' '
                    : ''}
                </a>
              ) : (
                ''
              )}
              <select
                name='orden'
                onChange={onChange}>
                <option value='Default Sorting'>{i18n.t('general.sortby')}</option>
                <option value='codigo'>{i18n.t('general.sortbyca')}</option>
                <option value='descripcion'>{i18n.t('general.sortbyds')}</option>
              </select>
              <select
                name='resultados'
                onChange={changeArtPag}>
                <option value=''>{i18n.t('general.mostrar')}</option>
                <option value='12'>12</option>
                <option value='24'>24</option>
                <option value='36'>36</option>
              </select>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    )
  }

  const retornaTotales = () => {
    if (!articulos_render) return ''

    const titulos = {
      articulos: 'articulos.tittart',
      equivalentes: 'articulos.titarteq',
      novedades: 'articulos.titnov',
      ofertas: 'articulos.titofe',
      relacionados: 'articulos.titartrl',
      pedido_habitual: 'articulos.titarthb',
      sustitutos: 'articulos.titartst',
    }

    const tituloKey = titulos[props.origen]
    const articulosLength = articulos_render?.articulos?.length || 0

    if (
      !tituloKey ||
      (articulosLength === 0 &&
        ['equivalentes', 'novedades', 'ofertas', 'relacionados', 'sustitutos'].includes(
          props.origen,
        ))
    ) {
      return null
    }

    return (
      <h1 className='tt-title'>
        {i18n.t(tituloKey)}{' '}
        {/* <span className='tt-title-total'>({articulos_render.articulos_total})</span> */}
      </h1>
    )
  }

  // const muestraStock = articulo => {
  //   if (
  //     !Number.isNaN(
  //       Number(stocks?.[articulo.codigo_articulo]?.[articulo.presentacion_web]?.['']?.stock),
  //     )
  //   ) {
  //     return stocks?.[articulo.codigo_articulo]?.[articulo.presentacion_web]?.['']?.stock
  //   } else {
  //     return stocks?.[articulo.codigo_articulo]?.[articulo.presentacion_web]?.['']?.texto_stock
  //   }
  // }

  let cNContentIndent =
    props.origen && (props.origen === 'relacionados' || props.origen === 'equivalentes')
      ? 'content-indent ' + props.origen
      : 'content-indent'

  if (props.mostrar === 'lista') {
    return (
      <Fragment>
        <div className={cNContentIndent}>
          {retornaFiltros()}
          <div
            className='tt-product-listing row'
            itemScope
            itemType='https://schema.org/ItemList'>
            {articulos_render.isLoading || parametros === undefined || parametros === null ? (
              <Spinner showSpinner={articulos_render.isLoading}></Spinner>
            ) : (
              articulos_render.articulos?.map((articulo, index) => (
                <div
                  key={articulo.id}
                  className='col-6 col-md-4 col-lg-3 col-xl-3 tt-col-item'
                  itemProp='itemListElement'
                  itemScope
                  itemType='https://schema.org/Product'>
                  <meta
                    itemProp='sku'
                    content={articulo.codigo_articulo}
                  />
                  <meta
                    itemProp='url'
                    content={creaURLArticulo(articulo?.id, articulo?.descrip_comercial)}
                  />
                  <div className='tt-product thumbprod-center'>
                    <div className='tt-image-box'>
                      {articulo.tiene_agrupaciones !== 'S' && anadir_lista && (
                        <BotonAnadirLista
                          origen={props.origen}
                          btn_subs={parametros.btn_subs}
                          codigo_articulo={articulo.codigo_articulo}
                          presentacion={articulo.presentacion_web}
                        />
                      )}
                      <Link
                        to={creaURLArticulo(
                          articulo?.codigo_articulo,
                          articulo?.descrip_comercial,
                        )}>
                        <span
                          className='tt-img'
                          itemScope
                          itemType='https://schema.org/ImageObject'>
                          <Imagen
                            codigo_articulo={articulo.codigo_articulo}
                            numero_imagen={1}
                            principal='S'
                            tipo_imagen='min'
                          />
                        </span>
                        <Badges codigoArticulo={articulo.codigo_articulo} />
                      </Link>
                    </div>
                    <div className='tt-description'>
                      <div className='tt-row'>
                        <ul className='tt-add-info'>
                          <li>
                            <Link
                              to={creaURLArticulo(
                                articulo?.codigo_articulo,
                                articulo?.descrip_comercial,
                              )}>
                              {articulo.codigo_articulo}
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <h2 className='tt-title'>
                        <Link
                          to={creaURLArticulo(
                            articulo?.codigo_articulo,
                            articulo?.descrip_comercial,
                          )}
                          itemProp='name'>
                          {articulo.descrip_comercial}
                        </Link>
                      </h2>
                      <div className='tt-price'>
                        <Precio
                          codigo_articulo={articulo.codigo_articulo}
                          presentacion={articulo.presentacion_web}
                          mostrar_iva={parametros.mostrar_iva}
                          tipo_precio={
                            user?.puede_modificar_precios && articulo.tiene_agrupaciones === 'S'
                              ? 'agrup'
                              : parametros.tipo_precio
                          }
                          mostrar_pres={parametros.mostrar_pres}
                          estilo_precio={parametros.estilo_precio}
                        />
                      </div>
                      {parametros.badgestock && parametros.badgestockfc ? (
                        <StockBadge
                          codigoArticulo={articulo.codigo_articulo}
                          showStock={parametros.badgestock}
                          showStockFC={parametros.badgestockfc}
                        />
                      ) : null}
                      <div className='tt-product-inside-hover'>
                        <div className='tt-row-btn'>
                          {articulo.tiene_agrupaciones !== 'S' ? (
                            <AnadirCarrito
                              articulo={articulo}
                              origen='lista'
                              index={index}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='tt-row-btn'>
                          {articulo.tiene_agrupaciones !== 'S' && anadir_lista ? (
                            <BotonAnadirLista
                              origen='articulos_mobile'
                              codigo_articulo={articulo.codigo_articulo}
                              presentacion={articulo.presentacion_web}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='fila-articulos-contenedor-textos'>
                      {articulo?.textos_articulo
                        ? Object.keys(articulo.textos_articulo['01']).map(txtIndex => {
                            return <p key={txtIndex}>{articulo.textos_articulo['01'][txtIndex]}</p>
                          })
                        : null}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        {props.origen !== 'relacionados' &&
        props.origen !== 'equivalentes' &&
        props.origen !== 'sustitutos' &&
        !articulos_render.isLoading &&
        articulos_render?.articulos?.length > 0 ? (
          <div className='text-center mt-5'>
            {articulos_render.articulos_siguiente && articulos_render.articulos_siguiente !== '' ? (
              <button
                onClick={masArticulos}
                className='btn btn-border'>
                {i18n.t('articulos.mosmasar')}
              </button>
            ) : (
              <div className='text-center mt-5'>
                <button className='btn btn-border01'>{i18n.t('articulos.nomasar')}</button>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
      </Fragment>
    )
  } else {
    return (
      <div className='tabla-articulos'>
        <div className='content-indent'>{retornaFiltros()}</div>
        {/*----------------------Tabla desctop----------------------*/}
        <div className='tt-article-table tabla_articulos_desctop row'>
          <div className='col-sm-12 col-md-12 col-lg-12 tabla_articulos_desctop_int'>
            {props.origen !== 'equivalentes' && (
              <div className='row titulos'>
                <div className='col-sm-1 col-md-1 col-lg-1'>{i18n.t('general.imagen')}</div>
                <div className='col-sm-1 col-md-1 col-lg-1'>{i18n.t('general.articulo')}</div>
                <div className='col-sm-3 col-md-3 col-lg-3'>{i18n.t('general.descrip')}</div>
                <div className='col-sm-1 col-md-1 col-lg-1 titulo_precio'>
                  {i18n.t('general.pvp')}
                </div>
                <div className='col-sm-1 col-md-1 col-lg-1 titulo_impuesto'>
                  {i18n.t('general.impuesto')}
                </div>
                <div className='col-sm-1 col-md-1 col-lg-1 titulo_descuento'>
                  {i18n.t('general.descuent')}
                </div>
                <div className='col-sm-1 col-md-1 col-lg-1'>{i18n.t('general.info')}</div>
                <div className='col-sm-1 col-md-1 col-lg-1'>{i18n.t('general.comment')}</div>
                <div className='col-sm-2 col-md-2 col-lg-2'>{i18n.t('general.actions')}</div>
              </div>
            )}
            {articulos_render.isLoading || parametros === undefined || parametros === null ? (
              <Spinner showSpinner={articulos_render.isLoading}></Spinner>
            ) : (
              articulos_render.articulos.map((articulo, index) => (
                <Fragment key={articulo.codigo_articulo + index}>
                  <div
                    className='row registro-articulos'
                    key={articulo.codigo_articulo + index}>
                    <div className={'col-12 registro-' + index}>
                      <div className='row datos-registro'>
                        <div className='col-sm-1 col-md-1 col-lg-1 imagen'>
                          <Link
                            to={creaURLArticulo(
                              articulo?.codigo_articulo,
                              articulo?.descrip_comercial,
                            )}>
                            <div className='tt-item-img'>
                              <Imagen
                                codigo_articulo={articulo.codigo_articulo}
                                principal='S'
                                numero_imagen={1}
                              />
                            </div>
                          </Link>
                        </div>
                        <div className='col-sm-1 col-md-1 col-lg-1 articulo'>
                          <div className='link-articulo'>
                            <Link
                              to={creaURLArticulo(
                                articulo?.codigo_articulo,
                                articulo?.descrip_comercial,
                              )}>
                              {articulo.codigo_articulo}
                            </Link>
                          </div>
                        </div>
                        <div className='col-sm-3 col-md-3 col-lg-3'>
                          {props.origen !== 'equivalentes' &&
                          articulo.tiene_equivalentes === 'S' ? (
                            <div className='row desc_articulo_equ'>
                              <button
                                className='boton-articulos-equ'
                                onClick={e => {
                                  e.preventDefault()
                                  verEquivalentes(articulo.codigo_articulo)
                                }}>
                                <List
                                  size={18}
                                  alt={i18n.t('articulos.altmoseq')}
                                />
                              </button>
                              <div className='descripcion'>{articulo.descrip_comercial}</div>
                            </div>
                          ) : (
                            <div className='row desc_articulo'>
                              <div className='descripcion'>{articulo.descrip_comercial}</div>
                            </div>
                          )}
                        </div>
                        <div className='col-sm-1 col-md-1 col-lg-1 precio PVP'>
                          <Precio
                            codigo_articulo={articulo.codigo_articulo}
                            presentacion={articulo.presentacion_web}
                            mostrar_iva={parametros.mostrar_iva}
                            tipo_precio={parametros.tipo_precio}
                            mostrar_pres={parametros.mostrar_pres}
                            estilo_precio={parametros.estilo_precio}
                          />
                        </div>
                        <div className='col-sm-1 col-md-1 col-lg-1'>
                          {/*IVA*/}
                          <Precio
                            codigo_articulo={articulo.codigo_articulo}
                            presentacion={articulo.presentacion_web}
                            mostrar_iva={parametros.mostrar_iva}
                            tipo_precio={'impuesto'}
                          />
                        </div>
                        <div className='col-sm-1 col-md-1 col-lg-1'>
                          {/*Bonif*/}
                          <Precio
                            codigo_articulo={articulo.codigo_articulo}
                            presentacion={articulo.presentacion_web}
                            mostrar_iva={parametros.mostrar_iva}
                            tipo_precio={'descuento'}
                          />
                        </div>
                        <div className='col-sm-1 col-md-1 col-lg-1'>
                          {/*Necesidades Articulo*/}
                          <ArticuloNecesidades
                            articulo={articulo}
                            origen='tabla'
                          />
                        </div>
                        <div className='col-sm-1 col-md-1 col-lg-1'>
                          {/*Observaciones*/}
                          <div className='botonera-mod'>
                            <button
                              className='btn modificar-obs'
                              id={'boton-obs-' + articulo.codigo_articulo}
                              onClick={() => modificarObs(articulo.codigo_articulo)}>
                              <Note
                                size={20}
                                alt={i18n.t('general.altaddob')}
                              />
                            </button>
                          </div>
                        </div>
                        <div className='col-sm-2 col-md-2 col-lg-2'>
                          <AnadirCarrito
                            articulo={articulo}
                            getObservaciones={getObservaciones}
                            origen='tabla'
                            anadir_lista={anadir_lista}
                            index={index}
                          />
                        </div>
                      </div>
                      <div className='row datos-desplegables'>
                        <div className={'col-12 desplegables-' + articulo.codigo_articulo}>
                          <div
                            className='row observaciones-desplegables'
                            hidden={true}
                            id={'obs-desp-' + articulo.codigo_articulo}>
                            <textarea
                              className='form-control form-observaciones-desplegables'
                              rows='2'
                              name='observaciones'
                              id={'observaciones-' + articulo.codigo_articulo}
                              onChange={e => {
                                e.preventDefault()
                                desactivarBoton(articulo.codigo_articulo)
                              }}
                              onBlur={e => {
                                e.preventDefault()
                                setObservaciones(articulo)
                              }}
                            />
                          </div>
                          {equivalentes?.codigo_articulo === articulo.codigo_articulo ? (
                            <div className='row tt-article-table tabla_equivalentes_desctop'>
                              <ArticulosEquivalentes
                                id_articulo={articulo.codigo_articulo}
                                origen='tabla'
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              ))
            )}
          </div>
        </div>
        {/*----------------------Tabla mobile----------------------*/}
        <div className='tt-article-table tabla_articulos_mobile row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            {articulos_render.isLoading || parametros === undefined || parametros === null ? (
              <Spinner showSpinner={articulos_render.isLoading}></Spinner>
            ) : (
              articulos_render.articulos.map((articulo, index) => (
                <div
                  className='row'
                  key={articulo.codigo_articulo}>
                  <div className='col-12'>
                    <div className='row '>
                      <div className='col-6 titulos'>{i18n.t('general.articulo')}</div>
                      <div className='col-6'>{articulo.codigo_articulo}</div>
                    </div>

                    <div className='row '>
                      <div className='col-12 titulos'>{i18n.t('general.descrip')}</div>
                      <div className='col-12'>
                        {props.origen !== 'equivalentes' && articulo.tiene_equivalentes === 'S' ? (
                          <div className='row'>
                            <button
                              className='boton-articulos-equ'
                              //data-tooltip="Mostrar artículos equivalentes"
                              onClick={e => {
                                e.preventDefault()
                                verEquivalentes(articulo.codigo_articulo)
                              }}>
                              <List size={20} />
                            </button>
                            <div className='descripcion'>
                              <textarea
                                className='form-control'
                                rows='2'
                                name='observaciones'
                                id={'observaciones-mob-' + articulo.codigo_articulo}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className='descripcion'>{articulo.descrip_comercial}</div>
                        )}
                      </div>
                    </div>
                    <div className='row '>
                      <div className='col-6 titulos'>{i18n.t('general.precio')}</div>
                      <div className='col-6'>
                        <Precio
                          codigo_articulo={articulo.codigo_articulo}
                          presentacion={articulo.presentacion_web}
                          mostrar_iva={parametros.mostrar_iva}
                          tipo_precio={parametros.tipo_precio}
                          mostrar_pres={parametros.mostrar_pres}
                          estilo_precio={parametros.estilo_precio}
                        />
                      </div>
                    </div>
                    <div className='row '>
                      <div className='col-12 titulos'>{i18n.t('general.actions')}</div>
                      <div className='col-12'>
                        <div className='row'>
                          <div className='col-12'>
                            <AnadirCarrito
                              articulo={articulo}
                              origen='tabla'
                              anadir_lista={anadir_lista}
                              index={index}
                              observaciones={
                                document.getElementById(
                                  'observaciones-mob-' + articulo.codigo_articulo,
                                )?.value
                                  ? document.getElementById(
                                      'observaciones-mob-' + articulo.codigo_articulo,
                                    ).value
                                  : ''
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {equivalentes?.codigo_articulo === articulo.codigo_articulo ? (
                      <div className='row '>
                        <div
                          className='col-12 titulos'
                          id='tit_equ_mob'>
                          {i18n.t('articulos.titarteq')}
                        </div>
                        <div className='tt-article-table tabla_equivalentes_mobile row'>
                          <ArticulosEquivalentes
                            id_articulo={articulo.codigo_articulo}
                            origen='tabla'
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        {props.origen !== 'relacionados' &&
        props.origen !== 'equivalentes' &&
        !articulos_render.isLoading &&
        articulos_render?.articulos?.length > 0 ? (
          <div className='text-center mt-5'>
            {articulos_render.articulos_siguiente && articulos_render.articulos_siguiente !== '' ? (
              <button
                onClick={masArticulos}
                className='btn btn-border'>
                {i18n.t('articulos.mosmasar')}
              </button>
            ) : (
              <div className='text-center mt-5'>
                <button className='btn btn-border01'>{i18n.t('articulos.nomasar')}</button>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}

export default ListadoArticulos
