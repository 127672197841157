import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { login } from '../../actions/auth'
import { getPedidoActivo } from '../../actions/pedidos'
import { getMenus } from '../../actions/portal'
import { ordenarMenu, programaActivo } from '../../helpers/funciones'
import i18n from '../../lang/idiomas'

const Login = props => {
  const dispatch = useDispatch()

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const hash = useSelector(state => state.auth.hash)
  const invitado = useSelector(state => state.auth.invitado)
  const portal = useSelector(state => state.portal.portal)
  const menus = useSelector(state => state.portal.menus)
  const id_pedido_seleccionado = useSelector(state => state.pedidos.id_pedido_seleccionado)
  const P = useSelector(state => state.auth.P)
  const rememberDate = useSelector(state => state.auth.rememberDate)
  const remember = useSelector(state => state.auth.remember)

  const [username, setUser] = useState('')
  const [password, setPass] = useState('')
  const [remember2, setRemember] = useState(false)

  useEffect(() => {
    if (P && P !== '' && rememberDate && rememberDate !== '') {
      if (Date.now() - rememberDate > 1296000000) {
        localStorage.removeItem('remember')
        localStorage.removeItem('P')
        localStorage.removeItem('rememberDate')
      } else {
        setUser(atob(P).split(':')[0])
        setPass(atob(P).split(':')[1])
        // eslint-disable-next-line
        setRemember(remember == 'true')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.wookie()
    dispatch(getMenus())
    if (menus) {
      if (programaActivo(menus, 'articulos')) {
        dispatch(getPedidoActivo(hash != null ? hash : '', 'S', id_pedido_seleccionado))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, invitado])

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(login(username, password, hash, remember2))
  }

  let menu_registro
  let direccion_registro = ''

  if (menus?.length > 0) {
    menus.sort(ordenarMenu)
    menu_registro = menus.find(
      menu =>
        (menu.activo === 'S' && menu.programa === 'registro') ||
        (menu.activo === 'S' && menu.programa === 'registroCompleto'),
    )
  }

  if (menu_registro) {
    direccion_registro = (
      <Link
        className='link-registro'
        to={'/' + menu_registro.programa}>
        {i18n.t('login.regform')}
      </Link>
    )
  }

  if (!isAuthenticated || invitado) {
    return (
      <div
        id='tt-pageContent'
        className='login'>
        <div className='container-indent'>
          <div className='container'>
            <h1 className='tt-title-subpages noborder'>{portal.descripcion}</h1>
            <div className='tt-login-form'>
              <div className='row    justify-content-center'>
                <div className='col-md-5'>
                  <div className='tt-item'>
                    <h2 className='tt-title'>{i18n.t('login.title')}</h2>
                    <div className='form-default justify-content-center'>
                      <form onSubmit={handleSubmit}>
                        <div className='form-group'>
                          <input
                            className='form-control'
                            type='text'
                            id='username'
                            autoComplete='username'
                            placeholder={i18n.t('usuarios.uusweb')}
                            onChange={e => setUser(e.target.value)}
                            value={username}
                          />
                        </div>
                        <div className='form-group'>
                          <input
                            className='form-control'
                            type='password'
                            id='password'
                            autoComplete='current-password'
                            placeholder={i18n.t('usuarios.ucont')}
                            onChange={e => setPass(e.target.value)}
                            value={password}
                          />
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            onChange={e => setRemember(e.target.checked)}
                            checked={remember2}
                            id='remember'
                          />
                          <label
                            className='form-check-label'
                            htmlFor='remember'>
                            {i18n.t('login.remember')}
                          </label>
                        </div>
                        <div className='row'>
                          <div className='col-auto mr-auto'>
                            <div className='form-group'>
                              <button
                                type='submit'
                                className='btn btn-primary'>
                                {i18n.t('login.login')}
                              </button>
                            </div>
                          </div>
                        </div>
                        <p>
                          {i18n.t('login.remembp')}{' '}
                          <Link
                            to='/recuperar'
                            className='link-rec-pass'>
                            {i18n.t('login.recpass')}
                          </Link>
                        </p>
                        <p>
                          {i18n.t('login.nocuenta')} {direccion_registro || ''}
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <Navigate to={'/'} />
  }
}

export default Login
