import {
  FETCH_PEDIDOS_PENDIENTES,
  FLUSH_PEDIDOS_PENDIENTES,
  GET_PEDIDOS_PENDIENTES,
  GET_PEDIDOS_PENDIENTES_FAIL,
  SET_VER_PEDIDO_PENDIENTE,
  DELETE_PEDIDO_PENDIENTE,
  PEDIDOS_PENDIENTES_ISLOADING,
} from "../actions/types";

const initialState = {
  pedidos_pendientes: [],
  pedido_pendiente_detalle: 0,
  lineas_pedido_pendiente_detalle: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PEDIDOS_PENDIENTES_ISLOADING:
      return { ...state, isLoading: true };
    case FETCH_PEDIDOS_PENDIENTES:
      return {
        ...state,
        pedidos_pendientes: [],
        pedido_pendiente_detalle: 0,
        lineas_pedido_pendiente_detalle: [],
        isLoading: true,
      };
    case FLUSH_PEDIDOS_PENDIENTES:
      return {
        pedidos_pendientes: [],
        pedido_pendiente_detalle: 0,
        lineas_pedido_pendiente_detalle: [],
        isLoading: false,
      };

    case SET_VER_PEDIDO_PENDIENTE:
      let pedido;
      if (action.payload) {
        pedido = state.pedidos_pendientes.filter((ped) => ped.id_pedido === parseInt(action.payload));
      }

      return {
        ...state,
        pedido_pendiente_detalle: action.payload ? parseInt(action.payload) : 0,
        lineas_pedido_pendiente_detalle: pedido?.[0]?.lineas ? pedido[0].lineas : [],
      };

    case GET_PEDIDOS_PENDIENTES:
      return {
        ...state,
        isLoading: false,
        pedidos_pendientes: action.payload,
      };
    case DELETE_PEDIDO_PENDIENTE:
      let pedidos_pdtes = state.pedidos_pendientes;
      pedidos_pdtes = pedidos_pdtes.filter((pedido) => pedido.id_pedido !== Number.parseInt(action.payload));
      return {
        ...state,
        pedidos_pendientes: pedidos_pdtes,
        isLoading: false,
      };
    case GET_PEDIDOS_PENDIENTES_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
