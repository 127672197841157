import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getArticulos, getArticulosByFamily } from '../../actions/articulos'
import { setFamiliaSeleccionada, unsetFamiliaSeleccionada } from '../../actions/familias'
import { añadirFiltro, limpiarFiltros, quitarFiltro } from '../../actions/filtros'
import i18n from '../../lang/idiomas'
import Spinner from '../layout/Spinner'

const ArticulosFiltros = () => {
  const dispatch = useDispatch()

  const filtros = useSelector(state => state.articulos.filtros)
  const filtros_claves_estadisticas = useSelector(
    state => state.articulos.filtros_claves_estadisticas,
  )
  const filtros_aplicados = useSelector(state => state.articulos.filtros_aplicados)
  const articulos_search = useSelector(state => state.listadoArticulos.articulos_search)
  const articulos_pagina = useSelector(state => state.listadoArticulos.articulos_pagina)
  const articulos_orden = useSelector(state => state.listadoArticulos.articulos_orden)
  const filtros_aplicables = useSelector(
    state => state.listadoArticulos.articulos.filtros_aplicables,
  )
  const filtros_claves_aplicables = useSelector(
    state => state.listadoArticulos.articulos.filtros_claves_aplicables,
  )
  const pedido = useSelector(state => state.pedidos.pedido)
  const busqueda_avanzada = useSelector(state => state.listadoArticulos.articulos_advanced_search)
  const familias = useSelector(state => state.familias.familias)
  const familia_seleccionada = useSelector(state => state.familias.familia_seleccionada)

  const prevProps = useRef()

  useEffect(() => {
    if (prevProps.current) {
      if (filtros_aplicados.length > 0) {
        dispatch(
          getArticulosByFamily(
            filtros_aplicados,
            articulos_pagina,
            articulos_orden,
            articulos_search,
            pedido.domicilio_envio,
            busqueda_avanzada.avanzada,
            busqueda_avanzada.codigo_articulo,
            busqueda_avanzada.codigo_barras,
            busqueda_avanzada.descripcion,
            busqueda_avanzada.palabras_clave,
            busqueda_avanzada.equivalentes,
          ),
        )
      } else {
        dispatch(
          getArticulos(
            articulos_search,
            articulos_pagina,
            articulos_orden,
            pedido.domicilio_envio,
            busqueda_avanzada.avanzada,
            busqueda_avanzada.codigo_articulo,
            busqueda_avanzada.codigo_barras,
            busqueda_avanzada.descripcion,
            busqueda_avanzada.palabras_clave,
            busqueda_avanzada.equivalentes,
          ),
        )
      }
    }

    prevProps.current = filtros_aplicados

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filtros_aplicados)])

  const onClick = e => {
    e.preventDefault()

    let _tipo_filtro = e.target.dataset.tipo_filtro

    if (_tipo_filtro && _tipo_filtro === 'estad') {
      let _estadistico = e.target.dataset.estadistico
      let _familia = e.target.dataset.codigo
      let _nombre = e.target.dataset.familia

      let findPos = elem => elem.estadistico === _estadistico && elem.familia === _familia

      if (filtros_aplicados.findIndex(findPos) === -1) {
        dispatch(
          añadirFiltro({
            estadistico: _estadistico,
            familia: _familia,
            nombre: _nombre,
            tipo: 'estad',
          }),
        )
        dispatch(setFamiliaSeleccionada(_estadistico, _familia, _nombre))
      }
    } else {
      let _clave_estadistica = e.target.dataset.clave_estadistica
      let _valor_clave = e.target.dataset.valor_clave
      let _nombre = e.target.dataset.nombre_clave

      let findPos = elem => elem.estadistico === _clave_estadistica && elem.familia === _valor_clave

      if (filtros_aplicados.findIndex(findPos) === -1) {
        dispatch(
          añadirFiltro({
            estadistico: _clave_estadistica,
            familia: _valor_clave,
            nombre: _nombre,
            tipo: 'clave',
          }),
        )
        dispatch(setFamiliaSeleccionada(_clave_estadistica, _valor_clave, _nombre))
      }
    }
  }

  const eliminarFiltros = e => {
    e.preventDefault()
    dispatch(limpiarFiltros())
  }

  const eliminarFiltro = e => {
    e.preventDefault()
    const familia = familias.filter(
      elem => elem.codigo_familia === familia_seleccionada.codigo_familia,
    )[0]

    if (familia?.codigo_padre == null) {
      dispatch(unsetFamiliaSeleccionada())
      dispatch(limpiarFiltros())
    } else {
      const familia_padre = familias.filter(elem => elem.codigo_familia === familia.codigo_padre)[0]

      const e = {
        currentTarget: {
          dataset: {
            estadistico: familia_padre.numero_tabla,
            codigo: familia_padre.codigo_familia,
            familia: familia_padre.descripcion,
          },
        },
      }
      let _estadistico = e.currentTarget.dataset.estadistico
      let _familia = e.currentTarget.dataset.codigo
      let _nombre = e.currentTarget.dataset.familia

      dispatch(unsetFamiliaSeleccionada())
      dispatch(
        quitarFiltro({
          estadistico: _estadistico,
          familia: _familia,
          nombre: _nombre,
        }),
      )

      añadirFiltro({
        estadistico: _estadistico,
        familia: _familia,
        nombre: _nombre,
        tipo: 'estad',
      })
    }
  }

  const returnAplicables = filtro => {
    if (filtro?.claves?.length > 0) {
      filtro.claves.map(clave => {
        if (
          filtros_claves_aplicables.some(
            filtro_aplicable =>
              filtro_aplicable.clave_estadistica === filtro.clave_estadistica &&
              filtro_aplicable.claves.some(
                valor_clave_aplicable => valor_clave_aplicable === clave.valor_clave,
              ),
          )
        ) {
          return (
            <li
              key={clave.clave + clave.valor_clave}
              className=''>
              <a
                href='/#'
                data-nombre_clave={clave.nombre}
                data-clave_estadistica={clave.clave}
                data-valor_clave={clave.valor_clave}
                data-tipo_filtro='clave'
                onClick={onClick}>
                {clave.nombre}
              </a>
            </li>
          )
        } else return null
      })
    } else return null
  }

  if (
    filtros_aplicados?.length > 0 ||
    filtros?.filtros?.length > 0 ||
    filtros_claves_estadisticas?.filtros_claves_estadisticas?.length > 0
  ) {
    return (
      <div className='col-md-4 col-lg-3 col-xl-3 leftColumn aside'>
        <Spinner showSpinner={filtros.isLoading || filtros_claves_estadisticas.isLoading}></Spinner>
        <div className='tt-btn-col-close'>
          <a href='/#'>{i18n.t('general.cerrar')}</a>
        </div>
        <div className='tt-collapse open tt-filter-detach-option'>
          <div className='tt-collapse-content'>
            <div className='filters-mobile'>
              <div className='filters-row-select'></div>
            </div>
          </div>
        </div>
        {filtros_aplicados?.length > 0 && (
          <div className='tt-collapse open '>
            <h3 className='tt-collapse-title'>{i18n.t('articulos.filtact')}</h3>
            <div className='tt-collapse-content'>
              <ul className='tt-filter-list'>
                {filtros_aplicados &&
                  filtros_aplicados.length > 0 &&
                  filtros_aplicados.map((filtro, index) => {
                    let nombre_filtro = filtro.nombre
                    if (nombre_filtro === '') {
                      let fam_sel
                      if (familias?.length > 0) {
                        fam_sel = familias.filter(
                          f =>
                            f.numero_tabla === parseInt(filtro.estadistico) &&
                            f.codigo_familia === filtro.familia,
                        )
                      }
                      if (fam_sel?.length === 0 && filtros) {
                        filtros.filtros.forEach(f => {
                          if (f.estadistico === parseInt(filtro.estadistico)) {
                            fam_sel = f.familias.filter(
                              f =>
                                f.numero_tabla === parseInt(filtro.estadistico) &&
                                f.codigo_familia === filtro.familia,
                            )
                          }
                        })
                      }
                      if (fam_sel?.length > 0) {
                        nombre_filtro = fam_sel[0].descripcion
                      }
                    }

                    return (
                      <li
                        className='active'
                        key={filtro.estadistico + filtro.familia}>
                        <a
                          href='/#'
                          data-estadistico={filtro.estadistico}
                          data-codigo={filtro.familia}
                          onClick={eliminarFiltro}>
                          {nombre_filtro}
                        </a>
                      </li>
                    )
                  })}
              </ul>
              <a
                href='/#'
                className='btn-link-02'
                onClick={eliminarFiltros}>
                {i18n.t('articulos.limptod')}
              </a>
            </div>
          </div>
        )}
        {filtros?.filtros?.length > 0 &&
          filtros.filtros.map(filtro => {
            if (
              filtros_aplicables.some(
                filtro_aplicable =>
                  filtro_aplicable.estadistico === filtro.estadistico &&
                  filtro?.familias?.length > 0,
              )
            ) {
              return (
                <div
                  key={filtro.estadistico + filtro.nombre}
                  className='tt-collapse open '>
                  <h3 className='tt-collapse-title'>{filtro.nombre}</h3>
                  <div className='tt-collapse-content'>
                    <ul className='tt-list-row'>
                      {filtro.familias.map((familia, index) => {
                        if (
                          filtros_aplicables.some(
                            filtro_aplicable =>
                              filtro_aplicable.estadistico === filtro.estadistico &&
                              filtro_aplicable.familias.some(
                                familia_aplicable => familia_aplicable === familia.codigo_familia,
                              ),
                          )
                        ) {
                          return (
                            <li
                              key={familia.numero_tabla + familia.codigo_familia}
                              className=''>
                              <a
                                href='/#'
                                data-familia={familia.descripcion}
                                data-estadistico={familia.numero_tabla}
                                data-codigo={familia.codigo_familia}
                                data-tipo_filtro='estad'
                                onClick={onClick}>
                                {familia.descripcion}
                              </a>
                            </li>
                          )
                        } else return null
                      })}
                    </ul>
                  </div>
                </div>
              )
            } else return null
          })}

        {filtros_claves_estadisticas?.filtros_claves_estadisticas?.length > 0 &&
          filtros_claves_estadisticas.filtros_claves_estadisticas.map((filtro, index) => {
            if (
              filtros_claves_aplicables.some(
                filtro_clave_aplicable =>
                  filtro_clave_aplicable.clave_estadistica === filtro.clave_estadistica,
              )
            ) {
              return (
                <div
                  key={filtro.nombre + filtros_aplicados.clave_estadistica}
                  className='tt-collapse open '>
                  <h3 className='tt-collapse-title'>{filtro.nombre}</h3>
                  <div className='tt-collapse-content'>
                    <ul className='tt-list-row'>{returnAplicables(filtro)}</ul>
                  </div>
                </div>
              )
            } else return null
          })}
      </div>
    )
  }
}

export default ArticulosFiltros
