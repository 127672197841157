import React, { useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import ReactModal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import { crearLista, postLineaLista, postLineasLista } from '../../actions/listasCompra'
import { closeModal } from '../../actions/modalGenerica'
import { postLinea } from '../../actions/pedidos'
import '../../css/modal.css'
import { programaActivo } from '../../helpers/funciones'
import i18n from '../../lang/idiomas'
import AnalyticsEdisa from '../common/AnalyticsEdisa'

ReactModal.setAppElement('#root')

const ModalGenerica = props => {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => state.modalGenerica.isOpen)
  const codigo_articulo = useSelector(state => state.modalGenerica.codigo_articulo)
  const cantidad_linea = useSelector(state => state.modalGenerica.cantidad_linea)
  const presentacion = useSelector(state => state.modalGenerica.presentacion)
  const lineas_carrito = useSelector(state => state.modalGenerica.lineas)
  const origen = useSelector(state => state.modalGenerica.origen)
  const listas_compra = useSelector(state => state.listasCompra.listas_compra)
  const menus = useSelector(state => state.portal.menus)
  const pedido = useSelector(state => state.pedidos.pedido)
  const hash = useSelector(state => state.auth.hash)
  const id_pedido_seleccionado = useSelector(state => state.pedidos.id_pedido_seleccionado)
  const presentaciones = useSelector(state => state.presentaciones.presentaciones)

  const [lista_seleccionada, setListaSeleccionada] = useState('')
  const [nueva_lista, setNuevaLista] = useState(false)
  const [nombre_lista, setNombreLista] = useState('')
  const [filesAux, setFilesAux] = useState([])
  const [fileInput, setFileInput] = useState(React.createRef)
  const [checkNoRc, setCheckNorc] = useState(false)
  const [checkRE, setCheckRE] = useState(false)
  const [checkRP, setCheckRP] = useState(false)
  const [valor, setValor] = useState('')

  const handleListaSeleccionadaChange = e => {
    setListaSeleccionada(e.target.value)
  }

  const handleValorChange = e => {
    setValor(e.target.value)
  }

  const handleNombreListaChange = e => {
    setNombreLista(e.target.value)
  }

  const handlCheckNoRcChange = e => {
    setCheckNorc(e.target.value)
  }

  const handleCheckREChange = e => {
    setCheckRE(e.target.value)
  }

  const handleCheckRPChange = e => {
    setCheckRP(e.target.value)
  }

  const onSubmit = e => {
    e.preventDefault()
    if (origen && origen !== '') {
      switch (origen) {
        case 'lin-ped-obs':
          // Actualizar observaciones en linea de pedido
          if (pedido.lineas && pedido.lineas.length > 0) {
            let index = -1
            index = pedido.lineas.findIndex(linea => linea.id === lineas_carrito.id)
            if (index !== -1) {
              pedido.lineas[index].observaciones = valor
              dispatch(postLinea(pedido.lineas[index], '', pedido.id_pedido, 'S'))
            }
          }
          break
        case 'carnet-articulo':
          // Futuro codigo de insercion de carnet profesional
          break
        case 'receta':
          // Seleccionar tipo receta
          if (checkNoRc || checkRE || checkRP || (filesAux && filesAux?.length > 0)) {
            if (checkNoRc) {
              //No receta
              lineas_carrito.valida_receta = 'M1'
            } else if (checkRE) {
              //Receta Electronica
              lineas_carrito.valida_receta = 'M2'
            } else if (checkRP) {
              //Receta Papel
              lineas_carrito.valida_receta = 'M3'
            } else {
              //Añade receta
              lineas_carrito.valida_receta = 'M'
            }

            //Reiniciamos estado
            setListaSeleccionada('')
            setNuevaLista(false)
            setNombreLista('')
            setFilesAux([])
            setFileInput(React.createRef())
            setCheckNorc(false)
            setCheckRE(false)
            setCheckRP(false)

            dispatch(
              postLinea(lineas_carrito, hash, id_pedido_seleccionado || pedido?.id_pedido, 'N'),
            )
          } else {
            confirmAlert({
              title: i18n.t('modal.faltdat'),
              message: i18n.t('modal.debarec'),
              closeOnEscape: true,
              closeOnClickOutside: true,
              buttons: [
                {
                  label: i18n.t('general.aceptar'),
                  onClick: () => {
                    ''
                  },
                },
              ],
            })
          }
          break
        default:
          break
      }
    } else {
      if (nueva_lista) {
        // Crear nueva lista
        if (nombre_lista !== '') {
          dispatch(crearLista(nombre_lista))
          setNombreLista('')
          setNuevaLista(false)
          return
        }
      }

      if (codigo_articulo && codigo_articulo !== '') {
        const linea = {
          codigo_lista: lista_seleccionada,
          codigo_articulo: codigo_articulo,
          cantidad: cantidad_linea,
          cantidad_pedida: cantidad_linea,
          presentacion_pedido: presentacion,
          pres_fc: presentaciones?.[codigo_articulo]?.[presentacion]?.factor_conversion || '',
        }
        dispatch(postLineaLista(linea))

        let analytics = new AnalyticsEdisa()

        analytics.registraEvento({
          modulo: 'Articulos',
          tipo: 'AddToWishlist',
          valor: codigo_articulo,
          params: {
            codigo_articulo: codigo_articulo,
          },
        })
      } else if (lineas_carrito && lineas_carrito.length > 0) {
        let lineas = []

        lineas_carrito.forEach(linea_carrito => {
          let lin = {
            codigo_lista: lista_seleccionada,
            codigo_articulo: linea_carrito.articulo,
            cantidad: linea_carrito.cantidad_pedida,
            cantidad_pedida: Number.parseFloat(linea_carrito.cantidad_pedida),
            presentacion_pedido: linea_carrito.presentacion_pedido,
            observaciones: linea_carrito.observaciones,
            sub_pres: linea_carrito.sub_pres,
            sub_pres_cant: Number.parseInt(linea_carrito.sub_pres_cant) || '',
            sub_pres_fc: Number.parseFloat(linea_carrito.sub_pres_fc) || '',
            pres_fc: Number.parseFloat(linea_carrito.pres_fc) || '',
            presentacion_escogida:
              linea_carrito.presentacion_escogida !== '' ? linea_carrito.presentacion_escogida : '',
          }
          lineas.push(lin)
        })

        dispatch(postLineasLista(lineas, '', id_pedido_seleccionado))
      }
    }
    dispatch(closeModal())
  }

  const cerrarModal = e => {
    e.preventDefault()

    setListaSeleccionada('')
    setNuevaLista(false)
    setNombreLista('')
    setFilesAux([])
    setFileInput(React.createRef())
    setCheckNorc(false)

    dispatch(closeModal())
  }

  const modalNuevaLista = e => {
    e.preventDefault()
    setNuevaLista(true)
  }

  let customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  if (origen && origen !== '') {
    switch (origen) {
      case 'lin-ped-obs':
        customStyles.content.width = '50%'
        return (
          <ReactModal
            isOpen={isOpen}
            onRequestClose={cerrarModal}
            style={customStyles}>
            <div>
              <h5>{i18n.t('modal.obsrcns')}</h5>
            </div>
            <div>
              <form
                id='modificarObservaciones'
                onSubmit={onSubmit}>
                <textarea
                  className='form-control'
                  rows={5}
                  name='valor'
                  id={'modificar-observaciones'}
                  defaultValue={lineas_carrito.observaciones}
                  onChange={handleValorChange}
                />
                <div className='botonera-modal'>
                  <button
                    type='submit'
                    form='modificarObservaciones'
                    className='btn btn-primary'>
                    {i18n.t('modal.ogrdr')}
                  </button>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={cerrarModal}>
                    {i18n.t('modal.ocrr')}
                  </button>
                </div>
              </form>
            </div>
          </ReactModal>
        )
      case 'carnet-articulo':
        customStyles.content.width = '50%'
        return (
          <ReactModal
            isOpen={isOpen}
            onRequestClose={cerrarModal}
            style={customStyles}>
            <div>
              <h5>{i18n.t('modal.mcarpro')}</h5>
            </div>
            <div className='modal-carnet-profesional'>
              <div className='datos-carnet'>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='row'>
                        <div className='col-5'>
                          <h6>{i18n.t('modal.mnumcar')}</h6>
                        </div>
                        <div className='col-7'>
                          <input
                            className='txt_numero_carnet'
                            value={lineas_carrito.numero_carnet}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='row'>
                        <div className='col-5'>
                          <h6>{i18n.t('modal.mcaduc')}</h6>
                        </div>
                        <div className='col-7'>
                          <input
                            className='txt_fecha_caducidad'
                            value={lineas_carrito.fecha_caducidad}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='row'>
                        <div className='col-5'>
                          <h6>{i18n.t('modal.mnivel')}</h6>
                        </div>
                        <div className='col-7'>
                          <input
                            className='txt_nivel'
                            value={lineas_carrito.nivel}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='row'>
                        <div className='col-5'>
                          <h6>{i18n.t('modal.mdescr')}</h6>
                        </div>
                        <div className='col-7'>
                          <input
                            className='txt_descripcion'
                            value={lineas_carrito.descripcion}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='datos-persona-fisica'>
                <h6>{i18n.t('modal.dtitul')}</h6>
                <div className='datos-personales'>
                  <div className='col-12'>
                    <div className='row'>
                      <div className='col-2'>
                        <h6>{i18n.t('modal.monombr')}</h6>
                      </div>
                      <div className='col-4'>
                        <input
                          className='txt_nombre'
                          value={lineas_carrito.nombre + ' ' + lineas_carrito.apellidos}
                          disabled={true}
                        />
                      </div>
                      <div className='col-2'>
                        <h6>{i18n.t('modal.modni')}</h6>
                      </div>
                      <div className='col-4'>
                        <input
                          className='txt_dni'
                          value={lineas_carrito.dni}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactModal>
        )
      case 'receta':
        return (
          <ReactModal
            isOpen={isOpen}
            onRequestClose={cerrarModal}
            style={customStyles}>
            <div>
              <h5>{i18n.t('modal.mselopc')}</h5>
            </div>
            <form
              id='añadirReceta'
              className='modal-receta'
              onSubmit={onSubmit}>
              <div className='col12'>
                <div className='row labels'>
                  <div className='col-4'>
                    <label htmlFor={'checkNoRc'}>{'No tengo receta: '}</label>
                  </div>
                  <div className='col-4'>
                    <label htmlFor={'checkRE'}>{'Dispongo de receta electronica: '}</label>
                  </div>
                  <div className='col-4'>
                    <label htmlFor={'checkRP'}>{'Dispongo de receta en papel: '}</label>
                  </div>
                </div>
                <div className='row checks'>
                  <div className='col-4'>
                    <input
                      id={'checkNoRc'}
                      type='checkbox'
                      name='checkNoRc'
                      checked={checkNoRc}
                      disabled={fileInput?.current?.files?.length > 0}
                      onChange={handlCheckNoRcChange}
                    />
                  </div>
                  <div className='col-4'>
                    <input
                      id={'checkRE'}
                      type='checkbox'
                      name='checkRE'
                      checked={checkRE}
                      disabled={fileInput?.current?.files?.length > 0}
                      onChange={handleCheckREChange}
                    />
                  </div>
                  <div className='col-4'>
                    <input
                      id={'checkRP'}
                      type='checkbox'
                      name='checkRP'
                      checked={checkRP}
                      disabled={fileInput?.current?.files?.length > 0}
                      onChange={handleCheckRPChange}
                    />
                  </div>
                </div>
                {}
              </div>
              <div className='botonera-modal'>
                <button
                  type='submit'
                  form='añadirReceta'
                  className='btn btn-primary'
                  disabled={!checkNoRc && !checkRE && !checkRP}>
                  {i18n.t('modal.o.ocrr')}
                </button>
                <button
                  type='button'
                  className='btn btn-secondary'
                  onClick={cerrarModal}>
                  {i18n.t('modal.ogrdr')}
                </button>
              </div>
            </form>
          </ReactModal>
        )
      default:
        break
    }
  } else {
    if (menus) {
      if (programaActivo(menus, 'listasCompra') && (codigo_articulo || lineas_carrito)) {
        return (
          <ReactModal
            isOpen={isOpen}
            onRequestClose={cerrarModal}
            style={customStyles}>
            <div>
              <div>
                <div>
                  <div>
                    {nueva_lista ? (
                      <h5>{i18n.t('modal.mnuelist')}</h5>
                    ) : (
                      <h5>{i18n.t('modal.manlist')}</h5>
                    )}
                  </div>
                  <div>
                    <form
                      id='anadirLista'
                      onSubmit={onSubmit}>
                      <div className='form-group'>
                        {!nueva_lista ? (
                          <>
                            <label htmlFor='lista_compra'>{i18n.t('modal.molist')}</label>
                            <select
                              className='form-control'
                              id='lista_compra'
                              name='lista_seleccionada'
                              onChange={handleListaSeleccionadaChange}>
                              <option value=''>{i18n.t('modal.mosellis')}</option>
                              {listas_compra
                                ? listas_compra.map((lista, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={lista.codigo_lista}>
                                        {lista.nombre_lista}
                                      </option>
                                    )
                                  })
                                : ''}
                            </select>
                          </>
                        ) : (
                          <div className='form-group'>
                            <label for='nombre_lista'>{i18n.t('modal.mnuelist')}</label>
                            <input
                              type='text'
                              className='form-control'
                              id='nombre_lista'
                              aria-describedby='nombre_lista_help'
                              placeholder={i18n.t('modal.monomlis')}
                              onChange={handleNombreListaChange}
                              name='nombre_lista'
                            />
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                  <div>
                    <button
                      type='submit'
                      form='anadirLista'
                      className='btn btn-primary'
                      disabled={
                        !nueva_lista && lista_seleccionada !== ''
                          ? false
                          : !(nueva_lista && nombre_lista !== '')
                      }>
                      {i18n.t('modal.ogrdr')}
                    </button>
                    <button
                      type='button'
                      className='btn btn-secondary'
                      onClick={cerrarModal}>
                      {i18n.t('modal.ocrr')}
                    </button>
                    {!nueva_lista ? (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '1rem',
                        }}>
                        <button
                          type='button'
                          className='btn btn-secondary'
                          onClick={modalNuevaLista}>
                          {i18n.t('modal.mnuelist')}
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ReactModal>
        )
      } else {
        return ''
      }
    }
  }
}
export default ModalGenerica
