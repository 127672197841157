import {
  ERROR_FETCHING_MAS_PLANTILLA_DETAIL,
  ERROR_FETCHING_PLANTILLA_DETAIL,
  ERROR_FETCHING_PLANTILLAS,
  FETCH_MAS_PLANTILLA_DETAIL,
  FETCH_PLANTILLAS,
  FETCH_PLANTILLA_DETAIL,
  FLUSH_PLANTILLAS,
  GET_MAS_PLANTILLA_DETAILS,
  GET_PLANTILLAS,
  GET_PLANTILLAS_DETAILS,
  SET_PLANTILLA_SELECCIONADA,
} from "../actions/types";

const initialState = {
  listaPlantillas: [],
  plantillaSeleccionada: null,
  plantillaDetails: [],
  totalArticulos: null,
  next: null,
  previous: null,
  mostrando: null,
  isLoading: false,
};

export default function reduce(state = initialState, action) {
  switch (action.type) {
    default:
      return state;

    case ERROR_FETCHING_MAS_PLANTILLA_DETAIL:
    case ERROR_FETCHING_PLANTILLA_DETAIL:
    case ERROR_FETCHING_PLANTILLAS:
      return {
        ...state,
        isLoading: false,
      };

    case FETCH_MAS_PLANTILLA_DETAIL:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_PLANTILLA_DETAIL:
      return {
        ...state,
        plantillaDetails: [],
        isLoading: true,
      };

    case FETCH_PLANTILLAS:
      return {
        ...state,
        listaPlantillas: [],
        isLoading: true,
      };

    case FLUSH_PLANTILLAS:
      return {
        listaPlantillas: [],
        plantillaSeleccionada: null,
        plantillaDetails: [],
        totalArticulos: null,
        next: null,
        previous: null,
        mostrando: null,
        isLoading: false,
      };

    case GET_PLANTILLAS:
      return {
        ...state,
        listaPlantillas: action.payload,
        isLoading: false,
      };

    case GET_MAS_PLANTILLA_DETAILS:
    case GET_PLANTILLAS_DETAILS:
      // Recuperamos estado a variable
      let nuevo_detalle = JSON.parse(JSON.stringify(state.plantillaDetails));
      // Recorremos los registros par eliminar datos innecesarios

      for (let articulo_detalle of action.payload.results) {
        let datos_articulo = JSON.parse(JSON.stringify(articulo_detalle));
        delete datos_articulo.codigo_empresa;
        delete datos_articulo.codigo_portal;
        delete datos_articulo.codigo_plantilla;
        // Insertamos registro en variable
        nuevo_detalle.push(datos_articulo);
      }

      return {
        ...state,
        plantillaDetails: nuevo_detalle,
        totalArticulos: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        mostrando: action.payload.offset,
        isLoading: false,
      };

    case SET_PLANTILLA_SELECCIONADA:
      return {
        ...state,
        plantillaSeleccionada: action.payload,
      };
  }
}
