import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDomEnvio, getEstado, getProvincia, setDireccion } from '../../actions/domiciliosEnvio'
import { getEstados } from '../../actions/estados'
import {
  setCodPostalDomEnvioMod,
  setDireccionDomEnvioMod,
  setEstadoDomEnvioMod,
  setLocalidadDomEnvioMod,
  setNombreDomEnvioMod,
  setProvinciaDomEnvioMod,
  setTipoPortesDomEnvioMod,
} from '../../actions/pedidos'
import { getProvincias } from '../../actions/provincias'
import i18n from '../../lang/idiomas'

const DomiciliosEnvio = props => {
  const dispatch = useDispatch()

  const domicilios_envio = useSelector(state => state.domiciliosEnvio.domicilios_envio)
  const domicilio = useSelector(state => state.domiciliosEnvio.domicilio)
  const estado = useSelector(state => state.domiciliosEnvio.estado)
  const provincia = useSelector(state => state.domiciliosEnvio.provincia)
  const pedido_cargando = useSelector(state => state.pedidos.isLoading)
  const provincias = useSelector(state => state.provincias.provincias)
  const estados = useSelector(state => state.estados.estados)
  const pedido = useSelector(state => state.pedidos.pedido)
  const forma_envio = useSelector(state => state.formasEnvio.forma_envio)

  const [state, setState] = useState({
    domicilio: '',
    estado: '',
    provincia: '',
  })

  useEffect(() => {
    dispatch(getDomEnvio())
    if (props.editable && estados.length === 0) {
      dispatch(getEstados())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!pedido_cargando) {
      if (domicilios_envio?.length === 1) {
        setState({ ...state, domicilio: domicilios_envio[0] })
        dispatch(getEstado(domicilios_envio[0].estado))
        dispatch(getProvincias(domicilios_envio[0].estado))
        dispatch(getProvincia(domicilios_envio[0].estado, domicilios_envio[0].provincia))
        dispatch(setDireccion(domicilios_envio[0]))
      }
      if (
        domicilios_envio?.length > 1 &&
        (pedido.domicilio_envio || pedido.domicilio_envio === 0) &&
        domicilio?.numero_direccion !== pedido.domicilio_envio
      ) {
        const dom_env = domicilios_envio.filter(
          domicilio => domicilio.numero_direccion === parseInt(pedido.domicilio_envio),
        )
        dispatch(getEstado(dom_env[0].estado))
        dispatch(getProvincias(dom_env[0].estado))
        dispatch(getProvincia(dom_env[0].estado, dom_env[0].provincia))
        dispatch(setDireccion(dom_env[0]))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pedido_cargando, domicilios_envio, forma_envio])

  const cargarTiposPortes = () => {
    // "tpdebids": "Debidos",
    // "tpcargds": "Cargados en factura",
    // "tppagads": "Pagados",
    // "tpsusmds": "Sus medios"
    return (
      <>
        <option
          key={'TP_N'}
          value={null}>
          {i18n.t('domicilios.tippormi')}
        </option>
        <option
          key={'TP_D'}
          value={'D'}>
          {i18n.t('domicilios.tpdebids')}
        </option>
        <option
          key={'TP_P'}
          value={'P'}>
          {i18n.t('domicilios.tpcargds')}
        </option>
        <option
          key={'TP_C'}
          value={'C'}>
          {i18n.t('domicilios.tppagads')}
        </option>
        <option
          key={'TP_S'}
          value={'S'}>
          {i18n.t('domicilios.tpsusmds')}
        </option>
      </>
    )
  }

  const onChange = e => {
    e.preventDefault()
    if (e.target.name === 'domicilio' && e.target.value !== '') {
      const direccion = domicilios_envio.filter(dom => dom.id === parseInt(e.target.value))

      setState({ ...state, domicilio: direccion[0] })

      dispatch(getEstado(direccion[0].estado))
      dispatch(getProvincias(direccion[0].estado))
      dispatch(getProvincia(direccion[0].estado, direccion[0].provincia))
      dispatch(setDireccion(direccion[0]))
      dispatch(setNombreDomEnvioMod(null))
      dispatch(setDireccionDomEnvioMod(null))
      dispatch(setLocalidadDomEnvioMod(null))
      dispatch(setEstadoDomEnvioMod(null))
      dispatch(setProvinciaDomEnvioMod(null))
      dispatch(setCodPostalDomEnvioMod(null))
    } else {
      switch (e.target.name) {
        case 'estado':
          dispatch(getProvincias(e.target.value))
          dispatch(setEstadoDomEnvioMod(e.target.value))
          break
        case 'provincia':
          dispatch(setProvinciaDomEnvioMod(e.target.value))
          break
        case 'tipo-portes':
          dispatch(setTipoPortesDomEnvioMod(e.target.value))
          break
        default:
          setState({
            ...state,
            [e.target.name]: e.target.value,
          })
          break
      }
    }
  }

  return (
    <div className='tt-shopcart-box domicilio-envio-box'>
      <h4 className='tt-title'>{i18n.t('domicilios.direnvio')}</h4>
      <p>{i18n.t('domicilios.seldiren')}</p>
      <form className='form-default'>
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div className='form-group'>
              <label htmlFor='input_domicilio'>
                {i18n.t('domicilios.domicil')} <sup>*</sup>
              </label>
              <select
                className='form-control'
                id='input_domicilio'
                name='domicilio'
                onChange={onChange}
                disabled={!!(pedido_cargando || props.pedidoGuardado)}
                value={domicilio.id || ''}>
                <option
                  key='0'
                  value=''
                  disabled>
                  {i18n.t('domicilios.seldoenv')}
                </option>
                {domicilios_envio.map(dom => {
                  return (
                    <option
                      key={dom.id}
                      value={dom.id}>
                      {dom.nombre}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12 col-md-6 col-lg-6'>
            <div className='form-group'>
              <label htmlFor='input_nombre'>{i18n.t('domicilios.nombre')}</label>
              <input
                type='text'
                id='input_nombre'
                name='nombre'
                value={pedido.nombre_dom_envio || domicilio.nombre || ''}
                className='form-control'
                placeholder='Nombre'
                onChange={e => {
                  dispatch(setNombreDomEnvioMod(e.target.value))
                }}
                disabled={pedido_cargando || props.pedidoGuardado || !props.editable}
              />
            </div>
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6'>
            <div className='form-group'>
              <label htmlFor='input_direccion'>{i18n.t('domicilios.direc')}</label>
              <input
                type='text'
                id='input_direccion'
                name='direccion'
                value={pedido.direccion_dom_envio || domicilio.direccion || ''}
                className='form-control'
                placeholder='Dirección'
                onChange={e => {
                  dispatch(setDireccionDomEnvioMod(e.target.value))
                }}
                disabled={pedido_cargando || props.pedidoGuardado || !props.editable}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-6 col-lg-6'>
            <div className='form-group'>
              <label htmlFor='input_estado'>
                {i18n.t('domicilios.estdomay')} <sup>*</sup>
              </label>

              {props.editable ? (
                <select
                  className='form-control'
                  name='estado'
                  id='input_estado'
                  onChange={onChange}
                  value={pedido.estado_dom_envio || domicilio.estado || ''}
                  placeholder='Estado'
                  disabled={pedido_cargando || props.pedidoGuardado}>
                  <option value=''>{i18n.t('domicilios.estdomin')}</option>
                  {estados?.length > 0
                    ? estados.map((estado, index) => {
                        return (
                          <option
                            key={index}
                            value={estado.codigo}>
                            {estado.nombre}
                          </option>
                        )
                      })
                    : ''}
                </select>
              ) : (
                <input
                  type='text'
                  id='input_estado'
                  name='estado'
                  value={estado?.nombre || ''}
                  className='form-control'
                  placeholder={i18n.t('domicilios.estdomin')}
                  onChange={onChange}
                  disabled={pedido_cargando || props.pedidoGuardado || !props.editable}
                />
              )}
            </div>
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6'>
            <div className='form-group'>
              <label htmlFor='input_provincia'>
                {i18n.t('domicilios.prov')} <sup>*</sup>
              </label>
              {props.editable ? (
                <select
                  className='form-control'
                  name='provincia'
                  id='input_provincia'
                  onChange={onChange}
                  value={pedido.provincia_dom_envio || domicilio.provincia || ''}
                  placeholder='Provincia'
                  disabled={pedido_cargando || props.pedidoGuardado}>
                  <option value=''>{i18n.t('domicilios.provmin')}</option>
                  {provincias && provincias.length > 0
                    ? provincias.map((provincia, index) => {
                        return (
                          <option
                            key={index}
                            value={provincia.provincia}>
                            {provincia.nombre}
                          </option>
                        )
                      })
                    : ''}
                </select>
              ) : (
                <input
                  type='text'
                  name='provincia'
                  id='input_provincia'
                  value={provincia?.nombre || ''}
                  className='form-control'
                  placeholder={i18n.t('domicilios.provmin')}
                  onChange={onChange}
                  disabled={pedido_cargando || props.pedidoGuardado || !props.editable}
                />
              )}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-4 col-lg-4'>
            <div className='form-group'>
              <label htmlFor='input_localidad'>{i18n.t('domicilios.local')}</label>
              <input
                type='text'
                name='localidad'
                id='input_localidad'
                value={pedido.localidad_dom_envio || domicilio.localidad || ''}
                className='form-control'
                placeholder={i18n.t('domicilios.localmin')}
                onChange={e => {
                  dispatch(setLocalidadDomEnvioMod(e.target.value))
                }}
                disabled={pedido_cargando || props.pedidoGuardado || !props.editable}
              />
            </div>
          </div>
          <div className='col-sm-12 col-md-4 col-lg-4'>
            <div className='form-group'>
              <label htmlFor='input_cod_postal'>{i18n.t('domicilios.codpstal')}</label>
              <input
                type='text'
                id='input_cod_postal'
                name='cod_postal'
                value={pedido.cod_postal_dom_envio || domicilio.cod_postal || ''}
                className='form-control'
                placeholder={i18n.t('domicilios.codposmi')}
                onChange={e => {
                  dispatch(setCodPostalDomEnvioMod(e.target.value))
                }}
                disabled={pedido_cargando || props.pedidoGuardado || !props.editable}
              />
            </div>
          </div>
          {props.editable ? (
            <div className='col-sm-12 col-md-4 col-lg-4'>
              <div className='form-group tipo-portes-group'>
                <label htmlFor='input_tipo_portes'>{i18n.t('domicilios.tipport')}</label>
                <select
                  className='form-control'
                  name='tipo-portes'
                  id='input_tipo_portes'
                  onChange={onChange}
                  value={pedido.tipo_portes_dom_envio || domicilio.tipo_portes || ''}
                  placeholder='Tipo portes'
                  disabled={pedido_cargando || props.pedidoGuardado}>
                  {cargarTiposPortes()}
                </select>
              </div>
            </div>
          ) : null}
        </div>
      </form>
    </div>
  )
}

export default DomiciliosEnvio
