import { Minus, Plus, X } from 'phosphor-react'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Precio from '../../ecommerce/Precio'
//Importar Acciones
import { createMessage } from '../../../actions/messages'
import { getPedidoActivo, postLineas, setIdPedidoSeleccionado } from '../../../actions/pedidos'
import { getPreciosYPermisos } from '../../../actions/precios'
import { getStocksArticulos } from '../../../actions/stocks'
import { cargarArrayPrecios, cargarArrayStocks, cargarParametros } from '../../../helpers/funciones'
import { multiploMasCercano } from '../../../helpers/multiplos'
import { creaURLArticulo } from '../../../helpers/urls/articulos'
import i18n from '../../../lang/idiomas'
import { crearPedido, getMasDetallePlantila, limpiarPlantillas } from '../actions/plantillas'
import { NO_ESTA_EL_PRECIO, generarLineasPedidos } from '../functions/addToCart'
import DatosPlantilla from './DatosPlantilla'

let parametros_usados = ['maxim_agrup']
let parametros

const DetallesPlantilla = props => {
  const dispatch = useDispatch()

  const plantillaDetails = useSelector(state => state.plantillas.plantillaDetails)
  const siguientePagina = useSelector(state => state.plantillas.next)
  const plantillaSeleccionada = useSelector(state => state.plantillas.plantillaSeleccionada)
  const precio = useSelector(state => state.precios.precio)
  const stock = useSelector(state => state.stocks.stock)
  const usuario = useSelector(state => state.auth.user)
  const subpresentacion = useSelector(state => state.subpresentaciones.subpresentacion)
  const presentacion = useSelector(state => state.presentaciones.presentacion)
  const pedido = useSelector(state => state.pedidos.pedido)
  const hash = useSelector(state => state.auth.hash)
  const pedidos_loading = useSelector(
    state => state.pedidos.isLoading || state.pedidos.isLoadingLines,
  )
  const isLoading = useSelector(state => state.plantillas.isLoading)
  const portal = useSelector(state => state.portal.portal)

  const [pedidoInicial, setPedidoInicial] = useState(null)
  const [responses, setResponses] = useState([])
  const [currentValues, setCurrentValues] = useState([])
  const [valores, setValores] = useState([])
  const prevProps = useRef()

  parametros = cargarParametros(parametros_usados, portal.parametros_ecom, 'PART')
  prevProps.current ??= {}

  useEffect(() => {
    if (!pedidoInicial && pedido?.id_pedido) {
      setPedidoInicial(pedido.id_pedido)
    }
    return () => {
      dispatch(limpiarPlantillas())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (plantillaDetails?.length > 0) {
      const mapped_elements = plantillaDetails.map(element => {
        return element.cantidad_minima
      })

      setCurrentValues(mapped_elements)
      let articulos_aux = []
      articulos_aux = cargarArrayPrecios('plantillas', plantillaDetails, precio)
      if (
        articulos_aux?.length > 0 &&
        JSON.stringify(prevProps.current.aux_precios) !== JSON.stringify(articulos_aux)
      ) {
        //Solo llamamos a getPrecios si hai al menos un elemento en el array
        dispatch(
          getPreciosYPermisos(
            articulos_aux, //articulos_cantidades
            'plantillas', //origen
            null, //domicilio_envio
            parametros.maxim_agrup, // Maximo de peticiones agrupadas
          ),
        )
        // Guardamos los valores a prevProps
        prevProps.current.aux_precios = articulos_aux
      }

      articulos_aux = cargarArrayStocks('plantillas', plantillaDetails, stock, pedido)
      if (
        articulos_aux?.length > 0 &&
        JSON.stringify(prevProps.current.aux_stock) !== JSON.stringify(articulos_aux)
      ) {
        //Solo llamamos a getStocks si hai al menos 1 elemento en el array

        dispatch(
          getStocksArticulos(
            articulos_aux, // articulos_stock
            'plantillas', // origen
            parametros.maxim_agrup, // Maximo de peticiones agrupadas
          ),
        )
        // Guardamos los valores a prevProps
        prevProps.current.aux_stock = articulos_aux
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantillaDetails])

  const createLinesBulk = pedido => {
    if (plantillaDetails?.length > 0) {
      const articulos_cantidades = []
      plantillaDetails.forEach((articulo, index) => {
        if (valores[index]) {
          articulos_cantidades.push({
            articulo,
            cantidad: parseFloat(valores[index]),
            cantidad_minima: articulo.cantidad_minima,
          })
        }
      })
      try {
        const lineasGeneradas = generarLineasPedidos(
          articulos_cantidades,
          pedido,
          'P',
          usuario,
          hash,
          precio,
          presentacion,
          subpresentacion,
        )
        dispatch(postLineas(lineasGeneradas, hash ?? '', pedido, failedLinesCreation))
      } catch (error) {
        console.error({ error })
      }
    }
  }
  const failedLinesCreation = () => {
    dispatch(setIdPedidoSeleccionado(pedidoInicial))
    dispatch(getPedidoActivo('', 'S', pedidoInicial))
  }

  const restarValor = (element, index) => {
    const val = JSON.parse(JSON.stringify(valores))
    const cantidad_minima = element.cantidad_minima
    let valor_nuevo = val[index] ? val[index] - cantidad_minima : cantidad_minima
    valor_nuevo = valor_nuevo < cantidad_minima ? cantidad_minima : valor_nuevo

    val[index] = valor_nuevo

    setValores(val)
  }

  const modificarValor = (element, value, index) => {
    const val = JSON.parse(JSON.stringify(valores))
    let valor_nuevo = parseFloat(value)
    let valor_final = multiploMasCercano(valor_nuevo, element.cantidad_minima)
    if (valor_final !== valor_nuevo) {
      dispatch(
        createMessage('El número debe ser multiplo de : ' || element.cantidad_minima, 'warning'),
      )
    }
    val[index] = valor_nuevo
    setValores(val)
  }

  const sumarValor = (element, index) => {
    const val = JSON.parse(JSON.stringify(valores))
    const cantidad_minima = element.cantidad_minima
    let valor_nuevo = val[index] ? val[index] + cantidad_minima : cantidad_minima
    valor_nuevo = valor_nuevo < cantidad_minima ? cantidad_minima : valor_nuevo

    val[index] = valor_nuevo

    setValores(val)
  }

  return (
    <>
      <DatosPlantilla />
      <div className='tt-shopcart-table listas_compra_detalle_desctop row'>
        <div className='col-sm-12 col-md-12 col-lg-12'>
          <div className='row titulos'>
            <div className='col-sm-12 col-md-2 col-lg-3'>{i18n.t('plantillas.parti')}</div>
            <div className='col-sm-12 col-md-3 col-lg-3'>{i18n.t('plantillas.pdescrip')}</div>
            <div className='col-sm-12 col-md-2 col-lg-3'>{i18n.t('plantillas.plprecio')}</div>
            <div className='col-sm-12 col-md-1 col-lg-3'>{i18n.t('plantillas.pcantid')}</div>
          </div>
          <span
            style={{
              display: 'block',
              background: '#2b2b2b',
              opacity: 0.12,
              marginBottom: '10px',
              width: '100%',
              height: '2px',
              marginTop: '5px',
              borderRadius: '1000px',
            }}></span>
          {plantillaDetails?.length > 0 ? (
            plantillaDetails.map((element, index) => {
              return (
                <div
                  key={`art${element.id}-l1 ${index}`}
                  className='row lista-plantilla-row'>
                  <div className='col-sm-12 col-md-3 col-lg-3'>
                    <Link
                      to={creaURLArticulo(element?.codigo_articulo, element?.descrip_comercial)}>
                      {element.codigo_articulo}
                    </Link>
                  </div>
                  <div className='col-sm-12 col-md-3 col-lg-3'>{element.descrip_comercial}</div>
                  <div className='col-sm-12 col-md-3 col-lg-3'>
                    <Precio
                      cantidad={1}
                      codigo_articulo={element.codigo_articulo}
                      presentacion={element.presentacion_web}
                      origen='ficha'
                    />
                  </div>
                  <div className='row col-sm-12 col-md-3 col-lg-3'>
                    <div className='row col-sm-12 col-md-12 col-lg-12 actions'>
                      <button
                        type='button'
                        className='col-2'
                        onClick={e => {
                          e.preventDefault()
                          restarValor(element, index)
                        }}>
                        <Minus
                          size={22}
                          weight={'bold'}
                        />
                      </button>
                      <input
                        className='quantity-input input-cantidad-listas prueba col-7'
                        onChange={e => {
                          e.preventDefault()
                          modificarValor(element, e.target.value, index)
                        }}
                        style={{ WebkitAppearance: 'none' }}
                        type='number'
                        min={element.cantidad_minima}
                        value={valores[index]}
                        step={1}
                        tabIndex={1}
                        name='cantidad'
                        id={'in-' + element.codigo_articulo}
                        key={element.codigo_articulo + index}
                        placeholder={element.cantidad_minima}
                        size='5'
                      />
                      <button
                        type='button'
                        className='col-2'
                        onClick={e => {
                          e.preventDefault()
                          sumarValor(element, index)
                        }}>
                        <Plus
                          size={22}
                          weight={'bold'}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <></>
          )}
        </div>
      </div>

      <div
        style={{ marginTop: '10px' }}
        className='tt-shopcart-table listas_compra_detalle_mobile row'>
        <div className='col-sm-12 col-md-12 col-lg-12'>
          {plantillaDetails?.length > 0 ? (
            plantillaDetails.map((element, index) => {
              return (
                <div
                  className='row row lista-plantilla-row'
                  key={'line' + element.descrip_comercial + index}>
                  <div className='col-12'>
                    <div className='row element-row'>
                      <div className='col-5 titulos'>{i18n.t('plantillas.parti')}</div>
                      <div className='col-7'>
                        <Link
                          to={creaURLArticulo(
                            element?.codigo_articulo,
                            element?.descrip_comercial,
                          )}>
                          {element.codigo_articulo}
                        </Link>
                      </div>
                    </div>

                    <div className='row element-row'>
                      <div className='col-5 titulos'>{i18n.t('plantillas.pdescrip')}</div>
                      <div className='col-7'>{element.descrip_comercial}</div>
                    </div>

                    <div className='row element-row'>
                      <div className='col-12 titulos'>{i18n.t('plantillas.plprecio')}</div>
                      <div
                        className='col-12'
                        style={{ display: 'flex' }}>
                        <Precio
                          cantidad={1}
                          codigo_articulo={element.codigo_articulo}
                          presentacion={element.presentacion_web}
                          origen='ficha'
                        />
                      </div>
                    </div>
                    <div className='row element-row'>
                      <div className='col-12 titulos'>{i18n.t('plantillas.pcantid')}</div>
                      <button
                        type='button'
                        className='col-2'
                        onClick={e => {
                          e.preventDefault()
                          restarValor(element, index)
                        }}>
                        <Minus
                          size={22}
                          weight={'bold'}
                        />
                      </button>
                      <div className='col-8'>
                        <input
                          className='quantity-input input-cantidad-listas prueba'
                          type='number'
                          name='cantidad'
                          style={{ WebkitAppearance: 'none' }}
                          id={'in-' + element.codigo_articulo}
                          key={'elemInp' + element.codigo_articulo + index}
                          value={currentValues[index] || element.cantidad_minima}
                          placeholder={element.cantidad_minima}
                          size='5'
                          onChange={e => {
                            e.preventDefault()
                            modificarValor(element, e.target.value, index)
                          }}
                        />
                      </div>
                      <button
                        type='button'
                        className='col-2'
                        onClick={e => {
                          e.preventDefault()
                          sumarValor(element, index)
                        }}>
                        <Plus
                          size={22}
                          weight={'bold'}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <></>
          )}
        </div>
      </div>
      {plantillaDetails?.length === 0 ? (
        <span className='plantilla-vacia'>
          {isLoading ? i18n.t('plantillas.pcardato') : i18n.t('plantillas.pnoartep')}
        </span>
      ) : (
        <></>
      )}
      <span style={{ marginTop: '40px', display: 'block' }}></span>

      {responses.length > 0 ? (
        <div className='alert-holder container'>
          <div className='row messages-titles-plantilla'>
            <div className='col-11'>{i18n.t('plantillas.pmsg')}</div>
            <div
              onClick={e => {
                e.preventDefault()
                setResponses([])
              }}
              className='col-1 exit-holder'>
              <X />
            </div>
          </div>
          {responses.map((element, index) => {
            return (
              <div
                key={'alert-error' + element.articulo + index}
                class='alert alert-danger'
                role='alert'>
                {element.message}
              </div>
            )
          })}
        </div>
      ) : (
        <></>
      )}

      {!isLoading && !pedidos_loading ? (
        <>
          <div
            className='row'
            style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
            {siguientePagina ? (
              <button
                onClick={e => {
                  e.preventDefault()

                  dispatch(getMasDetallePlantila(siguientePagina))
                }}
                className='btn btn-border col-md-2 col-lg-2 col-sm-12'>
                {i18n.t('articulos.mosmasar')}
              </button>
            ) : (
              <button className='btn btn-border01 col-md-2 col-lg-2 col-sm-12'>
                {i18n.t('articulos.nomasar')}
              </button>
            )}
          </div>
          <div className='row btn-holder'>
            <button
              disabled={isLoading || plantillaDetails?.length === 0 || valores?.length === 0}
              onClick={e => {
                e.preventDefault()
                let stop = false
                if (plantillaDetails?.length > 0) {
                  setResponses([])
                  let iterations = plantillaDetails.length
                  const articulos = plantillaDetails
                  for (let articulo_plantilla of articulos) {
                    stop = false
                    if (
                      !precio?.[articulo_plantilla.codigo_articulo]?.[
                        articulo_plantilla.presentacion_web
                      ] ||
                      precio?.[articulo_plantilla.codigo_articulo]?.[
                        articulo_plantilla.presentacion_web
                      ].status !== 'iddling'
                    ) {
                      setResponses([
                        ...responses,
                        {
                          articulo: articulo_plantilla,
                          message:
                            NO_ESTA_EL_PRECIO +
                            `${i18n.t('plantillas.pdelarti')} ` +
                            articulo_plantilla.descrip_comercial,
                        },
                      ])
                      stop = true
                    }
                    if (!--iterations && stop) {
                      return
                    }
                  }
                  if (
                    !pedido?.codigo_plantilla ||
                    pedido.codigo_plantilla !== plantillaSeleccionada.codigo_plantilla
                  ) {
                    dispatch(crearPedido(plantillaSeleccionada.codigo_plantilla, createLinesBulk))
                  } else {
                    createLinesBulk(pedido)
                  }
                }
              }}
              className='btn btn-primary btn-crear-pedido-plantilla col-md-2 col-lg-2 col-sm-12'>
              {!pedido?.codigo_plantilla ||
              pedido.codigo_plantilla !== plantillaSeleccionada.codigo_plantilla
                ? i18n.t('plantillas.pcrear').toUpperCase()
                : i18n.t('plantillas.pandir').toUpperCase()}
            </button>
          </div>
        </>
      ) : (
        ''
      )}

      <span className='cart-warn-plantillas'>{i18n.t('plantillas.pclcart')}</span>
    </>
  )
}

export default DetallesPlantilla
