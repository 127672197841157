import React, { Component, Fragment, forwardRef } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import i18n from '../../lang/idiomas'
import es from 'date-fns/locale/es'
registerLocale('es', es)

let id
let elemento
export class InformesFiltroFecha extends Component {
  state = {
    startDate:
      !Array.isArray(this.props.filtro.valor_inicial_filtro) &&
      this.props.filtro.valor_inicial_filtro
        ? Date.parse(this.props.filtro.valor_inicial_filtro)
        : this.props.filtro.valor_inicial_filtro_desde &&
          !Array.isArray(this.props.filtro.valor_inicial_filtro_desde)
        ? Date.parse(this.props.filtro.valor_inicial_filtro_desde)
        : null,
    endDate:
      !Array.isArray(this.props.filtro.valor_inicial_filtro_hasta) &&
      this.props.filtro.valor_inicial_filtro_hasta
        ? Date.parse(this.props.filtro.valor_inicial_filtro_hasta)
        : null,
  }

  handleChange = date => {
    this.setState({
      startDate: date,
    })
    // Seteamos elemento
    id = this.props.filtro.titulo_columna
    elemento = document.getElementById(id)
    if (elemento) {
      elemento.style.border = 'unset'
    }
  }

  handleChangeHasta = date => {
    if (!this.state.startDate || date < this.state.startDate) {
      this.setState({
        startDate: date,
        endDate: date,
      })
    } else {
      this.setState({
        endDate: date,
      })
    }
    // Seteamos elemento HASTA
    id = this.props.filtro.titulo_columna + '_HASTA'
    elemento = document.getElementById(id)
    if (elemento) {
      elemento.style.border = 'unset'
    }
  }

  handleChangeDesde = date => {
    if (!this.state.endDate || date > this.state.endDate) {
      this.setState({
        startDate: date,
        endDate: date,
      })
    } else {
      this.setState({
        startDate: date,
      })
    }
    // Seteamos elemento DESDE
    id = this.props.filtro.titulo_columna + '_DESDE'
    elemento = document.getElementById(id)
    if (elemento) {
      elemento.style.border = 'unset'
    }
  }

  render() {
    const InputDesde = ({ value, onClick }) => (
      <input
        className={'form-control ' + this.props.filtro.nombre_filtro_idi}
        id={this.props.filtro.titulo_columna + '_DESDE'}
        type='text'
        value={value}
        name={this.props.filtro.nombre_filtro + '_DESDE'}
        placeholder={this.props.filtro.titulo_columna + ' ' + i18n.t('general.desde')}
        required={this.props.filtro.obligatorio === 'S'}
        onClick={onClick}
        readOnly
      />
    )
    const InputHasta = ({ value, onClick }) => (
      <input
        className={'form-control ' + this.props.filtro.nombre_filtro_idi}
        id={this.props.filtro.titulo_columna + '_HASTA'}
        type='text'
        value={value}
        name={this.props.filtro.nombre_filtro + '_HASTA'}
        placeholder={this.props.filtro.titulo_columna + ' ' + i18n.t('general.hasta')}
        required={this.props.filtro.obligatorio === 'S'}
        onClick={onClick}
        readOnly
      />
    )

    const InputSimple = forwardRef(({ value, onClick }, ref) => (
      <input
        className={'form-control ' + this.props.filtro.nombre_filtro_idi}
        id={this.props.filtro.titulo_columna}
        type='text'
        value={value}
        name={this.props.filtro.nombre_filtro}
        placeholder={this.props.filtro.titulo_columna}
        required={this.props.filtro.obligatorio === 'S'}
        onClick={onClick}
        ref={ref}
        readOnly
      />
    ))

    this.props.filtro.mascara = this.props.filtro.mascara
      .replace('YYYY', 'yyyy')
      .replace('DD', 'dd')
    if (this.props.filtro.tipo_filtro === 'I') {
      return (
        <Fragment>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <DatePicker
                  onChange={this.handleChangeDesde}
                  isClearable
                  selected={this.state.startDate}
                  dateFormat={this.props.filtro.mascara ? this.props.filtro.mascara : 'dd/MM/yyyy'}
                  todayButton='Hoy'
                  locale='es'
                  customInput={<InputDesde />}
                  className='form-control'
                  name={this.props.filtro.nombre_filtro + '_DESDE'}
                  placeholderText={this.props.filtro.titulo_columna + ' ' + i18n.t('general.desde')}
                  required={this.props.filtro.obligatorio === 'S'}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <DatePicker
                  onChange={this.handleChangeHasta}
                  isClearable
                  selected={this.state.endDate}
                  dateFormat={this.props.filtro.mascara ? this.props.filtro.mascara : 'dd/MM/yyyy'}
                  todayButton='Hoy'
                  locale='es'
                  customInput={<InputHasta />}
                  className='form-control'
                  name={this.props.filtro.nombre_filtro + '_HASTA'}
                  placeholderText={this.props.filtro.titulo_columna + ' ' + i18n.t('general.hasta')}
                  required={this.props.filtro.obligatorio === 'S'}
                />
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group'>
                <DatePicker
                  onChange={this.handleChange}
                  isClearable
                  selected={this.state.startDate}
                  dateFormat={this.props.filtro.mascara ? this.props.filtro.mascara : 'dd/MM/yyyy'}
                  todayButton='Hoy'
                  locale='es'
                  customInput={<InputSimple />}
                  className='form-control'
                  name={this.props.filtro.nombre_filtro}
                  placeholderText={this.props.filtro.titulo_columna}
                  required={this.props.filtro.obligatorio === 'S'}
                />
              </div>
            </div>
          </div>
        </Fragment>
      )
    }
  }
}

export default InformesFiltroFecha
