import general from '../translations/general/pt_PT.json'
import informes from '../translations/informes/pt_PT.json'
import login from '../translations/login/pt_PT.json'
import resumen from '../translations/resumen/pt_PT.json'
// eslint-disable-next-line
export default {
  translation: {
    login,
    informes,
    general,
    resumen,
  },
}
