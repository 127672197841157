import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPlantillaSeleccionada, getDetallePlantila } from "../actions/plantillas";
import { Eye } from "phosphor-react";
import i18n from "../../../lang/idiomas";
 
const ListaPlantillas  = (props) => {
  const dispatch = useDispatch();
  
  const locale = useSelector((state) => state.precios.locale)
  const listaPlantillas = useSelector((state) => state.plantillas.listaPlantillas)
  const plantillasLoading = useSelector((state) => state.plantillas.isLoading)
 
 
  return listaPlantillas ? (
    <>
      <div style={{ marginTop: "10px" }} className="tt-shopcart-table listas_compra_detalle_desctop row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="row titulos">
            <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("plantillas.pdescrip")}</div>
            <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("plantillas.pcod")}</div>
            <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("plantillas.pfin")}</div>
            <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("plantillas.paccnes")}</div>
          </div>
          <span style={{ display: "block", background: "#2b2b2b", opacity: 0.12, width: "100%", height: "2px", marginTop: "5px", borderRadius: "1000px" }}></span>
          {listaPlantillas.map((element) => {
            return (
              <div key={`plantilla${element.id}`} className="row lista-compra-row">
                <div className="col-sm-12 col-md-3 col-lg-3">{element.descripcion}</div>
                <div className="col-sm-12 col-md-3 col-lg-3">{element.codigo_plantilla}</div>
                <div className="col-sm-12 col-md-3 col-lg-3">{isNaN(Date.parse(element.fecha_fin)) ? " - - - " : new Date(Date.parse(element.fecha_fin)).toLocaleDateString(locale)}</div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          dispatch(getDetallePlantila(element.codigo_plantilla));
                          dispatch(setPlantillaSeleccionada(element));
                        }}
                        data-codigo-lista={listaPlantillas.codigo_lista}
                        disabled={plantillasLoading}
                      >
                        <Eye size={22} weight={"regular"} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ marginTop: "10px" }} className="tt-shopcart-table listas_compra_detalle_mobile row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          {listaPlantillas.map((element) => {
            return (
              <div className="row lista-compra-row" key={element.descripcion}>
                <div className="col-12">
                  <div className="row element-row">
                    <div className="col-5 titulos">{i18n.t("plantillas.pdescrip")}</div>
                    <div className="col-7">{element.descripcion}</div>
                  </div>
 
                  <div className="row element-row">
                    <div className="col-5 titulos">{i18n.t("plantillas.pcod")}</div>
                    <div className="col-7">{element.codigo_plantilla}</div>
                  </div>
 
                  <div className="row element-row">
                    <div className="col-5 titulos">{i18n.t("plantillas.pfin")}</div>
                    <div className="col-7">{isNaN(Date.parse(element.fecha_fin)) ? " - - - " : new Date(Date.parse(element.fecha_fin)).toLocaleDateString(locale)}</div>
                  </div>
 
                  <div className="row element-row">
                    <div className="col-5 titulos">{i18n.t("plntillas.paccnes")}</div>
                    <div className="col-7">
                      <div className="row">
                        <button
                          className="btn btn-primary col-12"
                          onClick={() => {
                            dispatch(getDetallePlantila(element.codigo_plantilla));
                            dispatch(setPlantillaSeleccionada(element));
                          }}
                          data-codigo-lista={listaPlantillas.codigo_lista}
                          disabled={plantillasLoading}
                        >
                          <Eye size={22} weight={"regular"} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}
 
export default ListaPlantillas;
