import React, { Fragment, useEffect, useRef, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { useDispatch, useSelector } from 'react-redux'
import {
  getTiposExpedientesCampos,
  nuevoExpediente,
  reiniciaLV,
  setTipoExpediente,
  setValorCampoExpediente,
  validaCampoExpediente,
} from '../../actions/expedientes'
import { dynamicSort } from '../../helpers/funciones'
import i18n from '../../lang/idiomas'
import Spinner from '../layout/Spinner'
import InputFecha from './InputFecha'
import Selector from './Selector'

let elemento
let valores = {}
let variables = []
let index = -1

const NuevoExpediente = props => {
  const dispatch = useDispatch()
  const prevProps = useRef()

  const tipos_expedientes = useSelector(state => state.expedientes.tipos_expedientes)
  const tipo_expediente_seleccionado = useSelector(
    state => state.expedientes.tipo_expediente_seleccionado,
  )
  const tipos_expedientes_campos = useSelector(state => state.expedientes.tipos_expedientes_campos)
  const expedientes_loading = useSelector(state => state.expedientes.isLoading)
  const isLoading = useSelector(state => state.expedientes.isLoading)
  const isValidating = useSelector(state => state.expedientes.isValidating)

  const [state, setState] = useState({
    filesAux: [],
    fileInput: React.createRef(),
  })

  useEffect(() => {
    if (tipos_expedientes_campos?.length === 0 && !expedientes_loading) {
      dispatch(getTiposExpedientesCampos(tipos_expedientes[tipo_expediente_seleccionado]))
    } else {
      // Reiniciamos los valores de los campos
      for (const campo of tipos_expedientes_campos) {
        if (campo?.valor) {
          dispatch(setValorCampoExpediente(campo.columna, ''))
          setState({ ...state, [campo.columna]: '' })
        }
      }
    }
    prevProps.current = tipo_expediente_seleccionado
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isValidating) {
      document.body.style.cursor = 'wait'
    } else {
      document.body.style.cursor = 'default'
    }
  }, [isValidating])

  useEffect(() => {
    if (prevProps?.current !== tipo_expediente_seleccionado) {
      if (tipos_expedientes_campos?.length > 0 && !expedientes_loading) {
        dispatch(getTiposExpedientesCampos(tipos_expedientes[tipo_expediente_seleccionado]))
      }
    }

    prevProps.current = tipo_expediente_seleccionado
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipo_expediente_seleccionado])

  const reiniciaValoresPosteriores = campo => {
    // Reinicia los valores de los campos porteriores, en función del "orden"
    index = buscaIndice(tipos_expedientes_campos, campo)

    if (index !== -1) {
      for (let campo_aux of tipos_expedientes_campos) {
        if (campo_aux.orden > tipos_expedientes_campos[index].orden) {
          if (campo_aux.valor) {
            dispatch(setValorCampoExpediente(campo_aux.columna, ''))
            if (campo_aux.tipo_input === 'lv') {
              dispatch(
                reiniciaLV(
                  tipos_expedientes[tipo_expediente_seleccionado].tipo_expediente,
                  campo_aux.codigo_lov,
                  campo_aux.columna,
                ),
              )
            }
            setState({ ...state, [campo_aux.columna]: '' })
          }
        }
      }
    }
  }

  const onBlur = e => {
    // Reiniciamos variables
    variables = []

    // Recorremos los campos para cargar el array
    for (let campo of tipos_expedientes_campos) {
      variables.push({
        campo: campo.columna,
        valor: campo.valor || campo.valor === 0 ? campo.valor : '',
      })
    }
    // Llamamos a la validación
    dispatch(
      validaCampoExpediente(
        tipos_expedientes[tipo_expediente_seleccionado].programa,
        e.currentTarget.id,
        e.currentTarget.value,
        tipos_expedientes[tipo_expediente_seleccionado].id_pers,
        variables,
      ),
    )
  }

  const onChange = e => {
    index = buscaIndice(tipos_expedientes_campos, e.target.name)

    if (e.target.value !== tipos_expedientes_campos[index].valor) {
      if (
        tipos_expedientes_campos[index].tamano_max &&
        e.target.value.length > tipos_expedientes_campos[index].tamano_max
      ) {
        e.target.value = e.target.value.slice(0, tipos_expedientes_campos[index].tamano_max)
      }
      dispatch(setValorCampoExpediente(e.target.name, e.target.value))
      reiniciaValoresPosteriores(e.target.name)
    }

    setState({ ...state, [e.target.name]: e.target.value })
    elemento = document.getElementById(e.target.name)
    elemento.style.border = 'unset'
  }

  const onChangeFecha = (nombre_campo, valor_campo) => {
    index = buscaIndice(tipos_expedientes_campos, nombre_campo)

    if (valor_campo !== state[nombre_campo.value]) {
      reiniciaValoresPosteriores(nombre_campo)
      if (index !== -1) {
        variables = []
        if (tipos_expedientes_campos[index].validacion === 'S') {
          for (let campo of tipos_expedientes_campos) {
            variables.push({
              campo: campo.columna,
              valor: campo.valor ? campo.valor : '',
            })
          }
          dispatch(
            validaCampoExpediente(
              tipos_expedientes[tipo_expediente_seleccionado].programa,
              nombre_campo,
              valor_campo,
              tipos_expedientes[tipo_expediente_seleccionado].id_pers,
              variables,
            ),
          )
        }
      }
    }
    setState({ ...state, [nombre_campo]: valor_campo })
    elemento = document.getElementById(nombre_campo)
    if (elemento) {
      elemento.style.border = 'unset'
    }
  }

  const onChangeLOV = (nombre_campo, valor_campo) => {
    index = buscaIndice(tipos_expedientes_campos, nombre_campo)

    if (valor_campo !== state[nombre_campo]) {
      reiniciaValoresPosteriores(nombre_campo)
      if (index !== -1) {
        variables = []
        if (tipos_expedientes_campos[index].validacion === 'S') {
          for (let campo of tipos_expedientes_campos) {
            variables.push({
              campo: campo.columna,
              valor: campo.valor ? campo.valor : '',
            })
          }
          dispatch(
            validaCampoExpediente(
              tipos_expedientes[tipo_expediente_seleccionado].programa,
              nombre_campo,
              valor_campo,
              tipos_expedientes[tipo_expediente_seleccionado].id_pers,
              variables,
            ),
          )
        }
      }
    }
    setState({ ...state, [nombre_campo]: valor_campo })
    elemento = document.getElementById(nombre_campo)
    if (elemento) {
      elemento.style.border = 'unset'
    }
  }

  const buscaIndice = (array, nombre_campo) => {
    let index = -1
    index = array.findIndex(campo => campo.columna === nombre_campo)

    return index
  }

  const onSubmit = e => {
    e.preventDefault()
    let falta_campo = true
    let contador_campos = 0
    let id_campo = []
    tipos_expedientes_campos.forEach(campo_prop => {
      if (campo_prop.obligatorio === 'S') {
        falta_campo = true
        if (campo_prop.tipo_input === 'file') {
          if (state?.filesAux?.length > 0) {
            //Seteamos valor de campo a S (Indicando la existencia de los mismos)
            valores[campo_prop.columna] = 'S'
            falta_campo = false
          }
        } else {
          if (campo_prop.valor) {
            falta_campo = false
          }
        }

        if (falta_campo) {
          contador_campos = contador_campos + 1
          id_campo.push(campo_prop.columna)
        }
      } else {
        if (campo_prop.tipo_input === 'file') {
          if (state?.filesAux?.length > 0) {
            //Seteamos valor de campo a N (Indicando la existencia de los mismos)
            valores[campo_prop.columna] = 'S'
          } else {
            //Seteamos valor de campo a N (Indicando que no existen ficheros)
            valores[campo_prop.columna] = 'N'
          }
        }
      }
    })
    if (contador_campos === 0) {
      let readers = []
      let fileArray = []

      const readFile = file => {
        return new Promise(function (resolve, reject) {
          let fr = new FileReader()

          fr.onload = function () {
            resolve(fr.result)
          }

          fr.onerror = function () {
            reject(fr)
          }

          fr.readAsBinaryString(file)
        })
      }

      if (state.filesAux) {
        for (let index = 0; index < state.filesAux?.length; index++) {
          let selectedFile = state.filesAux?.item(index)
          if (selectedFile) {
            readers.push(readFile(selectedFile))
          }
        }
        Promise.all(readers).then(values => {
          for (let index = 0; index < state.filesAux.length; index++) {
            let selectedFile = state.filesAux.item(index)
            if (selectedFile) {
              let fileName = selectedFile?.name
              let fileType = selectedFile?.type
              let contenido = btoa(values[index])
              fileArray.push({
                nombre: fileName,
                tipo: fileType,
                contenido: contenido,
              })
            }
          }

          valores['id_personalizacion'] = tipos_expedientes[tipo_expediente_seleccionado].id_pers
          valores['tipo_expediente'] =
            tipos_expedientes[tipo_expediente_seleccionado].tipo_expediente
          valores['ficheros'] = fileArray?.length > 0 ? fileArray : []

          //Convertimos las keys a minusculas para evitar problemas en el back
          let val_aux = Object.keys(valores)
          val_aux.map(valor => {
            let val_aux_Upper = valor // Nombre campo original (Puede estar en mayusculas)
            let val_aux_Lower = val_aux_Upper.toLowerCase() // Nombre campo minusculas
            if (val_aux_Upper !== val_aux_Lower) {
              Object.defineProperty(
                valores,
                val_aux_Lower,
                Object.getOwnPropertyDescriptor(valores, val_aux_Upper),
              )
              delete valores[val_aux_Upper]
            }
            return valores
          })
          dispatch(nuevoExpediente(valores))
          dispatch(setTipoExpediente(tipo_expediente_seleccionado))
        })
      }
    } else {
      confirmAlert({
        title: i18n.t('expedientes.exfadat'),
        message: i18n.t('expedientes.exfacuca'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: i18n.t('general.aceptar'),
            onClick: () => {
              ''
            },
          },
        ],
        willUnmount: () => {
          for (const element of id_campo) {
            elemento = document.getElementById(element)
            elemento.style.border = '1px solid #FF0000'
          }
        },
      })
    }
  }

  const onSelectFile = e => {
    const fileNamesElement = document.getElementById(`fileNames-${e.id}`)
    if (state.fileInput?.current?.files?.length > 0) {
      setState({ ...state, filesAux: state.fileInput.current.files })
      const fileNames = Array.from(state.fileInput.current.files).map(file => file.name)
      fileNamesElement.textContent = fileNames.join(', ')
    } else {
      fileNamesElement.textContent = '' // Borrar el contenido si no se seleccionaron archivos
    }
  }

  let origen = props.origen
    ? (i18n.t('expedientes.exnuexp') + ' ( ' + props.origen + ' )').toUpperCase()
    : i18n.t('expedientes.exnuexp')

  tipos_expedientes_campos.forEach(campo_prop => {
    valores[campo_prop.columna] = campo_prop.valor || campo_prop.valor === 0 ? campo_prop.valor : ''
  })

  return (
    <Fragment>
      <div className='container'>
        <div id='tt-pageContent'>
          <div className='container-indent'>
            <div className='container'>
              <h1 className='tt-title-subpages noborder'>{origen}</h1>
              <div className='row justify-content-center'>
                <div className='col form-body'>
                  <div className='form-default justify-content-center'>
                    {!isLoading ? (
                      <form onSubmit={onSubmit}>
                        {tipos_expedientes_campos?.length > 0
                          ? tipos_expedientes_campos
                              .sort(dynamicSort('orden'))
                              .map((campo, index) => {
                                switch (campo.tipo_input) {
                                  case 'textarea':
                                    return (
                                      <div
                                        className='form-group text'
                                        key={index}>
                                        <label
                                          className={
                                            campo.desactivado === 'S'
                                              ? 'campo-expediente-label-disabled'
                                              : 'campo-expediente-label'
                                          }
                                          htmlFor={campo.columna}
                                          hidden={campo.oculto === 'S'}>
                                          {campo.literal}
                                        </label>
                                        <textarea
                                          id={campo.columna}
                                          className={
                                            'form-control' +
                                            (campo.desactivado === 'S'
                                              ? ' campo-expediente-input-disabled'
                                              : ' campo-expediente-input')
                                          }
                                          style={campo.ancho ? { width: campo.ancho } : null}
                                          type='text'
                                          maxLength={campo.tamano_max ? campo.tamano_max : 9999}
                                          hidden={campo.oculto === 'S'}
                                          disabled={!!(campo.desactivado === 'S' || isValidating)}
                                          name={campo.columna}
                                          placeholder={campo.literal}
                                          onChange={onChange}
                                          onBlur={campo.validacion === 'S' ? onBlur : null}
                                          value={campo.valor ? campo.valor : ''}
                                        />
                                      </div>
                                    )
                                  case 'text':
                                    return (
                                      <div
                                        className='form-group text'
                                        key={index}>
                                        <label
                                          className={
                                            campo.desactivado === 'S'
                                              ? 'campo-expediente-label-disabled'
                                              : 'campo-expediente-label'
                                          }
                                          htmlFor={campo.columna}
                                          hidden={campo.oculto === 'S'}>
                                          {campo.literal}
                                        </label>
                                        <input
                                          id={campo.columna}
                                          className={
                                            'form-control' +
                                            (campo.desactivado === 'S'
                                              ? ' campo-expediente-input-disabled'
                                              : ' campo-expediente-input')
                                          }
                                          style={campo.ancho ? { width: campo.ancho } : null}
                                          type='text'
                                          maxLength={campo.tamano_max ? campo.tamano_max : 9999}
                                          hidden={campo.oculto === 'S'}
                                          disabled={!!(campo.desactivado === 'S' || isValidating)}
                                          name={campo.columna}
                                          placeholder={campo.literal}
                                          onChange={onChange}
                                          onBlur={campo.validacion === 'S' ? onBlur : null}
                                          value={campo.valor ? campo.valor : ''}
                                        />
                                      </div>
                                    )

                                  case 'number':
                                    return (
                                      <div
                                        className='form-group number'
                                        key={index}>
                                        <label
                                          className={
                                            campo.desactivado === 'S'
                                              ? 'campo-expediente-label-disabled'
                                              : 'campo-expediente-label'
                                          }
                                          htmlFor={campo.columna}
                                          hidden={campo.oculto === 'S'}>
                                          {campo.literal}
                                        </label>
                                        <input
                                          id={campo.columna}
                                          className={
                                            'form-control' +
                                            (campo.desactivado === 'S'
                                              ? ' campo-expediente-input-disabled'
                                              : ' campo-expediente-input')
                                          }
                                          style={campo.ancho ? { width: campo.ancho } : null}
                                          type='number'
                                          maxLength={campo.tamano_max ? campo.tamano_max : 9999}
                                          hidden={campo.oculto === 'S'}
                                          name={campo.columna}
                                          placeholder={campo.literal}
                                          onChange={onChange}
                                          onBlur={campo.validacion === 'S' ? onBlur : null}
                                          disabled={!!(campo.desactivado === 'S' || isValidating)}
                                          value={
                                            campo.valor || campo.valor === 0
                                              ? campo.valor.toString()
                                              : ''
                                          }
                                        />
                                      </div>
                                    )

                                  case 'file':
                                    return (
                                      <div
                                        className='form-group file'
                                        key={index}>
                                        <label
                                          className={
                                            campo.desactivado === 'S'
                                              ? 'campo-expediente-label-disabled'
                                              : 'campo-expediente-label'
                                          }
                                          htmlFor={campo.columna}
                                          hidden={campo.oculto === 'S'}>
                                          {campo.literal}
                                        </label>
                                        <input
                                          id={campo.columna}
                                          ref={state.fileInput}
                                          type='file'
                                          hidden={campo.oculto === 'S'}
                                          multiple={true}
                                          onChange={ev => {
                                            onSelectFile(ev.nativeEvent.target)
                                          }}
                                        />
                                        <span id={`fileNames-${campo.columna}`}></span>
                                      </div>
                                    )

                                  case 'date':
                                    return (
                                      <InputFecha
                                        key={index}
                                        campo={campo}
                                        origen={'expedientes'}
                                        index={index}
                                        handleChange={onChangeFecha}
                                      />
                                    )

                                  case 'lv':
                                    return (
                                      <Selector
                                        key={index}
                                        campo={campo}
                                        origen={'expedientes'}
                                        index={index}
                                        handleChange={onChangeLOV}
                                        filtros={valores}
                                      />
                                    )

                                  default:
                                    return (
                                      <div
                                        className='form-group'
                                        key={index}>
                                        <label
                                          className={
                                            campo.desactivado === 'S'
                                              ? 'campo-expediente-label-disabled'
                                              : 'campo-expediente-label'
                                          }
                                          htmlFor={campo.columna}
                                          hidden={campo.oculto === 'S'}>
                                          {campo.literal}
                                        </label>
                                        <input
                                          id={campo.columna}
                                          className={
                                            'form-control' +
                                            (campo.desactivado === 'S'
                                              ? ' campo-expediente-input-disabled'
                                              : ' campo-expediente-input')
                                          }
                                          type='text'
                                          maxLength={campo.tamano_max ? campo.tamano_max : 9999}
                                          hidden={campo.oculto === 'S'}
                                          name={campo.columna}
                                          placeholder={campo.literal}
                                          onChange={onChange}
                                          disabled={!!(campo.desactivado === 'S' || isValidating)}
                                          value={campo.valor ? campo.valor : ''}
                                        />
                                      </div>
                                    )
                                }
                              })
                          : 'No se han encontrado campos'}
                        <div className='row'>
                          <div className='col-auto mr-auto'>
                            <div className='form-group'>
                              <button
                                type='submit'
                                className='btn btn-primary'>
                                {i18n.t('expedientes.exenv')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    ) : (
                      <Spinner showSpinner={isLoading} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default NuevoExpediente
