import React, { Fragment, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getImagen } from '../../actions/imagen'
import { cargarParametros } from '../../helpers/funciones'
import Spinner from '../layout/Spinner'

let src_pers = ''
let parametros_usados = ['img_cargadas']
let parametros

const Imagen = props => {
  const dispatch = useDispatch()
  const imagenes = useSelector(state => state.imagen.imagenes)
  const portal = useSelector(state => state.portal.portal)
  const prevProps = useRef()

  useEffect(() => {
    prevProps.current = props
    dispatch(
      getImagen(
        props.codigo_articulo,
        props.numero_imagen ? props.numero_imagen : 1,
        props.principal ? props.principal : 'S',
        parametros.img_cargadas,
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      prevProps.current.codigo_articulo !== props.codigo_articulo ||
      props.numero_imagen !== prevProps.current.numero_imagen
    ) {
      if (
        imagenes[props.codigo_articulo] === undefined ||
        imagenes[props.codigo_articulo][props.numero_imagen] === undefined ||
        imagenes[props.codigo_articulo][props.numero_imagen].error
      ) {
        dispatch(
          getImagen(
            props.codigo_articulo,
            props.numero_imagen ? props.numero_imagen : 1,
            props.principal ? props.principal : 'S',
            parametros.img_cargadas,
          ),
        )
      }
    }
    prevProps.current = props
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const cargarSRCImagenes = () => {
    if (props.src_manual) {
      src_pers = props.src_manual
    } else if (
      //Imagen de articulo referencia
      imagenes?.[props.codigo_articulo]?.[props.numero_imagen]?.articulo_referencia &&
      imagenes?.[props.codigo_articulo]?.[props.numero_imagen]?.articulo_referencia !== ''
    ) {
      src_pers =
        '/assets/img/' +
        portal.parametros_ecom[0].codigo_empresa +
        '-' +
        imagenes[props.codigo_articulo][props.numero_imagen].articulo_referencia +
        '/' +
        portal.parametros_ecom[0].codigo_empresa +
        '-' +
        imagenes[props.codigo_articulo][props.numero_imagen].articulo_referencia +
        '-' +
        props.numero_imagen +
        (props.tipo_imagen ? '-' + props.tipo_imagen : '') +
        '.' +
        (imagenes[props.codigo_articulo][props.numero_imagen].extension
          ? imagenes[props.codigo_articulo][props.numero_imagen].extension
          : 'jpg')
    } else if (
      //Imagen propia
      !imagenes?.[props.codigo_articulo]?.[props.numero_imagen]?.articulo_referencia ||
      imagenes?.[props.codigo_articulo]?.[props.numero_imagen]?.articulo_referencia === ''
    ) {
      src_pers =
        '/assets/img/' +
        portal.parametros_ecom[0].codigo_empresa +
        '-' +
        props.codigo_articulo +
        '/' +
        portal.parametros_ecom[0].codigo_empresa +
        '-' +
        props.codigo_articulo +
        '-' +
        props.numero_imagen +
        (props.tipo_imagen ? '-' + props.tipo_imagen : '') +
        '.' +
        (imagenes?.[props.codigo_articulo]?.[props.numero_imagen]?.extension || 'jpg')
    } else {
      //No encuentra imagen
      src_pers = '/assets/img/placeholder.jpg'
    }
  }

  parametros = cargarParametros(parametros_usados, portal.parametros_ecom, 'GEN')
  if (parametros?.img_cargadas === 'S') {
    if (imagenes?.[props.codigo_articulo]?.[props.numero_imagen]?.isLoading) {
      return <Spinner showSpinner={true} />
    } else {
      cargarSRCImagenes()
      return (
        <Fragment>
          <img
            src={src_pers}
            data-src={src_pers}
            alt=''
            onError={event => {
              event.target.src = '/assets/img/placeholder.jpg'
              event.onerror = 'null'
            }}
          />
        </Fragment>
      )
    }
  } else if (imagenes?.[props.codigo_articulo]?.[props.numero_imagen]?.isLoading) {
    return <Spinner showSpinner={true} />
  } else if (
    imagenes?.[props.codigo_articulo]?.[props.numero_imagen]?.imagen &&
    imagenes?.[props.codigo_articulo]?.[props.numero_imagen]?.imagen !== ''
  ) {
    return (
      <Fragment>
        <img
          src={
            'data:image/png;base64, ' + imagenes[props.codigo_articulo][props.numero_imagen].imagen
          }
          data-src={
            'data:image/png;base64, ' + imagenes[props.codigo_articulo][props.numero_imagen].imagen
          }
          alt=''
        />
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <img
          src='/assets/img/placeholder.jpg'
          data-src='/assets/img/placeholder.jpg'
          alt=''
        />
      </Fragment>
    )
  }
}

export default Imagen
