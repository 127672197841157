import axios from '../axiosConfig'
import { tokenConfig } from './auth'
import { FAIL_GET_EQUIVALENTES, FETCH_EQUIVALENTES, GET_EQUIVALENTES } from './types'

// GET ARTICULOS
export const getArticulosEquivalentes =
  (id_articulo, domicilio_envio = '', limit = 12) =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_EQUIVALENTES,
      codigo_articulo: id_articulo,
    })
    axios
      .get(
        `/articulos/equivalentes/?codigo_articulo=${id_articulo}&domicilio_envio=${domicilio_envio}&limit=${limit}`,
        tokenConfig(getState),
      )
      .then(res => {
        dispatch({
          type: GET_EQUIVALENTES,
          payload: res.data,
          codigo_articulo: id_articulo,
        })
      })
      .catch(err => {
        console.log(err)
        dispatch({ type: FAIL_GET_EQUIVALENTES })
      })
  }
