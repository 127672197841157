import axios from '../axiosConfig'

import { tokenConfig } from './auth'
import { FETCH_NOVEDADES, GET_NOVEDADES } from './types'

// GET ARTICULOS
export const getNovedades =
  (domicilio_envio = '', limit = 12) =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_NOVEDADES,
      payload: true,
    })
    axios
      .get(
        `/articulos/novedades/?domicilio_envio=${domicilio_envio}&limit=${limit}`,
        tokenConfig(getState),
      )
      .then(res => {
        dispatch({
          type: GET_NOVEDADES,
          payload: res.data,
        })
      })
      .catch(err => console.log(err))
  }
