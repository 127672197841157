import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { getMenus } from '../../actions/portal'
import { getPendingLines } from '../../actions/restos'
import { ordenarMenu, ProtectedRoute } from '../../helpers/funciones'
import programasRutas from '../../helpers/programasRutas'
import i18n from '../../lang/idiomas'
import Articulo from '../ecommerce/Articulo'
import Cardnet from '../ecommerce/Cardnet'
import LineasPendientesModal from '../ecommerce/LineasPendientesModal'
import Resumen from '../ecommerce/Resumen'
import Wompi from '../ecommerce/Wompi'
import Loader from '../layout/Loader'
import Recuperar from '../usuarios/Recuperar'
import ConfigCookies from './ConfigCookies'
import Estatico from './Estatico'
import Inicio from './Inicio'
import Modular from './Modular'

const ControladorRutas = props => {
  const dispatch = useDispatch()
  const menus = useSelector(state => state.portal.menus)
  const portal = useSelector(state => state.portal.portal)
  const isLoadingMenus = useSelector(state => state.portal.isLoadingMenus)
  const isLoadingPortal = useSelector(state => state.portal.isLoadingPortal)
  const authenticated = useSelector(state => state.auth.isAuthenticated)
  const invitado = useSelector(state => state.auth.invitado)
  const user = useSelector(state => state.auth.user)

  const [showModal, setShowModal] = useState(false)
  const [pendingLines, setPendingLines] = useState([])

  const prevProps = useRef()
  useEffect(() => {
    prevProps.current = props
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const css = portal?.css_personalizado || ''
    const style = document.createElement('style')
    style.id = 'custom_portal_css'
    if (document.querySelector('style#custom_portal_css')) return
    style.innerHTML = css
    document.head.appendChild(style)

    // Cleanup the style element on component unmount
    return () => {
      document.head.removeChild(style)
    }
  }, [portal])


  useEffect(() => {
    if (!invitado && authenticated && user?.can_get_lines_remains) {
      getPending()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitado, authenticated, user?.can_get_lines_remains])

  useEffect(() => {
    if (prevProps.current?.portal !== portal && portal.descripcion && !isLoadingMenus) {
      dispatch(getMenus())
    }
    prevProps.current = props
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const getPending = useCallback(async () => {
    const pendingLines = await getPendingLines()
    setPendingLines(pendingLines)
    setShowModal(pendingLines.length > 0)
  }, [])

  const returnPath = useCallback(menu => {
    if (menu.programa === 'articulos') {
      return '/articulos/:d_estadistico?/:d_codigo_familia?/f?/:codigo_estadistico?/:codigo_familia?'
    } else if (menu.camino) {
      return `/${menu.programa}/${menu.camino}`
    } else {
      return `/${menu.programa}`
    }
  }, [])

  const iterarMenus = useCallback(
    menu => {
      if (menu.programa) {
        const path = returnPath(menu)
        const element = programasRutas(menu.parametros ?? null)

        return (
          <Route
            key={menu.programa}
            exact
            path={path}
            element={element[menu.programa]}
          />
        )
      } else if (menu.hijos.length > 0) {
        return menu.hijos.map(iterarMenus)
      } else {
        return null
      }
    },
    [returnPath],
  )

  let Rutainicio, ComponenteInicio, menuArticulos, PropsInicio
  const loged = !!(authenticated && !invitado)

  if (menus && menus.length > 0) {
    menus.sort(ordenarMenu)
    Rutainicio = menus.find(
      menu => menu.activo === 'S' && menu.programa && !menu.programa.startsWith('http'),
    )
    menuArticulos = menus.find(menu => menu.programa === 'articulos')
    ComponenteInicio = Rutainicio?.programa
    PropsInicio = Rutainicio?.parametros
  }

  if (portal.modo_mantenimiento === 'S') {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <h1>{i18n.t('common.cpagmant')}</h1>
        <p>{i18n.t('common.cporfinm')}</p>
      </div>
    )
  }

  return (
    <>
      {menus?.length > 0 && portal.descripcion ? (
        <>
          <Helmet>
            <meta charSet='utf-8' />
            <meta
              name='description'
              content={
                portal?.parametros_seo?.descripcion || portal?.descripcion || 'Portal Ecommerce'
              }
            />
            <meta
              name='keywords'
              content={portal.parametros_seo.palabras_clave || ''}
            />
            <title>
              {portal?.parametros_seo?.titulo || portal?.descripcion || 'Portal Ecommerce'}
            </title>
            {portal.favicon && (
              <link
                rel='icon'
                type='image/png'
                href={`data:image/png;base64, ${portal.favicon}`}
                sizes='16x16'
              />
            )}
          </Helmet>
          {isLoadingPortal ? (
            <Loader />
          ) : (
            <Routes>
              {ComponenteInicio ? (
                <Route
                  key='inicio'
                  exact
                  path='/'
                  element={
                    <Inicio
                      componente={ComponenteInicio}
                      props={PropsInicio}
                    />
                  }
                />
              ) : (
                <Route key='no-inicio' />
              )}
              {menus.map(iterarMenus)}
              <Route
                key='estatico'
                exact
                path='/estatico/:componente'
                element={<Estatico />}
              />
              <Route
                key='modular'
                exact
                path='/modular/:modulo'
                element={<Modular />}
              />
              <Route
                key='recuperar'
                exact
                path='/recuperar'
                element={<Recuperar />}
              />
              <Route
                key='resumen'
                exact
                path='/resumen'
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <Resumen />
                  </ProtectedRoute>
                }
              />
              <Route
                key='wompi'
                exact
                path='/wompi'
                element={
                  <ProtectedRoute isAllowed={loged}>
                    <Wompi />
                  </ProtectedRoute>
                }
              />
              <Route
                key='cardnet'
                exact
                path='/cardnet'
                element={<Cardnet />}
              />
              <Route
                key='config_cookies'
                exact
                path='/configurarCookies'
                element={<ConfigCookies />}
              />
              <Route
                key={`articulo${Math.floor(Math.random() * 1000000)}`}
                exact
                path='/articulo/:id_articulo/:descrip_comercial'
                element={
                  <ProtectedRoute isAllowed={menuArticulos ? loged : false}>
                    <Articulo />
                  </ProtectedRoute>
                }
              />
              <Route
                key='default'
                path='*'
                element={<Navigate to='/' />}
              />
            </Routes>
          )}
          <LineasPendientesModal
            getPendingLines={getPending}
            isOpen={showModal}
            pendingLines={pendingLines}
            onRequestClose={() => setShowModal(false)}
          />
        </>
      ) : null}
    </>
  )
}

export default React.memo(ControladorRutas)
