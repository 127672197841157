import {
  FLUSH_DTO_MANUAL,
  FLUSH_PRECIO_MANUAL,
  GET_PRECIO,
  GET_PRECIOS,
  GET_PRECIOS_FAIL,
  GET_PRECIOS_PERMISOS_FAIL,
  GET_PRECIOS_PERMISOS_SUCCESS,
  GET_PRECIOS_SUCCESS,
  GET_PRECIO_FAIL,
  GET_PRECIO_PERMISOS_FAIL,
  GET_PRECIO_PERMISOS_SUCCESS,
  GET_PRECIO_SUCCESS,
  LIMPIAR_PRECIOS,
  SET_DTO_MANUAL,
  SET_PRECIO_MANUAL,
} from '../actions/types.js'

const initialState = {
  precio: {},
  permisos: {},
  locale: 'es-ES',
  moneda: 'EUR',
  decimales_significativos: 4,
  decimales_pvp: 2,
  decimales_precios: 2,
}
let locale = ''
let moneda = ''
let decimales_significativos
let decimales_precios
let decimales_pvp

export default function reducer(state = initialState, action) {
  let lista_precios
  switch (action.type) {
    case GET_PRECIO:
      lista_precios = JSON.parse(JSON.stringify(state.precio))

      // Comprobamos si existe algun precio para el articulo
      if (
        lista_precios[action.codigo_articulo] &&
        Object.keys(lista_precios[action.codigo_articulo]).length > 0
      ) {
        // Pisamos el valor previo con la nueva busqueda
        lista_precios[action.codigo_articulo][action.presentacion] = {
          status: 'fetching',
        }
      } else {
        // Creamos el la lista y añadimos el primer registro
        lista_precios[action.codigo_articulo] = {}
        lista_precios[action.codigo_articulo][action.presentacion] = {
          status: 'fetching',
        }
      }
      return {
        ...state,
        precio: lista_precios,
      }

    case GET_PRECIO_FAIL:
      lista_precios = JSON.parse(JSON.stringify(state.precio))
      // Seteamos el status a error
      lista_precios[action.codigo_articulo][action.presentacion] = {
        status: 'error',
      }

      return {
        ...state,
        precio: lista_precios,
      }

    case GET_PRECIO_PERMISOS_FAIL:
      lista_precios = JSON.parse(JSON.stringify(state.precio))
      // Seteamos el status a error
      lista_precios[action.codigo_articulo][action.presentacion] = {
        status: 'error',
      }

      return {
        ...state,
        precio: lista_precios,
        permisos: {},
      }

    case GET_PRECIO_PERMISOS_SUCCESS:
      lista_precios = JSON.parse(JSON.stringify(state.precio))
      // Seteamos el status a iddling
      action.payload.status = 'iddling'
      let permisos_nuevos = {}
      if (action.payload) {
        // Eliminamos los datos acerca de decimales
        if (action.payload.decimales_significativos) {
          decimales_significativos = action.payload.decimales_significativos
          delete action.payload.decimales_significativos
        }
        if (action.payload.decimales_precios) {
          decimales_precios = action.payload.decimales_precios
          delete action.payload.decimales_precios
        }
        if (action.payload.decimales_pvp) {
          decimales_pvp = action.payload.decimales_pvp
          delete action.payload.decimales_pvp
        }
        if (action.payload.permisos) {
          permisos_nuevos = action.payload.permisos
          delete action.payload.permisos
        }
      }

      // Pisamos los datos con los nuevos valores
      lista_precios[action.codigo_articulo][action.presentacion] = action.payload

      locale = ''
      // Recogemos el posible nuevo locale
      switch (lista_precios[action.codigo_articulo][action.presentacion].divisa_precio) {
        case 'COP':
          locale = 'es-CO'
          break

        case 'EUR':
          locale = 'es-ES'
          break

        case 'BOP':
          locale = 'es-BO'
          break

        case 'USD':
          locale = 'en-US'
          break

        case 'DOP':
          locale = 'es-DO'
          break

        case 'GBP':
          locale = 'en-GB'
          break

        default:
          locale = 'es-ES'
          break
      }

      return {
        ...state,
        precio: lista_precios,
        permisos: permisos_nuevos,
        locale: locale,
        moneda: lista_precios[action.codigo_articulo][action.presentacion].divisa_precio,
        decimales_significativos: decimales_significativos || 4,
        decimales_precios: decimales_precios || 2,
        decimales_pvp: decimales_pvp || 2,
      }

    case GET_PRECIO_SUCCESS:
      lista_precios = JSON.parse(JSON.stringify(state.precio))
      // Seteamos el status a iddling
      action.payload.status = 'iddling'

      if (action.payload) {
        // Eliminamos los datos acerca de decimales
        if (action.payload.decimales_significativos) {
          decimales_significativos = action.payload.decimales_significativos
          delete action.payload.decimales_significativos
        }
        if (action.payload.decimales_precios) {
          decimales_precios = action.payload.decimales_precios
          delete action.payload.decimales_precios
        }
        if (action.payload.decimales_pvp) {
          decimales_pvp = action.payload.decimales_pvp
          delete action.payload.decimales_pvp
        }
      }

      // Pisamos los datos con los nuevos valores
      lista_precios[action.codigo_articulo][action.presentacion] = action.payload

      locale = ''
      // Recogemos el posible nuevo locale
      switch (lista_precios[action.codigo_articulo][action.presentacion].divisa_precio) {
        case 'COP':
          locale = 'es-CO'
          break

        case 'EUR':
          locale = 'es-ES'
          break

        case 'BOP':
          locale = 'es-BO'
          break

        case 'USD':
          locale = 'en-US'
          break

        case 'DOP':
          locale = 'es-DO'
          break

        case 'GBP':
          locale = 'en-GB'
          break

        default:
          locale = 'es-ES'
          break
      }

      return {
        ...state,
        precio: lista_precios,
        locale: locale,
        moneda: lista_precios[action.codigo_articulo][action.presentacion].divisa_precio,
        decimales_significativos: decimales_significativos || 4,
        decimales_precios: decimales_precios || 2,
        decimales_pvp: decimales_pvp || 2,
      }

    case GET_PRECIOS:
      lista_precios = JSON.parse(JSON.stringify(state.precio))
      // Recorremos el array de articulos y seteamos los nuevos status
      for (let articulo of action.articulos_cantidades) {
        if (
          lista_precios[articulo.codigo_articulo] &&
          Object.keys(lista_precios[articulo.codigo_articulo]).length > 0
        ) {
          lista_precios[articulo.codigo_articulo][articulo.presentacion] = {
            status: 'fetching',
          }
        } else {
          lista_precios[articulo.codigo_articulo] = {}
          lista_precios[articulo.codigo_articulo][articulo.presentacion] = {
            status: 'fetching',
          }
        }
      }
      return {
        ...state,
        precio: lista_precios,
      }

    case GET_PRECIOS_FAIL:
      lista_precios = JSON.parse(JSON.stringify(state.precio))
      // Recorremos el array de articulos seteando en estatus error
      for (let articulo of action.articulos_cantidades) {
        lista_precios[articulo.codigo_articulo][articulo.presentacion] = {
          status: 'error',
        }
      }

      return {
        ...state,
        precio: lista_precios,
      }

    case GET_PRECIOS_PERMISOS_FAIL:
      lista_precios = JSON.parse(JSON.stringify(state.precio))
      // Recorremos el array de articulos seteando en estatus error
      for (let articulo of action.articulos_cantidades) {
        lista_precios[articulo.codigo_articulo][articulo.presentacion] = {
          status: 'error',
        }
      }

      return {
        ...state,
        precio: lista_precios,
        permisos: {},
      }

    case GET_PRECIOS_PERMISOS_SUCCESS:
      lista_precios = JSON.parse(JSON.stringify(state.precio))

      locale = ''
      moneda = ''
      let nuevos_precios = action.payload.precios
      let nuevos_permisos = action.payload.permisos

      // Comprobamos si la respuesta es de un solo objeto (WA por cambio en LISA)
      if (
        typeof nuevos_precios.articulos === 'object' &&
        !Array.isArray(nuevos_precios.articulos)
      ) {
        nuevos_precios.articulos.articulo.status = 'iddling'

        lista_precios[nuevos_precios.articulos.articulo.codigo_articulo][
          nuevos_precios.articulos.articulo.presentacion
        ] = nuevos_precios.articulos.articulo

        switch (
          lista_precios[nuevos_precios.articulos.articulo.codigo_articulo][
            nuevos_precios.articulos.articulo.presentacion
          ].divisa_precio
        ) {
          case 'COP':
            locale = 'es-CO'
            moneda = 'COP'
            break

          case 'BOP':
            locale = 'es-BO'
            moneda = 'BOP'
            break

          case 'USD':
            locale = 'en-US'
            moneda = 'USD'
            break

          case 'DOP':
            locale = 'es-DO'
            moneda = 'DOP'
            break

          case 'GBP':
            locale = 'en-GB'
            moneda = 'GBP'
            break

          default:
            locale = 'es-ES'
            moneda = 'EUR'
            break
        }
      } else {
        for (let articulo of nuevos_precios.articulos) {
          //Setamos el nuevo status de los articulos
          articulo.status = 'iddling'
          // Eliminamos datos de los decimales y seteamos variables para el estado de precios global
          if (articulo.decimales_significativos) {
            decimales_significativos = articulo.decimales_significativos
            delete articulo.decimales_significativos
          }
          if (articulo.decimales_precios) {
            decimales_precios = articulo.decimales_precios
            delete articulo.decimales_precios
          }
          if (articulo.decimales_pvp) {
            decimales_pvp = articulo.decimales_pvp
            delete articulo.decimales_pvp
          }
          // Creamos una copia del precio_articulo
          let precio_articulo = JSON.parse(JSON.stringify(articulo))
          // Eliminamos los valores inutiles
          if (precio_articulo) {
            if (precio_articulo.codigo_articulo) {
              delete precio_articulo.codigo_articulo
            }
            if (precio_articulo.presentacion) {
              delete precio_articulo.presentacion
            }
          }
          // Seteamos los valores
          if (
            lista_precios[articulo.codigo_articulo] &&
            Object.keys(lista_precios[articulo.codigo_articulo]).length > 0
          ) {
            lista_precios[articulo.codigo_articulo][articulo.presentacion] = precio_articulo
          } else {
            lista_precios[articulo.codigo_articulo] = {}
            lista_precios[articulo.codigo_articulo][articulo.presentacion] = precio_articulo
          }
          if (locale === '' || moneda === '') {
            switch (lista_precios[articulo.codigo_articulo][articulo.presentacion].divisa_precio) {
              case 'COP':
                locale = 'es-CO'
                moneda = 'COP'
                break

              case 'BOP':
                locale = 'es-BO'
                moneda = 'BOP'
                break

              case 'USD':
                locale = 'en-US'
                moneda = 'USD'
                break

              case 'DOP':
                locale = 'es-DO'
                moneda = 'DOP'
                break

              case 'GBP':
                locale = 'en-GB'
                moneda = 'GBP'
                break

              default:
                locale = 'es-ES'
                moneda = 'EUR'
                break
            }
          }
        }
      }

      return {
        ...state,
        precio: lista_precios,
        permisos: nuevos_permisos,
        locale: locale,
        moneda: moneda,
        decimales_significativos: decimales_significativos || 4,
        decimales_precios: decimales_precios || 2,
        decimales_pvp: decimales_pvp || 2,
      }

    case GET_PRECIOS_SUCCESS:
      lista_precios = JSON.parse(JSON.stringify(state.precio))

      locale = ''
      moneda = ''
      // Comprobamos si la respuesta es de un solo objeto (WA por cambio en LISA)
      if (
        typeof action.payload.articulos === 'object' &&
        !Array.isArray(action.payload.articulos)
      ) {
        action.payload.articulos.articulo.status = 'iddling'

        lista_precios[action.payload.articulos.articulo.codigo_articulo][
          action.payload.articulos.articulo.presentacion
        ] = action.payload.articulos.articulo

        switch (
          lista_precios[action.payload.articulos.articulo.codigo_articulo][
            action.payload.articulos.articulo.presentacion
          ].divisa_precio
        ) {
          case 'COP':
            locale = 'es-CO'
            moneda = 'COP'
            break

          case 'BOP':
            locale = 'es-BO'
            moneda = 'BOP'
            break

          case 'USD':
            locale = 'en-US'
            moneda = 'USD'
            break

          case 'DOP':
            locale = 'es-DO'
            moneda = 'DOP'
            break

          case 'GBP':
            locale = 'en-GB'
            moneda = 'GBP'
            break

          default:
            locale = 'es-ES'
            moneda = 'EUR'
            break
        }
      } else {
        for (let articulo of action.payload.articulos) {
          //Setamos el nuevo status de los articulos
          articulo.status = 'iddling'
          // Eliminamos datos de los decimales y seteamos variables para el estado de precios global
          if (articulo.decimales_significativos) {
            decimales_significativos = articulo.decimales_significativos
            delete articulo.decimales_significativos
          }
          if (articulo.decimales_precios) {
            decimales_precios = articulo.decimales_precios
            delete articulo.decimales_precios
          }
          if (articulo.decimales_pvp) {
            decimales_pvp = articulo.decimales_pvp
            delete articulo.decimales_pvp
          }
          // Creamos una copia del precio_articulo
          let precio_articulo = JSON.parse(JSON.stringify(articulo))
          // Eliminamos los valores inutiles
          if (precio_articulo) {
            if (precio_articulo.codigo_articulo) {
              delete precio_articulo.codigo_articulo
            }
            if (precio_articulo.presentacion) {
              delete precio_articulo.presentacion
            }
          }
          // Seteamos los valores
          if (
            lista_precios[articulo.codigo_articulo] &&
            Object.keys(lista_precios[articulo.codigo_articulo]).length > 0
          ) {
            lista_precios[articulo.codigo_articulo][articulo.presentacion] = precio_articulo
          } else {
            lista_precios[articulo.codigo_articulo] = {}
            lista_precios[articulo.codigo_articulo][articulo.presentacion] = precio_articulo
          }
          if (locale === '' || moneda === '') {
            switch (lista_precios[articulo.codigo_articulo][articulo.presentacion].divisa_precio) {
              case 'COP':
                locale = 'es-CO'
                moneda = 'COP'
                break

              case 'BOP':
                locale = 'es-BO'
                moneda = 'BOP'
                break

              case 'USD':
                locale = 'en-US'
                moneda = 'USD'
                break

              case 'DOP':
                locale = 'es-DO'
                moneda = 'DOP'
                break

              case 'GBP':
                locale = 'en-GB'
                moneda = 'GBP'
                break

              default:
                locale = 'es-ES'
                moneda = 'EUR'
                break
            }
          }
        }
      }

      return {
        ...state,
        precio: lista_precios,
        locale: locale,
        moneda: moneda,
        decimales_significativos: decimales_significativos || 4,
        decimales_precios: decimales_precios || 2,
        decimales_pvp: decimales_pvp || 2,
      }

    case LIMPIAR_PRECIOS:
      return {
        ...state,
        precio: {},
      }

    case SET_DTO_MANUAL:
      lista_precios = JSON.parse(JSON.stringify(state.precio))

      // Pisamos los datos con los nuevos valores
      lista_precios[action.codigo_articulo][action.presentacion].precio_manual = 'S'
      if (!lista_precios[action.codigo_articulo][action.presentacion].dto_origen) {
        lista_precios[action.codigo_articulo][action.presentacion].dto_origen =
          lista_precios[action.codigo_articulo][action.presentacion].dto_1
      }
      lista_precios[action.codigo_articulo][action.presentacion].dto_1 = action.nuevo_dto

      return {
        ...state,
        precio: lista_precios,
      }

    case SET_PRECIO_MANUAL:
      lista_precios = JSON.parse(JSON.stringify(state.precio))

      // Pisamos los datos con los nuevos valores
      lista_precios[action.codigo_articulo][action.presentacion].precio_manual = 'S'
      if (!lista_precios[action.codigo_articulo][action.presentacion].precio_origen) {
        lista_precios[action.codigo_articulo][action.presentacion].precio_origen =
          lista_precios[action.codigo_articulo][action.presentacion].precio_presentacion
      }

      lista_precios[action.codigo_articulo][action.presentacion].precio_presentacion =
        action.nuevo_precio

      return {
        ...state,
        precio: lista_precios,
      }

    case FLUSH_PRECIO_MANUAL:
      lista_precios = JSON.parse(JSON.stringify(state.precio))

      // Pisamos los datos con los nuevos valores
      if (lista_precios[action.codigo_articulo][action.presentacion].precio_manual === 'S') {
        if (
          lista_precios[action.codigo_articulo][action.presentacion].precio_origen &&
          !lista_precios[action.codigo_articulo][action.presentacion].dto_origen
        ) {
          lista_precios[action.codigo_articulo][action.presentacion].precio_manual = 'N'
        }
        let precio_recuperado =
          lista_precios[action.codigo_articulo][action.presentacion].precio_origen
        lista_precios[action.codigo_articulo][action.presentacion].precio_presentacion =
          precio_recuperado

        delete lista_precios[action.codigo_articulo][action.presentacion].precio_origen
      }
      return {
        ...state,
        precio: lista_precios,
      }

    case FLUSH_DTO_MANUAL:
      lista_precios = JSON.parse(JSON.stringify(state.precio))

      // Pisamos los datos con los nuevos valores
      if (lista_precios[action.codigo_articulo][action.presentacion].precio_manual === 'S') {
        if (
          lista_precios[action.codigo_articulo][action.presentacion].dto_origen &&
          !lista_precios[action.codigo_articulo][action.presentacion].precio_origen
        ) {
          lista_precios[action.codigo_articulo][action.presentacion].precio_manual = 'N'
        }
        let dto_recuperado = lista_precios[action.codigo_articulo][action.presentacion].dto_origen
        lista_precios[action.codigo_articulo][action.presentacion].dto_4 = dto_recuperado

        delete lista_precios[action.codigo_articulo][action.presentacion].dto_origen
      }
      return {
        ...state,
        precio: lista_precios,
      }

    default:
      return state
  }
}
