import axios from '../axiosConfig'
import { tokenConfig } from './auth'
import { createMessage } from './messages'
import {
  FETCH_LINEAS_PEDIDO_ACTIVO,
  FETCH_LINEAS_PEDIDO_ACTIVO_FAIL,
  FETCH_LINEAS_PEDIDO_ACTIVO_SUCCESS,
  FETCH_PEDIDO_ANTERIOR,
  FETCH_PEDIDOS_ANTERIORES,
  FLUSH_PEDIDOS_ANTERIORES,
  GENERAR_PEDIDO_PEDIDO_ANTERIOR,
  GET_PEDIDO_ACTIVO,
  GET_PEDIDO_ANTERIOR,
  GET_PEDIDOS_ANTERIORES,
  SET_VER_PEDIDO,
} from './types'

//////////////////////////////////////
//////////////CABECERA///////////////
/////////////////////////////////////

export const getPedidosAnteriores = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_PEDIDOS_ANTERIORES,
  })
  axios
    .get(`/pedidos/?anteriores=S`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_PEDIDOS_ANTERIORES,
        payload: res.data,
      })
    })
    .catch(err => console.log(err))
}

export const getLineasPedido = id_pedido => (dispatch, getState) => {
  dispatch({
    type: SET_VER_PEDIDO,
    payload: id_pedido,
  })
}

export const generarPedidoPedidoAnterior = pedido_anterior => (dispatch, getState) => {
  dispatch({
    type: GENERAR_PEDIDO_PEDIDO_ANTERIOR,
  })
  dispatch({ type: FETCH_LINEAS_PEDIDO_ACTIVO })
  axios
    .get(`/pedidos/generarpedido/?pedido_anterior=${pedido_anterior}`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GET_PEDIDO_ACTIVO,
        payload: res.data,
      })
      dispatch({ type: FETCH_LINEAS_PEDIDO_ACTIVO_SUCCESS })
      dispatch(createMessage('Se han añadido los artículos al pedido actual', 'success'))
    })
    .catch(err => {
      console.log(err)
      dispatch({ type: FETCH_LINEAS_PEDIDO_ACTIVO_FAIL })
      dispatch(
        createMessage(
          'No se han podido añadir los artículos a su pedido en este momento.',
          err.response.status,
        ),
      )
    })
}

export const getDatosLineasPedido = pedido_anterior => (dispatch, getState) => {
  let art_ped = []
  for (let linea of pedido_anterior.lineas) {
    if (linea.datos_articulo) {
      art_ped.push(linea.datos_articulo)
    }
  }
  if (art_ped.length > 0) {
    dispatch({
      type: GET_PEDIDO_ANTERIOR,
      payload: art_ped,
    })
  }
}

export const limpiarDatosLineasPedido = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_PEDIDO_ANTERIOR,
  })
}

export const limpiarDatosPedidosAnteriores = () => (dispatch, getState) => {
  dispatch({
    type: FLUSH_PEDIDOS_ANTERIORES,
  })
}
