//@ts-check
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import React from 'react'
import { MimeTypes } from '../libs/MimeTypes'
// @ts-ignore
import Chip from '@mui/material/Chip'

/**
 * This is the base document for all types of documents
 *
 * @param {Object} props
 * @param {string} props.title - The title for the card
 * @param {string} props.subtitle - Text to show bellow the title
 * @param {string} props.downloaded - The last date the file has been downloaded
 * @param {string} [props.readDate] - Date that the file was marked as readed
 * @param {React.ReactNode} props.HeaderAvatar - This will be show on the top left of the card
 * @param {React.ReactNode} props.content - Content of the card
 * @param {React.ReactNode} props.actionsPrimary - The bottom right actions
 * @param {React.ReactNode} props.actionsSecondary - The bottom right actions
 * @param {string} [props.MIMEType] - The extension of the file
 * @param {boolean} [props.forced=false] - This will show a warn icon on the top right of the card
 * @param {number | string} [props.document_id]
 * @param {number | string} [props.document_type]
 * @param {string} [props.document_url] - URL to download the file
 * @param {string} [props.img_title] - Title of the image
 * @param {string} [props.chip] - Chip of the card
 * @returns {JSX.Element}
 */
export default function DocumentCard({
  title,
  readDate,
  HeaderAvatar,
  content,
  actionsPrimary,
  actionsSecondary,
  forced = false,
  MIMEType = 'default',
  subtitle,
  img_title,
  chip,
}) {
  /**
   * @type {import("@phosphor-icons/react").Icon}
   */
  // @ts-ignore
  const MimeIcon = MimeTypes[MIMEType] || MimeTypes.default
  const route = img_title ? `/assets/img/documentos/${img_title}` : null
  return (
    <Card
      sx={{
        '& br': {
          height: '2px',
        },
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minHeight: '150px',
      }}>
      <Box
        sx={{
          aspectRatio: '16/9',
          mb: 1,
          display: 'flex',
          minHeight: '25em',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}>
        {route ? (
          <img
            src={route}
            alt={img_title}
            onError={event => {
              event.target.src = '/assets/img/placeholder.jpg'
              event.onerror = 'null'
            }}
            style={{
              height: '100%',
              objectFit: 'scale-down',
              backgroundColor: 'inherit',
            }}
          />
        ) : (
          <MimeIcon
            size='100%'
            style={{ opacity: '0.1' }}
          />
        )}
      </Box>
      <CardHeader
        sx={{
          mb: 'auto',
          '& .MuiCardHeader-content': { display: 'flex', flexDirection: 'column' },
        }}
        avatar={HeaderAvatar ?? null}
        action={null}
        title={<Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>}
        subheader={
          <Box sx={{ display: 'flex', gap: 1, position: 'absolute', top: '20em', left: '1em' }}>
            <Chip
              label={chip}
              color='info'
              size='small'
            />
            <Typography>{subtitle}</Typography>
          </Box>
        }
      />
      {content ? <CardContent sx={{ py: 1 }}>{content}</CardContent> : <Box></Box>}
      {actionsPrimary || actionsSecondary ? (
        <CardActions
          disableSpacing
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex' }}>{actionsSecondary}</Box>
          <Box sx={{ display: 'flex' }}>{actionsPrimary}</Box>
        </CardActions>
      ) : (
        <></>
      )}
    </Card>
  )
}
