import { Files, Info, ShoppingCart, SignOut, User, UserSwitch, X } from 'phosphor-react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getListasCompra } from '../../actions/listasCompra'
import { getNovedades } from '../../actions/novedades'
import { getOfertas } from '../../actions/ofertas'
import { getPedidoActivo } from '../../actions/pedidos'
import { getMenus } from '../../actions/portal'
import { getTiras } from '../../actions/tiras'
import { ordenarMenu, programaActivo } from '../../helpers/funciones'
import i18n from '../../lang/idiomas'
import Carrito from '../ecommerce/Carrito'
import SelectorCliente from '../usuarios/SelectorCliente'
import LanguageSelector from './LanguageSelector'

let menusMostrar = []

const Header = props => {
  const dispatch = useDispatch()

  const auth = useSelector(state => state.auth)
  const pedido = useSelector(state => state.pedidos.pedido)
  const portal = useSelector(state => state.portal.portal)
  const menus = useSelector(state => state.portal.menus)
  const id_pedido_seleccionado = useSelector(state => state.pedidos.id_pedido_seleccionado)
  const ofertas = useSelector(state => state.listadoArticulos.ofertas)
  const novedades = useSelector(state => state.listadoArticulos.novedades)
  const language = useSelector(state => state.languageSelector.language)
  const clientesPermitidos = useSelector(state => state.auth.user.clientes_permitidos)
  const clienteSeleccionado = useSelector(state => state.selectorCliente.cliente_seleccionado)

  useEffect(() => {
    dispatch(getMenus())
    // eslint-disable-next-line
    if (typeof window.wookie !== undefined) {
      window.wookie()
    } else {
      console.log('No se encuentra librería wookie')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated, language])

  useEffect(() => {
    if (menus?.length > 0 && portal.descripcion) {
      // Si existe el menu novedadesOfertas
      if (programaActivo(menus, 'novedadesOfertas')) {
        if (ofertas.articulos === undefined) {
          dispatch(getOfertas())
        }

        if (novedades.articulos === undefined) {
          dispatch(getNovedades())
        }
      }

      // Si existen articulos recuperamos pedido y tiras
      if (programaActivo(menus, 'articulos')) {
        dispatch(
          getPedidoActivo(
            auth.hash != null ? auth.hash : '',
            'N',
            id_pedido_seleccionado || pedido?.id_pedido,
          ),
        )
        dispatch(getTiras())
      }

      // Si existen listasCompra las solicitamos
      if (programaActivo(menus, 'listasCompra')) {
        dispatch(getListasCompra())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portal, menus])

  const ocultarMenu = e => {
    document.getElementsByClassName('mobile-main-menu')[0].classList.remove('mmitemopen')
  }

  const cargarIcono = menu => {
    if (menu.programa) {
      switch (menu.programa) {
        case 'login':
          return (
            <User
              size={22}
              weight='regular'
            />
          )
        case 'informes':
          return (
            <Files
              size={22}
              weight='regular'
            />
          )
        case 'contacto':
          return (
            <Info
              size={22}
              weight='regular'
            />
          )
        case 'cuenta':
          return (
            <User
              size={20}
              weight='fill'
            />
          )
        case 'logout':
          return (
            <SignOut
              size={20}
              weight='fill'
            />
          )
        case 'selectorCliente':
          return (
            <UserSwitch
              size={22}
              weight='fill'
            />
          )
        default:
          return ''
      }
    } else if (menu.nombre && (menu.nombre === 'USUARIO' || menu.nombre === 'CARRITO')) {
      if (menu.nombre === 'USUARIO') {
        return (
          <User
            size={22}
            weight='regular'
          />
        )
      } else {
        return (
          <ShoppingCart
            size={22}
            weight='regular'
          />
        )
      }
    }
  }

  const { isAuthenticated, user } = auth
  const { lineas } = pedido
  let ofertaAT = ofertas.articulos_total
  let novedadAT = novedades.articulos_total
  const invitado = auth.invitado

  menusMostrar = menus
  if (menusMostrar && menusMostrar.length > 0) {
    menusMostrar = menusMostrar.sort(ordenarMenu)
    if (auth.isAuthenticated && !invitado) {
      menusMostrar = menusMostrar.filter(menu => {
        return menu.programa !== 'login' && menu.programa !== 'registro'
      })
    }
    if (auth.user && auth.user.puede_crear_usuarios !== 'S') {
      menusMostrar = menusMostrar.filter(menu => {
        return menu.programa !== 'registroHijos'
      })
    }
  }
  const retornarMenu = (menu, origen, index) => {
    if (menu?.programa?.startsWith('http')) {
      return (
        <a
          key={menu.codigo + toString(index)}
          href={menu.programa}
          style={{ alignItems: 'center' }}>
          {menu.icono ? cargarIcono(menu) : ''}
          {menu.nombre}
        </a>
      )
    } else if (menu.programa === null && menu?.hijos?.length > 0) {
      return (
        <Link
          key={menu.codigo + toString(index)}
          to={
            '/' + menu.hijos[0].programa + (menu.hijos[0].camino ? '/' + menu.hijos[0].camino : '')
          }
          style={{ alignItems: 'center' }}>
          {menu.icono ? cargarIcono(menu) : ''}
          {menu.nombre}
        </Link>
      )
    } else {
      return (
        <Link
          key={menu.codigo + toString(index)}
          onClick={origen === 'mobile' ? ocultarMenu : null}
          to={'/' + menu.programa + (menu.camino ? '/' + menu.camino : '')}
          style={{ alignItems: 'center' }}>
          {menu.icono ? cargarIcono(menu) : ''}
          {menu.nombre}
        </Link>
      )
    }
  }

  const retornarHijos = (menu, origen, index) => {
    return menu.hijos.map((hijo, indexHijo) => {
      return hijo.visible === 'S' ? (
        <li key={hijo.codigo + toString(index)}>
          <Link
            key={hijo.codigo + toString(indexHijo)}
            onClick={origen === 'mobile' ? ocultarMenu : null}
            to={'/' + hijo.programa + (hijo.camino ? '/' + hijo.camino : '')}>
            {hijo.nombre}
          </Link>
        </li>
      ) : (
        ''
      )
    })
  }

  const desktoLinks = (
    <div className='tt-obj-menu'>
      <div className='container'>
        <div className='row justify-content-md-end no-gutters'>
          <div className='tt-desctop-parent-menu tt-parent-box'>
            <div className='tt-desctop-menu'>
              <nav>
                <ul>
                  {menusMostrar && menusMostrar.length > 0
                    ? menusMostrar.map((menu, index) => {
                        menu.hijos.sort(ordenarMenu)
                        return menu.visible === 'S' &&
                          menu.objeto_programa !== 'CARRITO' &&
                          menu.objeto_programa !== 'USUARIO' &&
                          menu.objeto_programa !== 'SELECTOR_CLIENTE' ? (
                          <li
                            key={menu.codigo + toString(index)}
                            className='dropdown  tt-megamenu-col-01'>
                            {retornarMenu(menu, 'desktop', index)}
                            {menu.programa === 'novedadesOfertas' &&
                            ofertaAT &&
                            novedadAT &&
                            (ofertaAT !== 0 || novedadAT !== 0) ? (
                              <span className='tt-badge-news'>
                                {ofertaAT !== 0 || novedadAT !== 0 ? ofertaAT + novedadAT : ''}
                              </span>
                            ) : (
                              ''
                            )}
                            {menu.hijos.length > 0 ? (
                              <div className='dropdown-menu'>
                                <div className='row tt-col-list'>
                                  <div className='col'>
                                    <ul className='tt-megamenu-submenu'>
                                      {retornarHijos(menu, 'desktop', index)}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </li>
                        ) : (
                          ''
                        )
                      })
                    : ''}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const mobileMenu = (
    <ul className='menu-mobile'>
      {menusMostrar && menusMostrar.length > 0
        ? menusMostrar.map((menu, index) => {
            menu.hijos.sort(ordenarMenu)
            return menu.visible === 'S' &&
              menu.objeto_programa !== 'CARRITO' &&
              menu.objeto_programa !== 'USUARIO' &&
              menu.objeto_programa !== 'SELECTOR_CLIENTE' ? (
              <li key={menu.codigo + toString(index)}>
                {retornarMenu(menu, 'mobile', index)}
                {menu.hijos.length > 0 ? <ul>{retornarHijos(menu, 'mobile', index)}</ul> : ''}
              </li>
            ) : (
              ''
            )
          })
        : ''}
    </ul>
  )

  const desktopAuthObjects = (
    <div className='tt-obj-options obj-move-right'>
      {menusMostrar && menusMostrar.length > 0
        ? menusMostrar.map((menu, index) => {
            return menu.visible === 'S' &&
              menu.objeto_programa === 'SELECTOR_CLIENTE' &&
              clientesPermitidos !== undefined &&
              clientesPermitidos.length > 1 ? (
              <div
                key={menu.codigo + toString(index)} //'boton_selector_cliente'
                className='tt-desctop-parent-client-select tt-parent-box'>
                <div
                  className='tt-client-select tt-dropdown-obj'
                  data-tposition='bottom'>
                  <button className='tt-dropdown-toggle select-cambio-cliente'>
                    {menu.icono ? cargarIcono(menu) : ''}
                    <small>
                      {isAuthenticated
                        ? clientesPermitidos.filter(cliente => {
                            return clienteSeleccionado === ''
                              ? cliente.codigo_cliente === user.codigo_cliente
                              : cliente.id === clienteSeleccionado
                          })[0].nombre
                        : ''}
                    </small>
                  </button>
                  <SelectorCliente />
                </div>
              </div>
            ) : (
              ''
            )
          })
        : ''}
      {menusMostrar && menusMostrar.length > 0
        ? menusMostrar.map((menu, index) => {
            return menu.visible === 'S' && menu.objeto_programa === 'CARRITO' ? (
              <div
                key={menu.codigo + toString(index)} //'boton_carrito'
                className='tt-desctop-parent-cart tt-parent-box'>
                <div
                  className='tt-cart tt-dropdown-obj'
                  //data-tooltip="Carrito"
                  data-tposition='bottom'>
                  <button className='tt-dropdown-toggle'>
                    {menu.icono ? cargarIcono(menu) : ''}
                    <span className='tt-badge-cart'>{lineas ? lineas.length : '0'}</span>
                  </button>
                  <Carrito />
                </div>
              </div>
            ) : (
              ''
            )
          })
        : ''}
      {menusMostrar && menusMostrar.length > 0
        ? menusMostrar.map((menu, index) => {
            if (menu.visible === 'S' && menu.objeto_programa === 'USUARIO') {
              menu.hijos.sort(ordenarMenu)
            }
            return menu.visible === 'S' && menu.objeto_programa === 'USUARIO' && !invitado ? (
              <div
                key={menu.codigo + toString(index)} //'boton_usuario'
                className='tt-desctop-parent-account tt-parent-box'>
                <div className='tt-account tt-dropdown-obj'>
                  <button
                    className='tt-dropdown-toggle mi-cuenta'
                    data-tposition='bottom'>
                    {menu.icono ? cargarIcono(menu) : ''}{' '}
                    <small>{isAuthenticated ? user.usuario_web : ''}</small>
                  </button>
                  <div className='tt-dropdown-menu sub-mi-cuenta'>
                    <div className='tt-mobile-add'>
                      <button className='tt-close'>{i18n.t('general.cerrar')}</button>
                    </div>
                    <div className='tt-dropdown-inner'>
                      <ul>
                        {menu.hijos.map((hijo, index) => {
                          return hijo.visible === 'S' ? (
                            <li key={hijo.codigo + toString(index)}>
                              <Link to={'/' + hijo.programa}>
                                {cargarIcono(hijo)}
                                {hijo.nombre}
                              </Link>
                            </li>
                          ) : (
                            ''
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )
          })
        : ''}
    </div>
  )

  return (
    <header id='cabecera-principal'>
      <nav className='panel-menu mobile-main-menu'>
        <div className='mm-navbtn-names'>
          <div
            className='mm-closebtn'
            style={{ marginTop: '5px', marginRight: '5px' }}>
            <X
              size={16}
              weight='bold'
            />
          </div>
        </div>
        {mobileMenu}
      </nav>
      <div className='tt-mobile-header'>
        <div className='container-fluid'>
          <div className='tt-header-row'>
            <div className='tt-mobile-parent-menu'>
              <div className='tt-menu-toggle'>
                <i className='icon-03'></i>
              </div>
            </div>
            <div className='tt-mobile-parent-cart tt-parent-box'></div>
            <div className='tt-mobile-parent-account tt-parent-box'></div>
          </div>
        </div>
        <div className='container-fluid tt-top-line'>
          <div className='row'>
            <div className='tt-logo-container'>
              <Link
                to={'/'}
                className='tt-logo tt-logo-alignment'>
                {portal.logo ? (
                  <img
                    src={'data:image/png;base64, ' + portal.logo}
                    alt=''
                    className='loading'
                    data-was-processed='true'
                  />
                ) : (
                  <span className='tt-title'>{portal.descripcion}</span>
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='tt-desktop-header'>
        <div className='tt-color-scheme-01'>
          <div className='container-fluid'>
            <div className='tt-header-holder'>
              <div className='tt-obj-logo'>
                <Link
                  to={'/'}
                  className='tt-logo tt-logo-alignment'>
                  {portal.logo ? (
                    <img
                      src={'data:image/png;base64, ' + portal.logo}
                      alt=''
                      className='loading'
                      data-was-processed='true'
                    />
                  ) : (
                    <span className='tt-title'>{portal.descripcion}</span>
                  )}
                </Link>
              </div>
              <LanguageSelector />
              {desktoLinks}
              {desktopAuthObjects}
            </div>
          </div>
        </div>
      </div>
      <div className='tt-stuck-nav'>
        <div className='container'>
          <div className='tt-header-row '>
            <div className='tt-stuck-parent-menu'></div>
            <div className='tt-stuck-parent-search tt-parent-box'></div>
            <div className='tt-stuck-parent-cart tt-parent-box'></div>
            <div className='tt-stuck-parent-account tt-parent-box'></div>
            <div className='tt-stuck-parent-multi tt-parent-box'></div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
