import verificarCarnet from "./comprobarCarnet";
import i18n from "../../../lang/idiomas";

/**
 * @typedef {Object} articulo_cantidad
 * @property {Object} articulo
 * @property {number} cantidad
 * @property {number} cantidad_minima
 */

export const NO_ESTA_EL_PRECIO = `${i18n.t("plantillas.pnoespre")} `;
export const CANTIDAD_NO_VALIDA = `${i18n.t("plantillas.pnocaval")} `;

/**
 * @param {articulo_cantidad[]} articulos_cantidades
 * @param {string} pedido_seleccionado
 * @param {string} tipo
 * @param {Object} usuario
 * @param {string} hash
 * @param {Object} precio
 * @param {Object} presentacion
 * @param {Object} subpresentacion
 */

export const generarLineasPedidos = (
  articulos_cantidades,
  pedido_seleccionado,
  tipo,
  usuario,
  hash,
  precio,
  presentacion,
  subpresentacion,
  comprobar_carnet = verificarCarnet
) => {
  const lineasGeneradas = [];
  articulos_cantidades.forEach((articulo) => {
    const artEnPrecio = precio[articulo.articulo.codigo_articulo];

    const EXISTE_PRECIO_PEDIDO = artEnPrecio?.[articulo.articulo.presentacion_web];

    if (!EXISTE_PRECIO_PEDIDO) {
      throw new Error(NO_ESTA_EL_PRECIO + ` de: ${articulo.articulo.descrip_comercial}`);
    }

    if (articulo.cantidad < articulo.cantidad_minima) {
      throw new Error(CANTIDAD_NO_VALIDA + ` de: ${articulo.articulo.descrip_comercial}`);
    }

    lineasGeneradas.push(
      createLineModel(
        articulo.articulo,
        pedido_seleccionado,
        tipo,
        usuario,
        articulo.cantidad,
        artEnPrecio[articulo.articulo.presentacion_web],
        presentacion,
        subpresentacion,
        comprobar_carnet
      )
    );
  }); //END FOREACH

  return lineasGeneradas;
};

/**
 *
 * @param {Object} articulo
 * @param {*} pedido_seleccionado
 * @param {*} tipo
 * @param {*} codigo_plantilla
 * @param {*} usuario
 * @param {number} cantidad
 * @param {*} cantidadMinima
 * @param {*} hash
 * @param {*} precio
 * @param {*} presentacion
 * @param {*} subpresentacion
 * @param {*} postLinea
 * @param {*} nuevaReceta
 * @param {*} comprobarCarnet
 */
const createLineModel = (articulo, pedido_seleccionado, tipo, usuario, cantidad, precio, presentacion, subpresentacion, comprobarCarnet = verificarCarnet) => {
  let linea = {
    articulo: articulo.codigo_articulo,
    descripcion: articulo.descrip_comercial,
    precio_manual: precio.precio_manual,
    precio_venta: precio.precio_presentacion,
    observaciones: "",
    estado_linea: "B",
    cantidad_pedida: cantidad,
    presentacion_pedido: articulo.presentacion_web,
    tipo_linea: tipo,
    sub_pres: "",
    sub_pres_cant: "",
    sub_pres_fc: "",
    presentacion_escogida: "",
    pres_fc: "",
    valida_carnet: "",
  };

  if (articulo.tipo_carnet_profesional) linea = comprobarCarnet(articulo.articulo, linea, "N", null, usuario);

  //Comprobar si el articulo necesita receta
  if (articulo.numero_receta) {
    if (articulo.codigo_estad10 && articulo.codigo_estad10 === "10") {
      if (usuario.con_receta && usuario.con_receta === "S") {
        if (usuario.tipo_cliente === "01") linea = comprobarCarnet(articulo.articulo, linea, "S", "otros", usuario);
        else linea.valida_otros = "N";
      } else linea.valida_otros = "S";
    } else {
      if (usuario.con_receta && usuario.con_receta === "S") {
        if (usuario.tipo_cliente === "01") linea = comprobarCarnet(articulo.articulo, linea, "S", "receta", usuario);
        else linea.valida_receta = "N";
      } else linea.valida_receta = "S";
    }
  } else {
    if (articulo.codigo_estad10 && articulo.codigo_estad10 === "10") {
      if (usuario.tipo_cliente === "01") linea = comprobarCarnet(articulo.articulo, linea, "S", "otros", usuario);
    }
  }

  if (subpresentacion.subpresentacion) {
    linea.sub_pres = subpresentacion.subpresentacion;
    linea.sub_pres_cant = Number.parseInt(cantidad);
    linea.sub_pres_fc = Number.parseFloat(subpresentacion.factor_conversion);
    linea.observaciones = linea.sub_pres_cant + " de " + linea.sub_pres;
  }

  if (presentacion.presentacion) {
    linea.pres_fc = Number.parseFloat(presentacion.factor_conversion);
    linea.pres_escogida = presentacion.presentacion;
  }

  if (!linea.valida_receta || linea.valida_receta === "S" || linea.valida_receta === "M") {
  }

  return linea;
};
