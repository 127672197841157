import axios from "../axiosConfig";
import {
  FETCH_LISTAS_COMPRA,
  GET_LISTAS_COMPRA,
  DELETE_LISTA_COMPRA,
  DELETE_LISTA_COMPRA_SUCCESS,
  CREATE_LISTA_COMPRA,
  CREATE_LISTA_COMPRA_SUCCESS,
  SET_VER_LISTA,
  SET_VER_LISTA_SUCCESS,
  DELETE_LINEA_LISTA_COMPRA,
  GENERA_PEDIDO_LISTA,
  GENERA_PEDIDO_LISTA_SUCCESS,
  GENERA_PEDIDO_LISTA_FAIL,
  GET_PEDIDO_ACTIVO,
  ACTUALIZAR_LINEA_LISTA,
  ACTUALIZAR_LINEA_LISTA_SUCCESS,
  DELETE_LINEA_LISTA_COMPRA_SUCCESS,
  GET_LISTAS_COMPRA_FAIL,
  FLUSH_LISTAS_COMPRA_DETALLE,
} from "./types";
import { tokenConfig } from "./auth";
import { createMessage } from "./messages";

//////////////////////////////////////
//////////////CABECERA///////////////
/////////////////////////////////////

export const getListasCompra = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_LISTAS_COMPRA,
  });
  axios
    .get(`/listasCompra/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_LISTAS_COMPRA,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_LISTAS_COMPRA_FAIL,
      });
    });
};

export const generarPedidoListaCompra = (codigo_lista) => (dispatch, getState) => {
  dispatch({
    type: GENERA_PEDIDO_LISTA,
  });
  axios
    .get(`/listasCompra/generarpedido/?lista_compra=${codigo_lista}`, tokenConfig(getState))

    .then((res) => {
      dispatch({
        type: GET_PEDIDO_ACTIVO,
        payload: res.data,
      });
      dispatch({
        type: GENERA_PEDIDO_LISTA_SUCCESS,
      });
      dispatch(createMessage("Se han añadido los artículos de la lista al pedido", "success"));
    })
    .catch((err) => {
      dispatch({
        type: GENERA_PEDIDO_LISTA_FAIL,
      });
      if (err.response.data !== undefined) {
        console.log(err);
        dispatch(createMessage(err.response.data, err.response.status));
      } else {
        console.log(err);
        dispatch(createMessage("No se han podido añadir los artículos a su pedido en este momento.", err.response.status));
      }
    });
};

export const getLineasListaCompra = (codigo_lista) => (dispatch, getState) => {
  dispatch({
    type: SET_VER_LISTA,
  });
  let config = tokenConfig(getState);
  config.params = {
    lista_compra: codigo_lista,
  };

  axios
    .get(`/listasCompraLineas/`, config)
    .then((res) => {
      let aux = {
        codigo_lista: codigo_lista,
        lineas: res.data,
      };

      dispatch({
        type: SET_VER_LISTA_SUCCESS,
        payload: aux,
      });
    })
    .catch((err) => console.log(err));
};

// ELIMINAR LA LISTA
export const eliminarLista = (codigo_lista) => (dispatch, getState) => {
  dispatch({
    type: DELETE_LISTA_COMPRA,
  });
  axios
    .delete(`/listasCompra/${codigo_lista}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage("Lista Eliminada", "success"));
      dispatch({
        type: DELETE_LISTA_COMPRA_SUCCESS,
        payload: codigo_lista,
      });
    })
    .catch((err) => console.log(err));
};

export const crearLista = (_nombre_lista) => (dispatch, getState) => {
  dispatch({
    type: CREATE_LISTA_COMPRA,
  });
  const body = JSON.stringify({ nombre_lista: _nombre_lista });

  axios
    .post(`/listasCompra/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage("Lista creada", "success"));
      dispatch({
        type: CREATE_LISTA_COMPRA_SUCCESS,
        payload: res.data,
      });
      dispatch(createMessage("Lista creada correctamente", "success"));
    })
    .catch((err) => console.log(err));
};

//////////////////////////////////////
////////////////LINEAS///////////////
/////////////////////////////////////

// // DELETE LINEA
export const eliminarLineaLista = (id) => (dispatch, getState) => {
  dispatch({
    type: DELETE_LINEA_LISTA_COMPRA,
  });
  axios
    .delete(`/listasCompraLineas/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage("Linea Eliminada", "success"));
      dispatch({
        type: DELETE_LINEA_LISTA_COMPRA_SUCCESS,
        payload: id,
      });
    })
    .catch((err) => console.log(err));
};

// // CREATE LINEA
export const postLineaLista = (linea) => (dispatch, getState) => {
  const body = JSON.stringify(linea);

  axios
    .post("/listasCompraLineas/", body, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage("Se ha añadido el artículo a su lista", "success"));
      dispatch({
        type: ACTUALIZAR_LINEA_LISTA_SUCCESS,
        payload: { linea: res.data },
      });
    })
    .catch((err) => {
      dispatch(createMessage("No se ha podido añadir el artículo a su lista en este momento.", err.response.status));
      console.log(err);
    });
};

// // CREATE N LINEAS
export const postLineasLista = (_lineas) => (dispatch, getState) => {
  const body = JSON.stringify({ lineas: _lineas });

  axios
    .post("/listasCompraLineas/createmany/", body, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage("Se han añadido los artículos a la lista", "success"));
    })
    .catch((err) => {
      if (err?.response?.data) {
        dispatch(createMessage(err.response.data, err.response.status));
      } else {
        dispatch(createMessage("No se han podido añadir los artículos a su lista en este momento.", err.response.status));
      }
      console.log(err);
    });
};

export const actualizaLineaLista = (_linea) => (dispatch, getState) => {
  dispatch({
    type: ACTUALIZAR_LINEA_LISTA,
  });

  const body = JSON.stringify(_linea);

  axios
    .post("/listasCompraLineas/actualizalinealista/", body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ACTUALIZAR_LINEA_LISTA_SUCCESS,
        payload: { linea: res.data },
      });

      dispatch(createMessage("Se ha actualizado la lista con la cantidad introducida", "success"));
    })
    .catch((err) => {
      console.log(err);
      if (err && err.response && err.status) {
        dispatch(createMessage("No se ha podido actualizar la lista", err.response.status));
      }

      console.log(err);
    });
};

export const limpiarDatosListasCompra = () => (dispatch, getState) => {
  dispatch({
    type: FLUSH_LISTAS_COMPRA_DETALLE,
  });
};
