import axios from "../axiosConfig";
import { tokenConfig } from "./auth";
import store from "../store";

/**
 *@returns {Promise<Array.<Object>>} listOfLines
 */
export const getPendingLines = async () => {
  try {
    const response = await axios.get("/restos", tokenConfig(store.getState));
    return response.data.data_list;
  } catch (error) {
    throw error;
  }
};

/**
 *@returns {Promise<Boolean>}
 */
export const rejectLines = async () => {
  try {
    await axios.post("/restos/reject/", {}, tokenConfig(store.getState));
    return true;
  } catch (error) {
    throw error;
  }
};

/**
 * @returns {Promise<Boolean>}
 */
export const acceptLines = async (lines = []) => {
  try {
    await axios.post("/restos/accept/", { lines }, tokenConfig(store.getState));
    return true;
  } catch (error) {
    throw error;
  }
};
