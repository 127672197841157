import { compareDecimals } from '../numbers/functions'

export const isMinimumQuantity = (itemData, quantity) => {
  let minimo_a_valorar = 1
  if (itemData?.cantidad_minima && itemData?.multiplo) {
    minimo_a_valorar =
      itemData.cantidad_minima >= itemData.multiplo ? itemData.cantidad_minima : itemData.multiplo
  } else if (itemData?.cantidad_minima) {
    minimo_a_valorar = itemData?.cantidad_minima
  }
  return compareDecimals(quantity, minimo_a_valorar)
}

export const isMultiple = (itemData, quantity, dN = 0) => {
  if (itemData?.multiplo) {
    // Si el articulo tiene multiplo configurado
    return Number(quantity) % Number(itemData?.multiplo) === 0
  } else if (dN === 0) {
    // Si se indican 0 decimales comprobamos que sea entero
    return Number(quantity) % Number(1) === 0
  } else {
    return true
  }
}

export const retrieveLowerMultiple = (itemData, quantity) => {
  let multiple = itemData?.multiplo || 1

  let quotient = Math.floor(quantity / multiple)
  // Si el multiplo mas cercano es 0 retornamos el multiplo parametrizado
  return quotient ? quotient * multiple : multiple
}

export const exceedsMinimumQuantity = (itemData, quantity) => {
  if (itemData?.cantidad_minima) {
    return Number(quantity) >= Number(itemData.cantidad_minima)
  } else {
    return Number(quantity) >= 1
  }
}
