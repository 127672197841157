// MuiIconLoader.js
import {
  AccountCircle,
  CancelOutlined,
  Close,
  Description,
  Discount,
  ExitToApp,
  Facebook,
  FastRewindRounded,
  Google,
  Home,
  InfoOutlined,
  Instagram,
  LinkedIn,
  MenuBookTwoTone,
  Person,
  PersonOutlineOutlined,
  Settings,
  ShoppingCartOutlined,
  SwitchAccount,
  Twitter,
  UploadFileRounded,
} from '@mui/icons-material'
import PropTypes from 'prop-types'
import React from 'react'

const MuiIconLoader = ({ iconName, size, color, style }) => {
  const iconMap = {
    Home,
    Settings,
    AccountCircle,
    PersonOutlineOutlined,
    Description,
    InfoOutlined,
    Person,
    ExitToApp,
    SwitchAccount,
    ShoppingCartOutlined,
    Close,
    UploadFileRounded,
    MenuBookTwoTone,
    Discount,
    FastRewindRounded,
    CancelOutlined,
    Facebook,
    Twitter,
    Google,
    Instagram,
    LinkedIn,
    // Añade más íconos según sea necesario
  }

  const IconComponent = iconMap[iconName]

  if (!IconComponent) {
    console.error(`Ícono no encontrado: ${iconName}`)
    return null // O algún componente de fallback
  }

  return <IconComponent style={{ fontSize: size, color: color, ...style }} />
}

MuiIconLoader.propTypes = {
  iconName: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
}

MuiIconLoader.defaultProps = {
  size: '24px',
  color: 'inherit',
  style: {},
}

export default MuiIconLoader
