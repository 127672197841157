import axios from "../../../axiosConfig";
import { tokenConfig } from "../../../actions/auth";
import {
  ERROR_FETCHING_MAS_PLANTILLA_DETAIL,
  ERROR_FETCHING_PLANTILLA_DETAIL,
  ERROR_FETCHING_PLANTILLAS,
  FETCH_MAS_PLANTILLA_DETAIL,
  FETCH_PLANTILLAS,
  FETCH_PLANTILLA_DETAIL,
  FLUSH_PLANTILLAS,
  GET_MAS_PLANTILLA_DETAILS,
  GET_PEDIDO_ACTIVO,
  GET_PLANTILLAS,
  GET_PLANTILLAS_DETAILS,
  SET_PLANTILLA_SELECCIONADA,
  FETCH_PEDIDO_ACTIVO,
  FETCH_PEDIDO_ACTIVO_FAIL,
  FETCH_PEDIDO_ACTIVO_SUCCESS,
} from "../../../actions/types";

export const crearPedido =
  (codigo_plantilla = null, callback) =>
  (dispatch, getState) => {
    dispatch({ type: FETCH_PEDIDO_ACTIVO });
    axios
      .post("/pedidos/", { codigo_plantilla }, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: GET_PEDIDO_ACTIVO, payload: res.data });
        dispatch({ type: FETCH_PEDIDO_ACTIVO_SUCCESS });
        callback(res.data);
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: FETCH_PEDIDO_ACTIVO_FAIL });
      });
  };

export const getDetallePlantila =
  (codigo_plantilla, limit = 30, order) =>
  (dispatch, getState) => {
    dispatch({ type: FETCH_PLANTILLA_DETAIL });
    axios
      .get(`plantillasdet/?codigo_plantilla=${codigo_plantilla}&limit=${limit}&order=${order}`, tokenConfig(getState))
      .then((res) => {
        dispatch({ type: GET_PLANTILLAS_DETAILS, payload: res.data });
      })
      .catch((_) => dispatch({ type: ERROR_FETCHING_PLANTILLA_DETAIL }));
  };

export const getListaPlantillas = () => (dispatch, getState) => {
  dispatch({ type: FETCH_PLANTILLAS });
  axios
    .get("plantillas", tokenConfig(getState))
    .then((res) => {
      dispatch({ type: GET_PLANTILLAS, payload: res.data });
    })
    .catch((err) => {
      console.error({ err });
      dispatch({ type: ERROR_FETCHING_PLANTILLAS });
    });
};

export const getMasDetallePlantila = (url) => (dispatch, getState) => {
  dispatch({
    type: FETCH_MAS_PLANTILLA_DETAIL,
  });

  axios
    .get(url, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_MAS_PLANTILLA_DETAILS,
        payload: res.data,
      });
    })
    .catch((_) => dispatch({ type: ERROR_FETCHING_MAS_PLANTILLA_DETAIL }));
};

export const limpiarPlantillas = () => (dispatch) => {
  dispatch({ type: FLUSH_PLANTILLAS });
};

export const setPlantillaSeleccionada =
  (value = null) =>
  (dispatch) => {
    dispatch({ type: SET_PLANTILLA_SELECCIONADA, payload: value });
  };
