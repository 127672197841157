import AddSharpIcon from '@mui/icons-material/AddSharp'
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp'
import { CaretDown, CaretUp } from 'phosphor-react'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createMessage } from '../../actions/messages'
import { postLinea, setLineaActualizada } from '../../actions/pedidos'
import { hasSameParent } from '../../helpers/elements/parents'
import { cargarParametros } from '../../helpers/funciones'
import { compareDecimals, isInt } from '../../helpers/numbers/functions'
import {
  exceedsMinimumQuantity,
  isMinimumQuantity,
  isMultiple,
  retrieveLowerMultiple,
} from '../../helpers/quantitys/articulos'
import i18n from '../../lang/idiomas'

let parametros_usados = [
  'btns_inp_cant',
  'ej_onblur',
  'cant_def',
  'def_input',
  'btns_inp_cant',
  'actualizar_lineas_resumen',
  'qty_inp_style',
]
let parametros = {}

const InputNumberPersonalizado = props => {
  InputNumberPersonalizado.propTypes = {
    datos: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    decimales: PropTypes.number.isRequired,
  }

  const dispatch = useDispatch()
  const portal = useSelector(state => state.portal.portal)
  const pedido = useSelector(state => state.pedidos.pedido)
  const id_pedido_seleccionado = useSelector(state => state.pedidos.id_pedido_seleccionado)
  const hash = useSelector(state => state.auth.hash)

  const [cantidadPedida, setCantidadPedida] = useState('')
  const [estilo, setEstilo] = useState('')
  const [linea, setLinea] = useState({})
  const inputResumen = useRef()

  useEffect(() => {
    setLinea(props.datos)
  }, [props.datos])

  useEffect(() => {
    if (compruebaLinea(linea) && parametros?.btns_inp_cant === 'S') {
      if (parametros?.qty_inp_style === '2') {
        setEstilo('input-cantidad-resumen-botones')
      } else {
        setEstilo('input-qty-resumen-button-surrounded')
      }
    } else {
      setEstilo('input-cantidad-resumen')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parametros])

  const blurQuantityResumen = () => {
    //Recuperamos la cantidadMinima del articulo
    let cantidad_minima = linea?.datos_articulo?.cantidad_minima || 1
    if (isInt(cantidad_minima)) {
      cantidad_minima = parseInt(cantidad_minima)
    }
    //Recuperamos la cantidad previa
    let cantidad_previa = isInt(linea.cantidad_pedida)
      ? parseInt(linea.cantidad_pedida)
      : linea.cantidad_pedida
    // Comprobamos que exceda el minimo y sino lo seteamos y retornamos error
    if (!exceedsMinimumQuantity(linea?.datos_articulo, cantidadPedida || cantidad_previa)) {
      setCantidadLineaPedido(linea, cantidad_previa)
      dispatch(createMessage(i18n.t('general.cantnia') + ' ' + cantidad_minima, 'warning'))
    } else if (
      !isMultiple(linea?.datos_articulo, cantidadPedida || cantidad_previa, props?.decimales)
    ) {
      // Comprobamos que sea multiplo
      let multiploMasCercano = retrieveLowerMultiple(
        linea?.datos_articulo,
        cantidadPedida || cantidad_previa,
      )
      let multiplo = isInt(linea?.datos_articulo?.multiplo || 1)
        ? parseInt(linea?.datos_articulo?.multiplo || 1)
        : linea?.datos_articulo?.multiplo || 1
      if (isInt(multiploMasCercano)) {
        multiploMasCercano = parseInt(multiploMasCercano)
      }
      // Podemos setear el multiplo más cercano como nueva cantidad
      //setCantidadLineaPedido(linea, multiploMasCercano)

      setCantidadLineaPedido(linea, cantidad_previa)
      dispatch(createMessage(i18n.t('general.cantdsm') + ' ' + multiplo, 'warning'))
    } else if (cantidadPedida) {
      setCantidadLineaPedido(linea, cantidadPedida, 'blur')
    }
  }

  const changeQuantityResumen = e => {
    // Recuperamos el input
    let input = e.target.parentNode.getElementsByClassName('quantity-input')[0]

    let count = 0
    //Comprobamos si es input mobil o no
    if (
      e.currentTarget.className !== 'btn-mas' &&
      e.currentTarget.className !== 'btn-menos' &&
      e.currentTarget.className !== 'btn-plus' &&
      e.currentTarget.className !== 'btn-less'
    ) {
      if (!input.value) {
        count = null
      } else {
        count = input.value
      }
    } else {
      // Calculamos el multiplo para los botones
      let multiplo = linea?.datos_articulo?.multiplo || 1
      count =
        parseInt(cantidadPedida || linea.cantidad_pedida || parametros.cant_def || 1, 10) +
        parseInt(
          e.currentTarget.className === 'btn-mas' || e.currentTarget.className === 'btn-plus'
            ? multiplo
            : multiplo * -1,
          10,
        )
      if (!exceedsMinimumQuantity(linea?.datos_articulo, count)) {
        count = linea?.datos_articulo?.cantidad_minima || 1
      }
      if (!isMultiple(linea?.datos_articulo, count, props?.decimales)) {
        count = retrieveLowerMultiple(linea?.datos_articulo, count)
      }
      if (isMinimumQuantity(linea?.datos_articulo, count)) {
        inputResumen?.current?.focus()
      }
    }

    setCantidadLineaPedido(linea, count)
  }

  const compruebaLinea = linea => {
    return (
      linea.tipo_articulo !== 'R' &&
      linea.tipo_articulo !== 'T' &&
      linea.tipo_articulo !== 'F' &&
      !props.disabled
    )
  }

  const setCantidadLineaPedido = (linea_pedido, cantidad, accion = null) => {
    // Cargamos en la variable recibida la nueva cantidad
    let nueva_linea = JSON.parse(JSON.stringify(linea_pedido))
    nueva_linea.cantidad_pedida = cantidad
    // La seteamos en el estado local
    setCantidadPedida(cantidad)
    if (parametros?.actualizar_lineas_resumen === 'S') {
      //Actualizamos la linea y la bandera en redux
      if (!compareDecimals(linea.cantidad_pedida, cantidad)) {
        dispatch(setLineaActualizada(nueva_linea))
      }
    } else if (!compareDecimals(linea.cantidad_pedida, cantidad, 2) && accion === 'blur') {
      //Si las cantidades son distintas posteamos la linea
      dispatch(
        postLinea(
          nueva_linea,
          hash,
          id_pedido_seleccionado || pedido.id_pedido,
          'S',
          linea.cantidad_pedida,
        ),
      )
    }
  }

  const renderQtyInputButtons = side => {
    switch (side) {
      case 'left':
        if (
          compruebaLinea(linea) &&
          parametros.btns_inp_cant === 'S' &&
          parametros.qty_inp_style !== '2'
        ) {
          return (
            <div className='input-button-pad-remove'>
              <button
                type='button'
                className='btn-less'
                disabled={isMinimumQuantity(
                  linea?.datos_articulo,
                  cantidadPedida || linea.cantidad_pedida,
                )}
                onClick={e => {
                  e.preventDefault()
                  changeQuantityResumen(e)
                }}>
                <RemoveSharpIcon />
              </button>
            </div>
          )
        }
        break
      case 'right':
        if (compruebaLinea(linea) && parametros.btns_inp_cant === 'S') {
          if (parametros.qty_inp_style === '2') {
            return (
              <div className='botonera-input'>
                <button
                  type='button'
                  className='btn-mas'
                  onClick={e => {
                    e.preventDefault()
                    changeQuantityResumen(e)
                  }}>
                  <CaretUp
                    size={10}
                    weight={'duotone'}
                  />
                </button>
                <button
                  type='button'
                  className='btn-menos'
                  disabled={isMinimumQuantity(
                    linea?.datos_articulo,
                    cantidadPedida || linea.cantidad_pedida,
                  )}
                  onClick={e => {
                    e.preventDefault()
                    changeQuantityResumen(e)
                  }}>
                  <CaretDown
                    size={10}
                    weight={'duotone'}
                  />
                </button>
              </div>
            )
          } else {
            return (
              <div className='input-button-pad-add'>
                <button
                  type='button'
                  className='btn-plus'
                  onClick={e => {
                    e.preventDefault()
                    changeQuantityResumen(e)
                  }}>
                  <AddSharpIcon />
                </button>
              </div>
            )
          }
        }
        break
      default:
        break
    }
  }

  if (Object.keys(linea).length > 0) {
    parametros = cargarParametros(parametros_usados, portal.parametros_ecom, 'RES')

    if (linea.sub_pres) {
      return linea.sub_pres_cant + ' X ' + linea.sub_pres
    } else {
      return (
        <div
          className='detach-quantity-desctope info-cantidad'
          id={linea.id}>
          <div
            className='tt-input-counter style-01 div-cantidad-resumen'
            id={linea.id}
            style={{ display: 'flex' }}
            onBlur={e => {
              e.preventDefault()
              if (
                !hasSameParent(
                  e?.relatedTarget?.parentNode,
                  'detach-quantity-desctope info-cantidad',
                  linea.id,
                )
              ) {
                blurQuantityResumen()
              }
            }}>
            {renderQtyInputButtons('left')}
            <input
              ref={inputResumen}
              className={'quantity-input ' + estilo}
              type='number'
              step={linea?.datos_articulo?.multiplo || 1}
              min={linea?.datos_articulo?.cantidad_minima || 0}
              placeholder={
                isInt(linea.cantidad_pedida)
                  ? parseInt(linea.cantidad_pedida)
                  : Number(linea.cantidad_pedida).toFixed(props.decimales).replace(/\./g, ',')
              }
              value={cantidadPedida}
              onChange={e => {
                e.preventDefault()
                changeQuantityResumen(e)
              }}
              size='5'
              data-articulo={linea.articulo}
              data-presentacion_pedido={linea.presentacion_pedido}
              data-descripcion={linea.descripcion}
              disabled={!compruebaLinea(linea)}
            />
            {renderQtyInputButtons('right')}
          </div>
        </div>
      )
    }
  }
}
export default InputNumberPersonalizado
