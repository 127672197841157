class YoutubeHelper {
  static isYouTubeLink(string) {
    let link = string
    const youtubePatterns = [
      /^https?:\/\/(www\.)?youtube\.com\/watch\?v=.*/,
      /^https?:\/\/(www\.)?youtube\.com\/shorts\/.*/,
      /^https?:\/\/(www\.)?youtube\.com\/embed\/.*/,
    ]

    if (YoutubeHelper.isStringContainIframe(link)) {
      link = YoutubeHelper.htmlIframeSrc(link)
    }

    // Check if the link matches any of the patterns
    for (let i = 0; i < youtubePatterns.length; i++) {
      if (youtubePatterns[i].test(link)) {
        return true
      }
    }
    return false
  }

  static isEmbedString(str) {
    const embedRegex = /^https?:\/\/(www\.)?youtube\.com\/embed\/.*/
    return embedRegex.test(str)
  }

  static isStringContainIframe(str) {
    const iframeRegex = /<iframe.*?<\/iframe>/i
    return iframeRegex.test(str)
  }

  static isYoutubePlaylist(link) {
    const regex = /^https?:\/\/(www\.)?youtube\.com\/playlist\?list=.*/
    const res = regex.test(link)
    return res
  }

  static isYouTubeShortLink(link) {
    var youtubeShortLinkRegex = /^https?:\/\/(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]{11})/
    const res = youtubeShortLinkRegex.test(link)
    return res
  }

  static isYoutubeWatchLink(link) {
    const regex = /^https?:\/\/(www\.)?youtube\.com\/watch\?v=.*/
    const res = regex.test(link)
    return res
  }

  static convertWatchLinkToEmbed(link) {
    if (YoutubeHelper.isEmbedString(link)) return link
    // if (YoutubeHelper.isStringContainIframe(link)) return YoutubeHelper.htmlIframeSrc(link)
    if (YoutubeHelper.isYouTubeShortLink(link)) return link.replace('shorts', 'embed')
    if (YoutubeHelper.isYoutubeWatchLink(link))
      return `https://www.youtube.com/embed/${link.match(/[?&]v=([^&]+)/)[1]}`
    if (YoutubeHelper.isYoutubePlaylist(link)) return null
    return null
  }

  static htmlIframeSrc(htmlString) {
    var tempContainer = document.createElement('div')
    tempContainer.innerHTML = htmlString
    var iframeElement = tempContainer.querySelector('iframe')
    if (iframeElement) {
      var srcAttributeValue = iframeElement.getAttribute('src')
      return srcAttributeValue
    } else {
      return null
    }
  }
}
export default YoutubeHelper
