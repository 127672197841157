import axios from '../axiosConfig'

import { tokenConfig } from './auth'
import { FETCH_OFERTAS, GET_OFERTAS } from './types'

// GET ARTICULOS
export const getOfertas =
  (domicilio_envio = '', limit = 12) =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_OFERTAS,
      payload: true,
    })
    axios
      .get(
        `/articulos/ofertas/?domicilio_envio=${domicilio_envio}&limit=${limit}`,
        tokenConfig(getState),
      )
      .then(res => {
        dispatch({
          type: GET_OFERTAS,
          payload: res.data,
        })
      })
      .catch(err => console.log(err))
  }
