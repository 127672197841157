import { ChevronRight } from '@mui/icons-material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import { Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { useState } from 'react'
import { getFechaFromISO } from '../../../common/libs/fechas'
import FilesHelper from '../../../common/libs/Files'
import DocumentCard from './DocumentCard'

/**
 * Component that renders and download a document
 *
 * @param {Object} props
 * @param {DocumentacionDto} props.document - The document to show
 */
const DocumentacionCard = ({ document }) => {
  const [displayDocument, setDisplayDocument] = useState(document)

  return (
    <DocumentCard
      title={displayDocument.titulo}
      readDate={displayDocument.descargado}
      content={
        <List
          sx={{ width: '100%' }}
          dense={true}>
          <ListItem>
            <ListItemIcon>
              <AccessTimeIcon sx={{ color: 'green' }} />
            </ListItemIcon>
            <ListItemText
              primary={'Publicado el ' + getFechaFromISO(displayDocument.fecha_publicacion)}
              primaryTypographyProps={{ style: { color: 'green' } }}
              //secondary='Fecha publicación'
            />
          </ListItem>
          {displayDocument.fecha_baja ? (
            <ListItem>
              <ListItemIcon>
                <HourglassBottomIcon sx={{ color: 'red' }} />
              </ListItemIcon>
              <ListItemText
                primary={'Caduca el ' + getFechaFromISO(displayDocument.fecha_baja)}
                primaryTypographyProps={{ style: { color: 'red' } }}
                //secondary='Fecha publicación'
              />
            </ListItem>
          ) : null}
        </List>
      }
      actionsPrimary={
        <>
          {displayDocument.id && displayDocument.tipo_doc && document.tipo_doc > 0 ? (
            <Button
              style={{ textTransform: 'none' }}
              onClick={async () => {
                await FilesHelper.downloadFile(
                  displayDocument.id,
                  document.tipo_doc,
                  document.url,
                ).finally(() => {
                  const date = new Date().toISOString()
                  setDisplayDocument({ ...displayDocument, descargado: date })
                })
              }}
              endIcon={<ChevronRight />}>
              {'Abrir ' + displayDocument.d_tipo.toLowerCase()}
            </Button>
          ) : (
            <></>
          )}
        </>
      }
      forced={displayDocument.lectura_obligatoria}
      MIMEType={displayDocument.mime_type}
      subtitle={displayDocument.d_categoria}
      img_title={displayDocument.portada_nombre}
      chip={displayDocument.d_tag}
    />
  )
}

export default DocumentacionCard
