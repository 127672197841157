import { ArrowLineLeft, Eye, Trash, Upload } from 'phosphor-react'
import React, { Fragment, useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useDispatch, useSelector } from 'react-redux'
import { eliminarPedido, getPedidoActivo, setIdPedidoSeleccionado } from '../../actions/pedidos'
import {
  getPedidosPendientes,
  limpiarDatosPedidosPendientes,
  setPedido,
} from '../../actions/pedidosPendientes'
import { getPreciosYPermisos } from '../../actions/precios'
import { getStocksArticulos } from '../../actions/stocks'
import { cargarArrayPrecios, cargarArrayStocks, cargarParametros } from '../../helpers/funciones'
import i18n from '../../lang/idiomas'
import Spinner from '../layout/Spinner'
import Descripcion from './Descripcion'
import Precio from './Precio'

let articulos_aux = []
let parametros_usados = ['mostrar_iva', 'tipo_precio', 'maxim_agrup']
let parametros

let index = -1

const PedidosPendientes = props => {
  const dispatch = useDispatch()
  const portal = useSelector(state => state.portal.portal)
  const pedidos_pendientes = useSelector(state => state.pedidosPendientes.pedidos_pendientes)
  const pedido_pendiente_detalle = useSelector(
    state => state.pedidosPendientes.pedido_pendiente_detalle,
  )
  const lineas_pedido_pendiente_detalle = useSelector(
    state => state.pedidosPendientes.lineas_pedido_pendiente_detalle,
  )
  const precios = useSelector(state => state.precios.precio)
  const stocks = useSelector(state => state.stocks.stock)
  const locale = useSelector(state => state.precios.locale)
  const moneda = useSelector(state => state.precios.moneda)
  const id_pedido_seleccionado = useSelector(state => state.pedidos.id_pedido_seleccionado)
  const pedido = useSelector(state => state.pedidos.pedido)
  const pedidos_pendientes_loading = useSelector(state => state.pedidosPendientes.isLoading)

  const [mostrar_lineas, setMostrarLineas] = useState(false)

  parametros = cargarParametros(parametros_usados, portal.parametros_ecom, 'PEDP')
  useEffect(() => {
    window.wookie()
    return () => {
      dispatch(limpiarDatosPedidosPendientes())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (id_pedido_seleccionado) {
      dispatch(getPedidosPendientes(id_pedido_seleccionado))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_pedido_seleccionado])

  useEffect(() => {
    dispatch(getPedidosPendientes(pedido.id_pedido))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pedido.id_pedido])

  useEffect(() => {
    index = pedidos_pendientes.findIndex(
      pedido_selecionado => pedido_selecionado.id_pedido === pedido_pendiente_detalle,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pedido_pendiente_detalle])

  useEffect(() => {
    let ancla = window.document.getElementById('cabecera-principal')

    if (ancla) {
      ancla.scrollIntoView({ behavior: 'smooth' })
    }

    if (lineas_pedido_pendiente_detalle?.length > 0) {
      articulos_aux = cargarArrayPrecios(
        'pedidos_pendientes',
        lineas_pedido_pendiente_detalle,
        precios,
      )
      if (articulos_aux?.length > 0) {
        //Solo llamamos a getPrecios si hai al menos un elemento en el array
        dispatch(
          getPreciosYPermisos(
            articulos_aux, //articulos_cantidades
            'pedidoPendiente', //origen
            null, //domicilio_envio
            parametros.maxim_agrup,
          ),
        )
      }

      articulos_aux = cargarArrayStocks(
        'pedidos_pendientes',
        lineas_pedido_pendiente_detalle,
        stocks,
        pedidos_pendientes[index],
      )
      if (articulos_aux?.length > 0) {
        //Solo llamamos a getPrecios si hai al menos un elemento en el array
        dispatch(
          getStocksArticulos(
            articulos_aux, //articulos_stock
            'pedidoPendiente', //origen
            parametros.maxim_agrup,
          ),
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineas_pedido_pendiente_detalle])

  const cargarPedido = e => {
    e.preventDefault()
    let _id_pedido = e.currentTarget.dataset.id_pedido

    confirmAlert({
      title: i18n.t('pedido.precped'),
      message: i18n.t('pedido.pesrecp'),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: i18n.t('general.si'),
          onClick: () => {
            dispatch(setIdPedidoSeleccionado(_id_pedido))
            dispatch(getPedidoActivo('', 'S', _id_pedido))
          },
        },
        {
          label: i18n.t('general.no'),
        },
      ],
    })
  }

  const eliminarPedidoPendiente = e => {
    e.preventDefault()
    let idPedido = e.currentTarget.dataset.id_pedido
    confirmAlert({
      title: i18n.t('pedido.pelimped'),
      message: i18n.t('pedido.peselped'),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: i18n.t('general.si'),
          onClick: () => dispatch(eliminarPedido(idPedido, '', idPedido, 'pendientes')),
        },
        {
          label: i18n.t('general.no'),
        },
      ],
    })
  }

  const lineas = e => {
    e.preventDefault()
    let _id_pedido = e.currentTarget.dataset.id_pedido
    dispatch(setPedido(_id_pedido))
    verLineas(e)
  }

  const cerrarLineas = () => {
    setMostrarLineas(false)
  }

  const verLineas = e => {
    e.preventDefault()
    setMostrarLineas(true)
  }

  return (
    <Fragment>
      <div className='container'>
        <div
          id='tt-pageContent'
          className='pedidos-pendientes'>
          {!mostrar_lineas ? (
            <div className='container-indent'>
              <div className='container'>
                <h1 className='tt-title-subpages noborder'>{i18n.t('pedido.ppedpend')}</h1>
                <div className='row'>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <Spinner showSpinner={pedidos_pendientes_loading} />
                    {pedidos_pendientes.length === 0 && !pedidos_pendientes_loading ? (
                      <h5>{i18n.t('pedido.pnepedpe')}</h5>
                    ) : (
                      <Fragment>
                        <div className='tt-shopcart-table pedidos_pendientes_desctop row'>
                          <div className='col-sm-12 col-md-12 col-lg-12'>
                            {pedidos_pendientes.length > 0 ? (
                              <Fragment>
                                <div className='row titulos'>
                                  <div className='col-sm-12 col-md-4 col-lg-4'>
                                    {i18n.t('pedido.pfechped')}
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-4'>
                                    {i18n.t('pedido.pimprt')}
                                  </div>
                                  <div className='col-sm-12 col-md-3 col-lg-3'>
                                    {i18n.t('pedido.paccns')}
                                  </div>
                                </div>

                                {pedidos_pendientes.map(pedido => {
                                  return pedido.lineas === undefined ||
                                    pedido.lineas.length === 0 ? (
                                    ''
                                  ) : (
                                    <div
                                      className='row pedidos-pendientes-row'
                                      key={pedido.id_pedido}>
                                      <div className='col-sm-12 col-md-4 col-lg-4'>
                                        {new Date(
                                          Date.parse(pedido.fecha_pedido),
                                        ).toLocaleDateString(locale)}
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-4'>
                                        {!pedido?.lineas || pedido?.lineas?.length === 0
                                          ? 0
                                          : Intl.NumberFormat(locale, {
                                              style: 'currency',
                                              currency: moneda,
                                            }).format(
                                              Number.parseFloat(
                                                pedido.lineas
                                                  .map(linea => {
                                                    return Number.parseFloat(linea.importe_neto_lin)
                                                  })
                                                  .reduce(
                                                    (accumulator, currentValue) =>
                                                      accumulator + currentValue,
                                                  ),
                                              ).toFixed(2),
                                            )}
                                      </div>
                                      <div className='col-sm-12 col-md-1 col-lg-1'>
                                        <button
                                          className='btn btn-primary'
                                          onClick={lineas}
                                          data-id_pedido={pedido.id_pedido}>
                                          <Eye
                                            size={22}
                                            weight={'regular'}
                                          />
                                        </button>
                                      </div>
                                      <div className='col-sm-12 col-md-1 col-lg-1'>
                                        <button
                                          className='btn btn-primary'
                                          onClick={cargarPedido}
                                          data-id_pedido={pedido.id_pedido}>
                                          <Upload
                                            size={22}
                                            weight={'regular'}
                                          />
                                        </button>
                                      </div>
                                      <div className='col-sm-12 col-md-1 col-lg-1'>
                                        <button
                                          className='btn btn-primary'
                                          onClick={eliminarPedidoPendiente}
                                          data-id_pedido={pedido.id_pedido}>
                                          <Trash
                                            size={22}
                                            weight={'regular'}
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  )
                                })}
                              </Fragment>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className='tt-shopcart-table pedidos_pendientes_mobile row'>
                          <div className='col-sm-12 col-md-12 col-lg-12'>
                            {pedidos_pendientes.map(pedido => {
                              return (
                                <div
                                  className='row pedidos-pendientes-row'
                                  key={pedido.id_pedido}>
                                  <div className='col-12'>
                                    <div className='row element-row'>
                                      <div className='col-6 titulos'>
                                        {i18n.t('pedido.pfechped')}
                                      </div>
                                      <div className='col-6'>
                                        {new Date(
                                          Date.parse(pedido.fecha_pedido),
                                        ).toLocaleDateString(locale)}
                                      </div>
                                    </div>
                                    <div className='row element-row'>
                                      <div className='col-6 titulos'>{i18n.t('pedido.pimprt')}</div>
                                      <div className='col-6'>
                                        {!pedido?.lineas || pedido?.lineas?.length === 0
                                          ? 0
                                          : Intl.NumberFormat(locale, {
                                              style: 'currency',
                                              currency: moneda,
                                            }).format(
                                              Number.parseFloat(
                                                pedido.lineas
                                                  .map(linea => {
                                                    return Number.parseFloat(linea.importe_neto_lin)
                                                  })
                                                  .reduce(
                                                    (accumulator, currentValue) =>
                                                      accumulator + currentValue,
                                                  ),
                                              ).toFixed(2),
                                            )}
                                      </div>
                                    </div>
                                    <div className='row element-row'>
                                      <div className='col-6 titulos'>{i18n.t('pedido.paccns')}</div>
                                      <div className='col-6'>
                                        <div className='row'>
                                          <div className='col-3'>
                                            <button
                                              className='btn btn-primary pedidos-pendientes-accion'
                                              onClick={lineas}
                                              data-id_pedido={pedido.id_pedido}>
                                              <Eye
                                                size={22}
                                                weight={'regular'}
                                              />
                                            </button>
                                          </div>
                                          <div className='col-3'>
                                            {pedido.id_pedido === '' ? (
                                              ''
                                            ) : (
                                              <button
                                                className='btn btn-primary'
                                                onClick={cargarPedido}
                                                data-id_pedido={pedido.id_pedido}>
                                                <Upload
                                                  size={22}
                                                  weight={'regular'}
                                                />
                                              </button>
                                            )}
                                          </div>
                                          <div className='col-3'>
                                            {pedido.id_pedido === '' ? (
                                              ''
                                            ) : (
                                              <button
                                                className='btn btn-primary'
                                                onClick={eliminarPedidoPendiente}
                                                data-id_pedido={pedido.id_pedido}>
                                                <Trash
                                                  size={22}
                                                  weight={'regular'}
                                                />
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {mostrar_lineas ? (
            <Fragment>
              {!lineas_pedido_pendiente_detalle || lineas_pedido_pendiente_detalle?.length === 0 ? (
                <div className='container-indent'>
                  <div className='container modal-body'>
                    <h5
                      onClick={cerrarLineas}
                      className='atras-listas'>
                      <ArrowLineLeft
                        size={22}
                        weight={'bold'}
                      />{' '}
                      {i18n.t('pedido.ppendmin')}
                    </h5>
                    <h1 className='tt-title-subpages noborder'>{i18n.t('pedido.plineasp')}</h1>
                    <div className='row'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <p>{i18n.t('pedido.pnhlinep')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='container-indent'>
                  <div className='container modal-body'>
                    <h5
                      onClick={cerrarLineas}
                      className='atras-listas'>
                      <ArrowLineLeft
                        size={22}
                        weight={'bold'}
                      />{' '}
                      {i18n.t('pedido.ppendmin')}
                    </h5>
                    <h1 className='tt-title-subpages noborder'>{i18n.t('pedido.plineasp')}</h1>
                    <div className='row'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='tt-shopcart-table pedidos_pendientes_detalle_desctop row'>
                          <div className='col-sm-12 col-md-12 col-lg-12'>
                            <div className='row titulos'>
                              <div className='col-sm-12 col-md-2 col-lg-2'>
                                {i18n.t('pedido.partcl')}
                              </div>
                              <div className='col-sm-12 col-md-4 col-lg-4'>
                                {i18n.t('pedido.pdescr')}
                              </div>
                              <div className='col-sm-12 col-md-2 col-lg-2'>
                                {i18n.t('pedido.pprec')}
                              </div>
                              <div className='col-sm-12 col-md-2 col-lg-2'>
                                {i18n.t('pedido.pcant')}
                              </div>
                              <div className='col-sm-12 col-md-1 col-lg-1'>
                                {i18n.t('pedido.ptipo')}
                              </div>
                              <div className='col-sm-12 col-md-1 col-lg-1'>
                                {i18n.t('pedido.ppresnt')}
                              </div>
                            </div>

                            {lineas_pedido_pendiente_detalle.map(linea => {
                              return (
                                <div
                                  className='row pedidos-pendientes-row'
                                  key={linea.id}>
                                  <div className='col-sm-12 col-md-2 col-lg-2'>
                                    {linea.articulo}
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-4'>
                                    <Descripcion codigo_articulo={linea.articulo} />
                                  </div>
                                  <div className='col-sm-12 col-md-2 col-lg-2'>
                                    <Precio
                                      codigo_articulo={linea.articulo}
                                      presentacion={linea.presentacion_pedido}
                                      mostrar_iva={parametros.mostrar_iva}
                                      tipo_precio={parametros.tipo_precio}
                                      mostrar_pres={parametros.mostrar_pres}
                                      estilo_precio={parametros.estilo_precio}
                                    />
                                  </div>
                                  <div className='col-sm-12 col-md-2 col-lg-2'>
                                    {linea.cantidad_pedida}
                                  </div>
                                  <div className='col-sm-12 col-md-1 col-lg-1'>
                                    {linea.tipo_linea}
                                  </div>
                                  <div className='col-sm-12 col-md-1 col-lg-1'>
                                    {linea.presentacion_pedido}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>

                        <div className='tt-shopcart-table pedidos_pendientes_detalle_mobile row'>
                          <div className='col-sm-12 col-md-12 col-lg-12'>
                            {lineas_pedido_pendiente_detalle.map(linea => {
                              return (
                                <div
                                  key={linea.articulo + linea.presentacion_pedido}
                                  className='row pedidos-pendientes-row'>
                                  <div className='col-12'>
                                    <div className='row element-row'>
                                      <div className='col-6 titulos'>{i18n.t('pedido.partcl')}</div>
                                      <div className='col-6'>{linea.articulo}</div>
                                    </div>

                                    <div className='row element-row'>
                                      <div className='col-6 titulos'>{i18n.t('pedido.pdescr')}</div>
                                      <div className='col-6'>
                                        <Descripcion codigo_articulo={linea.articulo} />
                                      </div>
                                    </div>

                                    <div className='row element-row'>
                                      <div className='col-6 titulos'>{i18n.t('pedido.pprec')}</div>
                                      <div className='col-6'>
                                        <Precio
                                          codigo_articulo={linea.articulo}
                                          presentacion={linea.presentacion_pedido}
                                          mostrar_iva={parametros.mostrar_iva}
                                          tipo_precio={parametros.tipo_precio}
                                          mostrar_pres={parametros.mostrar_pres}
                                          estilo_precio={parametros.estilo_precio}
                                        />
                                      </div>
                                    </div>

                                    <div className='row element-row'>
                                      <div className='col-6 titulos'>{i18n.t('pedido.pcant')}</div>
                                      <div className='col-6'>{linea.cantidad_pedida}</div>
                                    </div>

                                    <div className='row element-row'>
                                      <div className='col-6 titulos'>{i18n.t('pedio.ptipo')}</div>
                                      <div className='col-6'>{linea.tipo_linea}</div>
                                    </div>

                                    <div className='row element-row'>
                                      <div className='col-6 titulos'>{i18n.t('pedio.ppresnt')}</div>
                                      <div className='col-6'>{linea.presentacion_pedido}</div>
                                    </div>

                                    <div className='row element-row'>
                                      <div className='col-6 titulos'>{i18n.t('pedido.pobsr')}</div>
                                      <div className='col-6'>{linea.observaciones}</div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          ) : (
            ''
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default PedidosPendientes
