import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Box, IconButton, Modal } from '@mui/material'
import { Download, Eye, Link } from 'phosphor-react'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { getArticulosAux, getArticulosDoc } from '../../../actions/articulo'
import { obtenerFicheroExpediente } from '../../../actions/expedientes'
import YoutubeHelper from '../../../helpers/YoutubeHelper'
import i18n from '../../../lang/idiomas'

export class InfoAdicional extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      videoUrl: null,
    }
  }

  static propTypes = {
    codigo_articulo: PropTypes.string.isRequired,
    articulos_doc: PropTypes.array.isRequired,
    articulos_aux: PropTypes.array.isRequired,
    getArticulosDoc: PropTypes.func.isRequired,
    getArticulosAux: PropTypes.func.isRequired,
    obtenerFicheroExpediente: PropTypes.func.isRequired,
  }

  componentDidMount() {
    if (this.props.codigo_articulo) {
      this.props.getArticulosDoc(this.props.codigo_articulo)
      this.props.getArticulosAux(this.props.codigo_articulo)
    }
  }

  componentDidUpdate(prevProps) {
    window.wookie()
  }

  handleClose = () => {
    this.setState({
      showModal: false,
      videoUrl: null,
    })
  }

  onClickArchivo = e => {
    e.preventDefault()
    let codigo_articulo = e.currentTarget.dataset['articulo']
    let numero_doc = Number.parseInt(e.currentTarget.dataset['doc'])
    let fichero = this.props.articulos_doc.filter(
      articulo_doc =>
        articulo_doc.codigo_articulo === codigo_articulo && articulo_doc.numero_doc === numero_doc,
    )[0]

    if (
      e.currentTarget.dataset['type'] === 'link' &&
      fichero?.url_documento &&
      YoutubeHelper.isYouTubeLink(fichero.url_documento)
    ) {
      const videoUrl = YoutubeHelper.convertWatchLinkToEmbed(fichero.url_documento)
      this.setState({
        videoUrl,
        showModal: true,
      })
      return
    }

    if (fichero && fichero.archivo === null && fichero.token) {
      this.props.obtenerFicheroExpediente(fichero)
    } else {
      const binary = atob(fichero.archivo.replace(/\s/g, ''))
      const len = binary.length
      const buffer = new ArrayBuffer(len)
      const view = new Uint8Array(buffer)

      for (let i = 0; i < len; i += 1) {
        view[i] = binary.charCodeAt(i)
      }
      // create the blob object with content-type "application/pdf"
      const blob = new Blob([view], { type: 'application/octet-stream' })
      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = url
      a.download = fichero.nombre_fichero

      a.click()
    }
  }

  render() {
    return (
      <Fragment>
        {this.props.opiniones ||
        (this.props.articulos_doc && this.props.articulos_doc.length > 0) ||
        (this.props.articulos_aux && this.props.articulos_aux.length > 0) ? (
          <div
            className='content-indent'
            id='informacion-adicional'>
            <div className='container container-fluid-mobile'>
              <div className='row'>
                <div className='col-sm-12'>
                  <ul
                    className='nav nav-tabs'
                    id='infoAdicionalTab'
                    role='tablist'>
                    {this.props.articulos_aux && this.props.articulos_aux.length > 0 ? (
                      <li className='nav-item'>
                        <a
                          className={
                            !this.props.articulos_doc || this.props.articulos_doc.length === 0
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          id='auxiliares-tab'
                          data-toggle='tab'
                          href='#auxiliares'
                          role='tab'
                          aria-controls='auxiliares'
                          aria-selected={
                            !this.props.articulos_doc || this.props.articulos_doc.length === 0
                              ? 'true'
                              : 'false'
                          }>
                          {i18n.t('eparciales.epavisos')}
                        </a>
                      </li>
                    ) : (
                      ''
                    )}
                    {this.props.articulos_doc && this.props.articulos_doc.length > 0 ? (
                      <li className='nav-item'>
                        <a
                          className={
                            !this.props.articulos_aux || this.props.articulos_aux.length === 0
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          id='documentos-tab'
                          data-toggle='tab'
                          href='#documentos'
                          role='tab'
                          aria-controls='documentos'
                          aria-selected={
                            !this.props.articulos_aux || this.props.articulos_aux.length === 0
                              ? 'true'
                              : 'false'
                          }>
                          {i18n.t('eparciales.epdocmin')}
                        </a>
                      </li>
                    ) : (
                      ''
                    )}
                    {this.props.opiniones ? (
                      <li className='nav-item'>
                        <a
                          className={
                            (!this.props.articulos_doc || this.props.articulos_doc.length === 0) &&
                            (!this.props.articulos_aux || this.props.articulos_aux.length === 0)
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          id='opiniones-tab'
                          data-toggle='tab'
                          href='#opiniones'
                          role='tab'
                          aria-controls='opiniones'
                          aria-selected={
                            (!this.props.articulos_doc || this.props.articulos_doc.length === 0) &&
                            (!this.props.articulos_aux || this.props.articulos_aux.length === 0)
                              ? 'true'
                              : 'false'
                          }>
                          {i18n.t('eparciales.epopmin')}
                        </a>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                  <div
                    className='tab-content'
                    id='infoAdicionalContent'>
                    {this.props.articulos_aux && this.props.articulos_aux.length > 0 ? (
                      <div
                        className='tab-pane fade show active'
                        id='auxiliares'
                        role='tabpanel'
                        aria-labelledby='auxiliares-tab'>
                        <div className='row listaAuxiliares'>
                          <div className='col'>
                            {this.props.articulos_aux.map(articulo_aux => {
                              return (
                                <Fragment key={articulo_aux.id + '_1'}>
                                  <div className='row auxiliares'>
                                    <div className='col-6'>
                                      <span>{i18n.t('eparciales.epauxil')} 1</span>
                                    </div>
                                    <div className='col-6'>
                                      <span>{articulo_aux.auxiliar_1}</span>
                                    </div>
                                  </div>
                                  <div className='row auxiliares'>
                                    <div className='col-6'>
                                      <span>{i18n.t('eparciales.epauxil')} 2</span>
                                    </div>
                                    <div className='col-6'>
                                      <span>{articulo_aux.auxiliar_2}</span>
                                    </div>
                                  </div>
                                </Fragment>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {this.props.articulos_doc && this.props.articulos_doc.length > 0 ? (
                      <div
                        className={
                          !this.props.articulos_aux || this.props.articulos_aux.length === 0
                            ? 'tab-pane fade show active'
                            : 'tab-pane fade'
                        }
                        id='documentos'
                        role='tabpanel'
                        aria-labelledby='documentos-tab'>
                        <div
                          style={{ alignItems: 'center' }}
                          className='listaDocumentos'>
                          {this.props.articulos_doc
                            .filter(
                              element => element.id_archivo || element.url_documento || element.txt,
                            )
                            .map(articulo_doc => {
                              return (
                                <div key={articulo_doc.id_archivo}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h3>{articulo_doc.descripcion}</h3>
                                    <div style={{ display: 'flex', gap: '3px' }}>
                                      {articulo_doc.id_archivo || articulo_doc.url_documento ? (
                                        <>
                                          {articulo_doc.id_archivo ? (
                                            <button
                                              className='btn boton-descarga-archivo'
                                              key={articulo_doc.numero_doc}
                                              data-articulo={articulo_doc.codigo_articulo}
                                              data-doc={articulo_doc.numero_doc}
                                              data-type='download'
                                              onClick={this.onClickArchivo}>
                                              <Download size={20} />
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                          {articulo_doc.url_documento &&
                                          YoutubeHelper.isYouTubeLink(
                                            articulo_doc.url_documento,
                                          ) ? (
                                            <button
                                              className='btn boton-descarga-archivo'
                                              key={articulo_doc.numero_doc}
                                              data-articulo={articulo_doc.codigo_articulo}
                                              data-doc={articulo_doc.numero_doc}
                                              data-type='link'
                                              onClick={this.onClickArchivo}>
                                              <Eye size={20} />
                                            </button>
                                          ) : articulo_doc.url_documento ? (
                                            <button
                                              className='btn boton-descarga-archivo'
                                              key={articulo_doc.numero_doc}
                                              data-articulo={articulo_doc.codigo_articulo}
                                              data-doc={articulo_doc.numero_doc}
                                              onClick={() => {
                                                window.location.href = articulo_doc.url_documento
                                              }}>
                                              <Link size={20} />
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                  {articulo_doc.txt ? (
                                    <div
                                      dangerouslySetInnerHTML={{ __html: articulo_doc.txt }}
                                      style={{ widht: '100%', marginBottom: '1em' }}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              )
                            })}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {this.props.opiniones ? (
                      <div
                        className={
                          (!this.props.articulos_doc || this.props.articulos_doc.length === 0) &&
                          (!this.props.articulos_aux || this.props.articulos_aux.length === 0)
                            ? 'tab-pane fade show active'
                            : 'tab-pane fade'
                        }
                        id='opiniones'
                        role='tabpanel'
                        aria-labelledby='opiniones-tab'></div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {this.state.videoUrl && this.state.showModal ? (
          <Modal
            open={this.state.videoUrl && this.state.showModal}
            onClose={this.handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                background: 'white',
                width: '70vw',
                maxWidth: '560px',
                maxHeight: '315px',
              }}>
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <IconButton
                  onClick={this.handleClose}
                  sx={{ ml: 'auto' }}>
                  <HighlightOffIcon />
                </IconButton>
              </Box>
              <Box sx={{ width: '100%', aspectRatio: '16/9', maxHeight: '60vh', maxWidth: '70vw' }}>
                <iframe
                  width='100%'
                  height='100%'
                  src={this.state.videoUrl}
                  title='YouTube Video Player'
                  frameBorder='0'
                  allowFullScreen></iframe>
              </Box>
            </Box>
          </Modal>
        ) : (
          <></>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  articulos_doc: state.articulo.articulos_doc,
  articulos_aux: state.articulo.articulos_aux,
})

export default connect(mapStateToProps, {
  getArticulosDoc,
  getArticulosAux,
  obtenerFicheroExpediente,
})(InfoAdicional)
