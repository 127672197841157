import { ArrowLineLeft } from 'phosphor-react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../layout/Spinner'
import { getListaPlantillas, setPlantillaSeleccionada } from './actions/plantillas'

import i18n from '../../lang/idiomas'
import DetallesPlantilla from './components/DetallesPlantilla'
import ListaPlantillas from './components/ListaPlantillas'

const Plantillas = props => {
  const dispatch = useDispatch()

  const plantillaSeleccionada = useSelector(state => state.plantillas.plantillaSeleccionada)
  const isLoading = useSelector(state => state.plantillas.isLoading)

  useEffect(() => {
    window.wookie()
    dispatch(getListaPlantillas())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let textoMostrar = i18n.t('plantillas.pplantil')
  if (plantillaSeleccionada) textoMostrar = plantillaSeleccionada.descripcion

  return (
    <>
      <div className='container plantillas-pedido'>
        <div
          id='tt-pageContent'
          className='listas-compra'>
          <div className='container-indent'>
            <div className='container'>
              <>
                {plantillaSeleccionada ? (
                  <h5
                    onClick={() => dispatch(setPlantillaSeleccionada())}
                    className='atras-listas'>
                    <ArrowLineLeft
                      size={22}
                      weight={'regular'}
                    />{' '}
                    {i18n.t('plantillas.pplantil')}
                  </h5>
                ) : (
                  <></>
                )}
                <h1 className='tt-title-subpages noborder mt-3'>{textoMostrar}</h1>
                <div className='row'>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <>{plantillaSeleccionada ? <DetallesPlantilla /> : <ListaPlantillas />}</>
                    <Spinner showSpinner={isLoading} />
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Plantillas
