import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLineaActualizada } from '../../actions/pedidos'
import { getPrecioYPermisos, setDtoManual, setPrecioManual } from '../../actions/precios'
import i18n from '../../lang/idiomas'
import Spinner from '../layout/Spinner'

const Precio = props => {
  Precio.propTypes = {
    codigo_articulo: PropTypes.string.isRequired, // Codigo de articulo del que se va a obtener el precio
    origen: PropTypes.string, // Origen de la llamada al componente
    forzar_peticion: PropTypes.string, // Forzar la petición del precio del articulo
    presentacion: PropTypes.string, // Presentación en la que se va a solicitar el precio
    tipo_precio: PropTypes.string, // Tipo de precio a mostrar (bruto/neto), tipos: descuento, bruto, impuesto, mixto, neto
    linea: PropTypes.object, // Datos de linea a mostrar (Componentes Carrito y Resumen)
    precio_manual: PropTypes.string, // Indicativo de si el precio es manual (regalos) o calculado
    añadir_descuento: PropTypes.string, // Muestra el dto_final aplicado o lo que se envie en precio_manual al mismo en %
    estilo_precio: PropTypes.string, // Permite elegir entre unitario o grupal (defectivo)
    mostrar_iva: PropTypes.string, // Concatenar el IVA
    mostrar_pres: PropTypes.string, // Concatenar la presentacion
  }

  const dispatch = useDispatch()

  const portal = useSelector(state => state.portal.portal)
  const precio = useSelector(state => state.precios.precio)
  const decimalesPVP = useSelector(state => state.precios.decimales_pvp)
  const decimalesSignificativos = useSelector(state => state.precios.decimales_significativos)
  const locale = useSelector(state => state.precios.locale)
  const auth = useSelector(state => state.auth)
  const id_pedido_seleccionado = useSelector(state => state.pedidos.id_pedido_seleccionado)
  const pedido = useSelector(state => state.pedidos.pedido)
  const presentaciones = useSelector(state => state.presentaciones.presentaciones)
  const ponerPrecioManual = useSelector(state => state.precios.permisos?.puede_modificar_precio)
  const ponerDtoManual = useSelector(state => state.precios.permisos?.puede_modificar_descuento)
  const prevProps = useRef()

  const [precioManualLocal, setPrecioManualLocal] = useState()
  const [dtoManualLocal, setDtoManualLocal] = useState()

  useEffect(() => {
    // Comprobamos si el precio es para la ficha, y si lo es lo volvemos a pedir (para garantizar la mayor fiabilidad posible)
    if (
      (props?.codigo_articulo &&
        props?.origen === 'ficha' &&
        precio[props.codigo_articulo] === undefined) ||
      props.forzar_peticion === 'S'
    ) {
      if (
        (props.presentacion && presentaciones?.[props.codigo_articulo]?.[props.presentacion]) ||
        props.forzar_peticion === 'S'
      ) {
        if (props.codigo_articulo) {
          dispatch(
            getPrecioYPermisos(
              props.codigo_articulo,
              1,
              id_pedido_seleccionado || pedido?.id_pedido,
              pedido.domicilio_envio,
              props.presentacion,
            ),
          )
        }
      }
    }
    prevProps.current = {
      portal,
      precio,
      locale,
      auth,
      id_pedido_seleccionado,
      pedido,
    }
    prevProps.current.codigo_articulo = props.codigo_articulo
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      props?.codigo_articulo &&
      props?.origen === 'ficha' &&
      precio[props.codigo_articulo] === undefined &&
      prevProps.current.codigo_articulo !== props.codigo_articulo
    ) {
      if (props.presentacion && presentaciones?.[props.codigo_articulo]?.[props.presentacion]) {
        if (props.codigo_articulo) {
          dispatch(
            getPrecioYPermisos(
              props.codigo_articulo,
              1,
              id_pedido_seleccionado || pedido?.id_pedido,
              pedido.domicilio_envio,
              props.presentacion,
            ),
          )
        }
      }
    }
    prevProps.current = {
      portal,
      precio,
      locale,
      auth,
      id_pedido_seleccionado,
      pedido,
    }
    prevProps.current.codigo_articulo = props.codigo_articulo
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.codigo_articulo])

  useEffect(() => {
    if ((ponerPrecioManual || ponerDtoManual) && props?.origen === 'resumen') {
      pedido.lineas.forEach(linea => {
        if (
          linea.articulo === props.codigo_articulo &&
          linea.tipo_linea === 'P' &&
          linea.presentacion_pedido === props.presentacion
        ) {
          if (precioManualLocal) {
            let precio_actual = precioManualLocal
            if (precio_actual.includes(',')) {
              precio_actual = precio_actual.replace(/,/g, '.')
            }
            if (
              Number(precio_actual).toFixed(decimalesPVP) !==
              Number(linea.precio_venta).toFixed(decimalesPVP)
            ) {
              setPrecioManualLocal(linea.precio_venta.toString().replace(/\./g, ','))
            }
          }
          if (dtoManualLocal) {
            let dto_actual = dtoManualLocal
            if (dto_actual.includes(',')) {
              dto_actual = dto_actual.replace(/,/g, '.')
            }
            if (
              Number(dto_actual).toFixed(decimalesPVP) !== Number(linea.dto_1).toFixed(decimalesPVP)
            ) {
              setDtoManualLocal(linea.dto_1.toString().replace(/\./g, ','))
            }
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pedido])

  const devolverPrecio = () => {
    if (ponerPrecioManual || ponerDtoManual) {
      if (props.tipo_precio === 'descuento' && ponerDtoManual) {
        return obtenerInputDtoManual()
      } else if (props.tipo_precio === 'descuento') {
        return obtenerDescuento()
      } else if (ponerPrecioManual) {
        return obtenerInputPrecioManual()
      } else {
        return obtenerPrecioMixto()
      }
    } else {
      switch (props.tipo_precio) {
        case 'bruto':
          return obtenerPrecioBruto()
        case 'descuento':
          return obtenerDescuento()
        case 'impuesto':
          return obtenerImpuesto()
        case 'mixto':
          return obtenerPrecioMixto()
        case 'neto':
          return obtenerPrecioNeto()
        default:
          return obtenerPrecioMixto()
      }
    }
  }

  const devolverPrecioManual = () => {
    if (props?.origen === 'resumen' && props?.linea) {
      if (props.tipo_precio === 'descuento') {
        return obtenerInputDtoManualResumen()
      } else {
        return obtenerInputPrecioManualResumen()
      }
    } else if (props.tipo_precio !== 'descuento') {
      return (
        <span className='etiqueta-precio manual'>
          {new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: precio?.[props.codigo_articulo]?.[props.presentacion]?.divisa_precio || 'EUR',
          }).format(Number.parseFloat(props.precio_manual).toFixed(decimalesPVP))}
          {mostrarPresentacion()}
        </span>
      )
    } else if (props.precio_manual > 1) {
      if (
        props?.añadir_descuento &&
        props.añadir_descuento !== 'null' &&
        props.añadir_descuento !== 'N'
      ) {
        return ' -' + Number(props.precio_manual).toFixed(decimalesPVP).toString() + '%'
      } else {
        return (
          <span className='etiqueta-descuento'>
            {Number(props.precio_manual).toFixed(decimalesPVP).toString() + '%'}
          </span>
        )
      }
    }
  }

  const formatDecimal = e => {
    if (e.currentTarget.value) {
      // Reemplaza cualquier caracter que no sea un número o una coma con una cadena vacía
      e.currentTarget.value = e.currentTarget.value.replace(/[^0-9,]/g, '')
    }
  }

  const mostrarImpuestos = () => {
    return (
      <>
        {!Array.isArray(precio[props.codigo_articulo]) &&
        precio[props.codigo_articulo][props.presentacion].impuesto_articulo !== null &&
        props.mostrar_iva !== 'N'
          ? ' + ' + precio[props.codigo_articulo][props.presentacion].impuesto_articulo + '% IVA'
          : ''}
      </>
    )
  }

  const mostrarPresentacion = () => {
    return (
      <>
        {precio?.[props.codigo_articulo]?.[props.presentacion] && props.mostrar_pres !== 'N'
          ? '/' + props.presentacion
          : ''}
      </>
    )
  }

  const obtenerDescuento = () => {
    if (
      Number.parseFloat(precio[props.codigo_articulo][props.presentacion].dto_final).toFixed(
        decimalesSignificativos,
      ) > 1
    ) {
      if (
        props.añadir_descuento &&
        props.añadir_descuento !== 'null' &&
        props.añadir_descuento !== 'N'
      ) {
        return (
          ' -' +
          Number(precio[props.codigo_articulo][props.presentacion].dto_final)
            .toFixed(decimalesPVP)
            .toString() +
          '%'
        )
      } else {
        return (
          <span className='etiqueta-descuento'>
            {Number(precio[props.codigo_articulo][props.presentacion].dto_final)
              .toFixed(decimalesPVP)
              .toString() + '%'}
          </span>
        )
      }
    }
  }

  const obtenerImpuesto = () => {
    if (precio?.[props.codigo_articulo]?.[props.presentacion]?.impuesto_articulo) {
      let impuesto = Number.parseFloat(
        precio[props.codigo_articulo][props.presentacion].impuesto_articulo,
      ).toFixed(decimalesPVP)
      return <span className='etiqueta-impuesto'>{impuesto.toString() + '%'}</span>
    }
  }

  const obtenerInputDtoManual = () => {
    return (
      <input
        className='form-control input-precio-manual'
        style={{ textAlign: 'center' }}
        placeholder={
          Number(precio[props.codigo_articulo][props.presentacion].dto_1)
            .toFixed(decimalesPVP)
            .replace(/\./g, ',') || 0
        }
        type='text'
        value={dtoManualLocal}
        onChange={onChangeDto}
        onInput={formatDecimal}
      />
    )
  }

  const obtenerInputDtoManualResumen = () => {
    return (
      <input
        className='form-control input-precio-manual'
        style={{ textAlign: 'center', display: 'block' }}
        placeholder={Number(props.linea.dto_1).toFixed(decimalesPVP).replace(/\./g, ',') || 0}
        type='text'
        value={dtoManualLocal}
        onChange={onChangeDtoResumen}
        onInput={formatDecimal}
      />
    )
  }

  const obtenerInputPrecioManual = () => {
    return (
      <input
        className='form-control input-precio-manual'
        style={{ textAlign: 'center' }}
        placeholder={Number(precio[props.codigo_articulo][props.presentacion].precio_presentacion)
          .toFixed(decimalesPVP)
          .replace(/\./g, ',')}
        type='text'
        value={precioManualLocal}
        onChange={onChangePrecio}
        onInput={formatDecimal}
      />
    )
  }

  const obtenerInputPrecioManualResumen = () => {
    return (
      <input
        className='form-control input-precio-manual'
        style={{ textAlign: 'center', display: 'block' }}
        placeholder={Number(props.linea.precio_venta).toFixed(decimalesPVP).replace(/\./g, ',')}
        type='text'
        value={precioManualLocal}
        onChange={onChangePrecioResumen}
        onInput={formatDecimal}
      />
    )
  }

  const obtenerPrecioBruto = () => {
    if (props.estilo_precio === 'unitario') {
      return (
        <span className='etiqueta-precio-bruto'>
          {precio[props.codigo_articulo][props.presentacion].precio_presentacion
            ? new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
              }).format(
                Number.parseFloat(
                  precio[props.codigo_articulo][props.presentacion].precio_presentacion,
                ).toFixed(decimalesPVP),
              )
            : ''}

          {mostrarImpuestos()}

          {mostrarPresentacion()}
          {obtenerDescuento()}
        </span>
      )
    } else {
      return (
        <span className='etiqueta-precio-bruto'>
          {precio[props.codigo_articulo][props.presentacion].importe_bruto
            ? new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
              }).format(
                Number.parseFloat(
                  precio[props.codigo_articulo][props.presentacion].importe_bruto,
                ).toFixed(decimalesPVP),
              )
            : ''}

          {mostrarImpuestos()}

          {mostrarPresentacion()}
          {obtenerDescuento()}
        </span>
      )
    }
  }

  const obtenerPrecioNeto = () => {
    if (props.estilo_precio === 'unitario') {
      return (
        <span className='etiqueta-precio'>
          {precio[props.codigo_articulo][props.presentacion].precio_neto_presentacion
            ? new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
              }).format(
                Number.parseFloat(
                  precio[props.codigo_articulo][props.presentacion].precio_neto_presentacion,
                ).toFixed(decimalesPVP),
              )
            : ''}

          {mostrarImpuestos()}

          {mostrarPresentacion()}
        </span>
      )
    } else {
      return (
        <span className='etiqueta-precio'>
          {precio[props.codigo_articulo][props.presentacion].importe_neto
            ? new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
              }).format(
                Number.parseFloat(
                  precio[props.codigo_articulo][props.presentacion].importe_neto,
                ).toFixed(decimalesPVP),
              )
            : ''}

          {mostrarImpuestos()}

          {mostrarPresentacion()}
        </span>
      )
    }
  }

  const obtenerPrecioMixto = () => {
    if (props.estilo_precio === 'unitario') {
      return obtenerPrecioMixtoUnitario()
    } else if (
      auth?.user?.netear_precio &&
      auth.user.netear_precio !== 'N' &&
      Number.parseFloat(precio[props.codigo_articulo][props.presentacion].importe_bruto).toFixed(
        decimalesPVP,
      ) !==
        Number.parseFloat(precio[props.codigo_articulo][props.presentacion].importe_neto).toFixed(
          decimalesPVP,
        )
    ) {
      return (
        <Fragment>
          <span className='etiqueta-precio-tachado mixto'>
            {precio[props.codigo_articulo][props.presentacion].importe_bruto
              ? new Intl.NumberFormat(locale, {
                  style: 'currency',
                  currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
                }).format(
                  Number.parseFloat(
                    precio[props.codigo_articulo][props.presentacion].importe_bruto,
                  ).toFixed(decimalesPVP),
                )
              : ''}

            {mostrarImpuestos()}

            {mostrarPresentacion()}
          </span>
          <span className='etiqueta-precio mixto'>
            {precio[props.codigo_articulo][props.presentacion].importe_neto
              ? new Intl.NumberFormat(locale, {
                  style: 'currency',
                  currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
                }).format(
                  Number.parseFloat(
                    precio[props.codigo_articulo][props.presentacion].importe_neto,
                  ).toFixed(decimalesPVP),
                )
              : ''}
          </span>
        </Fragment>
      )
    } else {
      return (
        <span className='etiqueta-precio mixto'>
          {precio[props.codigo_articulo][props.presentacion].importe_neto
            ? new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
              }).format(
                Number.parseFloat(
                  precio[props.codigo_articulo][props.presentacion].importe_neto,
                ).toFixed(decimalesPVP),
              )
            : ''}

          {mostrarImpuestos()}

          {mostrarPresentacion()}
        </span>
      )
    }
  }

  const obtenerPrecioMixtoUnitario = () => {
    if (
      auth?.user?.netear_precio &&
      auth.user.netear_precio !== 'N' &&
      Number.parseFloat(
        precio[props.codigo_articulo][props.presentacion].precio_presentacion,
      ).toFixed(decimalesPVP) !==
        Number.parseFloat(
          precio[props.codigo_articulo][props.presentacion].precio_neto_presentacion,
        ).toFixed(decimalesPVP)
    ) {
      return (
        <Fragment>
          <span className='etiqueta-precio-tachado mixto'>
            {precio[props.codigo_articulo][props.presentacion].precio_presentacion
              ? new Intl.NumberFormat(locale, {
                  style: 'currency',
                  currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
                }).format(
                  Number.parseFloat(
                    precio[props.codigo_articulo][props.presentacion].precio_presentacion,
                  ).toFixed(decimalesPVP),
                )
              : ''}

            {mostrarImpuestos()}

            {mostrarPresentacion()}
          </span>
          <span className='etiqueta-precio mixto'>
            {precio[props.codigo_articulo][props.presentacion].precio_neto_presentacion
              ? new Intl.NumberFormat(locale, {
                  style: 'currency',
                  currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
                }).format(
                  Number.parseFloat(
                    precio[props.codigo_articulo][props.presentacion].precio_neto_presentacion,
                  ).toFixed(decimalesPVP),
                )
              : ''}
          </span>
        </Fragment>
      )
    } else {
      return (
        <span className='etiqueta-precio mixto'>
          {precio[props.codigo_articulo][props.presentacion].precio_neto_presentacion
            ? new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
              }).format(
                Number.parseFloat(
                  precio[props.codigo_articulo][props.presentacion].precio_neto_presentacion,
                ).toFixed(decimalesPVP),
              )
            : ''}

          {mostrarImpuestos()}

          {mostrarPresentacion()}
        </span>
      )
    }
  }

  // const obtenerPrecioTachado = () => {
  //   return (
  //     <span className='etiqueta-precio-tachado'>
  //       {precio[props.codigo_articulo][props.presentacion].precio_presentacion
  //         ? new Intl.NumberFormat(locale, {
  //             style: 'currency',
  //             currency: precio[props.codigo_articulo][props.presentacion].divisa_precio,
  //           }).format(
  //             Number.parseFloat(
  //               precio[props.codigo_articulo][props.presentacion].precio_presentacion,
  //             ).toFixed(decimalesPVP),
  //           )
  //         : ''}
  //       {mostrarImpuestos()}
  //       {mostrarPresentacion()}
  //     </span>
  //   )
  // }

  const onChangePrecio = e => {
    e.preventDefault()
    let nuevo_precio = validarPrecio(e.target.value)
    dispatch(
      setPrecioManual(
        props.codigo_articulo,
        Number(nuevo_precio).toFixed(decimalesSignificativos),
        props.presentacion,
      ),
    )
  }

  const onChangePrecioResumen = e => {
    e.preventDefault()
    let nuevo_precio = validarPrecio(e.target.value)

    let linea_precio = props.linea
    linea_precio.precio_venta = Number(nuevo_precio).toFixed(decimalesSignificativos)
    linea_precio.precio_manual = 'S'
    dispatch(setLineaActualizada(linea_precio))
  }

  const onChangeDto = e => {
    e.preventDefault()
    let nuevo_dto = validarDto(e.target.value)
    dispatch(
      setDtoManual(
        props.codigo_articulo,
        Number(nuevo_dto).toFixed(decimalesSignificativos),
        props.presentacion,
      ),
    )
  }

  const onChangeDtoResumen = e => {
    e.preventDefault()
    let nuevo_dto = validarDto(e.target.value)

    let linea_dto = props.linea
    linea_dto.dto_1 = Number(nuevo_dto).toFixed(decimalesSignificativos)
    linea_dto.precio_manual = 'S'

    dispatch(setLineaActualizada(linea_dto))
  }

  const validarDto = descuento => {
    let nuevo_dto =
      (descuento || descuento === 0) && parseFloat(descuento) >= 0
        ? descuento
        : precio?.[props.codigo_articulo]?.[props.presentacion]?.dto_origen ||
          precio?.[props.codigo_articulo]?.[props.presentacion]?.dto_1 ||
          0
    let maximo = '99'
    if (parseFloat(nuevo_dto) >= parseFloat(maximo)) nuevo_dto = maximo
    if (parseFloat(nuevo_dto) < 1) nuevo_dto = '1'
    setDtoManualLocal(nuevo_dto)
    nuevo_dto = nuevo_dto.replace(/,/g, '.')
    return nuevo_dto
  }

  const validarPrecio = precioManual => {
    let nuevo_precio =
      (precioManual || precioManual === 0) && parseFloat(precioManual) >= 0
        ? precioManual
        : precio?.[props.codigo_articulo]?.[props.presentacion]?.precio_origen ||
          precio?.[props.codigo_articulo]?.[props.presentacion]?.precio_presentacion ||
          0
    setPrecioManualLocal(nuevo_precio)
    nuevo_precio = nuevo_precio.replace(/,/g, '.')
    return nuevo_precio
  }

  // Precio cargando retornamos spinner
  if (auth?.user?.ver_precios === 'S') {
    if (
      (!precio?.[props.codigo_articulo]?.[props.presentacion] ||
        precio?.[props.codigo_articulo]?.[props.presentacion]?.status === 'fetching') &&
      (props.precio_manual === undefined || props.precio_manual === '')
    ) {
      return <Spinner showSpinner={true} />
    } else if (props.precio_manual || props.precio_manual === 0 || props.origen === 'resumen') {
      // Si el precio es manual lo retornamos tal cual
      return <>{devolverPrecioManual()}</>
    } else if (precio?.[props.codigo_articulo]?.[props.presentacion]?.status === 'error') {
      // Error retornamos mensaje
      return <span className='etiqueta-precio no-disp'>{i18n.t('general.precnodi')}</span>
    } else if (precio?.[props.codigo_articulo]?.[props.presentacion]) {
      // En cualquier otro caso retornamos lo que se nos indique en el prop tipo precio
      return (
        <Fragment>
          <meta
            itemProp='priceCurrency'
            content={precio[props.codigo_articulo][props.presentacion].divisa_precio}
          />
          <meta
            itemProp='price'
            content={Number.parseFloat(
              precio[props.codigo_articulo][props.presentacion].precio_neto_presentacion,
            ).toFixed(decimalesPVP)}
          />
          {devolverPrecio()}
        </Fragment>
      )
    }
  }
}

export default Precio
