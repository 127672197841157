import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { setBusqueda } from '../../actions/articulos'
import { setFamiliaSeleccionada } from '../../actions/familias'
import { añadirFiltro, getFiltros, limpiarFiltros } from '../../actions/filtros'
import { cargarParametros } from '../../helpers/funciones'
import ArticulosFiltros from './ArticulosFiltros'
import Buscador from './Buscador'
import BuscadorAvanzado from './BuscadorAvanzado'
import FamiliasMenu from './FamiliasMenu'
import ListadoArticulos from './ListadoArticulos'

let parametros_usados = ['bus_avanz', 'tipo_precio']
let parametros
let estadistico_url
let familia_url
let descrip_url

const Articulos = () => {
  const dispatch = useDispatch()

  const portal = useSelector(state => state.portal.portal)
  const filtros = useSelector(state => state.articulos.filtros)
  const filtros_aplicados = useSelector(state => state.articulos.filtros_aplicados)
  const filtros_claves_estadisticas = useSelector(
    state => state.articulos.filtros_claves_estadisticas,
  )

  const [buscador, setBuscador] = useState('basico')

  parametros = cargarParametros(parametros_usados, portal.parametros_ecom, 'ART')

  // Partimos la URL
  let url_params = useParams()
  estadistico_url = url_params.codigo_estadistico
  familia_url = url_params.codigo_familia
  descrip_url = url_params.d_codigo_familia

  useEffect(() => {
    window.wookie()

    let ancla = window.document.getElementById('cabecera-principal')
    if (ancla) {
      ancla.scrollIntoView({ behavior: 'smooth' })
    }
  })

  useEffect(() => {
    if (estadistico_url && familia_url && descrip_url) {
      dispatch(limpiarFiltros())
      dispatch(setBusqueda(''))
      dispatch(setFamiliaSeleccionada(estadistico_url, familia_url, ''))
      dispatch(
        añadirFiltro({
          estadistico: estadistico_url,
          familia: familia_url,
          nombre: '',
          tipo: 'estad',
        }),
      )
    }

    if (
      (filtros?.filtros?.length ?? 0) === 0 ||
      (filtros_claves_estadisticas?.filtros_claves_estadisticas?.length ?? 0) === 0
    ) {
      dispatch(getFiltros())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cambiarBuscador = () => {
    if (buscador === 'basico') {
      setBuscador('avanzado')
    } else {
      setBuscador('basico')
    }
  }

  const returnBuscador = () => {
    if (parametros?.bus_avanz) {
      if (buscador === 'basico') {
        return (
          <Buscador
            cambiarBuscador={cambiarBuscador}
            mostrar_avanzado={parametros.bus_avanz}
          />
        )
      } else {
        return <BuscadorAvanzado cambiarBuscador={cambiarBuscador} />
      }
    } else {
      return (
        <Buscador
          cambiarBuscador={cambiarBuscador}
          mostrar_avanzado={'N'}
        />
      )
    }
  }

  return (
    <Fragment>
      <FamiliasMenu />
      {returnBuscador()}
      <div className='container'>
        <div
          id='tt-pageContent'
          className='articulos'>
          <div className='container-indent'>
            <div className='container-fluid-custom container-fluid-custom-mobile-padding'>
              <div className='row'>
                <ArticulosFiltros />
                <div
                  className={
                    filtros_aplicados?.length > 0 ||
                    filtros?.filtros?.length > 0 ||
                    filtros_claves_estadisticas?.filtros_claves_estadisticas?.length > 0
                      ? 'col-md-12 col-lg-9 col-xl-9'
                      : 'col-md-12 col-lg-12 col-xl-12'
                  }>
                  <FamiliasMenu usar_imagenes={true} />
                  <ListadoArticulos
                    origen='articulos'
                    mostrar='lista'
                    filtro_url={
                      estadistico_url && familia_url && descrip_url
                        ? {
                            estadistico: estadistico_url,
                            familia: familia_url,
                            nombre: descrip_url,
                          }
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Articulos
