import React, { Fragment, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getExpedientesTipo,
  getMenuExpedientes,
  limpiarFiltrosExpedientes,
} from '../../actions/expedientes'
import Spinner from '../layout/Spinner'
import ExpedienteDetalle from './ExpedienteDetalle'
import ExpedientesListado from './ExpedientesListado'
import ExpedientesMenu from './ExpedientesMenu'
import NuevoExpediente from './NuevoExpediente'

const Expedientes = () => {
  const dispatch = useDispatch()
  const prevProps = useRef()

  const tipos_expedientes = useSelector(state => state.expedientes.tipos_expedientes)
  const expedientes = useSelector(state => state.expedientes.expedientes)
  const expedientes_loading = useSelector(state => state.expedientes.isLoading)
  const expediente_seleccionado = useSelector(state => state.expedientes.expediente_seleccionado)
  const tipo_expediente_seleccionado = useSelector(
    state => state.expedientes.tipo_expediente_seleccionado,
  )
  const mostrar = useSelector(state => state.expedientes.mostrar)
  const orden = useSelector(state => state.expedientes.orden)
  const inicio = useSelector(state => state.expedientes.inicio)
  const fin = useSelector(state => state.expedientes.fin)

  useEffect(() => {
    window.wookie()

    if (tipos_expedientes?.length === 0 && !expedientes_loading) {
      dispatch(getMenuExpedientes())
    }
    prevProps.current = { mostrar, orden }
    return () => {
      dispatch(limpiarFiltrosExpedientes())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      (tipo_expediente_seleccionado !== '' &&
        tipos_expedientes?.length > 0 &&
        !expedientes_loading) ||
      (!expedientes_loading &&
        (prevProps?.current?.mostrar !== mostrar || prevProps?.current?.orden !== orden))
    ) {
      dispatch(
        getExpedientesTipo(
          tipos_expedientes[tipo_expediente_seleccionado],
          mostrar,
          orden,
          inicio,
          fin,
        ),
      )
    }
    prevProps.current = { mostrar, orden }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipo_expediente_seleccionado])

  const devolverContenido = () => {
    if (expedientes_loading && !tipo_expediente_seleccionado) {
      return <Spinner showSpinner={expedientes_loading} />
    } else if (
      expedientes &&
      tipo_expediente_seleccionado !== '' &&
      expediente_seleccionado === ''
    ) {
      return <ExpedientesListado />
    } else if (
      expedientes &&
      tipo_expediente_seleccionado !== '' &&
      expediente_seleccionado !== ''
    ) {
      if (expediente_seleccionado === 'new') {
        return <NuevoExpediente origen={tipos_expedientes[tipo_expediente_seleccionado].nombre} />
      } else {
        return <ExpedienteDetalle />
      }
    }
  }

  return (
    <Fragment>
      <ExpedientesMenu />
      <div className='container contenedor_expedientes'>{devolverContenido()}</div>
    </Fragment>
  )
}

export default Expedientes
