import axios from "../axiosConfig";
import { createMessage } from "./messages";

import {
  FETCH_SUBSCRIPCIONES_STOCK,
  GET_SUBSCRIPCIONES_STOCK,
  GET_SUBSCRIPCIONES_STOCK_SUCCESS,
  GET_SUBSCRIPCIONES_STOCK_FAIL,
  ACTUALIZA_SUB_STOCK,
  ACTUALIZA_SUB_STOCK_SUCCESS,
  ACTUALIZA_SUB_STOCK_FAIL,
  CREAR_SUB_STOCK,
  CREAR_SUB_STOCK_SUCCESS,
  CREAR_SUB_STOCK_FAIL,
} from "../actions/types.js";

import { tokenConfig } from "./auth";

// GET SUBSCRIPCION
export const getSubscripcionesStock = (codigo_articulo, status) => (dispatch, getState) => {
  dispatch({
    type: FETCH_SUBSCRIPCIONES_STOCK,
  });
  if (codigo_articulo === undefined || codigo_articulo === null) {
    codigo_articulo = "";
  }
  if (status === undefined || status === null) {
    status = "";
  }
  dispatch({
    type: GET_SUBSCRIPCIONES_STOCK,
    codigo_articulo: codigo_articulo,
    status: status,
  });
  axios
    .get(`/usuario/subscripciones/?codigo_articulo=${codigo_articulo}&status=${status}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SUBSCRIPCIONES_STOCK_SUCCESS,
        payload: res.data,
        codigo_articulo: codigo_articulo,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_SUBSCRIPCIONES_STOCK_FAIL,
      });
      console.log(err);
    });
};

//UPDATE SUBSCRIPCION
export const actualizaSubscripcionStock =
  (codigo_articulo, status = "ACT", aviso_unico = "S", origen) =>
  (dispatch, getState) => {
    // Request Body
    const body = JSON.stringify({
      codigo_articulo,
      status,
      aviso_unico,
      origen,
    });
    dispatch({
      type: ACTUALIZA_SUB_STOCK,
    });
    axios
      .put(`/usuario/subscripciones/${codigo_articulo}/`, body, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: ACTUALIZA_SUB_STOCK_SUCCESS,
          payload: res.data,
          status: status,
        });
        dispatch(createMessage("Subscripcion actualizada", "success"));
      })
      .catch((err) => {
        dispatch(createMessage("Error modificando la subscripción. Por favor, inténtelo más tarde.", err.response.status));
        dispatch({
          type: ACTUALIZA_SUB_STOCK_FAIL,
        });
      });
  };
//UPDATE SUBSCRIPCION
export const creaSubscripcionStock =
  (codigo_articulo, status = "ACT", aviso_unico = "S") =>
  (dispatch, getState) => {
    // Request Body
    const body = JSON.stringify({
      codigo_articulo,
      status,
      aviso_unico,
    });

    dispatch({
      type: CREAR_SUB_STOCK,
    });
    axios
      .post(`/usuario/subscripciones/`, body, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: CREAR_SUB_STOCK_SUCCESS,
          payload: res.data,
        });
        dispatch(createMessage("Añadida subscripción", "success"));
      })
      .catch((err) => {
        dispatch(createMessage("Error creando la subscripción. Por favor, inténtelo más tarde.", err.response.status));
        dispatch({
          type: CREAR_SUB_STOCK_FAIL,
        });
      });
  };
