import React, { Fragment, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { setFamiliaSeleccionada } from '../../actions/familias'
import { añadirFiltro, limpiarFiltros } from '../../actions/filtros'
import { getMigasArticulo, limpiarMigas } from '../../actions/migas'

const Migas = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const migas = useSelector(state => state.migas.migas)
  const auth = useSelector(state => state.auth)

  const itemsToShow = useMemo(() => (migas ? migas.reverse() : []))

  let { id_articulo } = props
  let hash = auth.hash

  useEffect(() => {
    return () => dispatch(limpiarMigas())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(getMigasArticulo(id_articulo, hash != null ? hash : ''))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_articulo, hash])

  const onClick = e => {
    e.preventDefault()
    let _estadistico = e.target.dataset.estadistico
    let _familia = e.target.dataset.codigo
    let _nombre = e.target.dataset.familia

    dispatch(limpiarFiltros())

    dispatch(
      añadirFiltro({
        estadistico: _estadistico,
        familia: _familia,
        nombre: _nombre,
        tipo: 'estad',
      }),
    )

    dispatch(setFamiliaSeleccionada(_estadistico, _familia, _nombre))
    navigate('/articulos')
  }

  return (
    <Fragment>
      <div className='tt-breadcrumb'>
        <div className='container'>
          <ul>
            {itemsToShow ? (
              itemsToShow?.map((miga, idx) => (
                <li key={idx}>
                  <a
                    href='#'
                    onClick={onClick}
                    data-codigo={miga.familia}
                    data-estadistico={miga.estadistico}
                    data-familia={miga.descripcion}>
                    {miga.descripcion}
                  </a>
                </li>
              ))
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>
    </Fragment>
  )
}

export default Migas
