import AccessTimeIcon from '@mui/icons-material/AccessTime'
import PercentIcon from '@mui/icons-material/Percent'
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent'
import { useSelector } from 'react-redux'
import '../../css/Badges.css'

const Badges = ({ codigoArticulo }) => {
  const articulo = useSelector(state =>
    state.listadoArticulos.articulos.articulos.filter(
      art => art.codigo_articulo === codigoArticulo,
    ),
  )[0]

  return articulo !== undefined && articulo !== null ? (
    <div className='article-list-badges'>
      {articulo?.es_novedad === 'S' ? (
        <div className='badge-novedad'>
          <WbIncandescentIcon />
        </div>
      ) : null}
      {articulo?.es_oferta === 'S' ? (
        <div className='badge-oferta'>
          <PercentIcon />
        </div>
      ) : null}
      {articulo?.es_reciente === 'S' ? (
        <div className='badge-comprado-reciente'>
          <AccessTimeIcon />
        </div>
      ) : null}
    </div>
  ) : null
}

export default Badges
