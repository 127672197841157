import AddSharpIcon from '@mui/icons-material/AddSharp'
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp'
import { Bell, BellRinging, CaretDown, CaretUp, ClipboardText, ShoppingCart } from 'phosphor-react'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createMessage } from '../../actions/messages'
import { nuevaReceta } from '../../actions/modalGenerica'
import { postLinea } from '../../actions/pedidos'
import { getPrecioYPermisos } from '../../actions/precios'
import { getPresentaciones } from '../../actions/presentaciones'
import { getStockArticulo } from '../../actions/stocks'
import {
  actualizaSubscripcionStock,
  creaSubscripcionStock,
} from '../../actions/subscripcionesStock'
import { hasSameParent } from '../../helpers/elements/parents'
import { cargarParametros } from '../../helpers/funciones'
import { compareDecimals, isInt } from '../../helpers/numbers/functions'
import {
  exceedsMinimumQuantity,
  isMinimumQuantity,
  isMultiple,
  retrieveLowerMultiple,
} from '../../helpers/quantitys/articulos'
import { creaURLArticulo } from '../../helpers/urls/articulos'
import i18n from '../../lang/idiomas'
import AnalyticsEdisa from '../common/AnalyticsEdisa'
import BotonAnadirLista from './BotonAnadirLista'

let parametros_usados = [
  'btn_presup',
  'btn_subs',
  'cant_def',
  'def_input',
  'andr_cant_list',
  'ej_onblur',
  'mostrar_txts_mm',
  'btns_inp_cant',
  'qty_inp_style',
]
let parametros
let modulo

const AnadirCarrito = props => {
  AnadirCarrito.propTypes = {
    articulo: PropTypes.object,
    origen: PropTypes.string,
    getObservaciones: PropTypes.func,
    netear_precio: PropTypes.string,
    datos: PropTypes.object,
    index: PropTypes.number,
    mostrar_texto_btn: PropTypes.string,
    decimales: PropTypes.number,
    anadir_lista: PropTypes.bool,
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const precio = useSelector(state => state.precios.precio)
  const stock = useSelector(state => state.stocks.stock)
  const hash = useSelector(state => state.auth.hash)
  const pedido = useSelector(state => state.pedidos.pedido)
  const pedido_cargando = useSelector(state => state.pedidos.isLoading)
  const portal = useSelector(state => state.portal.portal)
  const subpresentacion = useSelector(state => state.subpresentaciones.subpresentacion)
  const presentacion = useSelector(state => state.presentaciones.presentacion)
  const id_pedido_seleccionado = useSelector(state => state.pedidos.id_pedido_seleccionado)
  const usuario = useSelector(state => state.auth.user)

  const [valor, setValor] = useState(0)
  const [articulo, setArticulo] = useState({})
  const [estilo, setEstilo] = useState('')
  const inputNormal = useRef()
  const inputMobil = useRef()

  switch (props.origen) {
    case 'ficha':
      modulo = 'ART'
      break
    case 'tabla':
      modulo = 'TART'
      break
    case 'lista':
      modulo = 'LART'
      break
    default:
      modulo = 'GEN'
      break
  }

  parametros = cargarParametros(parametros_usados, portal.parametros_ecom, modulo)

  useEffect(() => {
    // Seteamos el nuevo articulo
    setArticulo(props.articulo)
    dispatch(getPresentaciones(props.articulo.codigo_articulo))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.articulo])

  useEffect(() => {
    // Recuperamos los inputs
    setearValorInput(calcularCantidadDefectiva())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articulo])

  useEffect(() => {
    if (props?.origen === 'lista') {
      if (parametros?.btns_inp_cant === 'S') {
        if (parametros?.qty_inp_style === '2') {
          setEstilo('input-cantidad-botones-lista')
        } else {
          setEstilo('input-qty-button-surrounded')
        }
      } else {
        setEstilo('input-cantidad-lista')
      }
    } else if (parametros?.btns_inp_cant === 'S') {
      if (parametros?.qty_inp_style === '2') {
        setEstilo('input-cantidad-botones')
      } else {
        setEstilo('input-qty-button-surrounded')
      }
    } else {
      setEstilo('input-cantidad')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.origen, parametros])

  useEffect(() => {
    // Si existe una subpresentacion seleccionada
    if (
      articulo.codigo_articulo &&
      Object.keys(subpresentacion).length > 0 &&
      articulo.codigo_articulo === subpresentacion.articulo &&
      !tienePrecioManual()
    ) {
      // Se multiplica por el factor de conversion (en espera de modificar el paquete)
      dispatch(
        getPrecioYPermisos(
          articulo.codigo_articulo,
          parseFloat(valor) * subpresentacion.factor_conversion,
          id_pedido_seleccionado || pedido?.id_pedido,
          pedido.domicilio_envio,
          articulo.presentacion_web || '',
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subpresentacion])

  useEffect(() => {
    // Si existe una presentacion seleccionada
    if (
      articulo.codigo_articulo &&
      Object.keys(presentacion).length > 0 &&
      articulo.codigo_articulo === presentacion.articulo &&
      !tienePrecioManual()
    ) {
      dispatch(
        getPrecioYPermisos(
          articulo.codigo_articulo,
          parseFloat(valor),
          id_pedido_seleccionado || pedido?.id_pedido,
          pedido.domicilio_envio,
          presentacion.presentacion,
        ),
      )

      dispatch(
        getStockArticulo(
          articulo.codigo_articulo,
          presentacion?.presentacion || articulo.presentacion_web,
          pedido.almacen,
          id_pedido_seleccionado || pedido?.id_pedido,
          '',
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentacion])

  const anadirCarro = tipo => {
    if (!pedido_cargando) {
      if (
        precio?.[articulo.codigo_articulo]?.[
          presentacion.presentacion ||
            articulo.presentacion_escogida ||
            articulo.presentacion_pedido ||
            articulo.presentacion_web
        ]
      ) {
        let cantidad_pedida = valor
        let precio_articulo =
          precio[articulo.codigo_articulo][
            presentacion.presentacion || articulo.presentacion_pedido || articulo.presentacion_web
          ]
        if (cantidad_pedida === 0 || !cantidad_pedida) {
          cantidad_pedida = calcularCantidadDefectiva()
        }

        if (subpresentacion.subpresentacion) {
          cantidad_pedida = cantidad_pedida * subpresentacion.factor_conversion
        }

        let linea = {
          articulo: articulo?.codigo_articulo,
          descripcion: articulo.descrip_comercial,
          precio_venta: parseFloat(precio_articulo.precio_presentacion),
          precio_manual: precio_articulo.precio_manual,
          dto_1: precio_articulo.dto_1,
          observaciones: '',
          estado_linea: 'B',
          cantidad_pedida: cantidad_pedida,
          presentacion_pedido: presentacion.presentacion || articulo.presentacion_web,
          tipo_linea: tipo,
          sub_pres: '',
          sub_pres_cant: '',
          sub_pres_fc: '',
          presentacion_escogida: presentacion.presentacion || '',
          pres_fc: '',
          valida_carnet: '',
        }

        // Comprobamos si el articulo necesita carnet profesional
        if (articulo.tipo_carnet_profesional) {
          linea = comprobarCarnet(linea, 'N', null)
        }

        // Comprobamos si el articulo necesita receta
        if (articulo.numero_receta) {
          if (articulo?.codigo_estad10 && articulo?.codigo_estad10 === '10') {
            // Comprobamos si necesita autorizacion por otros canales
            if (usuario.con_receta && usuario.con_receta === 'S') {
              // Comprobamos si es mayorista
              // 01 - Mayorista
              // 02 - Minorista
              if (usuario.tipo_cliente === '01') {
                // Se envia mensaje a usuario
                linea = comprobarCarnet(linea, 'S', 'otros')
              } else {
                // Se indica que pasa a estar pendiente de validacion otros canales
                linea.valida_otros = 'N'
              }
            } else {
              // Se permite añadir sin otro control
              linea.valida_otros = 'S'
            }
          } else {
            // Comprobamos si el usuario está autorizado o no
            if (usuario.con_receta && usuario.con_receta === 'S') {
              // Comprobamos si es mayorista
              // 01 - Mayorista
              // 02 - Minorista
              if (usuario.tipo_cliente === '01') {
                // Se envia mensaje a usuario
                linea = comprobarCarnet(linea, 'S', 'receta')
              } else {
                // Se obliga al usuario a adjuntar receta
                linea.valida_receta = 'N'
              }
            } else {
              // Se permite añadir sin otro control
              linea.valida_receta = 'S'
            }
          }
        } else {
          // Comprobamos si necesita autorizacion por otros canales
          if (articulo?.codigo_estad10 && articulo?.codigo_estad10 === '10') {
            // Comprobamos si es mayorista
            // 01 - Mayorista
            // 02 - Minorista
            if (usuario.tipo_cliente === '01') {
              // Se envia mensaje a usuario
              linea = comprobarCarnet(linea, 'S', 'otros')
            }
          }
        }

        if (subpresentacion.subpresentacion) {
          linea.sub_pres = subpresentacion.subpresentacion
          linea.sub_pres_cant = parseInt(valor)
          linea.sub_pres_fc = parseFloat(subpresentacion.factor_conversion)
          linea.observaciones = linea.sub_pres_cant + ' de ' + linea.sub_pres
        }

        if (presentacion.presentacion) {
          linea.pres_fc = parseFloat(presentacion.factor_conversion)

          linea.presentacion_escogida = presentacion.presentacion
        }

        // Si se incluyeron observaciones en la linea se añaden a la linea de pedido
        if (props.getObservaciones) {
          linea.observaciones = props.getObservaciones(linea.articulo)
        }
        if (!linea.valida_receta || linea.valida_receta === 'S' || linea.valida_receta === 'M') {
          // Añadimos la linea a pedido
          dispatch(postLinea(linea, hash, id_pedido_seleccionado || pedido.id_pedido, 'N'))
        } else {
          //Se solicita el ingreso de la receta
          dispatch(nuevaReceta(linea, 'receta'))
        }

        let analytics = new AnalyticsEdisa()

        analytics.registraEvento({
          modulo: 'Pedido',
          tipo: 'AddToCart',
          valor: linea.articulo,
          params: {
            codigo_articulo: linea.articulo,
            descrip_comercial: linea.descripcion,
            divisa: precio[linea.articulo][linea.presentacion_pedido].divisa_precio,
            precio: parseFloat(
              props.netear_precio === 'S'
                ? precio[linea.articulo][linea.presentacion_pedido].importe_neto
                : precio[linea.articulo][linea.presentacion_pedido].precio_presentacion *
                    cantidad_pedida,
            ),
          },
        })
      }
    }
  }

  const blurQuantity = articulo => {
    let cantidad = valor
    let cantidad_minima = isInt(articulo?.cantidad_minima || 1)
      ? parseInt(articulo?.cantidad_minima || 1)
      : articulo?.cantidad_minima || 1
    // Comprobamos que exceda el minimo y sino lo seteamos y retornamos error
    if (!exceedsMinimumQuantity(articulo, cantidad)) {
      setValor(cantidad_minima)
      onBlur(cantidad_minima)
      dispatch(createMessage(i18n.t('general.cantnia') + ' ' + cantidad_minima, 'warning'))
    } else {
      // Comprobamos que sea multiplo
      if (!isMultiple(articulo, cantidad)) {
        let multiploMasCercano = retrieveLowerMultiple(articulo, cantidad)
        let multiplo = isInt(articulo?.multiplo || 1)
          ? parseInt(articulo?.multiplo || 1)
          : articulo?.multiplo || 1

        if (isInt(multiploMasCercano)) {
          multiploMasCercano = parseInt(multiploMasCercano)
        }

        if (exceedsMinimumQuantity(articulo, multiploMasCercano)) {
          setValor(multiploMasCercano)
          onBlur(multiploMasCercano)
          dispatch(createMessage(i18n.t('general.cantdsm') + ' ' + multiplo, 'warning'))
        } else {
          setValor(cantidad_minima)
          onBlur(cantidad_minima)
          // TODO: Concatenar texto cantidad minima
          dispatch(createMessage(i18n.t('general.cantdsm') + ' ' + multiplo, 'warning'))
        }
      } else {
        onBlur(cantidad)
      }
    }
  }

  const calcularCantidadDefectiva = () => {
    // Comprobamos que los parametros estén cargados
    if (Object.keys(parametros).length > 0) {
      let minimo_a_valorar
      let index
      switch (parametros.def_input) {
        case 'CANT_MIN':
          // Se comprueba si tiene cantidad minima y si es distinta de 0
          minimo_a_valorar = 1
          if (articulo?.cantidad_minima && articulo?.multiplo) {
            minimo_a_valorar =
              articulo.cantidad_minima >= articulo.multiplo
                ? articulo.cantidad_minima
                : articulo.multiplo
          } else if (articulo?.cantidad_minima) {
            minimo_a_valorar = articulo?.cantidad_minima
          } else if (articulo?.multiplo) {
            minimo_a_valorar = articulo?.multiplo
          }

          if (!compareDecimals(minimo_a_valorar, 0)) {
            if (props.datos?.cantidad_pedida) {
              return isInt(props.datos?.cantidad_pedida)
                ? parseInt(props.datos?.cantidad_pedida)
                : Number(props.datos?.cantidad_pedida).toFixed(props?.decimales || 0)
            } else {
              return isInt(minimo_a_valorar)
                ? parseInt(minimo_a_valorar)
                : Number(minimo_a_valorar).toFixed(props?.decimales || 0)
            }
          }
          break
        case 'PEDIDO':
          index = -1
          // Buscamos la linea en el pedido
          if (pedido?.lineas && pedido.lineas.length > 0) {
            index = pedido.lineas.findIndex(
              linea => linea.articulo === articulo.codigo_articulo && linea.tipo_linea === 'P',
            )
            if (index !== -1) {
              // Si el valor no es el mismo que el del pedido
              if (valor !== Number(pedido.lineas[index].cantidad_pedida).toFixed(0)) {
                let cantidad_pedido = Number(pedido.lineas[index].cantidad_pedida).toFixed(0)
                if (props?.datos?.cantidad_pedida) {
                  return Number(props.datos.cantidad_pedida).toFixed(0)
                } else {
                  return cantidad_pedido
                }
              }
            }
          }
          break
        default:
          if (props?.datos?.cantidad_pedida) {
            return isInt(props?.datos?.cantidad_pedida)
              ? parseInt(props?.datos?.cantidad_pedida)
              : Number(props?.datos?.cantidad_pedida).toFixed(props?.decimales || 0)
          } else {
            return isInt(parametros?.cant_def || 1)
              ? parseInt(parametros?.cant_def || 1)
              : Number(parametros?.cant_def || 1).toFixed(props?.decimales || 0)
          }
      }
      return 1
    } else {
      return articulo?.cantidad_minima ? Number(articulo?.cantidad_minima) : 1
    }
  }

  const cambiaURL = () => {
    let url = creaURLArticulo(articulo?.codigo_articulo, articulo?.descrip_comercial)

    navigate(url)
  }

  const cantidadPrecio = () => {
    return (
      precio?.[articulo.codigo_articulo]?.[presentacion.presentacion || articulo.presentacion_web]
        ?.cantidad_precio || 1
    )
  }

  const changeQuantity = (e, articulo) => {
    // Recuperamos el input
    let input = e.target.parentNode.getElementsByClassName('quantity-input')[0]

    let count = 0

    //Comprobamos si es input mobil o no
    if (
      e.currentTarget.className !== 'btn-mas' &&
      e.currentTarget.className !== 'btn-menos' &&
      e.currentTarget.className !== 'btn-plus' &&
      e.currentTarget.className !== 'btn-less'
    ) {
      if (!input.value) {
        count = null
      } else {
        count = input.value
      }
    } else {
      // Calculamos el multiplo para los botones
      let multiplo = articulo?.multiplo || 1
      count =
        parseInt(valor || parametros.cant_def || 1, 10) +
        parseInt(
          e.currentTarget.className === 'btn-mas' || e.currentTarget.className === 'btn-plus'
            ? multiplo
            : multiplo * -1,
          10,
        )
      if (!exceedsMinimumQuantity(articulo, count)) {
        count = articulo?.cantidad_minima || 1
      }
      if (!isMultiple(articulo, count)) {
        count = retrieveLowerMultiple(articulo, count)
      }
      if (isMinimumQuantity(articulo, count)) {
        inputNormal?.current.focus() || inputMobil?.current.focus()
      }
    }

    setValor(count)
    if (e.currentTarget.className === 'quantity-input ' + estilo) {
      onBlur(count)
    }
  }

  const comprobarCarnet = (linea, comprobar_especificos, valida) => {
    if (usuario?.carnets?.lineas.length > 0) {
      let index = -1
      if (comprobar_especificos === 'S') {
        // AUTORIZACIÓN VENTA MAIORISTA PROD. ZOOSANITARIOS
        index = usuario.carnets.lineas.findIndex(carnet => carnet.tipo_carnet === '00002')
        if (index !== -1) {
          // Procesamos sin mensaje
          if (valida === 'otros') {
            linea.valida_otros = 'S'
          } else {
            linea.valida_receta = 'S'
          }
        } else {
          // AUTORIZACIÓN VENTA MEDICAMENTOS OUTRAS CANLES
          index = usuario.carnets.lineas.findIndex(carnet => carnet.tipo_carnet === '00003')
          if (index !== -1) {
            // Procesamos con aviso
            if (valida === 'otros') {
              linea.valida_otros = 'M'
            } else {
              linea.valida_receta = 'M'
            }
          }
        }
      } else {
        index = usuario.carnets.lineas.findIndex(
          carnet => carnet.tipo_carnet === articulo.tipo_carnet_profesional,
        )

        if (index !== -1) {
          // Si existe carnet valido
          linea.valida_carnet = 'S'
        } else {
          linea.valida_carnet = 'N'
        }
      }
    } else if (comprobar_especificos === 'S') {
      if (valida === 'receta') {
        linea.valida_receta = 'N'
      }
    } else {
      linea.valida_carnet = 'N'
    }

    return linea
  }

  const onBlur = cantidad => {
    if (parametros.ej_onblur === 'S') {
      if (subpresentacion.subpresentacion) {
        cantidad = parseFloat(cantidad * subpresentacion.factor_conversion).toFixed(4)
      }
      if (!compareDecimals(cantidad, cantidadPrecio()) && !tienePrecioManual()) {
        dispatch(
          getPrecioYPermisos(
            articulo.codigo_articulo,
            cantidad,
            id_pedido_seleccionado || pedido?.id_pedido,
            pedido.domicilio_envio,
            presentacion?.presentacion || articulo.presentacion_web,
          ),
        )
      }
    }
  }

  const setearValorInput = valor_cant => {
    let input_normal = document.getElementById('input-cant-' + articulo.codigo_articulo)
    let input_mobil = document.getElementById('input-cant-mob-' + articulo.codigo_articulo)

    if (input_normal) {
      input_normal.value = valor_cant
    }
    if (input_mobil) {
      input_mobil.value = valor_cant
    }
    // Se setea el valor en el estado
    setValor(valor_cant)
  }

  const pintaBotonSubscripcion = () => {
    if (usuario?.subscripciones?.lineas?.length > 0) {
      let existe_sub = false
      let sub_aux = null
      usuario.subscripciones.lineas.forEach(sub => {
        if (sub.codigo_articulo === articulo.codigo_articulo) {
          existe_sub = true
          sub_aux = sub
        }
        if (existe_sub === true) {
          return
        }
      })
      if (existe_sub && sub_aux) {
        return (
          <button
            type='submit'
            className='btn btn-primary sub'
            onClick={e => {
              e.preventDefault()
              dispatch(
                actualizaSubscripcionStock(
                  articulo.codigo_articulo, // codigo_articulo
                  sub_aux.status, //status
                  sub_aux.aviso_unico, //aviso_unico
                  'articulos',
                ),
              )
            }}
            tabIndex={2 + (props.index ? props.index * 4 : 1)}>
            <BellRinging
              size={20}
              weight='duotone'
              alt={i18n.t('subscripciones.altdelsb')}
            />
            {props.mostrar_texto_btn === 'S' ? i18n.t('subscripciones.altdelsb').toUpperCase() : ''}
          </button>
        )
      } else {
        return (
          <button
            type='submit'
            className='btn btn-primary no-sub'
            onClick={e => {
              e.preventDefault()
              dispatch(
                creaSubscripcionStock(
                  articulo.codigo_articulo, // codigo_articulo
                  null, //status
                  'S', //aviso_unico
                ),
              )
            }}
            tabIndex={2 + (props.index ? props.index * 4 : 1)}>
            <Bell
              size={20}
              alt={i18n.t('subscripciones.altaddsb')}
            />
            {props.mostrar_texto_btn === 'S' ? i18n.t('subscripciones.altaddsb').toUpperCase() : ''}
          </button>
        )
      }
    } else {
      return (
        <button
          type='submit'
          className='btn btn-primary no-sub'
          onClick={e => {
            e.preventDefault()
            dispatch(
              creaSubscripcionStock(
                articulo.codigo_articulo, // codigo_articulo
                null, //status
                'S', //aviso_unico
              ),
            )
          }}
          tabIndex={2 + (props.index ? props.index * 4 : 1)}>
          <Bell
            size={20}
            alt={i18n.t('subscripciones.altaddsb')}
          />
          {props.mostrar_texto_btn === 'S' ? i18n.t('subscripciones.altaddsb').toUpperCase() : ''}
        </button>
      )
    }
  }

  const tienePrecioManual = () => {
    return (
      precio?.[articulo.codigo_articulo]?.[presentacion.presentacion || articulo.presentacion_web]
        ?.precio_manual === 'S'
    )
  }

  const renderQtyInputButtons = side => {
    switch (side) {
      case 'left':
        if (parametros.btns_inp_cant === 'S' && parametros.qty_inp_style !== '2') {
          return (
            <div className='input-button-pad-remove'>
              <button
                type='button'
                className='btn-less'
                disabled={
                  isMinimumQuantity(articulo, valor || parametros.cant_def || 1) ||
                  !comprobarDatos('P', 'input')
                }
                onClick={e => {
                  e.preventDefault()
                  changeQuantity(e, articulo)
                }}>
                <RemoveSharpIcon />
              </button>
            </div>
          )
        }
        break
      case 'right':
        if (parametros.btns_inp_cant === 'S') {
          if (parametros.qty_inp_style === '2') {
            return (
              <div className='botonera-input'>
                <button
                  type='button'
                  className='btn-mas'
                  onClick={e => {
                    e.preventDefault()
                    changeQuantity(e, articulo)
                  }}
                  disabled={!comprobarDatos('P', 'input')}>
                  <CaretUp
                    size={10}
                    weight={'duotone'}
                  />
                </button>
                <button
                  type='button'
                  className='btn-menos'
                  disabled={
                    isMinimumQuantity(articulo, valor || parametros.cant_def || 1) ||
                    !comprobarDatos('P', 'input')
                  }
                  onClick={e => {
                    e.preventDefault()
                    changeQuantity(e, articulo)
                  }}>
                  <CaretDown
                    size={10}
                    weight={'duotone'}
                  />
                </button>
              </div>
            )
          } else {
            return (
              <div className='input-button-pad-add'>
                <button
                  type='button'
                  className='btn-plus'
                  onClick={e => {
                    e.preventDefault()
                    changeQuantity(e, articulo)
                  }}
                  disabled={!comprobarDatos('P', 'input')}>
                  <AddSharpIcon />
                </button>
              </div>
            )
          }
        }
        break
      default:
        break
    }
  }
  const comprobarStock = () => {
    if (!portal?.controlar_stock || portal?.controlar_stock === 'S') {
      return (
        stock?.[articulo.codigo_articulo]?.[
          presentacion?.articulo === articulo.codigo_articulo
            ? presentacion.presentacion
            : articulo.presentacion_pedido || articulo.presentacion_web
        ]?.['']?.stock === 'S' ||
        stock?.[articulo.codigo_articulo]?.[
          presentacion?.articulo === articulo.codigo_articulo
            ? presentacion.presentacion
            : articulo.presentacion_pedido || articulo.presentacion_web
        ]?.['']?.stock > 0
      )
    } else return true
  }

  const showButtonText = () => {
    if (props.mostrar_texto_btn === 'S') {
      if (!comprobarDatos('P', 'button')) {
        return i18n.t('articulos.altartnd').toUpperCase()
      } else {
        return i18n.t('general.altaddsc').toUpperCase()
      }
    }
  }

  const comprobarDatos = (tipo_linea, tipo_input) => {
    if (tipo_linea === 'O') {
      return (
        precio?.[articulo.codigo_articulo]?.[
          presentacion?.articulo === articulo.codigo_articulo
            ? presentacion.presentacion
            : articulo.presentacion_pedido || articulo.presentacion_web
        ]?.status === 'iddling' && !pedido_cargando
      )
    } else {
      if (tipo_input === 'button') {
        return (
          precio?.[articulo.codigo_articulo]?.[
            presentacion?.articulo === articulo.codigo_articulo
              ? presentacion.presentacion
              : articulo.presentacion_pedido || articulo.presentacion_web
          ]?.status === 'iddling' &&
          !pedido_cargando &&
          comprobarStock()
        )
      } else {
        return (
          precio?.[articulo.codigo_articulo]?.[
            presentacion?.articulo === articulo.codigo_articulo
              ? presentacion.presentacion
              : articulo.presentacion_pedido || articulo.presentacion_web
          ]?.status !== 'fetching' &&
          !pedido_cargando &&
          stock?.[articulo.codigo_articulo]?.[
            presentacion?.articulo === articulo.codigo_articulo
              ? presentacion.presentacion
              : articulo.presentacion_pedido || articulo.presentacion_web
          ]?.[''].status !== 'fetching'
        )
      }
    }
  }

  switch (props.origen) {
    case 'ficha':
      return (
        <form
          className='form-inline form-default control_anadir_carrito_ficha'
          id={articulo.id}>
          <div
            className='input-group'
            style={{ justifyContent: 'center' }}
            onBlur={e => {
              e.preventDefault()
              if (
                !hasSameParent(
                  e?.relatedTarget?.parentNode,
                  'form-inline form-default control_anadir_carrito_ficha',
                  articulo.id,
                )
              ) {
                blurQuantity(articulo)
              }
            }}>
            {renderQtyInputButtons('left')}
            <input
              className={'form-control desctop quantity-input ' + estilo}
              id={'input-cant-' + articulo.codigo_articulo}
              ref={inputNormal}
              disabled={!comprobarDatos('P', 'input')}
              type='number'
              step={articulo?.multiplo || 1}
              min={articulo?.cantidad_minima || 0}
              name='valor'
              value={valor}
              placeholder={calcularCantidadDefectiva()}
              onChange={e => setValor(e.target.value)}
            />
            <div
              className='detach-quantity-desctope mobile'
              style={{ width: '50%' }}>
              <div className='tt-input-counter style-01'>
                <input
                  ref={inputMobil}
                  className={'quantity-input ' + estilo}
                  id={'input-cant-mob-' + articulo.codigo_articulo}
                  disabled={!comprobarDatos('P', 'input')}
                  onChange={e => {
                    e.preventDefault()
                    changeQuantity(e, articulo)
                  }}
                  value={valor}
                  placeholder={calcularCantidadDefectiva()}
                  type='number'
                  step={articulo?.multiplo || 1}
                  min={articulo?.cantidad_minima || 0}
                  name='valor'
                  size='5'
                />
              </div>
            </div>
            {renderQtyInputButtons('right')}
            <button
              type='submit'
              className={
                estilo === 'input-qty-button-surrounded'
                  ? 'btn btn-primary surrounded'
                  : 'btn btn-primary '
              }
              onClick={e => {
                e.preventDefault()
                anadirCarro('P')
              }}
              tabIndex={2 + (props.index ? props.index * 4 : 1)}
              disabled={!comprobarDatos('P', 'button')}>
              <ShoppingCart
                size={20}
                alt={
                  !comprobarDatos('P', 'button')
                    ? i18n.t('articulos.altartnd')
                    : i18n.t('general.altaddsc')
                }
              />
              {showButtonText()}
            </button>
            {parametros?.mostrar_txts_mm !== 'N' ? (
              <div className='textos_aux_cantidad'>
                {articulo?.multiplo ? (
                  <p className='texto_aux_cantidad'>
                    {i18n.t('articulos.txtauxmi')}
                    {isInt(articulo?.cantidad_minima)
                      ? parseInt(articulo?.multiplo)
                      : articulo?.multiplo}
                  </p>
                ) : null}
                {articulo?.cantidad_minima ? (
                  <p className='texto_aux_cantidad'>
                    {i18n.t('articulos.txtauxmu')}
                    {isInt(articulo?.cantidad_minima)
                      ? parseInt(articulo?.cantidad_minima)
                      : articulo?.cantidad_minima}
                  </p>
                ) : null}
              </div>
            ) : null}
            <div className='row botones-secundarios'>
              <div className='tt-wrapper'>
                <ul className='tt-list-btn'>
                  {parametros?.btn_presup === 'S' ? (
                    <li>
                      <button
                        className='btn-link'
                        onClick={e => {
                          e.preventDefault()
                          anadirCarro('O')
                        }}
                        tabIndex={3 + (props.index ? props.index * 4 : 1)}
                        disabled={!comprobarDatos('O', 'button')}>
                        <ClipboardText
                          size={20}
                          alt={i18n.t('general.altaddbg')}
                        />
                        {props.mostrar_texto_btn === 'S'
                          ? i18n.t('general.altaddbg').toUpperCase()
                          : ''}
                      </button>
                    </li>
                  ) : (
                    ''
                  )}
                  {articulo.codigo_articulo &&
                  props.anadir_lista &&
                  articulo?.tiene_agrupaciones !== 'S' ? (
                    <li>
                      <BotonAnadirLista
                        origen='articulo'
                        mostrar_texto_btn={props.mostrar_texto_btn}
                        codigo_articulo={articulo.codigo_articulo}
                        presentacion={presentacion.presentacion || articulo.presentacion_web}
                        tabIndex={4 + (props.index ? props.index * 4 : 1)}
                      />
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </div>
            </div>
          </div>
        </form>
      )
    case 'tabla':
      if (props.datos && (props.datos.tipo_articulo === 'F' || props.datos.tipo_articulo === 'R')) {
        return ''
      } else {
        return (
          <form
            className='form-inline form-default control_anadir_carrito_tabla'
            style={{ justifyContent: 'center' }}
            id={articulo.id}>
            {articulo.tiene_agrupaciones === 'S' ? (
              <button
                type='submit'
                className='btn btn-primary boton_carrito'
                style={{ maxwidth: '90%!important' }}
                onClick={cambiaURL}
                tabIndex={2 + (props.index ? props.index * 4 : 1)}>
                {i18n.t('articulos.altartvm')}
              </button>
            ) : (
              <div
                className='input-group'
                onBlur={e => {
                  e.preventDefault()
                  if (
                    !hasSameParent(
                      e?.relatedTarget?.parentNode,
                      'form-inline form-default control_anadir_carrito_tabla',
                      articulo.id,
                    )
                  ) {
                    blurQuantity(articulo)
                  }
                }}>
                {renderQtyInputButtons('left')}
                <input
                  ref={inputNormal}
                  className={'form-control desctop quantity-input ' + estilo}
                  id={'input-cant-' + articulo.codigo_articulo}
                  disabled={!comprobarDatos('P', 'input')}
                  type='number'
                  step={articulo?.multiplo || 1}
                  min={articulo?.cantidad_minima || 0}
                  name='valor'
                  value={valor}
                  placeholder={calcularCantidadDefectiva()}
                  onChange={e => {
                    setValor(e.target.value)
                  }}
                />
                <div className='detach-quantity-desctope mobile'>
                  <div className='tt-input-counter style-01'>
                    <input
                      ref={inputMobil}
                      className={'quantity-input ' + estilo}
                      id={'input-cant-mob-' + articulo.codigo_articulo}
                      disabled={!comprobarDatos('P', 'input')}
                      onChange={e => {
                        e.preventDefault()
                        changeQuantity(e, articulo)
                      }}
                      value={valor}
                      placeholder={calcularCantidadDefectiva()}
                      type='number'
                      step={articulo?.multiplo || 1}
                      min={articulo?.cantidad_minima || 0}
                      name='valor'
                      size='5'
                    />
                  </div>
                </div>
                {renderQtyInputButtons('right')}
                <div className='botonera'>
                  {parametros.btn_subs === 'S' ? (
                    <Fragment>
                      {!comprobarDatos('P', 'input') ? (
                        pintaBotonSubscripcion()
                      ) : (
                        <button
                          type='submit'
                          className={
                            estilo === 'input-qty-button-surrounded'
                              ? 'btn btn-primary surrounded'
                              : 'btn btn-primary boton_carrito'
                          }
                          onClick={e => {
                            e.preventDefault()
                            anadirCarro('P')
                          }}
                          tabIndex={2 + (props.index ? props.index * 4 : 1)}>
                          {props.mostrar_texto_btn === 'S' ? (
                            'Pedido'
                          ) : (
                            <ShoppingCart
                              size={20}
                              alt={i18n.t('general.altaddsc')}
                            />
                          )}
                        </button>
                      )}
                    </Fragment>
                  ) : (
                    <button
                      type='submit'
                      className={
                        estilo === 'input-qty-button-surrounded'
                          ? 'btn btn-primary surrounded'
                          : 'btn btn-primary boton_carrito'
                      }
                      onClick={e => {
                        e.preventDefault()
                        anadirCarro('P')
                      }}
                      tabIndex={2 + (props.index ? props.index * 4 : 1)}
                      disabled={!comprobarDatos('P', 'button')}>
                      {props.mostrar_texto_btn === 'S' ? (
                        i18n.t('general.pedido')
                      ) : (
                        <ShoppingCart
                          size={20}
                          alt={
                            !comprobarDatos('P', 'button')
                              ? i18n.t('articulos.altartnd')
                              : i18n.t('general.altaddsc')
                          }
                        />
                      )}
                    </button>
                  )}
                  {parametros?.btn_presup === 'S' ? (
                    <button
                      className='btn boton-carrito'
                      onClick={e => {
                        e.preventDefault()
                        anadirCarro('O')
                      }}
                      tabIndex={3 + (props.index ? props.index * 4 : 1)}
                      disabled={!comprobarDatos('O', 'button')}>
                      {props.mostrar_texto_btn === 'S' ? (
                        i18n.t('general.presup')
                      ) : (
                        <ClipboardText
                          size={20}
                          alt={i18n.t('general.altaddbg')}
                        />
                      )}
                    </button>
                  ) : (
                    ''
                  )}
                  {articulo.codigo_articulo &&
                  props.anadir_lista &&
                  articulo?.tiene_agrupaciones !== 'S' ? (
                    <BotonAnadirLista
                      origen='tabla'
                      mostrar_texto_btn={props.mostrar_texto_btn}
                      codigo_articulo={articulo.codigo_articulo}
                      presentacion={presentacion.presentacion || articulo.presentacion_web}
                      tabIndex={4 + (props.index ? props.index * 4 : 1)}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}
          </form>
        )
      }

    default:
      return (
        <form
          className='form-inline form-default control_anadir_carrito'
          id={articulo.id}>
          <div
            className='input-group'
            style={{ justifyContent: 'center' }}
            onBlur={e => {
              e.preventDefault()
              if (
                !hasSameParent(
                  e?.relatedTarget?.parentNode,
                  'form-inline form-default control_anadir_carrito',
                  articulo.id,
                )
              ) {
                blurQuantity(articulo)
              }
            }}>
            {renderQtyInputButtons('left')}
            <input
              ref={inputNormal}
              className={'form-control desctop quantity-input ' + estilo}
              id={'input-cant-' + articulo.codigo_articulo}
              disabled={!comprobarDatos('P', 'input')}
              type='number'
              step={articulo?.multiplo || 1}
              min={articulo?.cantidad_minima || 0}
              name='valor'
              value={valor}
              placeholder={calcularCantidadDefectiva()}
              onChange={e => {
                setValor(e.target.value)
              }}
            />
            <div className='detach-quantity-desctope mobile'>
              <div className='tt-input-counter style-01'>
                <input
                  ref={inputMobil}
                  className={'quantity-input ' + estilo}
                  id={'input-cant-mob-' + articulo.codigo_articulo}
                  disabled={!comprobarDatos('P', 'input')}
                  onChange={e => {
                    e.preventDefault()
                    changeQuantity(e, articulo)
                  }}
                  value={valor}
                  type='number'
                  step={articulo?.multiplo || 1}
                  min={articulo?.cantidad_minima || 0}
                  name='valor'
                  size='5'
                />
              </div>
            </div>
            {renderQtyInputButtons('right')}
            <button
              type='submit'
              className={
                estilo === 'input-qty-button-surrounded'
                  ? 'btn btn-primary surrounded'
                  : 'btn btn-primary '
              }
              onClick={e => {
                e.preventDefault()
                anadirCarro('P')
              }}
              tabIndex={2 + (props.index ? props.index * 2 : 1)}
              disabled={!comprobarDatos('P', 'button')}>
              <ShoppingCart
                size={20}
                alt={
                  !comprobarDatos('P', 'button')
                    ? i18n.t('articulos.altartnd')
                    : i18n.t('general.altaddsc')
                }
              />
              {showButtonText()}
            </button>
          </div>
        </form>
      )
  }
}

export default AnadirCarrito
