import React, { Component } from 'react'

import Documentos from '../documentos/components/Documentos'
import Articulos from '../ecommerce/Articulos'
import ArticulosListItems from '../ecommerce/ArticulosListItems'
import Home from '../ecommerce/Home'
import Informes from '../ecommerce/Informes'
import NovedadesOfertas from '../ecommerce/NovedadesOfertas'
import Pedido from '../ecommerce/Pedido'
import EfacturaCompras from '../efactura/EfacturaCompras'
import EfacturaVentas from '../efactura/EfacturaVentas'
import Expedientes from '../expedientes/Expedientes'
import Cuenta from '../usuarios/Cuenta'
import Login from '../usuarios/Login'
import Registro from '../usuarios/Registro'
import Widgets from '../widgets/Widgets'
import Estatico from './Estatico'

export class Inicio extends Component {
  lista_componentes = {
    articulos: Articulos,
    cuenta: Cuenta,
    informes: Informes,
    expedientes: Expedientes,
    login: Login,
    registro: Registro,
    estatico: Estatico,
    widgets: Widgets,
    home: Home,
    pedido: Pedido,
    novedadesOfertas: NovedadesOfertas,
    articulosListItems: ArticulosListItems,
    efacturaCompras: EfacturaCompras,
    efacturaVentas: EfacturaVentas,
    documentacion: Documentos,
  }

  render() {
    const Componente = this.lista_componentes[this.props.componente]
    return <Componente {...this.props.props} />
  }
}

export default Inicio
