import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rejectLines, acceptLines } from "../../actions/restos";
import { createMessage } from "../../actions/messages";
import { getPedidoActivo } from "../../actions/pedidos";

import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

const LineasPendientesModal = ({ pendingLines, isOpen, onRequestClose, getPendingLines }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLines, setSelectedLines] = useState([]);
  const pedido_activo = useSelector((state) => state.pedidos.id_pedido_seleccionado);

  useEffect(() => {
  }, [selectedLines]);

  const toggleLine = (line, selected) => {
    if (!selected) {
      const lines = selectedLines.filter((currentLine) => {
        const lineas_iguales = currentLine.numero_linea === line.numero_linea;
        const pedidos_iguales = currentLine.numero_pedido === line.numero_pedido;
        const series_iguales = currentLine.numero_serie === line.numero_serie;
        return !(lineas_iguales && pedidos_iguales && series_iguales);
      });
      return setSelectedLines(lines);
    }
    const lines = [...selectedLines];
    lines.push(line);
    setSelectedLines(lines);
  };

  const rechazarLineas = async () => {
    try {
      setIsLoading(true);
      await rejectLines();
      setIsLoading(false);
      dispatch(createMessage("Se han rechazado las lineas de pedido", "success"));
      onRequestClose();
    } catch (error) {
      setIsLoading(false);
      dispatch(createMessage("No se ha podido realizar la operación", "error"));
    }
  };

  const aceptarLineas = async () => {
    try {
      setIsLoading(true);
      await acceptLines(selectedLines);
      dispatch(createMessage("Se han agregado los articulos al pedido", "success"));
      dispatch(getPedidoActivo("", "S", pedido_activo));
      await getPendingLines();
      setSelectedLines([]);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      dispatch(createMessage("No se ha podido realizar la operación", "error"));
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "80vh",
          maxWidth: "80vw",
          textAlign: "center",
          resize: "both",
        },
        overlay: {
          zIndex: "9999",
        },
      }}
    >
      <div className="pending-lines">
        {isLoading ? (
          <div className="processing">
            <span className="processing-text">Procesando</span>
          </div>
        ) : (
          <></>
        )}
        <div>
          <h4>Existen articulos que no te han sido entregados</h4>

          <div className="line-data-grid">
            <div></div>
            <div>
              <b>Articulo</b>
            </div>
            {"   "}
            <div style={{ textAlign: "right" }}>
              <b>Cantidad</b>
            </div>
            {"   "}
            <div style={{ textAlign: "right" }}>
              <b>Precio</b>
            </div>

            <span style={{ display: "none" }} className="separator"></span>
            {pendingLines.map((linea) => {
              return <ArticuloPendiente linea={linea} toggleLine={toggleLine} />;
            })}
          </div>
        </div>
        <div>
          <div className="buttons">
            <button disabled={isLoading || selectedLines.length > 0} onClick={rechazarLineas} className="btn mt-3 btn-danger">
              Rechazar
            </button>
            <button disabled={isLoading || selectedLines.length === 0} onClick={aceptarLineas} className="btn mt-3 btn-success">
              Añadir al pedido
            </button>
          </div>
          <p>Si pulsas en rechazar esta ventana no volverá a aparecer para estos artículos</p>
        </div>
      </div>
    </ReactModal>
  );
};

const ArticuloPendiente = ({ linea, toggleLine }) => {
  return (
    <>
      <input
        type="checkbox"
        id="cbox1"
        onChange={(e) => {
          toggleLine(linea, e.target.checked);
        }}
      />
      <div>{linea.descripcion_articulo}</div>
      {"   "}
      <div style={{ textAlign: "right" }}>
        {linea.cantidad_pedida - linea.unidades_servidas} {linea.presentacion_pedido}
      </div>
      {"   "}
      <div style={{ textAlign: "right" }}>
        <span className="price-tag">{(linea.precio_presentacion * (linea.cantidad_pedida - linea.unidades_servidas)).toFixed(2)}€</span>
      </div>
      <span className="separator"></span>
    </>
  );
};

export default LineasPendientesModal;
