import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTransportistas, setTransportista } from '../../actions/transportistas'
import i18n from '../../lang/idiomas'

const Transportistas = props => {
  const dispatch = useDispatch()

  const transportistas = useSelector(state => state.transportistas.transportistas)
  const transportista = useSelector(state => state.transportistas.transportista)
  const pedido_cargando = useSelector(state => state.pedidos.isLoading)
  const pedido = useSelector(state => state.pedidos.pedido)

  useEffect(() => {
    dispatch(getTransportistas())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!pedido_cargando) {
      if (transportistas?.length === 1) {
        if (transportista?.codigo_rapido !== transportistas[0].codigo_rapido) {
          dispatch(setTransportista(transportistas[0]))
        }
      }

      if (transportistas?.length > 1 && pedido?.transportista !== '') {
        if (transportista?.codigo_rapido !== pedido?.transportista) {
          const transportista_pedido = transportistas.filter(
            trans => trans.codigo_rapido === pedido.transportista,
          )
          dispatch(setTransportista(transportista_pedido[0]))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pedido_cargando, transportistas, pedido])

  const onChange = e => {
    if (e.target.value !== '') {
      if (transportista?.codigo_rapido !== e.target.value) {
        const transportista_pedido = transportistas.filter(
          trans => trans.codigo_rapido === e.target.value,
        )
        dispatch(setTransportista(transportista_pedido[0]))
      }
    }
  }

  return (
    <Fragment>
      {transportistas?.length > 0 ? (
        <div className='tt-shopcart-box'>
          <h4 className='tt-title'>{i18n.t('transportista.trtransp')}</h4>
          <p>{i18n.t('transportista.trseltra')}</p>
          <form className='form-default'>
            <div className='form-group'>
              <label htmlFor='input_transportista'>
                {i18n.t('transportista.trtransp')} <sup>*</sup>
              </label>
              <select
                className='form-control'
                id='input_transportista'
                name='transportista'
                onChange={onChange}
                disabled={pedido_cargando || props.pedidoGuardado}
                readOnly={!props.editable}
                value={transportista?.codigo_rapido || ''}>
                <option
                  key='0'
                  value=''>
                  {i18n.t('transportista.trstras')}
                </option>
                {transportistas.map((trans, index) => {
                  return (
                    <option
                      key={index}
                      value={trans.codigo_rapido}>
                      {trans.nombre}
                    </option>
                  )
                })}
              </select>
            </div>
          </form>
        </div>
      ) : (
        ''
      )}
    </Fragment>
  )
}

export default Transportistas
