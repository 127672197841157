import { ArrowLineLeft, Eye, ShoppingCart } from 'phosphor-react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { postLinea } from '../../actions/pedidos'
import {
  generarPedidoPedidoAnterior,
  getDatosLineasPedido,
  getLineasPedido,
  getPedidosAnteriores,
  limpiarDatosLineasPedido,
  limpiarDatosPedidosAnteriores,
} from '../../actions/pedidosAnteriores'
import { getPreciosYPermisos } from '../../actions/precios'
import { getStocksArticulos } from '../../actions/stocks'
import {
  cargarArrayPrecios,
  cargarArrayStocks,
  cargarParametros,
  programaActivo,
} from '../../helpers/funciones'
import { creaURLArticulo } from '../../helpers/urls/articulos'
import i18n from '../../lang/idiomas'
import Spinner from '../layout/Spinner'
import AnadirCarrito from './AnadirCarrito'
import Descripcion from './Descripcion'
import Precio from './Precio'

let articulos_aux = []
let parametros_usados = ['mostrar_iva', 'tipo_precio', 'maxim_agrup']
let parametros

const PedidosAnteriores = props => {
  const dispatch = useDispatch()
  const [mostrar_lineas, setMostrar_lineas] = useState(false)
  const prevProps = useRef()

  const portal = useSelector(state => state.portal.portal)
  const menus = useSelector(state => state.portal.menus)
  const pedidos_anteriores = useSelector(state => state.pedidosAnteriores.pedidos_anteriores)
  const pedido_anterior_detalle = useSelector(
    state => state.pedidosAnteriores.pedido_anterior_detalle,
  )
  const lineas_pedido_anterior_detalle = useSelector(
    state => state.pedidosAnteriores.lineas_pedido_anterior_detalle,
  )
  const precios = useSelector(state => state.precios.precio)
  const stocks = useSelector(state => state.stocks.stock)
  const descripciones = useSelector(state => state.descripcion.descripciones)
  const locale = useSelector(state => state.precios.locale)
  const moneda = useSelector(state => state.precios.moneda)
  const id_pedido_seleccionado = useSelector(state => state.pedidos.id_pedido_seleccionado)
  const pedidos_anteriores_loading = useSelector(state => state.pedidosAnteriores.isLoading)
  const pedido = useSelector(state => state.pedidos.pedido)
  const listado_pedido_anterior = useSelector(state => state.listadoArticulos.pedido_anterior)

  prevProps.current ??= {}
  parametros = cargarParametros(parametros_usados, portal.parametros_ecom, 'PEDA')
  let anadir_lista = false

  if (menus) {
    anadir_lista = programaActivo(menus, 'listasCompra')
  }

  useEffect(() => {
    dispatch(getPedidosAnteriores())
    return () => {
      dispatch(limpiarDatosPedidosAnteriores())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.wookie()
    let ancla = window.document.getElementById('cabecera-principal')
    // LLevamos la vista a la parte superior del componente
    if (ancla) {
      ancla.scrollIntoView({ behavior: 'smooth' })
    }
    if (lineas_pedido_anterior_detalle?.length > 0) {
      //Comprobamos si solicitan precios grupalmente
      articulos_aux = cargarArrayPrecios(
        'pedidos_anteriores',
        lineas_pedido_anterior_detalle,
        precios,
      )
      if (
        articulos_aux?.length > 0 &&
        JSON.stringify(prevProps?.current?.aux_precios) !== JSON.stringify(articulos_aux)
      ) {
        //Solo llamamos a getPrecios si hai al menos un elemento en el array
        dispatch(
          getPreciosYPermisos(
            articulos_aux, //articulos_cantidades
            'pedidoAnterior', //origen
            null, //domicilio_envio
            parametros.maxim_agrup,
          ),
        )
        prevProps.current.aux_precios = articulos_aux
      }
      //Comprobamos si solicitan stocks grupalmente
      articulos_aux = cargarArrayStocks(
        'pedidos_anteriores',
        lineas_pedido_anterior_detalle,
        stocks,
        pedido,
      )
      if (
        articulos_aux?.length > 0 &&
        JSON.stringify(prevProps?.current?.aux_stocks) !== JSON.stringify(articulos_aux)
      ) {
        //Solo llamamos a getStocks si hai al menos 1 elemento en el array
        dispatch(
          getStocksArticulos(
            articulos_aux, //articulos_stock
            'pedidoAnterior', //origen
            parametros.maxim_agrup,
          ),
        )
        prevProps.current.aux_stocks = articulos_aux
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineas_pedido_anterior_detalle])

  const anadirCarrito = e => {
    e.preventDefault()
    let _id_pedido = e.currentTarget.dataset.id_pedido
    dispatch(getLineasPedido(_id_pedido))

    confirmAlert({
      title: i18n.t('pedido.paaped'),
      message: i18n.t('pedido.pesatlpa'),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: i18n.t('general.si'),
          onClick: () => {
            dispatch(generarPedidoPedidoAnterior(_id_pedido))
          },
        },
        {
          label: i18n.t('general.no'),
        },
      ],
    })
  }

  const anadirLineaCarrito = e => {
    e.preventDefault()
    let _id = parseInt(e.currentTarget.dataset.id)

    lineas_pedido_anterior_detalle
      .filter(linea => linea.id === _id && linea.tipo_linea === 'P')
      .forEach(linea_detalle => {
        const linea = {
          articulo: linea_detalle.articulo,
          descripcion: descripciones[linea_detalle.articulo].data.descrip_comercial,
          precio_venta: null,
          observaciones: linea_detalle.observaciones,
          estado_linea: 'B',
          cantidad_pedida: Number.parseFloat(linea_detalle.cantidad_pedida),
          presentacion_pedido: linea_detalle.presentacion_pedido,
          tipo_linea: 'P',
          sub_pres: linea_detalle.sub_pres,
          sub_pres_cant:
            linea_detalle.sub_pres_cant !== '' ? Number.parseInt(linea_detalle.sub_pres_cant) : '',
          sub_pres_fc:
            linea_detalle.sub_pres_fc !== '' ? Number.parseFloat(linea_detalle.sub_pres_fc) : '',
          pres_fc: linea_detalle.pres_fc !== '' ? Number.parseFloat(linea_detalle.pres_fc) : '',
          presentacion_escogida:
            linea_detalle.presentacion_escogida !== ''
              ? Number.parseFloat(linea_detalle.presentacion_escogida)
              : '',
        }

        dispatch(postLinea(linea, '', id_pedido_seleccionado || pedido?.id_pedido, 'N'))
      })
  }

  const lineas = e => {
    e.preventDefault()
    let _id_pedido = e.currentTarget.dataset.id_pedido
    dispatch(getLineasPedido(_id_pedido))
    let index = -1
    index = pedidos_anteriores.findIndex(pedido => pedido.id_pedido === Number(_id_pedido))

    if (index !== 1) {
      dispatch(getDatosLineasPedido(pedidos_anteriores[index]))
    }

    verLineas(e)
  }

  const cerrarLineas = () => {
    dispatch(limpiarDatosLineasPedido())
    dispatch(getLineasPedido(-0))
    setMostrar_lineas(false)
  }

  const verLineas = e => {
    e.preventDefault()
    setMostrar_lineas(true)
  }

  const cargaBotonera = (linea, anadir_lista) => {
    let index = -1

    if (listado_pedido_anterior?.articulos?.length > 0) {
      index = listado_pedido_anterior.articulos.findIndex(
        articulo =>
          linea.articulo === articulo.codigo_articulo &&
          articulo.presentacion_pedido === linea.presentacion_pedido,
      )
    }
    if (listado_pedido_anterior && !listado_pedido_anterior.isLoading) {
      if (index !== -1) {
        return (
          <AnadirCarrito
            articulo={listado_pedido_anterior.articulos[index]}
            datos={linea}
            origen='tabla'
            anadir_lista={anadir_lista}
            index={index}
          />
        )
      } else {
        return (
          <AnadirCarrito
            articulo={linea.datos_articulo}
            origen='tabla'
            datos={linea}
            anadir_lista={anadir_lista}
            index={index}
          />
        )
      }
    } else {
      return <Spinner showSpinner={listado_pedido_anterior.isLoading} />
    }
  }

  return (
    <Fragment>
      <div className='container'>
        <div
          id='tt-pageContent'
          className='pedidos-anteriores'>
          {mostrar_lineas === false ? (
            <div className='container-indent'>
              <div className='container'>
                <h1
                  className='tt-title-subpages noborder'
                  onClick={cerrarLineas}>
                  {i18n.t('pedido.pedantr')}
                </h1>

                <div className='row'>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <Spinner showSpinner={pedidos_anteriores_loading} />

                    {pedidos_anteriores.length === 0 &&
                    pedidos_anteriores.filter(pedido => {
                      return (
                        pedido.lineas &&
                        pedido.lineas.filter(linea => linea.tipo_linea === 'P').length > 0
                      )
                    }) &&
                    !pedidos_anteriores_loading ? (
                      <h5>{i18n.t('pedido.pnexpeda')}</h5>
                    ) : (
                      <Fragment>
                        <div className='tt-shopcart-table pedidos_anteriores_desctop row'>
                          <div className='col-sm-12 col-md-12 col-lg-12'>
                            {pedidos_anteriores.length > 0 ? (
                              <Fragment>
                                <div className='row titulos'>
                                  <div className='col-sm-12 col-md-3 col-lg-3'>
                                    {i18n.t('pedido.pserped')}
                                  </div>
                                  <div className='col-sm-12 col-md-3 col-lg-3'>
                                    {i18n.t('pedido.pfechped')}
                                  </div>
                                  <div className='col-sm-12 col-md-2 col-lg-2'>
                                    {i18n.t('pedido.pimprt')}
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-4'>
                                    {i18n.t('pedido.paccns')}
                                  </div>
                                </div>
                                {pedidos_anteriores
                                  .filter(pedido => {
                                    return (
                                      pedido.lineas &&
                                      pedido.lineas.filter(linea => linea.tipo_linea === 'P')
                                        .length > 0
                                    )
                                  })
                                  .map(pedido => {
                                    return (
                                      <div
                                        className='row pedidos-anteriores-row'
                                        key={pedido.id_pedido}>
                                        <div className='col-sm-12 col-md-3 col-lg-3'>
                                          {pedido.numero_serie
                                            ? pedido.numero_serie + '/' + pedido.numero_pedido
                                            : pedido.numero_serie_pres + '/' + pedido.numero_pres}
                                        </div>
                                        <div className='col-sm-12 col-md-3 col-lg-3'>
                                          {new Date(
                                            Date.parse(pedido.fecha_valor),
                                          ).toLocaleDateString(locale)}
                                        </div>
                                        <div className='col-sm-12 col-md-2 col-lg-2'>
                                          {new Intl.NumberFormat(locale, {
                                            style: 'currency',
                                            currency: moneda,
                                          }).format(
                                            Number.parseFloat(pedido.importe_cobrado).toFixed(2),
                                          )}
                                        </div>
                                        <div className='col-sm-12 col-md-2 col-lg-2'>
                                          <button
                                            className='btn btn-primary'
                                            onClick={lineas}
                                            data-id_pedido={pedido.id_pedido}>
                                            <Eye
                                              size={22}
                                              weight={'regular'}
                                            />
                                          </button>
                                        </div>
                                        <div className='col-sm-12 col-md-2 col-lg-2'>
                                          <button
                                            className='btn btn-primary'
                                            onClick={anadirCarrito}
                                            data-id_pedido={pedido.id_pedido}>
                                            <ShoppingCart
                                              size={22}
                                              weight={'regular'}
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    )
                                  })}
                              </Fragment>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className='tt-shopcart-table pedidos_anteriores_mobile row'>
                          <div className='col-sm-12 col-md-12 col-lg-12'>
                            {pedidos_anteriores
                              .filter(pedido => {
                                return (
                                  pedido.lineas &&
                                  pedido.lineas.filter(linea => linea.tipo_linea === 'P').length > 0
                                )
                              })
                              .map(pedido => {
                                return (
                                  <div
                                    className='row pedidos-anteriores-row'
                                    key={pedido.id_pedido}>
                                    <div className='col-12'>
                                      <div className='row '>
                                        <div className='col-6 titulos'>
                                          {i18n.t('pedido.pserped')}
                                        </div>
                                        <div className='col-6'>
                                          {pedido.numero_serie
                                            ? pedido.numero_serie + '/' + pedido.numero_pedido
                                            : pedido.numero_serie_pres + '/' + pedido.numero_pres}
                                        </div>
                                      </div>
                                      <div className='row '>
                                        <div className='col-6 titulos'>
                                          {i18n.t('pedido.pfechped')}
                                        </div>
                                        <div className='col-6'>
                                          {new Date(
                                            Date.parse(pedido.fecha_valor),
                                          ).toLocaleDateString(locale)}
                                        </div>
                                      </div>
                                      <div className='row '>
                                        <div className='col-6 titulos'>
                                          {i18n.t('pedido.pimprt')}
                                        </div>
                                        <div className='col-6'>
                                          {new Intl.NumberFormat(locale, {
                                            style: 'currency',
                                            currency: moneda,
                                          }).format(
                                            Number.parseFloat(pedido.importe_cobrado).toFixed(2),
                                          )}
                                        </div>
                                      </div>
                                      <div className='row '>
                                        <div className='col-6 titulos'>
                                          {i18n.t('pedido.paccns')}
                                        </div>
                                        <div className='col-6'>
                                          <div className='row'>
                                            <div className='col-6'>
                                              <button
                                                className='btn btn-primary pedidos-anteriores-accion'
                                                onClick={lineas}
                                                data-id_pedido={pedido.id_pedido}>
                                                <Eye
                                                  size={22}
                                                  weight={'regular'}
                                                />
                                              </button>
                                            </div>
                                            <div className='col-6'>
                                              <button
                                                className='btn btn-primary pedidos-anteriores-accion'
                                                onClick={anadirCarrito}
                                                data-id_pedido={pedido.id_pedido}>
                                                <ShoppingCart
                                                  size={22}
                                                  weight={'regular'}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {mostrar_lineas ? (
            <Fragment>
              {lineas_pedido_anterior_detalle.filter(linea => linea.tipo_linea === 'P').length ===
                0 || pedido_anterior_detalle === 0 ? (
                <div className='container-indent'>
                  <div className='container modal-body'>
                    <h5
                      onClick={cerrarLineas}
                      className='atras-listas'>
                      <ArrowLineLeft
                        size={22}
                        weight={'regular'}
                      />{' '}
                      {i18n.t('pedido.ppanmin')}
                    </h5>
                    <h1 className='tt-title-subpages noborder'>{i18n.t('pedido.plineasp')}</h1>
                    <div className='row'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <p>{i18n.t('pedido.pnhlinep')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='container-indent'>
                  <div className='container modal-body'>
                    <h5
                      onClick={cerrarLineas}
                      className='atras-pedido'>
                      <ArrowLineLeft
                        size={22}
                        weight={'regular'}
                      />{' '}
                      {i18n.t('pedido.ppanmin')}
                    </h5>
                    <h1 className='tt-title-subpages noborder'>{i18n.t('pedido.plineasp')}</h1>
                    <div className='row'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='tt-shopcart-table pedidos_anteriores_detalle_desctop row'>
                          <div className='col-sm-12 col-md-12 col-lg-12'>
                            <div className='row titulos'>
                              <div className='col-sm-12 col-md-2 col-lg-2'>
                                {i18n.t('pedido.partcl')}
                              </div>
                              <div className='col-sm-12 col-md-3 col-lg-3'>
                                {i18n.t('pedido.pdescr')}
                              </div>
                              <div className='col-sm-12 col-md-2 col-lg-2'>
                                {i18n.t('pedido.pprec')}
                              </div>
                              <div className='col-sm-12 col-md-1 col-lg-1'>
                                {i18n.t('pedido.pcant')}
                              </div>
                              <div className='col-sm-12 col-md-2 col-lg-2'>
                                {i18n.t('pedido.ppresnt')}
                              </div>
                              <div className='col-sm-12 col-md-2 col-lg-2'>
                                {i18n.t('pedido.paccns')}
                              </div>
                            </div>

                            {lineas_pedido_anterior_detalle
                              .filter(linea => linea.tipo_linea === 'P')
                              .map(linea => {
                                return (
                                  <div
                                    className='row pedidos-anteriores-row'
                                    key={linea.id}>
                                    <div className='col-sm-12 col-md-2 col-lg-2'>
                                      <Link
                                        to={creaURLArticulo(
                                          linea?.articulo,
                                          linea?.datos_articulo?.descrip_comercial,
                                        )}>
                                        {linea.articulo}
                                      </Link>
                                    </div>
                                    <div className='col-sm-12 col-md-3 col-lg-3'>
                                      <Descripcion codigo_articulo={linea.articulo} />
                                    </div>
                                    <div className='col-sm-12 col-md-2 col-lg-2'>
                                      <Precio
                                        codigo_articulo={linea.articulo}
                                        presentacion={linea.presentacion_pedido}
                                        mostrar_iva={parametros.mostrar_iva}
                                        tipo_precio={parametros.tipo_precio}
                                        mostrar_pres={parametros.mostrar_pres}
                                        estilo_precio={parametros.estilo_precio}
                                      />
                                    </div>
                                    <div className='col-sm-12 col-md-1 col-lg-1'>
                                      {Number(linea.cantidad_pedida).toFixed(0)}
                                    </div>
                                    <div className='col-sm-12 col-md-2 col-lg-2'>
                                      {linea.presentacion_pedido}
                                    </div>
                                    <div className='col-sm-12 col-md-2 col-lg-2'>
                                      {cargaBotonera(linea, anadir_lista)}
                                    </div>
                                  </div>
                                )
                              })}
                          </div>
                        </div>

                        <div className='tt-shopcart-table pedidos_anteriores_detalle_mobile row'>
                          <div className='col-sm-12 col-md-12 col-lg-12'>
                            {lineas_pedido_anterior_detalle
                              .filter(linea => linea.tipo_linea === 'P')
                              .map((linea, index) => {
                                return (
                                  <div
                                    className='row pedidos-anteriores-row'
                                    key={index}>
                                    <div className='col-12'>
                                      <div className='row element-row'>
                                        <div className='col-6 titulos'>
                                          {i18n.t('pedido.partcl')}
                                        </div>
                                        <div className='col-6'>{linea.articulo}</div>
                                      </div>

                                      <div className='row element-row'>
                                        <div className='col-6 titulos'>
                                          {i18n.t('pedido.pdescr')}
                                        </div>
                                        <div className='col-6'>
                                          <Descripcion codigo_articulo={linea.articulo} />
                                        </div>
                                      </div>

                                      <div className='row element-row'>
                                        <div className='col-6 titulos'>
                                          {i18n.t('pedido.pprec')}
                                        </div>
                                        <div className='col-6'>
                                          <Precio
                                            codigo_articulo={linea.articulo}
                                            presentacion={linea.presentacion_pedido}
                                            mostrar_iva={parametros.mostrar_iva}
                                            tipo_precio={parametros.tipo_precio}
                                            mostrar_pres={parametros.mostrar_pres}
                                            estilo_precio={parametros.estilo_precio}
                                          />
                                        </div>
                                      </div>

                                      <div className='row element-row'>
                                        <div className='col-6 titulos'>
                                          {i18n.t('pedido.pcant')}
                                        </div>
                                        <div className='col-6'>
                                          {Number(linea.cantidad_pedida).toFixed(0)}
                                        </div>
                                      </div>

                                      <div className='row element-row'>
                                        <div className='col-6 titulos'>
                                          {i18n.t('pedido.ppresnt')}
                                        </div>
                                        <div className='col-6'>{linea.presentacion_pedido}</div>
                                      </div>

                                      <div className='row element-row'>
                                        <div className='col-6 titulos'>
                                          {i18n.t('pedido.paccns')}
                                        </div>
                                        <div className='col-6'>
                                          <button
                                            className='btn btn-primary pedidos-anteriores-accion'
                                            onClick={anadirLineaCarrito}
                                            data-id={linea.id}>
                                            <ShoppingCart
                                              size={22}
                                              weight={'regular'}
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          ) : (
            ''
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default PedidosAnteriores
