import axios from "../axiosConfig";
import { createMessage } from "./messages";
import { FETCH_OFERTAS_WEB, 
         GET_OFERTAS_WEB, 
         CHECK_OFERTAS_WEB, 
         SET_OFERTA_VALIDADA,
         GET_PEDIDO_ACTIVO
        } from "./types";
import { tokenConfig } from "./auth";

// GET ARTICULOS
export const getOfertas = () =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_OFERTAS_WEB,
      payload: true,
    });
    axios
      .get(`/ofertasWeb`, tokenConfig(getState))
      .then((res) => {
        // console.log("----> Respuesta ofertasWeb...")
        console.log(res)
        dispatch({
          type: GET_OFERTAS_WEB,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };
export const checkOferta = (oferta, actualizar = "N") => (dispatch, getState) => {
  dispatch({
    type: FETCH_OFERTAS_WEB,
    payload: true,
  });
  console.log("Actions: ",oferta)
  let body = oferta
  axios
    .post(`/ofertasWeb/`, body,tokenConfig(getState))
    .then((res) => {
      console.log("Respuesta check_ofertasWeb...", res.data);
      console.log("respuesta: ", res.data.respuesta);
      if(res.data.respuesta === "OK"){
        dispatch(createMessage("La oferta se ha añadido correctamente", "success"));
        dispatch({
          type: GET_PEDIDO_ACTIVO,
          payload: res.data.lineas,
        });
      }else{
        if(res.data.respuesta[0] === "MALSTOCK"){
          dispatch(createMessage("No queda stock del regalo...", "error"));
        }
        if(res.data.respuesta[0] === "NO_REGALO"){
          dispatch(createMessage("No se han seleccionado suficientes unidades para recibir el regalo...", "error"));
        }
        if(res.data.respuesta[0] === "NOCONDI"){
          dispatch(createMessage("La oferta no cumple con las condiciones", "error"));
        }
        if(res.data.respuesta[0] === "NO_PRECIO"){
          dispatch(createMessage("La oferta no pudo recuperar los precios...", "error"));
        }
      }
      dispatch({
        type: CHECK_OFERTAS_WEB,
        payload: res.data.respuesta,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch(createMessage("Ha ocurrido un error...", "error"));
    });
};

export const setOfertaValidada = () => (dispatch, getState) => {
  dispatch({
    type: SET_OFERTA_VALIDADA,
    payload: "no_ok"
  });
};