import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { getFiltros } from '../../actions/filtros'
import Buscador from './Buscador'
import FamiliasMenu from './FamiliasMenu'
import NovedadesOfertas from './NovedadesOfertas'
// import Banners from "./Banners";
import { getTiras } from '../../actions/tiras'
import Tira from '../layout/Tira'

export class Home extends Component {
  static propTypes = {
    getFiltros: PropTypes.func.isRequired,
    getTiras: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.getFiltros()
    this.props.getTiras()
  }

  componentDidUpdate() {
    window.wookie()
  }

  render() {
    return (
      <Fragment>
        {/* <Banners id_banner="BHOME" /> */}
        <Tira id_tira='HOME' />
        <FamiliasMenu />
        <Buscador />
        <NovedadesOfertas />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, {
  getFiltros,
  getTiras,
})(Home)
