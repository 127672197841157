import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import '../../css/StockBadge.css'

const StockBadge = ({ codigoArticulo, showStockFC, showStock }) => {
  const cartAmount = useSelector(state =>
    state.pedidos?.pedido?.lineas?.filter(linea => linea.articulo === codigoArticulo),
  )
  const pedido = useSelector(state => state.pedido)

  const [totalAmountFC, setTotalAmountFC] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)

  useEffect(() => {
    if (cartAmount !== undefined && cartAmount !== null && cartAmount.length > 0) {
      let alpha = 0
      let lambda = 0
      cartAmount.map(item => {
        if (item.pres_fc !== null && item.pres_fc !== undefined && item.pres_fc !== '') {
          alpha += Number.parseInt(item.cantidad_pedida) * item.pres_fc
        } else {
          alpha += Number.parseInt(item.cantidad_pedida) * 1
        }

        if (
          item.cantidad_pedida !== null &&
          item.cantidad_pedida !== undefined &&
          item.cantidad_pedida !== ''
        ) {
          lambda += Number.parseInt(item.cantidad_pedida)
        }
      })
      setTotalAmountFC(alpha)
      setTotalAmount(lambda)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigoArticulo, pedido])

  return showStock !== undefined && showStock !== null && showStockFC === 'S' ? (
    showStockFC !== undefined && showStockFC !== null && showStockFC === 'S' ? (
      totalAmountFC > 0 ? (
        <div className='badgeStock'>
          En carrito
          <span>{' ' + totalAmountFC}</span>
        </div>
      ) : null
    ) : totalAmount > 0 ? (
      <div className='badgeStock'>
        En carrito
        <span>{' ' + totalAmount}</span>
      </div>
    ) : null
  ) : null
}

export default StockBadge
