import { ShoppingCart, X } from 'phosphor-react'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { checkOferta, getOfertas, setOfertaValidada } from '../../actions/ofertasWeb'
import { creaURLArticulo } from '../../helpers/urls/articulos'
import '../../sass/componentes/ofertasWeb.scss'
import Spinner from '../layout/Spinner'
import Imagen from './Imagen'

const OfertasWeb = () => {
  const dispatch = useDispatch()

  const [ofertaSeleccionada, setOfertaSeleccionada] = useState(null)
  const [cantidadSeleccionada, setCantidadSeleccionada] = useState([])
  const [checkOfertaSel, setCheckOfertaSel] = useState('noChecking')
  const [noVacio, setnoVacio] = useState('vacio')

  const ofertas = useSelector(state => state.ofertasWeb.ofertas_web)
  const status = useSelector(state => state.ofertasWeb.isLoading)
  const check = useSelector(state => state.ofertasWeb.check)

  if (checkOfertaSel === 'checking') {
    setCheckOfertaSel('noChecking')
    setnoVacio('vacio')
    dispatch(checkOferta(cantidadSeleccionada))
    setOfertaSeleccionada(null)
    setCantidadSeleccionada([])
  }

  if (check === 'OK' && status === 'in process...') {
    setOfertaSeleccionada(null)
    setCantidadSeleccionada([])
    dispatch(setOfertaValidada())
  }
  useEffect(() => {
    if (status === 'loading') {
      dispatch(getOfertas())
    }
  }, [status, dispatch])

  function anade_oferta() {
    setCheckOfertaSel('checking')
  }

  function agrega_cantidad(cantidad, codigo, stock, linea) {
    var aux = []
    var precio = 0
    var tipo = ''
    var regalo = []
    var cantMinima = 0
    var descripcion = ''

    if (parseInt(stock) >= parseInt(cantidad)) {
      cantidadSeleccionada?.forEach(articulo => {
        if (articulo.articulo !== codigo) {
          aux = [
            ...aux,
            {
              articulo: articulo.articulo,
              descripcion: articulo.descripcion,
              cantidad: articulo.cantidad,
              precio: articulo.precio,
              id_oferta: articulo.id_oferta,
              linea: articulo.linea,
              regalo: articulo.regalo,
            },
          ]
        }
      })
      ofertas.ofertas.forEach(oferta => {
        if (oferta.codigo === ofertaSeleccionada) {
          tipo = oferta.tipo
          cantMinima = oferta.cantidad_grupo
          oferta.articulos.forEach(articulo => {
            descripcion = articulo.descripcion
            if (articulo.codigo === codigo) {
              if (articulo.grupo.length === undefined) {
                precio = articulo.grupo.precio
              } else {
                articulo.grupo.forEach(grupo => {
                  if (cantidad >= grupo.cantidad_desde && cantidad <= grupo.cantidad_hasta) {
                    precio = grupo.precio
                  }
                })
              }
              if (tipo === 'R') {
                if (articulo.regalos.length !== undefined) {
                  articulo.regalos.forEach(regalo => {
                    regalo = [
                      ...regalo,
                      {
                        codigo_regalo: regalo.codigo_regalo,
                        cantidad_regalo: Math.floor(cantidad / cantMinima),
                      },
                    ]
                  })
                } else {
                  regalo = [
                    ...regalo,
                    {
                      codigo_regalo: articulo.regalos.codigo_regalo,
                      cantidad_regalo: Math.floor(cantidad / cantMinima),
                    },
                  ]
                }
              }
            }
          })
        }
      })
      if (cantidad > 0) {
        aux = [
          ...aux,
          {
            articulo: codigo,
            descripcion: descripcion,
            cantidad: cantidad,
            precio: precio,
            id_oferta: ofertaSeleccionada,
            linea: linea,
            regalo: regalo,
          },
        ]
      }
      setCantidadSeleccionada(aux)
      setnoVacio('noVacio')
    }
  }

  function cerrarSeleccionada() {
    setCantidadSeleccionada([])
    setOfertaSeleccionada(null)
  }

  function tipo_oferta(e) {
    switch (e) {
      case 'U':
        return 'Descuento en precio.'
      case 'R':
        return 'Producto de regalo.'
      case 'G':
        return 'Compra de grandes cantidades en diferentes articulos.'
      case 'C':
        return 'Compra de lotes.'
      default:
        return 'Otros.'
    }
  }

  function scrollAndSelec(e) {
    window.scrollTo(0, 0)
    setOfertaSeleccionada(e)
  }

  return (
    <div className='container'>
      <div
        id='tt-pageContent'
        className='articulos'>
        <div className='container-indent'>
          <div className='container-fluid-custom container-fluid-custom-mobile-padding'>
            <div
              className='col-sm-12 col-md-12 col-lg-12'
              style={{ display: !ofertaSeleccionada ? 'flex' : 'none', justifyContent: 'center' }}>
              <div
                className='row'
                style={{ maxWidth: '1400px' }}>
                {ofertas.ofertas ? (
                  ofertas.ofertas.map((oferta, index) => (
                    <div className='col-sm-12 col-md-6 col-lg-3 cardOferta'>
                      <div
                        className='tt-oferta'
                        key={oferta.codigo + '-' + index}>
                        <p className='tt-oferta-code'>{oferta.codigo}</p>
                        <h2 className='tt-title'>{oferta.titulo}</h2>
                        <p style={{ color: '#3296f3' }}>{tipo_oferta(oferta.tipo)}</p>
                        <div className='row'>
                          <div className='col-sm-12 col-md-12 col-lg-6'>
                            <p className='tt-oferta-fields'>
                              <b style={{ fontWeight: 'bold' }}>Estado: </b>
                              <b
                                className={
                                  oferta.oferta_disponible === 'true'
                                    ? 'stock-disponible'
                                    : 'stock-no-disponible'
                                }>
                                {oferta.oferta_disponible === 'true' ? 'Disponible.' : 'Sin Stock.'}
                              </b>
                            </p>
                          </div>
                          {oferta.cantidad_grupo !== '1' ? (
                            <div
                              className='col-sm-12 col-md-12 col-lg-6'
                              style={{ marginBottom: '10px' }}>
                              <p className='tt-oferta-fields'>
                                <span style={{ fontWeight: 'bold' }}>Cant. Minima: </span>
                                {oferta.cantidad_grupo}
                              </p>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div
                          className='col-sm-12 col-md-12 col-lg-12 tt-oferta-fields'
                          style={{ textAlign: 'justify', overflowY: 'scroll', maxHeight: '200px' }}>
                          {oferta.descripcion_oferta}
                        </div>
                        <div className='row'>
                          {/* <div className="col-sm-6 col-md-6 col-lg-6">
                            <p className="tt-oferta-fields">
                              <b>Cantidad Grupo: </b>
                              {oferta.cantidad_grupo}
                            </p>
                          </div> */}
                          <div
                            className='col-sm-12 col-md-12 col-lg-12'
                            style={{
                              paddingBottom: '15px',
                              paddingTop: '10px',
                              position: 'absolute',
                              bottom: '0',
                            }}>
                            <button
                              onClick={() =>
                                // setOfertaSeleccionada(oferta.codigo)
                                scrollAndSelec(oferta.codigo)
                              }
                              disabled={oferta.oferta_disponible !== 'true' ? true : false}
                              className='btn btn-border'>
                              Ir a la Oferta
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : status === 'loading' ? (
                  <Spinner showSpinner={status === 'loading'}></Spinner>
                ) : (
                  <h1>No hay ofertas disponibles...</h1>
                )}
              </div>
            </div>
            <div
              className='col-sm-12 col-md-12 col-lg-12'
              style={{
                display: ofertaSeleccionada ? 'flex' : 'none',
                justifyContent: 'center',
              }}>
              {ofertas.ofertas && ofertaSeleccionada !== null
                ? ofertas.ofertas.map((oferta, index) => {
                    if (ofertaSeleccionada === oferta.codigo) {
                      return (
                        <div
                          className='col-sm-12 col-md-12 col-lg-12 oferta-principal'
                          key={oferta.codigo + 'seleccionada' + index}
                          style={{ paddingBottom: '10px', maxWidth: '1000px' }}>
                          <div
                            className='tt-oferta'
                            key={oferta.codigo}>
                            <div className='row'>
                              <div className='col-sm-6 col-md-6 col-lg-6'>
                                <p className='tt-oferta-principal-code'>{oferta.codigo}</p>
                              </div>
                              <div className='col-sm-6 col-md-6 col-lg-6 tt-close-oferta'>
                                <button
                                  onClick={() => cerrarSeleccionada()}
                                  className='btn boton-custom'>
                                  <X size={15} />
                                </button>
                              </div>
                            </div>
                            <h2 className='tt-title'>{oferta.titulo}</h2>
                            <h3
                              className='tt-title'
                              style={{ color: '#3296f3' }}>
                              {tipo_oferta(oferta.tipo)}
                            </h3>
                            <div className='col-sm-12 col-md-12 col-lg-12 tt-div-fix'>
                              <div className='row'>
                                <div className='col-sm-12 col-md-12 col-lg-12'>
                                  <p
                                    style={{
                                      fontWeight: 'bold',
                                      textAlign: 'justify',
                                      marginBottom: '10px',
                                    }}>
                                    {oferta.descripcion_oferta}
                                  </p>
                                </div>
                                <div
                                  className='col-sm-12 col-md-12 col-lg-12 card-style'
                                  style={{
                                    overflowY: 'scroll',
                                    maxHeight: '50vh',
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                  }}>
                                  <div className='row'>
                                    {oferta?.articulos.length > 0
                                      ? oferta.articulos.map((articulo, i) => {
                                          return (
                                            <Fragment>
                                              {i > 0 ? (
                                                <hr
                                                  style={{
                                                    background: 'grey',
                                                    color: 'grey',
                                                    borderColor: 'grey',
                                                    borderBottom: '1px solid grey',
                                                    width: '99%',
                                                    height: '1px',
                                                    paddingTop: '0px',
                                                    paddingBottom: '0px',
                                                    marginTop: '5px',
                                                    marginBottom: '0px',
                                                  }}
                                                />
                                              ) : (
                                                ''
                                              )}
                                              <div className='col-sm-6 col-md-6 col-lg-6 imagen'>
                                                <div className='tt-item-img'>
                                                  <Imagen
                                                    codigo_articulo={articulo.codigo}
                                                    principal='S'
                                                    numero_imagen={1}
                                                    src_manual={articulo.reservado_alfa_2 || null}
                                                  />
                                                </div>
                                              </div>
                                              <div className='col-sm-6 col-md-6 col-lg-6'>
                                                <div className='row'>
                                                  <div className='col-sm-12 col-md-12 col-lg-12'>
                                                    <p className='tt-oferta-principal'>
                                                      <span style={{ fontWeight: 'bold' }}>
                                                        Codigo:{' '}
                                                      </span>
                                                      {/* {articulo.codigo} */}
                                                      <Link
                                                        to={creaURLArticulo(
                                                          articulo?.codigo,
                                                          articulo?.descripcion,
                                                        )}>
                                                        {articulo.codigo}
                                                      </Link>
                                                    </p>
                                                  </div>
                                                  <div className='col-sm-10 col-md-10 col-lg-10'>
                                                    <p className='tt-oferta-principal'>
                                                      <span style={{ fontWeight: 'bold' }}>
                                                        Articulo:{' '}
                                                      </span>
                                                      {/* {articulo.descripcion} */}
                                                      <Link
                                                        to={creaURLArticulo(
                                                          articulo?.codigo,
                                                          articulo?.descripcion,
                                                        )}>
                                                        {articulo.descripcion}
                                                      </Link>
                                                    </p>
                                                  </div>
                                                  <div className='col-sm-6 col-md-6 col-lg-6'>
                                                    <p className='tt-oferta-principal'>
                                                      <span style={{ fontWeight: 'bold' }}>
                                                        Stock:{' '}
                                                      </span>
                                                      {articulo.stock === '0' ? (
                                                        <span style={{ color: 'red' }}>
                                                          Sin Stock
                                                        </span>
                                                      ) : (
                                                        articulo.stock
                                                      )}
                                                    </p>
                                                  </div>
                                                  <div className='col-sm-6 col-md-6 col-lg-6'>
                                                    <p className='tt-oferta-principal'>
                                                      <span style={{ fontWeight: 'bold' }}>
                                                        Minimo:{' '}
                                                      </span>
                                                      {articulo.unidad_minima}
                                                    </p>
                                                  </div>
                                                  {articulo.grupo.length !== undefined ? (
                                                    articulo.grupo.map(grupo => (
                                                      <Fragment>
                                                        <div className='col-sm-12 col-md-12 col-lg-6'>
                                                          <p className='tt-oferta-principal'>
                                                            <span style={{ fontWeight: 'bold' }}>
                                                              Rango:{' '}
                                                            </span>
                                                            {grupo.cantidad_desde} -{' '}
                                                            {grupo.cantidad_hasta} unidades
                                                          </p>
                                                        </div>
                                                        <div className='col-sm-12 col-md-12 col-lg-6'>
                                                          <p className='tt-oferta-principal'>
                                                            <span style={{ fontWeight: 'bold' }}>
                                                              Precio:{' '}
                                                            </span>
                                                            {grupo.precio} €
                                                          </p>
                                                        </div>
                                                        <div className='col-sm-12 col-md-12 col-lg-12'>
                                                          <p className='tt-oferta-principal'>
                                                            {articulo.regalos !== undefined
                                                              ? (
                                                                  <span
                                                                    style={{ fontWeight: 'bold' }}>
                                                                    Regalo:{' '}
                                                                  </span>
                                                                ) + articulo.regalos.regalo
                                                              : ''}
                                                          </p>
                                                        </div>
                                                      </Fragment>
                                                    ))
                                                  ) : (
                                                    <Fragment>
                                                      <div className='col-sm-12 col-md-12 col-lg-6'>
                                                        <p className='tt-oferta-principal'>
                                                          <span style={{ fontWeight: 'bold' }}>
                                                            Rango:
                                                          </span>{' '}
                                                          {articulo.grupo.cantidad_desde} -{' '}
                                                          {articulo.grupo.cantidad_hasta} unidades
                                                        </p>
                                                      </div>
                                                      <div className='col-sm-12 col-md-12 col-lg-6'>
                                                        <p className='tt-oferta-principal'>
                                                          <span style={{ fontWeight: 'bold' }}>
                                                            Precio:{' '}
                                                          </span>
                                                          {articulo.grupo.precio} €
                                                        </p>
                                                      </div>
                                                      {articulo.regalos !== undefined ? (
                                                        <Fragment>
                                                          {articulo.regalos.length !== undefined ? (
                                                            <Fragment>
                                                              {articulo.regalos.forEach(regalo => {
                                                                ;<div className='col-sm-12 col-md-12 col-lg-12'>
                                                                  <p className='tt-oferta-principal'>
                                                                    {(
                                                                      <span
                                                                        style={{
                                                                          fontWeight: 'bold',
                                                                        }}>
                                                                        Regalo:{' '}
                                                                      </span>
                                                                    ) + regalo.regalo_desc}
                                                                  </p>
                                                                </div>
                                                              })}
                                                            </Fragment>
                                                          ) : (
                                                            <Fragment>
                                                              <div className='col-sm-12 col-md-12 col-lg-12'>
                                                                <p className='tt-oferta-principal'>
                                                                  {articulo.regalos !== undefined
                                                                    ? (
                                                                        <span
                                                                          style={{
                                                                            fontWeight: 'bold',
                                                                          }}>
                                                                          Regalo:{' '}
                                                                        </span>
                                                                      ) +
                                                                      articulo.regalos.regalo_desc
                                                                    : ''}
                                                                </p>
                                                              </div>
                                                            </Fragment>
                                                          )}
                                                        </Fragment>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </Fragment>
                                                  )}
                                                </div>
                                                <div className='col-sm-12 col-md-12 col-lg-12 card-seleccionada'>
                                                  <div className='row'>
                                                    <div className='col-sm-8 col-md-8 col-lg-8'></div>
                                                    <div className='col-sm-4 col-md-4 col-lg-4'>
                                                      <div className='row'>
                                                        <div className='col-sm-6 col-md-6 col-lg-6'>
                                                          <input
                                                            className='form-control desctop'
                                                            id={'input-cant-mob-' + oferta.codigo}
                                                            disabled={
                                                              articulo.stock === '0' ? true : false
                                                            }
                                                            // value="0"
                                                            placeholder='0'
                                                            //data-tooltip="Cantidad"
                                                            // onInput={e => setCantidadSeleccionada([...cantidadSeleccionada,e.target.value])}
                                                            onInput={e =>
                                                              agrega_cantidad(
                                                                e.target.value,
                                                                articulo.codigo,
                                                                articulo.stock,
                                                                articulo.linea,
                                                              )
                                                            }
                                                            type='number'
                                                            pattern='[0-9]*'
                                                            name='valor'
                                                            step={articulo.unidad_minima}
                                                            max={
                                                              articulo.stock !== 0
                                                                ? articulo.stock
                                                                : '1'
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Fragment>
                                          )
                                        })
                                      : ''}
                                  </div>
                                </div>
                                <div
                                  className='col-sm-12 col-md-12 col-lg-12 btn  btn-border'
                                  style={{
                                    paddingBottom: '15px',
                                    paddingTop: '15px',
                                    marginTop: '20px',
                                    display:
                                      noVacio === 'noVacio' && check === 'no_ok' ? 'flex' : 'none',
                                  }}
                                  onClick={() => anade_oferta()}>
                                  <ShoppingCart size={32} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    } else {
                      return ''
                    }
                  })
                : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfertasWeb
