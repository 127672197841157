import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { comprobarToken, recuperarPass, reiniciarPass } from '../../actions/auth'
import { createMessage } from '../../actions/messages'
import { ordenarMenu, validaPass } from '../../helpers/funciones'
import i18n from '../../lang/idiomas'

const Recuperar = props => {
  const dispatch = useDispatch()

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const invitado = useSelector(state => state.auth.invitado)
  const irAInicio = useSelector(state => state.auth.irAInicio)
  const user = useSelector(state => state.auth.user)
  const menus = useSelector(state => state.portal.menus)
  const portal = useSelector(state => state.portal.portal)

  const [userName, setUserName] = useState('')
  const [token, setToken] = useState('')
  const [nuevoPass, setNuevoPass] = useState('')
  const [reNuevoPass, setReNuevoPass] = useState('')
  const [errorIguales, setErrorIguales] = useState(false)
  const [errorVacios, setErrorVacios] = useState(false)

  useEffect(() => {
    let params = queryString.parse(window.location.search)
    if (params.t) {
      setToken(params.t)
      dispatch(comprobarToken(params.t))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (irAInicio) {
      let url_inicio = window.location.protocol + '//' + window.location.hostname

      window.location.href = url_inicio
    }
  }, [irAInicio])

  const handleSubmit = e => {
    e.preventDefault()
    if (e.target.name === 'recuperar') {
      dispatch(recuperarPass(userName))
    } else if (nuevoPass !== '' && reNuevoPass !== '') {
      if (nuevoPass !== reNuevoPass) {
        dispatch(createMessage(i18n.t('usuarios.ucontnoi'), 'error'))
        setErrorIguales(true)
      } else if (!validaPass(nuevoPass) || !validaPass(reNuevoPass)) {
        dispatch(createMessage(i18n.t('usuarios.unocumre'), 'error'))
      } else {
        dispatch(reiniciarPass(user.usuario_web, token, nuevoPass))
      }
    } else {
      dispatch(createMessage(i18n.t('usuarios.ucamnopv'), 'error'))
      setErrorVacios(true)
    }
  }

  if (isAuthenticated && invitado === false && menus?.length > 0) {
    menus.sort(ordenarMenu)

    const irA = menus.find(menu => menu.activo === 'S' && menu.programa)

    if (irA) {
      return <Navigate to={'/' + irA.programa} />
    } else {
      return ''
    }
  }

  let formulario
  if (token) {
    formulario = (
      <div className='group-form'>
        <div className='form-group'>
          <label htmlFor='nuevoPass'>{i18n.t('usuarios.unuecont')}</label>
          <input
            type='password'
            name='nuevoPass'
            onChange={e => {
              e.preventDefault()
              setNuevoPass(e.target.value)
            }}
            value={nuevoPass}
            className={errorIguales || errorVacios ? 'form-control error' : 'form-control'}
            id='nuevoPass'
            placeholder={i18n.t('usuarios.unueconm')}
            required
          />
          <p>{i18n.t('usuarios.ureqmin')}</p>
        </div>
        <div className='form-group'>
          <label htmlFor='reNuevoPass'>{i18n.t('usuarios.urepcont')}</label>
          <input
            type='password'
            name='reNuevoPass'
            onChange={e => {
              e.preventDefault()
              setReNuevoPass(e.target.value)
            }}
            value={reNuevoPass}
            className={errorIguales || errorVacios ? 'form-control error' : 'form-control'}
            id='reNuevoPass'
            placeholder={i18n.t('usuarios.urecontm')}
            required
          />
          <p>{i18n.t('usuarios.ureqmin')}</p>
        </div>
        <div className='row'>
          <div className='col-auto'>
            <div className='form-group'>
              <button
                className='btn btn-border'
                type='submit'
                onClick={handleSubmit}>
                {i18n.t('usuarios.ucambiar')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    formulario = (
      <div className='group-form'>
        <div className='form-group'>
          <input
            className='form-control'
            type='text'
            name='userName'
            onChange={e => {
              e.preventDefault()
              setUserName(e.target.value)
            }}
            value={userName}
            placeholder='Usuario'
          />
        </div>
        <button
          type='submit'
          name='recuperar'
          className='btn btn-primary'
          onClick={handleSubmit}>
          {i18n.t('usuarios.uenvi')}
        </button>
        <Link
          to='/'
          className='btn btn-danger ml-3'>
          {i18n.t('usuarios.ucanc')}
        </Link>
        <p>
          {i18n.t('usuarios.unocuent')} <Link to='/register'>{i18n.t('usuarios.uforregi')}</Link>
        </p>
      </div>
    )
  }

  return (
    <div
      id='tt-pageContent'
      className='recuperar-contrasena'>
      <div className='container-indent'>
        <div className='container'>
          <a
            className='tt-logo'
            href='index.html'>
            <img
              className='tt-retina'
              src='images/custom/logo.png'
              alt=''
            />
          </a>
          <h1 className='tt-title-subpages noborder'>{portal.descripcion}</h1>
          <div className='tt-login-form'>
            <div className='row justify-content-center'>
              <div className='col-md-5'>
                <div className='tt-item'>
                  <h2 className='tt-title'>{i18n.t('usuarios.ureccon')}</h2>
                  <div className='form-default justify-content-center'>
                    {token ? (
                      <p>{i18n.t('usuarios.uinnueco')}</p>
                    ) : (
                      <p>{i18n.t('usuarios.uparecon')}</p>
                    )}

                    {formulario}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Recuperar
