import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTipoExpediente } from '../../actions/expedientes'

const ExpedientesMenu = () => {
  const dispatch = useDispatch()

  const tipos_expedientes = useSelector(state => state.expedientes.tipos_expedientes)
  const tipo_expediente_seleccionado = useSelector(
    state => state.expedientes.tipo_expediente_seleccionado,
  )

  useEffect(() => {
    return () => {
      dispatch(setTipoExpediente(''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (tipos_expedientes?.length > 0 && tipo_expediente_seleccionado === '') {
      dispatch(setTipoExpediente('0'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipos_expedientes])

  const handleTipoExpediente = e => {
    e.preventDefault()
    if (typeof e.target.dataset.expediente !== 'undefined') {
      dispatch(setTipoExpediente(e.target.dataset.expediente))
    }
  }

  return (
    <Fragment>
      <header className='menu-expedientes'>
        <div className='esquema-color'>
          <div className='container'>
            <nav>
              <ul className='listado_tipos_expedientes'>
                {tipos_expedientes?.length > 0
                  ? tipos_expedientes.map((expediente, index) => {
                      return (
                        <li
                          key={index}
                          className={'item-' + index}>
                          <a
                            href='/#'
                            data-expediente={index}
                            onClick={handleTipoExpediente}
                            style={
                              tipo_expediente_seleccionado === index.toString()
                                ? { fontWeight: 'bold' }
                                : null
                            }>
                            {expediente.nombre.toUpperCase()}
                          </a>
                        </li>
                      )
                    })
                  : ''}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </Fragment>
  )
}

export default ExpedientesMenu
