import {
  ARCHIVE_CONVERSATION,
  ARCHIVE_CONVERSATION_FAIL,
  ARCHIVE_CONVERSATION_SUCCESS,
  CERRAR_EXPEDIENTE,
  CERRAR_EXPEDIENTE_FAIL,
  CERRAR_EXPEDIENTE_SUCCESS,
  DELETE_CONVERSATION,
  DELETE_CONVERSATION_FAIL,
  DELETE_CONVERSATION_SUCCESS,
  DESCARGAR_FICHERO_EXPEDIENTE,
  FETCH_TIPOS_EXPEDIENTES,
  FETCH_TIPOS_EXPEDIENTES_CAMPOS,
  GET_ARCHIVED_CONVERSATIONS,
  GET_ARCHIVED_CONVERSATIONS_FAIL,
  GET_ARCHIVED_CONVERSATIONS_SUCCESS,
  GET_DATOS_EXPEDIENTE,
  GET_DATOS_EXPEDIENTE_FAIL,
  GET_DATOS_EXPEDIENTE_SUCCESS,
  GET_DATOS_FICHEROS_EXPEDIENTE,
  GET_DATOS_FICHEROS_EXPEDIENTE_FAIL,
  GET_DATOS_FICHEROS_EXPEDIENTE_SUCCESS,
  GET_DELETED_CONVERSATIONS,
  GET_DELETED_CONVERSATIONS_FAIL,
  GET_DELETED_CONVERSATIONS_SUCCESS,
  GET_EXPEDIENTES,
  GET_EXPEDIENTES_FAIL,
  GET_EXPEDIENTES_SUCCESS,
  GET_LINEAS_EXPEDIENTE,
  GET_LINEAS_EXPEDIENTE_FAIL,
  GET_LINEAS_EXPEDIENTE_SUCCESS,
  GET_LOV_EXPEDIENTE,
  GET_LOV_EXPEDIENTE_FAIL,
  GET_LOV_EXPEDIENTE_SUCCESS,
  GET_MAS_EXPEDIENTES,
  GET_MAS_EXPEDIENTES_FAIL,
  GET_OBSERVACIONES_EXPEDIENTE,
  GET_OBSERVACIONES_EXPEDIENTE_FAIL,
  GET_OBSERVACIONES_EXPEDIENTE_SUCCESS,
  GET_TIPOS_EXPEDIENTES,
  GET_TIPOS_EXPEDIENTES_CAMPOS,
  GET_TIPOS_EXPEDIENTES_CAMPOS_FAIL,
  GET_TIPOS_EXPEDIENTES_FAIL,
  LIMPIAR_FILTROS_EXPEDIENTES,
  MARCAR_LEIDOS,
  MARCAR_LEIDOS_FAIL,
  MARCAR_LEIDOS_SUCCESS,
  NEW_EXPEDIENTE,
  NEW_EXPEDIENTE_FAIL,
  NEW_EXPEDIENTE_SUCCESS,
  OBTENER_FICHERO_EXPEDIENTE,
  OBTENER_FICHERO_EXPEDIENTE_FAIL,
  REINICIA_LV,
  SEND_EXPEDIENTE,
  SEND_EXPEDIENTE_FAIL,
  SEND_EXPEDIENTE_SUCCESS,
  SEND_OBSERVACIONES_EXPEDIENTE,
  SEND_OBSERVACIONES_EXPEDIENTE_FAIL,
  SEND_OBSERVACIONES_EXPEDIENTE_SUCCESS,
  SET_EXPEDIENTE,
  SET_MOSTRAR_EXPEDIENTES,
  SET_ORDEN_EXPEDIENTES,
  SET_TIPO_EXPEDIENTE,
  SET_VALOR_CAMPO_EXPEDIENTE,
  VALIDAR_CAMPO,
  VALIDAR_CAMPO_ERROR,
  VALIDAR_CAMPO_SUCCESS,
} from '../actions/types'

const initialState = {
  tipos_expedientes: [],
  tipos_expedientes_campos: [],
  tipo_expediente_seleccionado: '',
  expediente_seleccionado: '',
  expedientes: [],
  archived_conversations: [],
  deleted_conversations: [],
  observaciones_expediente: [],
  lineas_expediente: [],
  valores_lovs: [],
  mostrar: 12,
  inicio: 0,
  isLoading: false,
  isValidating: false,
}

export default function reducer(state = initialState, action) {
  let lista_valores_lovs
  let nuevos_expedientes = []
  let index
  switch (action.type) {
    case REINICIA_LV:
      let nuevas_lovs = state.valores_lovs

      let lov_actualizada = nuevas_lovs.filter(
        lov =>
          lov.tipo_expediente === action.payload.expediente &&
          lov.codigo_lov === action.payload.codigo_lov &&
          lov.campo === action.payload.campo,
      )[0]

      if (lov_actualizada) {
        lov_actualizada.data = []
      }

      return {
        ...state,
        valores_lovs: nuevas_lovs,
      }

    case VALIDAR_CAMPO:
      return {
        ...state,
        isValidating: true,
      }

    case VALIDAR_CAMPO_ERROR:
      return {
        ...state,
        isValidating: false,
      }

    case VALIDAR_CAMPO_SUCCESS:
      let nuevos_campos = state.tipos_expedientes_campos

      if (action.payload && action.payload[0] && action.payload[0]?.campos) {
        for (let aux of nuevos_campos) {
          if (aux.columna.toLowerCase() in action.payload[0]?.campos) {
            aux.valor = action.payload[0]?.campos['' + aux.columna.toLowerCase() + ''].valor
          }
        }
      }
      return {
        ...state,
        tipos_expedientes_campos: nuevos_campos,
        isValidating: false,
      }

    case SET_VALOR_CAMPO_EXPEDIENTE:
      let nuevo_tipos_expedientes_campos = state.tipos_expedientes_campos

      index = -1

      index = nuevo_tipos_expedientes_campos.findIndex(
        campo => campo.columna === action.payload.campo,
      )

      if (index !== -1) {
        nuevo_tipos_expedientes_campos[index].valor = action.payload.valor
        if (action.payload.d_valor) {
          nuevo_tipos_expedientes_campos[index].d_valor = action.payload.d_valor
        }
      }

      return {
        ...state,
        tipos_expedientes_campos: nuevo_tipos_expedientes_campos,
      }

    case LIMPIAR_FILTROS_EXPEDIENTES:
      return {
        tipos_expedientes: [],
        tipos_expedientes_campos: [],
        tipo_expediente_seleccionado: '',
        expediente_seleccionado: '',
        expedientes: [],
        archived_conversations: [],
        deleted_conversations: [],
        observaciones_expediente: [],
        lineas_expediente: [],
        valores_lovs: [],
        mostrar: 12,
        inicio: 0,
        orden: 'num_des',
        isLoading: false,
      }

    case GET_DATOS_FICHEROS_EXPEDIENTE:
      return {
        ...state,
        lineas_expediente: [],
        isLoading: true,
      }

    case GET_DATOS_FICHEROS_EXPEDIENTE_SUCCESS:
      return {
        ...state,
        lineas_expediente: action.payload.expediente,
        isLoading: false,
      }

    case DESCARGAR_FICHERO_EXPEDIENTE:
    case GET_TIPOS_EXPEDIENTES_FAIL:
    case GET_EXPEDIENTES_FAIL:
    case OBTENER_FICHERO_EXPEDIENTE_FAIL:
    case GET_DATOS_FICHEROS_EXPEDIENTE_FAIL:
    case GET_MAS_EXPEDIENTES_FAIL:
    case CERRAR_EXPEDIENTE_FAIL:
    case GET_TIPOS_EXPEDIENTES_CAMPOS_FAIL:
    case NEW_EXPEDIENTE_FAIL:
    case SEND_EXPEDIENTE_FAIL:
    case GET_LINEAS_EXPEDIENTE_FAIL:
    case GET_OBSERVACIONES_EXPEDIENTE_FAIL:
    case SEND_OBSERVACIONES_EXPEDIENTE_FAIL:
    case MARCAR_LEIDOS_FAIL:
    case DELETE_CONVERSATION_FAIL:
    case ARCHIVE_CONVERSATION_FAIL:
    case GET_ARCHIVED_CONVERSATIONS_FAIL:
    case GET_DELETED_CONVERSATIONS_FAIL:
    case GET_DATOS_EXPEDIENTE_FAIL:
      return {
        ...state,
        isLoading: false,
      }

    case GET_MAS_EXPEDIENTES:
    case OBTENER_FICHERO_EXPEDIENTE:
    case CERRAR_EXPEDIENTE:
    case ARCHIVE_CONVERSATION:
    case GET_ARCHIVED_CONVERSATIONS:
    case GET_DELETED_CONVERSATIONS:
    case MARCAR_LEIDOS:
    case DELETE_CONVERSATION:
    case NEW_EXPEDIENTE:
    case GET_DATOS_EXPEDIENTE:
      return {
        ...state,
        isLoading: true,
      }

    case SET_ORDEN_EXPEDIENTES:
      return {
        ...state,
        orden: action.payload,
      }

    case SET_MOSTRAR_EXPEDIENTES:
      return {
        ...state,
        mostrar: action.payload,
        inicio: 0,
      }

    case FETCH_TIPOS_EXPEDIENTES:
      return {
        ...state,
        isLoading: true,
        tipos_expedientes: [],
      }

    case CERRAR_EXPEDIENTE_SUCCESS:
      let expedientes_nuevo = state.expedientes

      index = -1
      index = expedientes_nuevo.findIndex(
        expediente =>
          expediente.numero_expediente === parseInt(action.payload.expediente.numero_expediente) &&
          expediente.tipo_expediente === action.payload.expediente.tipo_expediente,
      )

      expedientes_nuevo[index].status = action.payload.expediente.status
      expedientes_nuevo[index].usuario_cierre = action.payload.expediente.usuario_cierre
      return {
        ...state,
        isLoading: false,
        expedientes: expedientes_nuevo,
      }

    case GET_DATOS_EXPEDIENTE_SUCCESS:
      let expedientes_nuevos = state.expedientes

      index = -1
      index = expedientes_nuevos.findIndex(
        expediente =>
          expediente.numero_expediente === action.numero_expediente &&
          expediente.tipo_expediente === action.tipo_expediente,
      )

      expedientes_nuevos[index].campos = action.payload.campos

      return {
        ...state,
        isLoading: false,
        expedientes: expedientes_nuevos,
      }

    case SET_TIPO_EXPEDIENTE:
      let aux_expedientes

      if (state.tipo_expediente_seleccionado !== action.payload) {
        aux_expedientes = []
      } else {
        aux_expedientes = state.expedientes
      }
      return {
        ...state,
        tipo_expediente_seleccionado: action.payload,
        expediente_seleccionado: '',
        expedientes: aux_expedientes,
      }

    case SET_EXPEDIENTE:
      return {
        ...state,
        expediente_seleccionado: action.payload,
      }

    case GET_TIPOS_EXPEDIENTES:
      return {
        ...state,
        isLoading: false,
        tipos_expedientes: action.payload.expedientes,
      }

    case FETCH_TIPOS_EXPEDIENTES_CAMPOS:
      return {
        ...state,
        isLoading: true,
        tipos_expedientes_campos: [],
      }

    case GET_TIPOS_EXPEDIENTES_CAMPOS:
      action.payload.expediente.campos.forEach(campo => {
        campo.orden = Number(campo.orden)
      })

      return {
        ...state,
        isLoading: false,
        tipos_expedientes_campos: action.payload.expediente.campos,
      }

    case GET_EXPEDIENTES:
      return {
        ...state,
        isLoading: true,
        inicio: 0,
      }

    case GET_EXPEDIENTES_SUCCESS:
      let aux_conversion
      if (action.inicio) {
        nuevos_expedientes = state.expedientes
      }

      for (let exp of action.payload.expedientes) {
        aux_conversion = Number(exp.numero_expediente)
        exp.numero_expediente = aux_conversion
        nuevos_expedientes.push(exp)
      }

      return {
        ...state,
        isLoading: false,
        expedientes: nuevos_expedientes,
        inicio: action.inicio ? action.inicio : state.inicio,
      }

    case NEW_EXPEDIENTE_SUCCESS:
      //Recuperamos campos del expediente a insertar en el state
      let campos = state.tipos_expedientes_campos

      //Seteamos valores nuevo expediente
      let nuevo_expediente = {
        tipo_expediente: action.expediente.tipo_expediente,
        numero_expediente: action.payload.numero_expediente,
        status: '0300',
        fecha_alta: action.payload.fecha_alta,
        usuario_cierre: null,
        tiene_ficheros: action.expediente?.ficheros?.length > 0 ? 'S' : 'N',
        campos: campos,
      }

      if (state.orden === 'num_des' || state.orden === 'fec_des') {
        //Añadimos el nuevo expediente al inicio
        nuevos_expedientes.push(nuevo_expediente)
        for (let expediente of state.expedientes) {
          nuevos_expedientes.push(expediente)
        }
      } else {
        //Añadimos el nuevo expediente al final
        nuevos_expedientes = state.expedientes
        nuevos_expedientes.push(nuevo_expediente)
      }

      return {
        ...state,
        isLoading: false,
        tipos_expedientes_campos: [],
        expedientes: nuevos_expedientes || state.expedientes,
      }

    //Nuevos de la App
    case SEND_EXPEDIENTE:
      return { ...state, loading: true }

    case SEND_EXPEDIENTE_SUCCESS:
      return {
        ...state,
        loading: false,
        expedientes: [...state.expedientes, action.payload.expediente],
      }

    case GET_LINEAS_EXPEDIENTE:
      return { ...state, lineas_expediente: [], loading: true }

    case GET_LINEAS_EXPEDIENTE_SUCCESS:
      return {
        ...state,
        lineas_expediente: action.payload.lineas_expediente,
        loading: false,
      }
    case GET_OBSERVACIONES_EXPEDIENTE:
      return { ...state, observaciones_expediente: [], loading: true }

    case GET_OBSERVACIONES_EXPEDIENTE_SUCCESS:
      return {
        ...state,
        observaciones_expediente: action.payload,
        loading: false,
      }
    case SEND_OBSERVACIONES_EXPEDIENTE:
      return { ...state, loading: true }

    case SEND_OBSERVACIONES_EXPEDIENTE_SUCCESS:
      return {
        ...state,
        observaciones_expediente: [
          ...state.observaciones_expediente,
          action.payload.observacion_expediente,
        ],
        loading: false,
      }

    case MARCAR_LEIDOS_SUCCESS:
      return { ...state }

    case ARCHIVE_CONVERSATION_SUCCESS:
    case DELETE_CONVERSATION_SUCCESS:
      let exp = state.expedientes.filter(
        expediente =>
          Number.parseInt(expediente.numero_expediente) !==
          Number.parseInt(action.payload.numero_expediente),
      )
      return {
        ...state,
        isLoading: false,
        expedientes: exp,
      }

    case GET_ARCHIVED_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        archived_conversations: action.payload,
      }

    case GET_DELETED_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted_conversations: action.payload,
      }

    case GET_LOV_EXPEDIENTE:
      lista_valores_lovs = state.valores_lovs.filter(lov_expediente => {
        return (
          lov_expediente.tipo_expediente !== action.tipo_expediente ||
          lov_expediente.codigo_lov !== action.codigo_lov ||
          lov_expediente.campo !== action.campo
        )
      })

      lista_valores_lovs.push({
        tipo_expediente: action.tipo_expediente,
        codigo_lov: action.codigo_lov,
        campo: action.campo,
        data: [],
        loading: true,
      })
      return {
        ...state,
        valores_lovs: lista_valores_lovs,
      }

    case GET_LOV_EXPEDIENTE_SUCCESS:
      lista_valores_lovs = state.valores_lovs.filter(lov_expediente => {
        return (
          lov_expediente.tipo_expediente !== action.tipo_expediente ||
          lov_expediente.codigo_lov !== action.codigo_lov ||
          lov_expediente.campo !== action.campo
        )
      })
      lista_valores_lovs.push({
        tipo_expediente: action.tipo_expediente,
        codigo_lov: action.codigo_lov,
        campo: action.campo,
        data: action.payload.lov,
        loading: false,
      })
      return {
        ...state,
        valores_lovs: lista_valores_lovs,
      }

    case GET_LOV_EXPEDIENTE_FAIL:
      lista_valores_lovs = state.valores_lovs.filter(lov_expediente => {
        return (
          (lov_expediente.tipo_expediente !== action.tipo_expediente &&
            lov_expediente.codigo_lov !== action.codigo_lov) ||
          (lov_expediente.tipo_expediente === action.tipo_expediente &&
            lov_expediente.codigo_lov !== action.codigo_lov) ||
          (lov_expediente.tipo_expediente !== action.tipo_expediente &&
            lov_expediente.codigo_lov === action.codigo_lov)
        )
      })
      lista_valores_lovs.push({
        tipo_expediente: action.tipo_expediente,
        codigo_lov: action.codigo_lov,
        data: [],
        loading: false,
      })
      return {
        ...state,
        valores_lovs: lista_valores_lovs,
      }

    default:
      return state
  }
}
