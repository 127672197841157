import { Trash } from 'phosphor-react'
import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setCarnetProfesional } from '../../actions/modalGenerica'
import {
  deleteLinea,
  getPedidoActivo,
  nuevoPedido,
  setIdPedidoSeleccionado,
} from '../../actions/pedidos'
import { cargarParametros, dynamicSort } from '../../helpers/funciones'
import { creaURLArticulo } from '../../helpers/urls/articulos'
import i18n from '../../lang/idiomas'
import Spinner from '../layout/Spinner'
import ArticuloNecesidades from './ArticuloNecesidades'
import Imagen from './Imagen'
import Precio from './Precio'

let parametros_usados = ['mostrar_iva', 'maxim_agrup']
let parametros

const Carrito = () => {
  const dispatch = useDispatch()

  const portal = useSelector(state => state.portal.portal)
  const pedido = useSelector(state => state.pedidos.pedido)
  const isLoading = useSelector(state => state.pedidos.isLoading)
  const isLoadingLines = useSelector(state => state.pedidos.isLoadingLines)
  const hash = useSelector(state => state.auth.hash)
  const invitado = useSelector(state => state.auth.invitado)
  const locale = useSelector(state => state.precios.locale)
  const moneda = useSelector(state => state.precios.moneda)
  const id_pedido_seleccionado = useSelector(state => state.pedidos.id_pedido_seleccionado)
  const user = useSelector(state => state.auth.user)

  parametros = cargarParametros(parametros_usados, portal.parametros_ecom, 'CAR')

  useEffect(() => {
    document.body.style.cursor = isLoading || isLoadingLines ? 'wait' : 'default'
  }, [isLoading, isLoadingLines])

  const onClick = e => {
    if (!isLoading) {
      e.preventDefault()
      // this.props.deleteLinea(e.currentTarget.dataset.linea, this.props.hash, this.props.id_pedido_seleccionado);
      eliminarLineaN(e.currentTarget.dataset.linea)
    }
  }

  const eliminarLineaN = linea => {
    let observaciones = ''
    let oferta = ''
    pedido.lineas.forEach(lineas => {
      if (parseInt(lineas.id) === parseInt(linea)) {
        observaciones = lineas.observaciones !== undefined ? lineas.observaciones : 'No tiene id'
        if (observaciones.startsWith('id_oferta')) {
          oferta = observaciones.substring(10)
        } else {
          oferta = '...'
        }
      }
    })
    if (oferta !== '...') {
      pedido.lineas.forEach(lineas => {
        observaciones = lineas.observaciones !== undefined ? lineas.observaciones : 'No tiene id'
        if (observaciones.substring(10) === oferta) {
          dispatch(deleteLinea(lineas.id, hash, id_pedido_seleccionado || pedido.id_pedido))
        }
      })
    } else {
      dispatch(deleteLinea(linea, hash, id_pedido_seleccionado || pedido.id_pedido))
    }
  }

  const ocultarCarrito = e => {
    if (document.getElementsByClassName('tt-dropdown-obj active').length > 0) {
      document
        .getElementsByClassName('tt-dropdown-obj active')[0]
        .getElementsByClassName('tt-dropdown-menu')[0].style = ''

      document.getElementsByClassName('tt-dropdown-obj active')[0].classList.remove('active')
    }
    if (document.getElementsByTagName('body').length > 0) {
      document.getElementsByTagName('body')[0].classList.remove('tt-popup-dropdown')
    }
  }

  const handleNuevoPedido = e => {
    e.preventDefault()

    dispatch(setIdPedidoSeleccionado(''))
    dispatch(nuevoPedido())
    dispatch(getPedidoActivo('', 'N'))
  }

  const cargarPrecio = linea => {
    if (linea.tipo_linea !== 'R') {
      return (
        <span>
          <Precio
            codigo_articulo={linea.articulo}
            presentacion={linea.presentacion_escogida || linea.presentacion_pedido}
            precio_manual={linea.importe_neto_lin}
            mostrar_iva={parametros.mostrar_iva}
            mostrar_pres={linea.precio_manual === 'S' ? 'N' : parametros.mostrar_pres}
            estilo_precio={parametros.estilo_precio}
          />
        </span>
      )
    } else {
      return (
        <span>
          <Precio
            codigo_articulo={linea.articulo}
            precio_manual={0}
            mostrar_iva={parametros.mostrar_iva}
          />
        </span>
      )
    }
  }

  const cargarLineasPedido = () => {
    return (
      <div className='info-pedido'>
        <div>
          <h3 className='titulo-carrito'>{i18n.t('general.pedido')}</h3>
        </div>
        <div className='tt-cart-list'>
          {!pedido?.lineas || pedido?.lineas?.length === 0
            ? ''
            : pedido.lineas
                .sort(dynamicSort('articulo'))
                .filter(linea => {
                  if (linea.tipo_linea === 'P') {
                    return true
                  } else if (linea.tipo_linea === 'R') {
                    return pedido.lineas.some(
                      linea_padre =>
                        linea_padre.numero_linea === linea.numero_linea_origen &&
                        linea_padre.tipo_linea === 'P',
                    )
                  } else return false
                })
                .map(linea => (
                  <div
                    className='tt-item'
                    key={linea.id}>
                    {
                      <Fragment>
                        <div className='tt-item-img'>
                          {linea.tipo_articulo !== 'F' ? (
                            <Link
                              to={creaURLArticulo(
                                linea?.datos_articulo?.cod_padre || linea.articulo,
                                linea?.descripcion,
                              )}>
                              <Imagen
                                codigo_articulo={linea.articulo}
                                principal='S'
                                numero_imagen={1}
                                tipo_imagen='min'
                                src_manual={linea?.datos_articulo?.reservado_alfa_2 || null}
                              />
                            </Link>
                          ) : (
                            <Imagen
                              codigo_articulo={linea.articulo}
                              principal='S'
                              numero_imagen={1}
                              tipo_imagen='min'
                              src_manual={linea?.datos_articulo?.reservado_alfa_2 || null}
                            />
                          )}
                        </div>
                        <div className='tt-item-descriptions'>
                          <div className='tt-base row'>
                            <h2 className='tt-title'>
                              {linea.tipo_articulo !== 'F' ? (
                                <Link
                                  to={creaURLArticulo(
                                    linea?.datos_articulo?.cod_padre || linea.articulo,
                                    linea?.descripcion,
                                  )}>
                                  {linea.articulo}
                                </Link>
                              ) : (
                                linea.articulo
                              )}
                            </h2>

                            {linea.tipo_articulo !== 'R' &&
                            linea.tipo_articulo !== 'T' &&
                            linea.tipo_articulo !== 'F' ? (
                              <div className='tt-item-close'>
                                <button
                                  onClick={onClick}
                                  data-linea={linea.id}
                                  className='btn-borrar-linea'>
                                  <Trash size={16} />
                                  <span className='ocultar'>{i18n.t('general.cerrar')}</span>
                                </button>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className='tt-add-info row'>
                            {linea.tipo_articulo !== 'F' ? (
                              <Link
                                to={creaURLArticulo(
                                  linea?.datos_articulo?.cod_padre || linea.articulo,
                                  linea?.descripcion,
                                )}>
                                <span>{linea.descripcion}</span>
                              </Link>
                            ) : (
                              <span>{linea.descripcion}</span>
                            )}
                          </div>
                          <div className='tt-inf-qty-price row'>
                            <div className='col-4 info-articulo'>
                              {linea?.datos_articulo ? (
                                <ArticuloNecesidades
                                  articulo={linea.datos_articulo}
                                  origen='resumen'
                                  carnets={user?.carnets || []}
                                  setCarnetProfesional={dispatch(setCarnetProfesional)}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                            <div className='col-8 qty-price'>
                              <div className='tt-quantity'>
                                {linea.sub_pres
                                  ? linea.sub_pres_cant + ' ' + linea.sub_pres + ' '
                                  : parseFloat(linea.cantidad_pedida).toFixed(0) +
                                    ' ' +
                                    linea.presentacion_pedido +
                                    ' '}
                              </div>
                              <div className='tt-price'>{cargarPrecio(linea)}</div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    }
                  </div>
                ))}
          {isLoading ? <Spinner showSpinner={isLoading} /> : ''}
        </div>
        {user?.ver_precios === 'S' ? (
          <>
            <div className='tt-cart-total-row'>
              <div className='tt-cart-total-title'>
                {i18n.t('general.total').toUpperCase() + ' :'}
              </div>
              <div className='tt-cart-total-price'>
                {!pedido?.lineas || pedido?.lineas?.length === 0
                  ? 0
                  : Intl.NumberFormat(locale, {
                      style: 'currency',
                      currency: moneda,
                    }).format(
                      Number.parseFloat(
                        pedido.lineas
                          .filter(linea => linea.tipo_linea === 'P')
                          .map(linea => {
                            return Number.parseFloat(linea.importe_neto_lin)
                          })
                          .reduce((accumulator, currentValue) => accumulator + currentValue),
                      ),
                    )}
              </div>
            </div>
            {parametros?.mostrar_iva === 'S' ? (
              <div className='tt-cart-total-row'>
                <div className='tt-cart-total-title'>
                  {i18n.t('general.total').toUpperCase() +
                    ' + ' +
                    i18n.t('general.impuesto') +
                    ' :'}
                </div>
                <div className='tt-cart-total-price'>
                  {!pedido?.lineas || pedido?.lineas?.length === 0
                    ? 0
                    : new Intl.NumberFormat(locale, {
                        style: 'currency',
                        currency: moneda,
                      }).format(
                        pedido.lineas
                          .filter(linea => linea.tipo_linea === 'P')
                          .map(linea => {
                            return Number.parseFloat(linea.importe_neto_lin_impuestos)
                          })
                          .reduce((accumulator, currentValue) => {
                            return accumulator + currentValue
                          }),
                      )}
                </div>
              </div>
            ) : null}{' '}
          </>
        ) : null}
      </div>
    )
  }

  const cargarLineasPresupuesto = () => {
    return (
      <div className='info-presupuesto'>
        <div>
          <h3 className='titulo-carrito'>{i18n.t('general.presup')}</h3>
        </div>
        <div className='tt-cart-list'>
          {pedido.lineas === undefined || pedido.lineas.length === 0
            ? ''
            : pedido.lineas
                .sort(dynamicSort('articulo'))
                .filter(linea => {
                  if (linea.tipo_linea === 'O') {
                    return true
                  } else if (linea.tipo_linea === 'R') {
                    return pedido.lineas.some(
                      linea_padre =>
                        linea_padre.numero_linea === linea.numero_linea_origen &&
                        linea_padre.tipo_linea === 'O',
                    )
                  } else return false
                })
                .map(linea => (
                  <div
                    className='tt-item'
                    key={linea.id}>
                    {
                      <Fragment>
                        {linea.tipo_articulo !== 'F' ? (
                          <div className='tt-item-img'>
                            <Link
                              to={creaURLArticulo(
                                linea?.datos_articulo?.cod_padre || linea.articulo,
                                linea?.descripcion,
                              )}>
                              <Imagen
                                codigo_articulo={linea.articulo}
                                principal='S'
                                numero_imagen={1}
                                tipo_imagen='min'
                                src_manual={linea?.datos_articulo?.reservado_alfa_2 || null}
                              />
                            </Link>
                          </div>
                        ) : (
                          <div className='tt-item-img'>
                            <Imagen
                              codigo_articulo={linea.articulo}
                              principal='S'
                              numero_imagen={1}
                              tipo_imagen='min'
                              src_manual={linea?.datos_articulo?.reservado_alfa_2 || null}
                            />
                          </div>
                        )}
                        <div className='tt-item-descriptions'>
                          <div className='tt-base row'>
                            <h2 className='tt-title'>
                              {linea.tipo_articulo !== 'F' ? (
                                <Link
                                  to={creaURLArticulo(
                                    linea?.datos_articulo?.cod_padre || linea.articulo,
                                    linea?.descripcion,
                                  )}>
                                  {linea.articulo}
                                </Link>
                              ) : (
                                linea.articulo
                              )}
                            </h2>

                            {linea.tipo_articulo !== 'R' &&
                            linea.tipo_articulo !== 'T' &&
                            linea.tipo_articulo !== 'F' ? (
                              <div className='tt-item-close'>
                                <button
                                  onClick={onClick}
                                  data-linea={linea.id}
                                  className='btn-borrar-linea'>
                                  <Trash size={16} />
                                  <span className='ocultar'>{i18n.t('general.cerrar')}</span>
                                </button>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className='tt-add-info row'>
                            {linea.tipo_articulo !== 'F' ? (
                              <Link
                                to={creaURLArticulo(
                                  linea?.datos_articulo?.cod_padre || linea.articulo,
                                  linea?.descripcion,
                                )}>
                                <span>{linea.descripcion}</span>
                              </Link>
                            ) : (
                              <span>{linea.descripcion}</span>
                            )}
                          </div>
                          <div className='tt-inf-qty-price row'>
                            <div className='col-4 info-articulo'>
                              {linea?.datos_articulo ? (
                                <ArticuloNecesidades
                                  articulo={linea.datos_articulo}
                                  origen='resumen'
                                  carnets={user?.carnets || []}
                                  setCarnetProfesional={dispatch(setCarnetProfesional)}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                            <div className='col-8 qty-price'>
                              <div className='tt-quantity'>
                                {linea.sub_pres
                                  ? linea.sub_pres_cant + ' ' + linea.sub_pres + ' '
                                  : parseFloat(linea.cantidad_pedida).toFixed(0) +
                                    ' ' +
                                    linea.presentacion_pedido +
                                    ' '}
                              </div>
                              <div className='tt-price'>{cargarPrecio(linea)}</div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    }
                  </div>
                ))}
          {isLoading ? <Spinner showSpinner={isLoading} /> : ''}
        </div>
      </div>
    )
  }

  return (
    <div className='tt-dropdown-menu'>
      <div className='tt-mobile-add'>
        <h6 className='tt-title'>{i18n.t('general.carrito').toUpperCase()}</h6>
        <button className='tt-close'>{i18n.t('general.cerrar')}</button>
      </div>
      <div className='tt-dropdown-inner'>
        <div className='tt-cart-layout'>
          <div className='tt-cart-content'>
            <div className='info-ped-pres'>
              {pedido?.codigo_plantilla && pedido?.lineas?.length > 0 ? (
                <span className='template'>{pedido.nombre_plantilla}</span>
              ) : (
                <></>
              )}
              {pedido?.lineas?.length > 0 && pedido.lineas.some(linea => linea.tipo_linea === 'P')
                ? cargarLineasPedido()
                : ''}

              {pedido?.lineas?.length > 0 && pedido.lineas.some(linea => linea.tipo_linea === 'O')
                ? cargarLineasPresupuesto()
                : ''}
            </div>
            <div className='tt-cart-btn'>
              <div className='tt-item'>
                {!pedido?.lineas || pedido?.lineas?.length === 0 ? (
                  i18n.t('carrito.crrnoitm').toUpperCase()
                ) : (
                  <Fragment>
                    {invitado ? (
                      <Link
                        to='/login'
                        className='btn'
                        onClick={ocultarCarrito}>
                        {i18n.t('login.login').toUpperCase()}
                      </Link>
                    ) : (
                      <Fragment>
                        {!isLoading && !isLoadingLines ? (
                          <Link
                            to='/resumen'
                            className='btn'
                            onClick={ocultarCarrito}>
                            {i18n.t('carrito.crrfinal').toUpperCase()}
                          </Link>
                        ) : (
                          <Link
                            to='/resumen'
                            className='btn disabled'
                            onClick={ocultarCarrito}>
                            {i18n.t('carrito.crrfinal').toUpperCase()}
                          </Link>
                        )}

                        <button
                          type='submit'
                          onClick={handleNuevoPedido}
                          className='btn btn-primary btn-nuevo-pedido'>
                          {i18n.t('carrito.crrnew').toUpperCase()}
                        </button>
                      </Fragment>
                    )}
                  </Fragment>
                )}
                {(!pedido?.lineas || pedido?.lineas?.length === 0) && pedido.codigo_plantilla ? (
                  <>
                    &nbsp;CREADO DESDE PLANTILLA. CREE UNO NUEVO.
                    <button
                      type='submit'
                      onClick={handleNuevoPedido}
                      className='btn btn-primary btn-nuevo-pedido'>
                      {i18n.t('carrito.crrnew').toUpperCase()}
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Carrito
