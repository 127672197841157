export const retrieveDefaultPresentation = (
  portal,
  usuario,
  presentacion_base,
  es_perfil = 'N',
) => {
  let presentacionDefecto
  if (es_perfil === 'S') {
    if (usuario.ex_presentacion_defecto) {
      presentacionDefecto = usuario.ex_presentacion_defecto
    } else if (portal.ex_presentacion_defecto) {
      presentacionDefecto = portal.ex_presentacion_defecto
    }
  } else if (usuario.presentacion_defecto) {
    presentacionDefecto = usuario.presentacion_defecto
  } else if (portal.presentacion_defecto) {
    presentacionDefecto = portal.presentacion_defecto
  }

  if (!presentacionDefecto) {
    presentacionDefecto = presentacion_base
  }

  return presentacionDefecto
}
