import React, { Component } from 'react'
import { connect } from 'react-redux'
import ListadoArticulos from './ListadoArticulos'

export class Novedades extends Component {
  render() {
    return (
      <div className='container-indent'>
        <div className='container-fluid-custom container-fluid-custom-mobile-padding'>
          <div className='row'>
            <div className='col-md-12 col-lg-12 col-xl-12'>
              <ListadoArticulos
                mostrar='lista'
                origen='novedades'
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps)(Novedades)
