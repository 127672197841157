import { CHANGE_LANGUAGE, FETCH_LANGUAGES, GET_LANGUAGES } from "../actions/types.js";

const userLang = navigator.language || navigator.userLanguage;
const defaultLocale = userLang.substring(0, 2);

const initialState = {
  language: defaultLocale,
  isLoading: false,
  languages: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case FETCH_LANGUAGES:
      return {
        ...state,
        isLoading: true,
      };
    case GET_LANGUAGES:
      return {
        ...state,
        isLoading: false,
        languages: action.payload,
      };
    default:
      return state;
  }
}
