export const esMultiplo = (valor, multiplo) => {
  return valor % multiplo === 0;
};

export const multiploMasCercano = (valor, multiplo) => {
  if (esMultiplo(valor, multiplo)) {
    return valor;
  } else {
    // Calcula el múltiplo más cercano inferior y superior
    const multiploInferior = Math.floor(valor / multiplo) * multiplo;
    const multiploSuperior = Math.ceil(valor / multiplo) * multiplo;

    // Devuelve el múltiplo más cercano en función de la distancia
    if (Math.abs(valor - multiploInferior) < Math.abs(valor - multiploSuperior)) {
      return multiploInferior;
    } else {
      return multiploSuperior;
    }
  }
};
