import { FilePlus, Paperclip } from 'phosphor-react'
import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  mostrarMasExpedientes,
  setExpediente,
  setMostrarExpedientes,
  setOrdenExpedientes,
} from '../../actions/expedientes'
import { dynamicSort } from '../../helpers/funciones'
import i18n from '../../lang/idiomas'
import Spinner from '../layout/Spinner'

const ExpedientesListado = props => {
  const dispatch = useDispatch()

  const expedientes = useSelector(state => state.expedientes.expedientes)
  const tipos_expedientes = useSelector(state => state.expedientes.tipos_expedientes)
  const tipo_expediente_seleccionado = useSelector(
    state => state.expedientes.tipo_expediente_seleccionado,
  )
  const expedientes_loading = useSelector(state => state.expedientes.isLoading)
  const mostrar = useSelector(state => state.expedientes.mostrar)
  const inicio = useSelector(state => state.expedientes.inicio)
  const orden = useSelector(state => state.expedientes.orden)

  const onChange = e => {
    e.preventDefault()
    dispatch(setOrdenExpedientes(e.target.value))
  }

  const changeExpPag = e => {
    e.preventDefault()
    dispatch(setMostrarExpedientes(Number(e.target.value)))
  }

  const pintarSelector = tipo => {
    if (tipo === 'orden') {
      return (
        <>
          <label
            id='label-select-orden'
            htmlFor='selector-orden'>
            {i18n.t('general.sortby')}
          </label>
          <select
            id='selector-orden'
            name='orden'
            onChange={onChange}
            defaultValue={orden}>
            <option value='num_des'>{i18n.t('general.sortbynd')}</option>
            <option value='num_asc'>{i18n.t('general.sortbyna')}</option>
            <option value='fec_asc'>{i18n.t('general.sortbyfa')}</option>
            <option value='fec_des'>{i18n.t('general.sortbyfd')}</option>
          </select>
        </>
      )
    } else {
      return (
        <>
          <label
            id='label-select-resultados'
            htmlFor='selector-resultados'>
            {i18n.t('general.mostrar')}
          </label>
          <select
            id='selector-resultados'
            name='resultados'
            onChange={changeExpPag}
            defaultValue={props.mostrar}>
            <option value='12'>12</option>
            <option value='24'>24</option>
            <option value='36'>36</option>
          </select>
        </>
      )
    }
  }

  const masExpedientes = e => {
    e.preventDefault()
    let nuevo_inicio = Number(inicio) + Number(mostrar)
    dispatch(
      mostrarMasExpedientes(
        tipos_expedientes[tipo_expediente_seleccionado],
        mostrar,
        orden,
        nuevo_inicio,
        mostrar,
      ),
    )
  }

  const devuelveOrden = () => {
    switch (orden) {
      case 'num_asc':
        return 'numero_expediente'
      case 'fec_asc':
        return 'fecha_alta'
      case 'fec_des':
        return '-fecha_alta'
      default:
        return '-numero_expediente'
    }
  }

  return (
    <Fragment>
      <div className='content-indent expedientes'>
        <div className='titulo-listado-expedientes'>
          {tipos_expedientes[tipo_expediente_seleccionado].nombre.toUpperCase()}
        </div>
        <div className='ordenar'>
          {expedientes?.length ? pintarSelector('orden') : ''}
          {expedientes?.length ? pintarSelector('resultados') : ''}
        </div>
        <div className='acciones-expedientes'>
          <button
            className='btn-add-expediente'
            onClick={e => {
              e.preventDefault()
              dispatch(setExpediente('new'))
            }}
            disabled={expedientes_loading}>
            <FilePlus size={20} />
            <span>{i18n.t('expedientes.exnuexp')}</span>
          </button>
        </div>
        <div className='listado_expedientes'>
          {expedientes.sort(dynamicSort(devuelveOrden())).map((expediente, index) => (
            <div
              key={index}
              className='col-6 col-md-4 col-lg-3 col-xl-3 expediente'>
              <div
                className='tarjeta'
                onClick={e => {
                  e.preventDefault()
                  dispatch(setExpediente(index))
                }}>
                <div className={expediente?.status !== '1000' ? 'titulo' : 'titulo cerrado'}>
                  <div className={'numero_exp'}>{expediente.numero_expediente}</div>
                  <div className={'fecha_alta'}>{expediente.fecha_alta}</div>
                </div>
                <div className='datos-expediente'>
                  <div className='datos-campos'>
                    <div className='campos'>
                      {expediente?.campos?.length > 0
                        ? expediente.campos.map((campo, index) =>
                            campo.preview === 'S' ? (
                              <div
                                key={campo.columna + '-' + index}
                                className={'campo ' + campo.columna}>
                                <div
                                  className='literal'
                                  style={
                                    campo.tipo_input === 'number'
                                      ? { width: '50%' }
                                      : { width: '100%' }
                                  }>
                                  <h6>{campo.literal}</h6>
                                </div>
                                <div
                                  className='valor'
                                  style={
                                    campo.tipo_input === 'number'
                                      ? { width: '50%' }
                                      : { width: '100%' }
                                  }>
                                  {campo.tipo_input === 'lv' ? campo.d_valor : campo.valor}
                                </div>
                              </div>
                            ) : (
                              ''
                            ),
                          )
                        : ''}
                    </div>
                  </div>
                  <div className='iconos'>
                    <div className='iconos_informativos'>
                      <div
                        className={'tiene_ficheros'}
                        hidden={expediente.tiene_ficheros !== 'S'}>
                        <Paperclip
                          size={16}
                          alt={'Tiene ficheros adjuntos'}
                        />
                      </div>
                    </div>
                    <div className='prioridad'>
                      <p>{expediente.prioridad}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Spinner showSpinner={expedientes_loading} />
        {expedientes?.length ? (
          <div className='botonera-mas'>
            {tipos_expedientes[tipo_expediente_seleccionado]?.totales &&
            tipos_expedientes[tipo_expediente_seleccionado].totales >= inicio + mostrar ? (
              <button
                onClick={masExpedientes}
                className='btn btn-border'>
                {i18n.t('expedientes.mosmasex')}
              </button>
            ) : (
              <button className='btn btn-border01'>{i18n.t('expedientes.nomasex')}</button>
            )}
          </div>
        ) : null}
      </div>
    </Fragment>
  )
}

export default ExpedientesListado
