import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFormasPago, setFormaPago } from '../../actions/formasPago'
import i18n from '../../lang/idiomas'

const FormasPago = props => {
  const dispatch = useDispatch()

  const formas_pago = useSelector(state => state.formasPago.formas_pago)
  const forma_pago = useSelector(state => state.formasPago.forma_pago)
  const pedido_cargando = useSelector(state => state.pedidos.isLoading)
  const pedido = useSelector(state => state.pedidos.pedido)
  const moneda = useSelector(state => state.precios.moneda)
  const locale = useSelector(state => state.precios.locale)

  useEffect(() => {
    dispatch(getFormasPago())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!pedido_cargando) {
      if (formas_pago?.length === 1) {
        dispatch(setFormaPago(formas_pago[0]))
      }

      if (formas_pago?.length > 1 && pedido?.forma_pago) {
        const fPago = formas_pago.filter(
          form_pag => form_pag.codigo_forma_pago_web === pedido.forma_pago,
        )

        dispatch(setFormaPago(fPago[0]))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formas_pago)])

  const onchange = e => {
    if (e.target.value !== '') {
      const fPago = formas_pago.filter(
        form_pag => form_pag.codigo_forma_pago_web === e.target.value,
      )
      dispatch(setFormaPago(fPago[0]))
    }
  }

  return (
    <Fragment>
      {formas_pago && formas_pago.length > 0 ? (
        <div className='tt-shopcart-box'>
          <h4 className='tt-title'>{i18n.t('formas.forspag')}</h4>
          <p>{i18n.t('formas.selfpagl')}</p>
          <form className='form-default'>
            <div className='form-group'>
              <label htmlFor='forma_envio'>
                {i18n.t('formas.forpamay')} <sup>*</sup>
              </label>
              <select
                className='form-control'
                value={forma_pago.codigo_forma_pago_web}
                name='forma_pago'
                onChange={onchange}
                disabled={pedido_cargando || props.pedidoGuardado}>
                <option
                  key='0'
                  value=''>
                  {i18n.t('formas.selfpa')}
                </option>
                {formas_pago.map((form_pago, index) => {
                  return (
                    <option
                      key={index + 'forma_pago'}
                      value={form_pago.codigo_forma_pago_web}>
                      {form_pago.descripcion_forma_pago}
                    </option>
                  )
                })}
              </select>
              {forma_pago && forma_pago.datos_plantilla ? (
                <div
                  style={{ marginTop: '1em' }}
                  className='detalles-tramos-plantillas'>
                  <span className='detalles-tramos-plantillas-header'>
                    {i18n.t('plantillas.ptrafp')} {forma_pago.descripcion_forma_pago}
                  </span>
                  <ul>
                    {forma_pago.datos_plantilla.map(datos_plantilla => {
                      return (
                        <li className='detalles-tramos-plantilla-elemento'>
                          <b>{i18n.t('plantillas.phaimprt')} </b>{' '}
                          {new Intl.NumberFormat(locale, {
                            style: 'currency',
                            currency: moneda,
                          }).format(datos_plantilla.hasta_importe)}
                          &emsp;
                          <b>{i18n.t('plantillas.pdto')}</b>
                          {datos_plantilla.dto_abono}%
                        </li>
                      )
                    })}
                  </ul>
                </div>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
      ) : (
        ''
      )}
    </Fragment>
  )
}

export default FormasPago
