export function hasSameParent(element, className, id) {
  if (!element) {
    return false
  }
  if (element?.className === className && Number(element?.id) === id) {
    return true
  }

  return hasSameParent(element?.parentNode, className, id)
}
